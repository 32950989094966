export const getMarketFromProcess = () => {
  const market = import.meta.env.MARKET;
  return market ? market.toUpperCase() : 'SE';
};

// TODO: Convert this to localStorage help function. Name state doesn't excist // Jonas
const state = JSON.parse(window.localStorage.getItem('state'));
// const cachedMarket // TODO. cache resault for performence

// Get's the current market from state if loggedin eler from host
// Usage: const MARKET = getMarket();
export const getMarket = () => {
  const market = (
    state && state.auth.User.market
  ) || (
    getMarketFromProcess()
  );

  return market;
};

export default getMarket;
