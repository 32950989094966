import React from 'react';
import PropTypes from 'prop-types';
import Button, { ButtonWrap } from '@hubins/components/Button';
import Wrapper, { WrapperContent } from '@hubins/components/Wrapper';
import Heading from 'components/Heading';
import Main from 'components/Main';

export class Success extends React.PureComponent {
  render() {
    return (
      <Main>
        <Wrapper narrow illustration={this.props.illustrationOffset} className="anim-fade-in">
          <img className={`anim-default-reveal illustration center ${this.props.illustrationOffset ? 'illustration--offset' : ''}`}
            src={this.props.illustration}
            alt={this.props.illustrationAlt || this.props.title}
            widht="300"
            height="300"
          />
          <WrapperContent>
            <Heading element="h1" size="2">
              {this.props.title}
            </Heading>
            <div className="body-big p">{this.props.ingress}</div>
          </WrapperContent>
          <ButtonWrap center>
            {this.props.button && (
              <Button href={this.props.backurl} className="cta">{this.props.button}</Button>
            )}
            {this.props.secondaryButton && (
              <Button href={this.props.secondaryBackurl}>{this.props.secondaryButton}</Button>
            )}
          </ButtonWrap>
        </Wrapper>
      </Main>
    );
  }
}

Success.propTypes = {
  title: PropTypes.string,
  ingress: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  illustration: PropTypes.string,
  illustrationAlt: PropTypes.string,
  backurl: PropTypes.string,
  button: PropTypes.string,
  illustrationOffset: PropTypes.bool,
};

Success.defaultProps = {
  illustration: '/illustrations/registration-finished.svg',
  illustrationAlt: 'Success illustration',
  backurl: '/',
  illustrationOffset: false,
};


export default Success;
