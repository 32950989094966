import React from 'react';
import Imgix from 'react-imgix';
import  ErrorBoundary  from "components/ErrorBoundary";

import './index.scss';

const Thumbnail = ({ fillSize = 120, ...props }) => {
  const { src, alt, ...mods } = props;
  let modifiers = Object.keys(mods).map(prop => ' thumbnail--' + prop).join('');

  return (
    src ? (
      // Make sure not update react-imgix couse they removed the background prop - https://github.com/imgix/react-imgix/issues/160
      <ErrorBoundary>
        <Imgix className={`thumbnail ${modifiers}`} type="bg" src={src}
          width={props.fill ? fillSize * window.devicePixelRatio : undefined}
          height={props.fill ? fillSize * window.devicePixelRatio : undefined}
          imgProps={{
            alt: alt
          }}
          imgixParams={{ ar: "1:1", fit: "crop" }}
        />
      </ErrorBoundary>
    ) : (
      <div className={`thumbnail ${modifiers}`}>
      </div>
    )
  );
};

export default Thumbnail;
