import React, { useEffect, useState } from 'react';
import ChartPie from 'components/Charts/ChartPie';
import { Colors } from 'settings';
import './DepositAndWithdrawalChart.scss';

const COLORS = [Colors.primary, Colors.secondary, Colors.accent, Colors.chartlineCombined];

const DepositAndWithdrawalChart = ({ data }) => {

  const [cells, setCells] = useState([]);

  useEffect(() => {
    setCells(data);
  }, []);

  return (
    <div className="daw-dropdown">
      <ChartPie
        cells={cells}
        // animation={false}
        colors={
          COLORS
        }
        valueType="percent"
      />
      <div className="daw-dropdown__pins">
        {data.length > 0 && data.map((entry, index) => (
          <span key={index} className="daw-dropdown__pin">
            <svg viewBox="0 0 100 100" height="15px" width="15px" xmlns="http://www.w3.org/2000/svg">
              <circle fill={COLORS[index % COLORS.length]} cx="50" cy="50" r="60" />
            </svg>
            <p>
              &nbsp;
              {entry.name}
            </p>
          </span>
        ))}
      </div>
    </div>
  );
};

export default DepositAndWithdrawalChart;
