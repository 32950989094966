

export const LocalContainer = ({ method, string, vars, number, market, language, languageObjects, fallbackLanguage, commonObjects }) => {
  // Good for debug to see what text hasn't been translated using __:
  // if (string) return;

  let localisationObject = languageObjects[language];
  let commonObject = commonObjects[language];

  // fallback
  if (!localisationObject) localisationObject = fallbackLanguage; // is this needed?

  return method({ string, vars, localisationObject, commonObject, market, number });
};

export default LocalContainer;
