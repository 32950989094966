import React, { useState, useEffect } from 'react';
import NotificationBanner from '@hubins/components/NotificationBanner';
import middleman from 'helpers/middleman';
import './index.scss';

const getBannerIconByType = (type) => {
  switch (type) {
    case 'info':
      return 'alert circle';
    case 'warning':
      return 'Sign Warning';
    default:
      return '';
  }
}

const NotificationBanners = () => {
  const [banners, setBanners] = useState<{text: string, icon: string}[]>([]);

  const closeBanner = (index) => {
    setBanners(banners.filter((_, i) => i !== index));
  }

  const fetchBanners = async () => {
    try {
      const res = await middleman.promiseGet(`/platform-alerts`);
      const newBanners = res.data.map(banner => ({
        text: banner.text,
        icon: getBannerIconByType(banner.type)
        }));
      setBanners(newBanners);
    } catch (e) {
      console.error(`Error fetching banners: ${e}`);
    }
  }

  useEffect(() => {
    fetchBanners();
  }, [])

  return (
    <div className='notification-banners'>
      {banners.map((banner, index) => (
        <NotificationBanner
          key={index}
          text={banner.text}
          icon={banner.icon}
          callback={() => closeBanner(index)}
        />
      ))}
    </div>
  )
};

export default NotificationBanners;