import React from 'react';
import './index.scss';
import LoadingBox from './LoadingBox';


const LoadingBar = (props) => {
  let modifier = Object.keys(props).map(prop => 'loading-bar--' + prop).join(' ');

  return (
    <span className={`loading-bar ${modifier}`}></span>
  );
};

const LoadingInput = () => {
  return (
    <LoadingBar input></LoadingBar>
  );
};

const LoadingDots = ({ text }) => (
  <span>
    {text} <span className="anim-dot">.</span>
    <span className="anim-dot">.</span>
    <span className="anim-dot">.</span>
  </span>
);

export { LoadingBar, LoadingInput, LoadingBox, LoadingDots };

export default LoadingBar;
