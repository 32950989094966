import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import Button from '@hubins/components/Button';
import Icon from '@hubins/components/IconNew';
import CaseVideo from 'components/CaseVideo';
import Imgix from 'react-imgix';
import './index.scss';
import __ from 'localisation';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class CaseSlider extends Component {
  constructor(props) {
    super(props);
    this.renderImages = this.renderImages.bind(this);
  }

  renderImages() {
    let images = this.props.slides.map((image, index) => {
      switch (image.type) {
        case 'image':
          if (!image.value) {
            console.error('CaseSlider: image src is missing');
            return;
          }
          return (
            <div key={index}>
              <Imgix
                src={image.value}
                fit="clip" sizes="100vw"   imgProps={{
                  alt: image.text || 'slider image',
                }}
              />
            </div>
          );
        case 'video':
          return (
            <div key={index}>
              <CaseVideo value={image.value} />
            </div>
          );
        default:
          return;
      }
    });
    return images;
  }

  //Prevent images from being draggable so that you can drag and slide over the images

  componentDidMount() {
    setTimeout(() => { window.dispatchEvent(new Event('resize')); }, 500);
    document.querySelectorAll('.not-draggable').forEach((elm) => {
      elm.ondragstart = () => (false);
    });
  }

  render() {

    return (
      <div>
        <div className="case-slider" >
          <Slider
            arrows={true}
            dots={true}
            infinite={true}
            speed={500}
            slideToShow={1}
            slideToScroll={1}
            responsive= {[{ breakpoint: 698, settings: { arrows: false, swipeToSlide: true }}]}
            nextArrow={<Button className="arrow" onlyIcon><Icon icon="arrow-right" size={16} label={__('next')} /></Button>}
            prevArrow={<Button className="arrow" onlyIcon><Icon icon="arrow-left" size={16} label={__('previous')} /></Button>}
          >
            {this.renderImages()}
          </Slider>
        </div>
      </div>
    );
  }

}

CaseSlider.defaultProps = {
  slides: []
};

CaseSlider.propTypes = {
  /**
  Ex image object:
  {
    type: 'image',
    value: '/assets/placeholder/image-1920x1080-1.jpg',
    text: 'Placeholder image alt text',
  }

  Ex video object:
  {
    type: 'video',
    value: 'https://vimeo.com/87110435'
  }
  */
  slides: PropTypes.array
};

export default CaseSlider;
