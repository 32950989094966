import React, { useEffect, useState } from 'react';
import { usePortfolios } from 'queries/portfolios';
import { __route } from 'localisation';
import {
  firstDateThisYear,
  firstDateOfYear,
  formatDate,
} from 'helpers';

interface ContextValues {
  startDate: string;
  setStartDate: (date: string) => void;
  endDate: string;
  setEndDate: (date: string) => void;
  portfolioUuid: string;
  setPortfolioUuid: (uuid: string) => void;
  portfolios: any[];
  portfolio: any;
  initiate: () => void;
  status: string;
  ready: boolean;
  datePickerCallback: (startDate: string, endDate: string) => void;
  noPortfolios: boolean;
  reset: () => void;
  refetch: () => void;
}

const initState: ContextValues = {
  startDate: '',
  setStartDate: () => { },
  endDate: '',
  setEndDate: () => { },
  portfolioUuid: '',
  setPortfolioUuid: () => { },
  portfolios: [],
  portfolio: false,
  initiate: () => { },
  status: 'idle',
  ready: false,
  datePickerCallback: () => { },
  noPortfolios: false,
  reset: () => { },
  refetch: () => { },
}

const Context = React.createContext(initState);

const ReportContext = ({ children, national_identity_number }) => {

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [portfolioUuid, setPortfolioUuid] = useState('');
  const [status, setStatus] = useState('idle'); // idle, loading, success, error
  const [ready, setReady] = useState(false);

  const { data, status: fetchStatus, refetch } = usePortfolios(national_identity_number);

  const initiate = () => {
    refetch();
  }

  const reset = () => {
    setReady(false);
    setPortfolioUuid('');
    setStartDate('');
    setEndDate('');
  }

  const datePickerCallback = (newStartDate, newEndDate) => {

    if (newStartDate instanceof Date) {
      newStartDate = formatDate(newStartDate.toISOString());
    }

    if (newEndDate instanceof Date) {
      newEndDate = formatDate(newEndDate.toISOString());
    }

    setStartDate(newStartDate);
    setEndDate(newEndDate);
  }

  useEffect(() => {
    setStatus(fetchStatus);
    if (fetchStatus === 'success') {
      if (data.length > 0) {
        let initialPortfolio = data[0];
        if (portfolioUuid) {
          const foundSelectedPortfolio = data.find(item => item.uuid === portfolioUuid)
          if (foundSelectedPortfolio) {
            initialPortfolio = foundSelectedPortfolio;
          }
        }
        setPortfolioUuid(initialPortfolio.uuid);
        if (initialPortfolio.start_date) {
          setStartDate(firstDateOfYear(initialPortfolio.start_date));
        } else {
          setStartDate(formatDate(firstDateThisYear().toISOString()));
        }
        setEndDate(formatDate(new Date().toISOString()));
      }
      setReady(true);
    }
  }, [fetchStatus, data]);

  useEffect(() => {
    if (national_identity_number) {
      initiate();
    } else {
      reset();
    }
  }, [national_identity_number]);

  const portfolio = (ready && portfolioUuid && data && Array.isArray(data)) ? data.find(p => p.uuid === portfolioUuid) : false;

  const contextValues: ContextValues = {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    portfolioUuid,
    setPortfolioUuid,
    portfolios: data,
    portfolio,
    initiate,
    status,
    ready: ready && Array.isArray(data),
    datePickerCallback,
    noPortfolios: ready && Array.isArray(data) && data.length === 0,
    reset,
    refetch,
  };

  return (
    <Context.Provider value={contextValues}>
      {children}
    </Context.Provider>
  )
};

export const useReportContext = () => {
  const result = React.useContext(Context);
  return result;
};

export default ReportContext;