import React from 'react';
import { motion } from 'framer-motion';

import './HubinsAnim.scss';

const lineAnimData = {
  strokeDasharray: "1 1.1",
  pathLength: 1,
  pathOffset: [-1, -0.5, 0, 0.5, 1],
  opacity: [0, 1, 1, 1, 0],
  transition: {
    duration: 4,
    ease: "linear",
    repeat: Infinity,
  },
};

const lineAnim = {
  waiting: lineAnimData,
  hidden: { pathLength: 1, pathOffset: 0, opacity: 0, strokeDasharray: "1 1.1" },
  visible: { pathLength: 1, pathOffset: 0, opacity: 0 },
  success: { pathLength: 1, opacity: 0, transition: { duration: 1 }},
};

const lineAnimCcw = {
  waiting: {
    ...lineAnimData,
    pathOffset: [1, 0.5, 0, -0.5, -1],
  },
  hidden: { pathLength: 1, pathOffset: 0, opacity: 0, strokeDasharray: "1 1.1" },
  visible: { pathLength: 1, pathOffset: 0, opacity: 0 },
  success: { pathLength: 1, opacity: 0, transition: { duration: 1 }},
};

const fpBoxT = {
  duration: 4,
  times: [0, 0.06, 0.12, 0.55, 0.7, 1],
  ease: ["linear", "easeIn", "easeOut", "easeOut", "easeIn"],
  repeat: Infinity,
};

const fpLogo = {
  waiting: {
    scale: 1,
    opacity: 1,
    rotate: [0, 0, 0, 180, 180, 180],
    transition: {
      rotate: {
        ...fpBoxT,
        ease: ["linear", "linear", "anticipate", "linear", "linear"],
      }
    },
  },
  hidden: {
    scale: 2.5,
    opacity: 0,
    rotate: 90,
  },
  visible: {
    scale: 2.5,
    opacity: 1,
    rotate: 180,
    transition: { ease: "backOut", duration: 1 },
  },
  success: {
    rotate: [180, 360],
    scale: [1, 4],
    times: [0, 1],
    transition: { ease: "anticipate", duration: 1 },
  }
};

const fpDown = {
  waiting: {
    y: [0, 0, -10, -10, 0, 0],
    scaleX: [1, 1, 1.1, 1.1, 1, 1], // Anti Aliasing fix
    rx: [2, 2, 0, 0, 2, 2],
    transition: fpBoxT,
  },
  hidden: { y: -10 },
  visible: { y: 0, transition: { delay: 0.4 }},
  success: {
    y: [-10, -10, 0],
    transition: { times: [0, 0.9, 1], ease: "easeOut", duration: 0.5, },
  },
};

const fpUp = {
  waiting: {
    y: [0, 0, 10, 10, 0, 0],
    scaleX: [1, 1, 1.1, 1.1, 1, 1], // Anti Aliasing fix
    rx: [2, 2, 0, 0, 2, 2],
    transition: fpBoxT,
  },
  hidden: { y: 10 },
  visible: { y: 0, transition: { delay: 0.35 }},
  success: {
    y: [10, 10, 0],
    transition: { times: [0, 0.9, 1], ease: "easeOut", duration: 0.5, }
  },
};


const qlLogo = {
  waiting: {
    scale: 1,
    opacity: 1,
    rotate: [0, 360],
    transition: {
      rotate: {
        duration: 4,
        ease: "anticipate",
        repeat: Infinity,
      }
    },
  },
  hidden: {
    scale: 2,
    opacity: 0,
    rotate: 90,
  },
  visible: {
    scale: 2.5,
    opacity: 1,
    rotate: 360,
    transition: { ease: "backOut", duration: 1 },
  },
  success: {
    rotate: [0, 0],
    scale: [1, 3],
    opacity: [1, 0],
    transition: { ease: "anticipate", duration: 1 },
  }
};

const Anim = ({
  variants,
  animateState,
  platform = 'Hubins' }) => {
  return (
    <motion.svg variants={variants} animate={animateState} initial="hidden" xmlns="http://www.w3.org/2000/svg" width="320" height="320" viewBox="0 0 320 320" className="hubinsanim">
      {/* <g className="hubins-logo">
        <motion.rect animate={fpDown} className="down fp-fill" x="153" y="184.24" width="15" height="9.34" rx="1.04" />
        <motion.rect animate={fpDown} className="up fp-fill" x="153" y="125.5" width="15" height="10" rx="1.04" />
        <path className="body fp-fill" d="M153.06,183.2V176a1,1,0,0,1,1-1h12.63a1,1,0,0,1,1,1v7.23a1,1,0,0,0,1,1h15a1,1,0,0,0,1-1V136.25a1,1,0,0,0-1-1h-15a1,1,0,0,0-1,1v7.24a1,1,0,0,1-1,1H154.1a1,1,0,0,1-1-1v-7.24a1,1,0,0,0-1-1H136.87a1,1,0,0,0-1.05,1V183.2a1,1,0,0,0,1.05,1H152A1,1,0,0,0,153.06,183.2Z" />
      </g> */}
      {platform === 'Hubins' && (
        <motion.g variants={fpLogo} className="hubinsanim__logo">
          <motion.rect variants={fpDown} className="down fp-fill" x="153" y="185" width="15" height="10" rx="2"/>
          <motion.rect variants={fpUp} className="up fp-fill" x="153" y="125" width="15" height="10" rx="2"/>
          <path className="body fp-fill" d="M183 135h-13a2 2 0 00-2 2v6a2 2 0 01-2 2h-11a2 2 0 01-2-2v-6a2 2 0 00-2-2h-13a2 2 0 00-2 2v46a2 2 0 002 2h13a2 2 0 002-2v-6a2 2 0 012-2h11a2 2 0 012 2v6a2 2 0 002 2h13a2 2 0 002-2v-46a2 2 0 00-2-2z"/>
          {/* <motion.path className="maskbottom" class="cls-3" d="M151 135h19v12h-19z"/> */}
          {/* <motion.path className="masktop" class="cls-3" d="M151 173h19v12h-19z"/> */}

          {/* <motion.path variants={fpDown} className="down fp-fill" d="M167 184h-13l-1 1v7l1 1h13l1-1v-7l-1-1z" />
          <motion.path variants={fpUp} className="up fp-fill" d="M167 126h-13l-1 1v7l1 1h13l1-1v-7l-1-1z" />
          <path className="body fp-fill" d="M153 183v-7l1-1h13l1 1v7l1 1h15l1-1v-47l-1-1h-15l-1 1v7l-1 1h-13l-1-1v-7l-1-1h-15l-1 1v47l1 1h15l1-1z" /> */}
        </motion.g>
      )}
      {platform === 'QL' && (
        <motion.g variants={qlLogo} className="hubinsanim__logo">
          <path className="fp-fill" d="M182.88 144.09l-20.76-12a4.28 4.28 0 00-4.24 0l-20.76 12a4.24 4.24 0 00-2.12 3.67v24a4.23 4.23 0 002.12 3.66l22.4 12.94a1 1 0 00.48.12 1 1 0 00.48-1.79l-22.4-12.93a2.34 2.34 0 01-1.16-2v-24a2.31 2.31 0 011.16-2l20.77-12a2.26 2.26 0 012.3 0l20.77 12a2.31 2.31 0 011.16 2v24a2.34 2.34 0 01-1.16 2l-9.83 5.67-10.16-5.87h.08l8.88-5.13a4 4 0 002-3.48v-10.31a4 4 0 00-2-3.49L162 146a4 4 0 00-4 0l-8.88 5.12a4 4 0 00-2 3.49v10.25a4 4 0 002 3.48l8.88 5.16.19.09 13.43 7.76a.92.92 0 001 0l10.31-5.95a4.23 4.23 0 002.12-3.66v-24a4.24 4.24 0 00-2.17-3.65zM149 164.89v-10.25a2.12 2.12 0 011-1.82l8.88-5.13a2.11 2.11 0 012.1 0l8.88 5.13a2.12 2.12 0 011 1.82v10.25a2.14 2.14 0 01-1 1.82l-8.88 5.12a2.11 2.11 0 01-1.91.1l-7.75-4.48a.53.53 0 00-.17-.08l-1.15-.66a2.14 2.14 0 01-1-1.82z"/>
          <path className="fp-fill" d="M166.52 183.18l-22.5-13a2.12 2.12 0 01-1.05-1.82v-17.21a2.15 2.15 0 011.05-1.83l14.98-8.61a2.08 2.08 0 012.1 0l14.9 8.61a2.15 2.15 0 011.05 1.83v17.23a2.12 2.12 0 01-1.05 1.82l-5.47 3.16a1 1 0 001 1.66l5.47-3.16a4 4 0 002-3.48v-17.23a4 4 0 00-2-3.49L162 139a4.06 4.06 0 00-4 0l-14.93 8.62a4 4 0 00-2 3.49v17.23a4 4 0 002 3.48l22.5 13a.91.91 0 00.48.13 1 1 0 00.48-1.8z"/>
        </motion.g>
      )}
      <motion.path initial={lineAnim.hidden} variants={lineAnim} className="fp-line" d="M222 51a89 89 0 0127 65v73" />
      <motion.path initial={lineAnim.hidden} variants={lineAnim} className="fp-line" d="M73 98a89 89 0 0187-72 89 89 0 0146 13" />
      <motion.path initial={lineAnim.hidden} variants={lineAnim} className="fp-line" d="M71 204v-88" />
      <motion.path initial={lineAnim.hidden} variants={lineAnim} className="fp-line" d="M249 204a90 90 0 01-1 15" />
      <motion.path initial={lineAnim.hidden} variants={lineAnim} className="fp-line" d="M127 45a77 77 0 0133-7 77 77 0 0177 77v20" />
      <motion.path initial={lineAnim.hidden} variants={lineAnim} className="fp-line" d="M94 146v-30a67 67 0 0166-67" />
      <motion.path initial={lineAnim.hidden} variants={lineAnim} className="fp-line" d="M111 91a55 55 0 0149-31 55 55 0 0155 56v7" />
      <motion.path initial={lineAnim.hidden} variants={lineAnim} className="fp-line" d="M216 138v55" />
      <motion.path initial={lineAnim.hidden} variants={lineAnim} className="fp-line" d="M115 174v-57a45 45 0 0132-43" />
      <motion.path initial={lineAnim.hidden} variants={lineAnim} className="fp-line" d="M160 72a45 45 0 0146 45v37" />
      <motion.path initial={lineAnim.hidden} variants={lineAnim} className="fp-line" d="M194 141v60" />
      <motion.path initial={lineAnimCcw.hidden} variants={lineAnimCcw} className="fp-line" d="M182 126v-10a22 22 0 00-22-22 22 22 0 00-22 22" />
      <motion.path initial={lineAnim.hidden} variants={lineAnim} className="fp-line" d="M149 117a11 11 0 0111-11 11 11 0 0112 11" />
      <motion.path initial={lineAnim.hidden} variants={lineAnim} className="fp-line" d="M237 167v46a152 152 0 01-7 46" />
      <motion.path initial={lineAnim.hidden} variants={lineAnim} className="fp-line" d="M182 53a67 67 0 0145 63v97a152 152 0 01-7 46l-5 16" />
      <motion.path initial={lineAnim.hidden} variants={lineAnim} className="fp-line" d="M215 206v7a152 152 0 01-7 46l-7 24" />
      <motion.path initial={lineAnim.hidden} variants={lineAnim} className="fp-line" d="M199 259l-10 29" />
      <motion.path initial={lineAnim.hidden} variants={lineAnim} className="fp-line" d="M206 171v42a153 153 0 01-5 37" />
      <motion.path initial={lineAnim.hidden} variants={lineAnim} className="fp-line" d="M194 213a152 152 0 01-8 46l-10 33" />
      <motion.path initial={lineAnimCcw.hidden} variants={lineAnimCcw} className="fp-line" d="M180 239a154 154 0 01-5 20l-11 34" />
      <motion.path initial={lineAnimCcw.hidden} variants={lineAnimCcw} className="fp-line" d="M182 192v21a153 153 0 010 16" />
      <motion.path initial={lineAnimCcw.hidden} variants={lineAnimCcw} className="fp-line" d="M159 277l-5 16" />
      <motion.path initial={lineAnimCcw.hidden} variants={lineAnimCcw} className="fp-line" d="M172 202v11a152 152 0 01-8 46" />
      <motion.path initial={lineAnimCcw.hidden} variants={lineAnimCcw} className="fp-line" d="M157 244a153 153 0 01-4 15l-10 32" />
      <motion.path initial={lineAnimCcw.hidden} variants={lineAnimCcw} className="fp-line" d="M160 213v12" />
      <motion.path initial={lineAnimCcw.hidden} variants={lineAnimCcw} className="fp-line" d="M142 259l-10 30" />
      <motion.path initial={lineAnimCcw.hidden} variants={lineAnimCcw} className="fp-line" d="M149 198v15a152 152 0 01-3 31" />
      <motion.path initial={lineAnimCcw.hidden} variants={lineAnimCcw} className="fp-line" d="M126 277l-3 8" />
      <motion.path initial={lineAnimCcw.hidden} variants={lineAnimCcw} className="fp-line" d="M138 192v21a152 152 0 01-7 46l-2 7" />
      <motion.path initial={lineAnimCcw.hidden} variants={lineAnimCcw} className="fp-line" d="M126 227a152 152 0 01-6 32l-7 21" />
      <motion.path initial={lineAnim.hidden} variants={lineAnim} className="fp-line" d="M127 213v-97a33 33 0 0133-33 33 33 0 0134 33" />
      <motion.path initial={lineAnimCcw.hidden} variants={lineAnimCcw} className="fp-line" d="M115 189v24a152 152 0 01-7 46l-5 14" />
      <motion.path initial={lineAnimCcw.hidden} variants={lineAnimCcw} className="fp-line" d="M105 224a152 152 0 01-7 35l-2 7" />
      <motion.path initial={lineAnim.hidden} variants={lineAnim} className="fp-line" d="M105 213v-97" />
      <motion.path initial={lineAnimCcw.hidden} variants={lineAnimCcw} className="fp-line" d="M91 242a130 130 0 01-3 14" />
      <motion.path initial={lineAnim.hidden} variants={lineAnim} className="fp-line" d="M94 216v-49" />
      <motion.path initial={lineAnim.hidden} variants={lineAnim} className="fp-line" d="M81 244a51 51 0 002-15V115a77 77 0 0130-61" />
    </motion.svg>
  );
};

export default Anim;
