export default {
  'select': {
    'excludeOptions': (field, params) => {
      const keysToExcludeIfTrue = Object.keys(params);
      field.options = field.options.filter((option) => {
        const { id } = option;
        if (keysToExcludeIfTrue.includes(id)) {
          return !params[id];
        }
        return true;
      });
      return field;
    },
    'disableOptions': (field, params) => {
      const keysToDisableIfTrue = Object.keys(params);
      field.options = field.options.map((option) => {

        const { id } = option;
        if (keysToDisableIfTrue.includes(id)) {
          option.disabled = params[id];
        }

        return option;
      });
      return field;
    }
  },
  'input': {
    'lockedIfTrue': (field, params) => {
      field.locked = params ? true : false;
      return field;
    }
  }
};
