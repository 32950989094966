import React, { useCallback } from 'react';
import './index.scss';
import __ from 'localisation';
import RangeDatePicker from 'modules/RangeDatePicker';
import WithHoldingType from './withHoldingType';
import PortfolioBalanceList from '../Portfolios/PortfolioBalanceList';
import LoadingBar from 'components/LoadingIndicators';
import AccountsTable from 'modules/Tables/AccountsTable';
import Heading from 'components/Heading';
import { Columns, Column } from 'components/Columns';
import { LoadingBox } from 'components/LoadingIndicators';
import { formatDate } from 'helpers';
// test
const Holdings = ({
  holdings,
  balance,
  crm,
  tools,
  from,
  to,
  callback,
  withRangePicker,
  loading,
  currency,
  accounts,
  report,
}) => {

  const getNewData = (newFrom, newTo) => {
    // Format "from" and "to" to date object if they are strings,
    // and fetch new data
    if (!(newFrom instanceof Date)) {
      newFrom = new Date(newFrom);
      newTo = new Date(newTo);
    }
    callback(formatDate(newFrom), formatDate(newTo));
  };

  const getSharedProps = useCallback(() => {
    return {
      crm,
      holdings
    };
  }, [holdings, crm]);

  return (
    <>
      <Columns>
        {withRangePicker && (
          <Column s="12" xl="6">
            {from ? (
              <RangeDatePicker
                from={from}
                to={to}
                onClick={getNewData}
                loading={loading}
              />
            ) : (
              <LoadingBar />
            )}
          </Column>
        )}
        {tools && (
          <>
            <Column s="12" xl="6">
              <PortfolioBalanceList
                balance={balance}
              />
            </Column>
            <Column s="12" xl="6">
              <Heading size="4" className="section-padding">
                {__('liquid_funds')}
              </Heading>
              <AccountsTable
                currency={currency}
                isLoading={false}
                accounts={accounts}
              />
            </Column>
          </>
        )}

      </Columns>
      {loading ? (
        <LoadingBox text={__('status_fetching_holdings')} white />
      ) : (
        <>
          <WithHoldingType key="PE Fund" {...getSharedProps()} type='PE Fund' report={report} />
          <WithHoldingType key="PROMNOTE" {...getSharedProps()} type='PROMNOTE' report={report} />
          <WithHoldingType key="BOND" {...getSharedProps()} type='BOND' report={report} />
          <WithHoldingType key="UNLISTSTOCK" {...getSharedProps()} type='STOCK' subType={['UNLIST', null]} report={report} />
          <WithHoldingType key="STOCK" {...getSharedProps()} type='STOCK' subType={['LIST']} title={__('listedstock')} report={report} />
          <WithHoldingType key="LISTEDSTOCK" {...getSharedProps()} type='LISTEDSTOCK' report={report} />
          <WithHoldingType key="FUND" {...getSharedProps()} type='FUND' report={report} />
          <WithHoldingType key="STRUCTURED" {...getSharedProps()} type='STRUCTURED' report={report} />
          <WithHoldingType key="CRYPTOCURRENCY" {...getSharedProps()} type='CRYPTOCURRENCY' report={report} />
          <WithHoldingType key="OPTION" {...getSharedProps()} type='OPTION' report={report} />
          <WithHoldingType
            title={__('other')}
            key="OTHER"
            {...getSharedProps()}
            type='*'
            exclude={
              [
                'PROMNOTE',
                'BOND',
                'STOCK',
                'LISTEDSTOCK',
                'FUND',
                'STRUCTURED',
                'CRYPTOCURRENCY',
                'OPTION',
                'CASH',
                'CURRENCY',
                "PE Fund"
              ]}
            report={report}
          />
        </>
      )}
    </>
  );
};

Holdings.defaultProps = {
  userHoldings: false,
};

export default Holdings;
