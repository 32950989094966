import React, {useEffect, useState} from 'react';
import middleman from 'helpers/middleman';
import Heading from 'components/Heading';
import ListButton from '../ListButton';
import __, { __route } from 'localisation';


type UnfinishedRegistrationsType = {
  profileUuid: string;
  portfolioUuid: string;
  name: string;
  nationalIdentityNumber: string;
  type: string;
}

type UnfinishedPowerOfAttorneyType = {
  profileUuid: string;
  name: string;
  nationalIdentityNumber: string;
}


const UnfinishedRegistrationsList = () => {

  const [unfinishedRegistrations, setUnfinishedRegistrations] = useState<UnfinishedRegistrationsType[]>([]);
  const [unfinishedPowerOfAttorney, setUnfinishedPowerOfAttorney] = useState<UnfinishedPowerOfAttorneyType[]>([]);

  const fetchUnfinishedRegistrations = async () => {
    try {
      const res = await middleman.get(`/unfinished-registrations`);
      setUnfinishedRegistrations(res?.data?.registrations || []);
      setUnfinishedPowerOfAttorney(res?.data?.proxyRequests || []);
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    fetchUnfinishedRegistrations();
  }, []);

  return (
    <>
      {unfinishedRegistrations && unfinishedRegistrations.length > 0 && (
        <>
      <Heading size="4" className="s-bottom-m">{__('started_registration')}</Heading>
      <div className='flex'>
        {unfinishedRegistrations.map((item: UnfinishedRegistrationsType) => (
          <ListButton
            key={item.profileUuid}
            href={`${__route("register_summary")}/${item.profileUuid}/${item.portfolioUuid}`}
            title={item.name}
            text={`${item.type === 'COMPANY' ? 'Organisationsnummer' : 'Personnummer'}: ${item.nationalIdentityNumber}`}
            icon={item.type === 'COMPANY' ? 'Briefcase 2' : 'Single Neutral Actions Add by Streamlinehq'}
          />
        ))}
      </div>
      </>
      )}
      {unfinishedPowerOfAttorney && unfinishedPowerOfAttorney.length > 0 && (
        <>
          <Heading size="4" className="s-bottom-m">{__('personnel_registration')}</Heading>
          <div className='flex'>
            {unfinishedPowerOfAttorney.map((item: UnfinishedPowerOfAttorneyType) => (
              <ListButton
                key={item.profileUuid}
                icon="Common File Text by Streamlinehq"
                href={`${__route("personnel_summary")}/${item.profileUuid}`}
                title={item.name}
                text={`Organisationsnummer: ${item.nationalIdentityNumber}`}
              />
            ))}
          </div>
        </>
      )}
    </>
  );
};

export default UnfinishedRegistrationsList;