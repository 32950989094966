import React, { Component, Fragment } from 'react';

// import { authOperations } from 'state/ducks/auth';
import __ from 'localisation';
import Button from '@hubins/components/Button';
import Flag from 'components/Flag';
import { getItem } from 'helpers';

const languageSwitch = {
  "SE": [
    {
      flag: "SE",
      title: __('lang_SE')
    },
    {
      flag: "EN",
      title: __('lang_EN')
    },
  ],
  "FI": [
    {
      flag: "FI",
      title: __('lang_FI')
    },
    {
      flag: "SE",
      title: __('lang_SE')
    },
    {
      flag: "EN",
      title: __('lang_EN')
    },
  ],
  "NO": [
    {
      flag: "NO",
      title: __('lang_NO')
    },
    {
      flag: "EN",
      title: __('lang_EN')
    },
  ]
};

class LanguageMenu extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selected: getItem('language')
    };
  }

  changeLanguage = (language) => {
    this.props.switchLanguage(language);
    this.setState({
      selected: language
    });
  };

  renderLanguages = () => {
    const selectedMarket = languageSwitch[this.props.market];
    return selectedMarket.map((item, index) => (
      <Button key={index}
        type="button"
        noStyle
        className="menu__link menu__link--language flag"
        onClick={() => this.changeLanguage(item.flag)}
        onFocus={this.props.onFocus}
        onBlur={this.props.onBlur}
      >
        <span className={`menu__label ${this.state.selected === item.flag && 'selected'}`}>
          {item.title}
        </span>
        <Flag flag={item.flag} />
      </Button>
    ));
  };

  render() {
    if (!VITE_LANG_SUPPORT) return false;

    return (
      <Fragment>
        <p className="menu__link">
          <strong className="menu__label">
            {__('change_lang')}
          </strong>
        </p>
        {this.renderLanguages()}
      </Fragment>
    );
  }

}
export default LanguageMenu;
