import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill(); // polyfill for .scrollIntoView options / scroll behavior
import React, { useEffect, useState } from 'react';
import Routes from './routes';
import 'globalstyles/helpclasses.scss';
import 'globalstyles/normalize.scss';
import 'globalstyles/main.scss';
import './styles/index.scss';
import { Provider } from 'react-redux';

import axios from 'axios';
import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react';

import UiProvider from 'components/UiContext';
import Sidebar from 'components/Sidebar';
import ReportButton from 'components/ReportButton';
import VersionHandler from 'components/VersionHandler';
import CookieBanner from 'components/CookieBanner';
import { CookieProvider } from 'components/CookieBanner/CookieContext';
import { LoadingBox } from 'components/LoadingIndicators';
import reportErrorContent from 'helpers/reportErrorContent';
import { cookieCategories, cookieContent } from './server/cookieConfig';
import configureStore from './state/store';
import { QueryClient, QueryClientProvider } from 'react-query';
import FormErrorHandler from '@hubins/components/FormErrorHandler';
import NotificationBanners from '@hubins/components/NotificationBanners/NotificationBanners';
import TrackingScriptsMatomo from 'components/TrackingScriptsMatomo';

const App = () => {

  const queryClient = new QueryClient();
  // const siteId = import.meta.env.VITE_MATOMO_SITE_ID;

  const [loading, setLoading] = useState(true);
  const [theBook, setTheBook] = useState(false);

  const init = async () => {
    const growthbook = new GrowthBook();
    await axios.get(import.meta.env.VITE_FEATURES_ENDPOINT)
      .then((parsed) => {
        try {
          const localState = localStorage.getItem('state_v2');
          if (localState) {
            const parsedState = JSON.parse(localState);
            const id = parsedState?.auth?.User?.id;
            if (id) {
              growthbook.setAttributes({ id: id.toString() });
            }
          }
        } catch (e) {
          console.log('error initialising growthbook state', e);
        }

        const features = parsed.data.features;
        growthbook.setFeatures(features);
        setTheBook(growthbook);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  // TODO: this should be placed to correct component
  // this needs to be invoked during user login
  const matomoUserTracking = async () => {
    if (typeof window._mtm !== 'undefined') {
      try {
        const localState = localStorage.getItem('state_v2');
        const consent_ux = localStorage.getItem('consent_ux');
        if (localState && consent_ux === 'true') {
          const parsedState = JSON.parse(localState);
          const userSSN = parsedState?.auth?.User?.national_identity_number;
          if (userSSN) {
            //window._mtm.push({ 'event': 'mtm.PageView' });
            window._mtm.push({ 'uid': userSSN.toString() });
          }
        }
      } catch (e) {
        console.log('error getting state', e);
      }
    }
  };

  useEffect(() => {
    init();
    matomoUserTracking();
  }, []);

  if (loading) return <LoadingBox />;

  return (
    <Provider store={configureStore()}>
      <QueryClientProvider client={queryClient}>
        <GrowthBookProvider growthbook={theBook}>
          <UiProvider>
            <CookieProvider categories={cookieCategories}>
              <NotificationBanners />
              <FormErrorHandler>
                <Routes>
                  <VersionHandler platform='platform' />
                </Routes>
              </FormErrorHandler>
              <Sidebar />
              <ReportButton icon='service_usage' questionId='9000' content={reportErrorContent} />
              <TrackingScriptsMatomo />
              <CookieBanner
                text='Hubins använder cookies för att undersöka hur vår hemsida används och ge våra besökare bästa möjliga upplevelse.'
                readMoreText='Läs mer och hantera'
                closeText='Stäng'
                acceptButtonText='Acceptera'
                saveText='Godkänn inställningar'
                categories={cookieCategories}
                content={cookieContent}
              />
            </CookieProvider>
          </UiProvider>
        </GrowthBookProvider>
      </QueryClientProvider>
    </Provider>
  );
};

export default App;
