import { actualTaxation } from "helpers/calculations";

const tradeOrderNumberStatuses = {
  "1": "await_delivery",
  "2": "executed",
  "3": "cancelled",
  "4": "draft",
  "5": "await_assignment",
  "10": "pay"
};

const generateEventCardData = (type, status, hasDate) => {

  const values = {
    // Type Buy
    'B_4': {
      component: 'TradeOrderCard',
      title: 'buy_open_title',
      text: 'buy_open_text',
      color: 'warning',
    },
    'B_5': {
      component: 'TradeOrderCard',
      title: 'buy_accepted_title',
      text: 'buy_accepted_text',
      color: 'warning',
    },
    'B_6': {
      component: 'TradeOrderCard',
      title: 'buy_sent_title',
      text: 'buy_sent',
      color: 'warning',
    },
    'B_1': {
      component: 'TradeOrderCard',
      title: 'buy_executable_title',
      text: 'buy_executable_text',
      color: 'warning',
    },
    'B_2': {
      component: 'TradeOrderCard',
      title: 'buy_executed_title',
      text: 'buy_executed_text',
      color: 'success', // Before it was warning - but no action is required
    },
    'B_2_withoutdate': {
      component: 'TradeOrderCard',
      title: 'buy_executed_withoutdate_title',
      text: 'buy_executed_withoutdate_text',
      color: 'success',
    },

    // Type Sell
    // Sell_Open = N/A
    'S_4': {
      component: 'TradeOrderCard',
      title: 'sell_accepted_title',
      text: 'sell_accepted_text',
      color: 'warning',
    },
    'S_5': {
      component: 'TradeOrderCard',
      title: 'sell_accepted_title',
      text: 'sell_accepted_text',
      color: 'warning',
    },
    'S_1': {
      component: 'TradeOrderCard',
      title: 'sell_executable_title',
      text: 'sell_executable_text',
      color: 'warning',
    },
    'S_2': {
      component: 'TradeOrderCard',
      title: 'sell_executed_title',
      text: 'sell_executed_text',
      color: 'success',
    },
    'S_6': {
      component: 'TradeOrderCard',
      title: 'buy_sent_title',
      text: 'buy_sent',
      color: 'warning',
    },
    'S_2_withoutdate': {
      component: 'TradeOrderCard',
      title: 'sell_executed_withoutdate_title',
      text: 'sell_executed_withoutdate_text',
      color: 'success',
    },

    // Type Add (TODO: This should probably be a special card, but pretended it was a TradeOrderCard for now)
    'Add_4': {
      component: 'TradeOrderCard',
      title: 'add_open_title',
      text: 'add_open_text',
      color: 'warning',
    },
    'Add_5': {
      component: 'TradeOrderCard',
      title: 'add_accepted_title',
      text: 'add_accepted_text',
      color: 'success',
    },
    // Add_Executable = N/A
    'Add_2': {
      component: 'TradeOrderCard',
      title: 'add_executed_title',
      text: 'add_executed_text',
      color: 'success',
    },
    'Add_2_withoutdate': {
      component: 'TradeOrderCard',
      title: 'add_executed_withoutdate_title',
      text: 'add_executed_withoutdate_text',
      color: 'success',
    },

    // Type Premium
    'PRE_4': {
      component: 'PayCard',
      title: 'premium_open_title',
      text: 'premium_open_text',
      color: 'warning',
    },
    'PRE_5': {
      component: 'PayCard',
      title: 'premium_accepted_title',
      text: 'premium_accepted_text',
      color: 'warning',
    },
    'PRE_10': {
      component: 'PayCard',
      title: 'premium_open_title',
      text: 'premium_open_text',
      color: 'warning',
    },
    // Premium_Executable = N/A
    'PRE_2': {
      component: 'PayCard',
      title: 'premium_executed_title',
      text: 'premium_executed_text',
      color: 'success',
    },
    'PRE_2_withoutdate': {
      component: 'PayCard',
      title: 'premium_executed_withoutdate_title',
      text: 'premium_executed_withoutdate_text',
      color: 'success',
    },
    'PRESEC_4': {
      component: 'TradeOrderCard',
      title: 'premium__security_pending_title',
      text: 'premium_security_open_withoutdate_text',
      color: 'warning',
    },
    'PRESEC_2_withoutdate': {
      component: 'TradeOrderCard',
      title: 'premium__security_executed_withoutdate',
      text: 'premium_security_executed_withoutdate_text',
      color: 'success',
    },
    'PRESEC_5': {
      component: 'TradeOrderCard',
      title: 'premium__security_pending_title',
      text: 'premium_security_executable_withoutdate_text',
      color: 'warning',
    },
    'PRESEC_6': {
      component: 'TradeOrderCard',
      title: 'premium__security_pending_title',
      text: 'presec_sent_to_counterpart',
      color: 'warning',
    },
    'PRESEC_10': {
      component: 'TradeOrderCard',
      title: 'premium__security_pending_title',
      text: 'sell_accepted_text',
      color: 'warning',
    },
    'PRESEC_3': {
      component: 'TradeOrderCard',
      title: 'premium__security_cancelled_title',
      text: 'premium__security_cancelled_title',
      color: 'danger',
    },
    'PRESEC_2': {
      component: 'TradeOrderCard',
      title: 'premium__security_executed_withoutdate',
      text: 'premium_security_executed_withoutdate_text',
      color: 'success',
    },
    'PRESEC_1': {
      component: 'TradeOrderCard',
      title: 'premium__security_pending_title',
      text: 'premium_security_executable_text',
      color: 'warning',
    },
    'PRE_1': {
      component: 'TradeOrderCard',
      title: 'premium_accepted_title',
      text: 'premium_accepted_text',
      color: 'warning',
    },
    'B_10': {
      component: 'TradeOrderCard',
      title: 'sell_accepted_title',
      text: 'sell_accepted_text',
      color: 'warning',
    },
    'S_10': {
      component: 'TradeOrderCard',
      title: 'sell_accepted_title',
      text: 'sell_accepted_text',
      color: 'warning',
    },
    'S_9': {
      component: 'TradeOrderCard',
      title: 'sell_accepted_title',
      text: 'sell_accepted_text',
      color: 'warning',
    },
    'B_9': {
      component: 'TradeOrderCard',
      title: 'sell_accepted_title',
      text: 'sell_accepted_text',
      color: 'warning',
    },
    'PRE_3': {
      component: 'TradeOrderCard',
      title: 'premium__cancelled_title',
      text: 'premium__cancelled_title',
      color: 'danger',
    },
    'EXCH_4': {
      component: 'TradeOrderCard',
      title: 'buy_open_title',
      text: 'buy_open_text',
      color: 'warning',
    },
    'EXCH_5': {
      component: 'TradeOrderCard',
      title: 'buy_open_title',
      text: 'buy_open_text',
      color: 'warning',
    },
    'EXCH_10': {
      component: 'TradeOrderCard',
      title: 'buy_open_title',
      text: 'buy_open_text',
      color: 'warning',
    },
    'EXCH_6': {
      component: 'TradeOrderCard',
      title: 'buy_sent_title',
      text: 'buy_sent',
      color: 'warning',
    },
    'EXCH_2': {
      component: 'TradeOrderCard',
      title: 'sell_executed_title',
      text: 'sell_executed_text',
      color: 'success',
    },
    'EXCH_1': {
      component: 'TradeOrderCard',
      title: 'buy_sent_title',
      text: 'buy_sent',
      color: 'warning',
    },
    'SURR_1': {
      component: 'TradeOrderCard',
      title: 'surr_title',
      text: 'surr_text',
      color: 'warning',
    },
    'SURR_3': {
      component: 'TradeOrderCard',
      title: 'surr_title',
      text: 'surr_text',
      color: 'warning',
    },
    'SURR_4': {
      component: 'TradeOrderCard',
      title: 'surr_title',
      text: 'surr_text',
      color: 'warning',
    },
    'SURR_5': {
      component: 'TradeOrderCard',
      title: 'surr_title',
      text: 'surr_text',
      color: 'warning',
    },
    'SURR_6': {
      component: 'TradeOrderCard',
      title: 'surr_title',
      text: 'surr_text',
      color: 'warning',
    },
    'SURR_10': {
      component: 'TradeOrderCard',
      title: 'surr_title',
      text: 'surr_text',
      color: 'warning',
    },
    'SURR_2': {
      component: 'TradeOrderCard',
      title: 'surr_title_2',
      text: 'surr_text_2',
      color: 'success',
    },
    'WDSEC_2': {
      component: 'TradeOrderCard',
      title: 'wdsec_title_2',
      text: 'wdsec_text_2',
      color: 'success',
    },
    'WDSEC_1': {
      component: 'TradeOrderCard',
      title: 'wdsec_title',
      text: 'wdsec_text',
      color: 'warning',
    },
    'WDSEC_3': {
      component: 'TradeOrderCard',
      title: 'wdsec_title',
      text: 'wdsec_text',
      color: 'warning',
    },
    'WDSEC_4': {
      component: 'TradeOrderCard',
      title: 'wdsec_title',
      text: 'wdsec_text',
      color: 'warning',
    },
    'WDSEC_5': {
      component: 'TradeOrderCard',
      title: 'wdsec_title',
      text: 'wdsec_text',
      color: 'warning',
    },
    'WDSEC_6': {
      component: 'TradeOrderCard',
      title: 'wdsec_title',
      text: 'wdsec_text',
      color: 'warning',
    },
    'WDSEC_10': {
      component: 'TradeOrderCard',
      title: 'wdsec_title',
      text: 'wdsec_text',
      color: 'warning',
    },
    fallback: {
      component: '',
      title: false,
      text: `Type: ${type} Status: ${status}`,
      color: 'danger',
    }
  };
  // Generate the key based on o_type, o_status and O_settlementDate.
  // We need to seperate status Executed depending if it has a settlement date or not,
  // depending if the bond is delivered (has no date) or not.
  const key = `${type}_${status}${(status === 'Executed' && !hasDate) ? '_withoutdate' : ''}`;

  return values[key] || values.fallback;
};

const stateStatus = {
  'Issue - New': 'upcoming_investments',
  'Issue - Marketing': 'upcoming_investments',
  'Issue - On Sale': 'primary_market',
  'Issue - Closed': 'draft',
  'Issue - Secondary market': 'active_investments',
  'Issue - Matured': 'active_investments',
  'Block sale': 'block_sale',
};

const mapStatus = (string) => {
  if (!string) { return ''; }
  let stringArray = string.split(',');
  let marketGroup = stringArray.map(s => stateStatus[s]);
  return marketGroup;
};

const formatTradeOrder = item => {
  // If its deposit or withdrawal, donät have a thubnail
  // If its a regular investement, show the investment thumbnail or a fallback
  const thumbnail =
    (item.o_type === "Premium" || item.o_type === "Withdrawal")
      ? false
      : item.hero
        ? item.hero.value
        : false;

  return {
    ...item,
    accountId: item.parentPortfolio.id,
    currency: item.currencyCode,
    externalId: item.extId,
    fromJIP: RegExp(/Trade Order source-JIP/).test(item.tags), // if the tradeOrder is made in JOOL Investment Portal = This site
    investmentValue: item.tradeAmount,
    isin: item.security ? item.security.isinCode : '',
    marketGroup: item.security ? mapStatus(item.security.tags) : '',
    name: item.security ? item.security.name : '',
    // parentPortfolio: item.parentPortfolio.shortName,
    paymentOption: {
      manual: RegExp(/Group-Manual/).test(item.tags),
      directDebit: RegExp(/Group-AG|Group-requestedAG/).test(item.tags)
    },
    reference: item.reference,
    securityId: item.security ? item.security.id : '',
    status: tradeOrderNumberStatuses[item.orderStatus],
    thumbnail: thumbnail,
    timeLimit: item.security ? item.security.maturityDate : '',
    // type: item.type.typeCode,
    volume: String(item.amount),
    yearlyRate: item.security ? item.security.coupon : '',
    placeholder: false,
    eventCard: generateEventCardData(item.type.typeCode, item.orderStatus, item.settlementDate),
  };
};

export const tradeOrdersHandler = (response: any) => {
  return response.data.map((order) => {
    const formatOrder = formatTradeOrder(order);
    return formatOrder;
  })
}
