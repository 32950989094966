// always write a / before the url for regular routes
// if you need to use the variable/props function,
// note that it's not possible to switch between languages
// and automatically change to the new translated url.
// So then, make multiple routes instead, like __route('case_start') + id + __route('case_end')

// To translator: No need to translate variables that starts with a :. Ex objectid in '/case/:objectid'

export const routes = () => ({

  root: '/', // tidigare /investeringskonton
  overview: '/oversikt',
  report: '/rapport',
  transactions: '/transaktioner',
  holdings: '/innehav',
  yearly_report: '/arsrapport',

  testroute: `/test/:id`,
  testStart: '/test/',
  testEnd: '/end',

  landing: 'https://hubins.com',
  clear: '/clear',
  login: '/loggain',
  logout: '/loggaut',
  loginSelect: '/loggain/konto',
  signup: '/bli-kund',
  signupComp: '/bli-kund/:org',
  landingPage: '/start',
  about: '/om-oss',
  contact: '/kontakt',
  legal: '/legalt',
  cookies: '/cookies',
  privacy: '/personuppgifter',

  myAccountsOverview: 'översikt', // for hash url, no slash
  myAccountsHolding: 'innehav', // for hash url, no slash
  myAccountsTransactions: 'transaktioner', // for hash url, no slash
  myAccountsReport: 'rapport', // for hash url, no slash
  myAccountsPolicy: 'policy',
  myAccountsYearly: 'arsrapport',
  mySources: 'kallor',

  yearlyCheckup: '/årsuppdatering',
  yearlyCheckupWithoutIntro: '/årsuppdatering/1',
  yearlyCheckupDA: '/årsuppdatering/behovsanalys/1',
  yearlyCheckupDASO: '/årsuppdatering/behovsanalys/2',

  invest: '/investera',
  invest_disclaimer: '/investera/disclaimer',
  case: '/produkt', // used as /case/1234
  case_objectid: '/produkt/:objectid',
  case_objectid_blocksale: '/produkt/blocksale',
  subscription: '/teckningsanmälan', // used as /subscription/1234
  subscription_stock: '/teckningsanmälan/aktie', // used as /subscription/1234
  subscription_stock_objectid: '/teckningsanmälan/aktie/:id', // used as /teckningsanmälan/aktie/1234
  subscription_crypto: '/teckningsanmälan/krypto', // used as /subscription/1234
  subscription_crypto_objectid: '/teckningsanmälan/krypto/:id', // used as /teckningsanmälan/aktie/1234
  subscription_stock_switch: '/teckningsanmälan/aktie/välj-typ',
  subscription_stock_switch_objectid: '/teckningsanmälan/aktie/välj-typ/:id',
  subscription_stock_direct: '/teckningsanmälan/aktie/direktinvestering', // used as /teckningsanmälan/aktie-direktinvestering/1234
  subscription_stock_direct_success: '/teckningsanmälan/aktie/direktinvestering/genomfört',
  subscription_stock_direct_objectid: '/teckningsanmälan/aktie/direktinvestering/:id',
  subscription_fund_objectid: '/teckningsanmälan/:fund/:objectid',
  subscription_objectid: '/teckningsanmälan/:objectid',
  blocksale: '/teckningsanmälan-blocksale',

  deposit: '/insättning',
  depositSuccessAg: '/insättning/genomförd',
  depositInstructions: '/insättning/instruktioner', // also used as /deposit/instructions/q/:account/:orderid/
  withdrawal: '/uttag',
  withdrawalSuccess: '/uttag/genomfört',

  settings: '/inställningar',
  settingsBeneficials: '/inställningar#bolagspersoner',
  savedDocuments: '/sparade-dokument', // for hash url, no slash
  settingsProfile: 'profilinställningar', // for hash url, no slash
  settingsKyc: 'kyc', // for hash url, no slash
  beneficials: 'bolagspersoner',
  settingsPayment: 'betalningsalternativ',  // for hash url, no slash

  registerautogiro: '/registrera-autogiro',
  messages: '/meddelanden',

  marketBuy: '/andrahandsmarknaden/köp',
  marketBuy_id: '/andrahandsmarknaden/köp/:id',
  marketBuySuccess: '/andrahandsmarknaden/köp-genomfört/',
  subscriptionBuySuccess: '/primärmarknaden/köp-genomfört/',

  marketSell: '/andrahandsmarknaden/sälj',
  marketSell_id: '/andrahandsmarknaden/sälj/:id',
  marketSellSuccess: '/andrahandsmarknaden/sälj-genomfört/',

  companySignatory: '/företag/firmatecknare',
  s_kyc: '/företag/signering/1',
  s_signoff: '/företag/signering/2',
  companySignatorySignupSuccess: '/företag/firmatecknare/genomförd',

  companyPrincipal: '/företag/verklig-huvudman',
  companyPrincipalSignup1: '/företag/verklig-huvudman/registrering/1',
  companyPrincipalSignup2: '/företag/verklig-huvudman/registrering/2',
  companyPrincipalSignupSuccess: '/företag/verklig-huvudman/genomförd',
  companySignup: '/företag/registrering',

  companyLogin: '/företag/loggain',

  c_bi: '/företag/registrering/grundläggande-uppgifter',
  companySignupSuccess: '/företag/registrering/genomförd',

  signupSwitch: '/registrering',
  signup1: '/registrering/1',
  signupPersonnel: '/registrering/konto',
  signupPersonnelNoTerms: '/registrering/information',
  signupCompanyPersonnel: '/registrering/bolagsrepresentant',
  p_da_terms: '/registrering/behovsanalys/1/avtal',
  c_da_terms: '/företag/registrering/behovsanalys/1/avtal',
  p_da_purpose: '/registrering/behovsanalys/1/syfte',
  c_da_purpose: '/företag/registrering/behovsanalys/1/syfte',
  p_da_transactions: '/registrering/behovsanalys/1/transaktioner',
  c_da_transactions: '/företag/registrering/behovsanalys/1/transaktioner',
  p_da_products: '/registrering/behovsanalys/1/tillgångsslag',
  c_da_products: '/företag/registrering/behovsanalys/1/tillgångsslag',
  p_da_target_return: '/registrering/behovsanalys/1/målavkastning',
  c_da_target_return: '/företag/registrering/behovsanalys/1/målavkastning',
  p_da_tax: '/registrering/behovsanalys/1/skatt',
  c_da_tax: '/företag/registrering/behovsanalys/1/skatt',
  p_da_beneficiaries: '/registrering/behovsanalys/1/förmånstagare',
  c_da_beneficiaries: '/företag/registrering/behovsanalys/1/förmånstagare',
  p_da: '/registrering/behovsanalys/1',
  p_da_so: '/registrering/behovsanalys/2',
  puppeteer_signoff: '/puppeteer/signoff',
  da_so_updated: '/uppdatering/behovsanalys/summering',
  da_so_init: '/init/behovsanalys/summering',
  demandassessment_signoff: '/registrering/da/signoff',
  companyHubinsSummary: '/företag/hubins-summering',
  companyHubinsSignoff: '/företag/hubins-signoff',
  yearlyReminder: '/arlig-paminnelse',
  p_aftersale: '/registrering/efterköpsinformation',
  c_aftersale: '/företag/efterköpsinformation',
  p_aftersale_preview: '/aftersale/preview',
  c_aftersale_preview: '/företag/aftersale/preview',

  tests: '/tester',
  tests0: '/tester/val',
  tests1: '/kunskapstest', // component Knowledge
  p_kt: '/registrering/kunskapstest',
  c_kt: '/företag/kunskapstest',
  knowledgeTest: '/tester/kunskapstest',
  p_kt_start: '/tester/kunskapstest-start',
  c_kt_start: '/företag/tester/kunskapstest-start',
  knowledgeTestResult: '/tester/kunskapstest-resultat',
  companyKnowledgeTestResult: '/företag/kunskapstest-resultat',
  knowledgeTestResult_correct_answer: '/tester/kunskapstest-resultat',
  tests2: '/passandeprövning',
  tests3: '/ekonomisk-situation',

  c_da: '/företag/behovsanalys/1', // Knowledge Assessment - previous companySignup20
  c_da_so: '/företag/behovsanalys/2', // motsvarande privat signup22 tidigare companySignup22
  companyTests1: '/företag/kunskapstest', // component Knowledge
  companyTests2: '/företag/passandeprövning', // component CompanyAptitude
  companyTests3: '/företag/ekonomisk-situation', // component CompanyObjective

  p_terms: '/villkor',
  c_terms: '/företag/villkor',

  brokeradvice: '/investeringsråd',

  cms: '/cms',
  cmsEdit: '/cms/redigera',
  cmsEdit_objectid_language: '/cms/redigera/:objectid/:language',
  cmsEdit_objectid: '/cms/redigera/:objectid/',

  //Hubins specifik routes
  p_ins: '/försäkrings-information',
  c_ins: '/företag/försäkrings-information',
  p_kyc: '/kundkännedom',
  c_kyc: '/företag/kundkännedom',
  kyc: '/kundkännedom',
  ckyc: '/företag/kundkännedom',
  c_hm: '/företag/kundkännedom/bolagsperson',
  signup2: '/kundkännedom',
  reg_sof: '/registrering/källa-till-kapital',
  reg_company_sof: '/registrering/företag/källa-till-kapital',
  sof: '/källa-till-kapital',
  companySof: '/företag/källa-till-kapital',
  reg_companySof: '/registrering/företag/källa-till-kapital',
  sofdoc: '/bevis-av-källa',
  reg_sofdoc: '/registrering/bevis-av-källa',
  companySofdoc: '/företag/bevis-av-källa',
  reg_companySofdoc: '/registrering/företag/bevis-av-källa',

  personnelOverview: '/insyn/företag',

  testForm: '/testform',

  sofTriggerSuccess: '/insättning/klar',

  welcome: '/välkommen',

  investment_knowledge_test: '/passandebedömning',
  intro_investment_knowledge_test: '/intro/passandebedömning',
  result_investment_knowledge_test: '/intro/passandebedömning',
  transfer: '/inflytt',
  registration_transfer: '/registrering/inflytt',
  transfer_start: '/inflytt/start',
  transfer_report: '/inflytt/rapport',
  transfer_info: '/inflytt/information',

  register_insurance: '/registrering/försäkring',
  p_presale: '/registrering/förköpsinformation',
  c_presale: '/företag/förköpsinformation',
  c_presale_existing: '/företag/registrering/förköpsinformation',
  register_summary: '/registrering/summering',
  personnel_summary: '/registrering/fullmakt/summering',

  reg_sow: '/registrering/källa-till-förmögenhet',
  company_reg_sow: '/företag/registrering/källa-till-förmögenhet',

  aptitude_tests: '/tester/passandeprövning',
  knowledge_test_cryptocurrency: 'Kunskapstest - Kryptovalutor',
  knowledge_test_stock: 'Kunskapstest - Aktier',
});

export default routes;

/**
 * Usage:
import { __route } from 'localisation';
 * replace href="/login" to href={__route('login')}
 */
