import {
  useQuery,
} from 'react-query';


import middleman from 'helpers/middleman';

export const useOnSaleSecurities = () => useQuery(['onSaleSecurities'], () => {
  return middleman.get(`/fa/securities/Issue - On Sale`)
  .then((response) => {
    return response.data;
  });
}, {enabled: true, refetchOnWindowFocus: false});

export const useAllSecurities = () => useQuery(['allSecurities'], () => {
  const promises = [
    middleman.get(`/fa/securities/Issue - Secondary Market`).catch(() => ({ data: []})),
    middleman.get(`/fa/securities/Issue - Marketing`).catch(() => ({ data: []})),
    middleman.get(`/fa/securities/Issue - On Sale`).catch(() => ({ data: []})),
    middleman.get(`/fa/securities/Issue - Closed`).catch(() => ({data: []})),
  ];

  return Promise.all(promises).then((arr) => {
    return [...arr[0].data, ...arr[1].data, ...arr[2].data, ...arr[3].data];
  });

}, { enabled: true, refetchOnWindowFocus: false });

export const useSecurity = (id: string) => useQuery(['security', id], () => {
  return middleman.get(`/securities/${id}/show`)
  .then((response) => {
    return response.data;
  });
} , {enabled: !!id, refetchOnWindowFocus: false});