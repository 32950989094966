import React from 'react';
import Wrapper from '@hubins/components/Wrapper';
import Heading from 'components/Heading';
import Button from '@hubins/components/Button';
import Tutorial from 'components/Tutorial';
import FAQ from '@hubins/components/FAQ/FAQ';

const TutorialTestPage = () => {
  return (
    <Wrapper>
      <Tutorial
        steps={[
          {
            id: 'Button', // make sure to use the React component name
            target: '#button1',
            title: 'Detta är testknappen 1',
            content: 'Detta är en beskrivning av vad knappen gör',
            disableBeacon: true,
          },
          {
            id: 'Button', // make sure to use the React component name
            target: '#button2',
            title: 'Detta är testknappen 2',
            content: 'Och detta är en beskrivning av vad knappen gör',
            disableBeacon: true,
          },
          {
            id: 'Heading', // make sure to use the React component name
            target: '#heading1',
            title: 'Detta är en rubrik',
            content: 'Och detta är en beskrivning av rubriken',
            disableBeacon: true,
          },
        ]}
      />
      <Heading size="2">Tutorial Test Page</Heading>
      <Button id="button1">
        Test button 1
      </Button>
      <Button id="button2">
        Test button 2
      </Button>
      <Heading id="heading1">
        Test heading 1
      </Heading>
      <FAQ
        rows={[
          {
            title: 'FAQ 1',
            content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, nunc vel tincidunt lacinia, nunc nisl aliquam nisl, eget aliquam nisl nisl sit amet nisl. Sed euismod, nunc vel tincidunt lacinia, nunc nisl aliquam nisl, eget aliquam nisl nisl sit amet nisl.',
          },
          {
            title: 'FAQ 2',
            content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, nunc vel tincidunt lacinia, nunc nisl aliquam nisl, eget aliquam nisl nisl sit amet nisl. Sed euismod, nunc vel tincidunt lacinia, nunc nisl aliquam nisl, eget aliquam nisl nisl sit amet nisl.',
          },
          {
            title: 'FAQ 3',
            content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, nunc vel tincidunt lacinia, nunc nisl aliquam nisl, eget aliquam nisl nisl sit amet nisl. Sed euismod, nunc vel tincidunt lacinia, nunc nisl aliquam nisl, eget aliquam nisl nisl sit amet nisl.',
          },
        ]}
      />
    </Wrapper>
  );
};

export default TutorialTestPage;
