import {
  useQuery,
} from 'react-query';

import middleman from 'helpers/middleman';

export const usePortfolios = (national_identity_number) => useQuery(['portfolios', national_identity_number], () => {
  return middleman.get(
    `/portfolios?status=issued`
  ).then((response) => {
    return response.data;
  });
},{ enabled: !!national_identity_number, refetchOnWindowFocus: false});

export const usePortfolioStats = (portfolioUuid) => useQuery(['portfolio_stats', portfolioUuid], () => {
  return middleman.get(
    `/premium/stats/${portfolioUuid}`
  ).then((response) => {
    return response.data;
  });
}
,{ enabled: !!portfolioUuid, refetchOnWindowFocus: false });