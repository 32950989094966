import React from 'react';
import Modal from 'components/Modal';
import { HubinsLoadingAnimation as LoadingAnimation } from "components/Icon/HubinsLoadingAnimation";
import __ from 'localisation';

const LoadingModal = ({ open = true }) => (
  <Modal
    open={open}
    callback={() => ''}
    className="animation-reveal"
    overlayClassName="modal__overlay--centermobile"
    contentLabel={__('loading')}
    noWrapper
  >
    <LoadingAnimation active />
  </Modal>
);

export default LoadingModal;
