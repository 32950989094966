import React, { useEffect, useRef, useContext } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import Media from 'react-media';
import { $ } from 'helpers';
import Button from '@hubins/components/Button';
import { uiContext } from 'components/UiContext';

import './index.scss';

import Icon from '@hubins/components/IconNew';
import __ from 'localisation';


//REACT
const Sidebar = () => {
  const closeButton = useRef(null);
  const {
    questionOpen,
    toggleQuestionOpen,
    currentQuestionId,
    lastInput,
    questionIcon,
    questionText,
    questionContent,
  } = useContext(uiContext);

  const handleFocus = (isOpen) => {
    if (closeButton.current && isOpen) closeButton.current.focus();
  };

  useEffect(() => {
    handleFocus(questionOpen);
  }, [questionOpen]);

  // BREAKPOINT
  let isLargerThanMobile = window.matchMedia(`(min-width: ${$.mq.sm})`).matches;

  // Animation
  // Different configuration on mobile and desktop
  const transition = {
    type: 'spring',
    stiffness: 400,
    damping: 100
  };

  let config = isLargerThanMobile
    ? {
      enter: { x: 0 },
      exit: { x: 420 },
    }
    : {
      enter: { y: 0 },
      exit: { y: '100%' },
    };

  return (
    <AnimatePresence>
      {questionOpen && (
        <motion.div className="sidebar__frame"
          initial={config.exit}
          animate={config.enter}
          exit={config.exit}
          transition={transition}
        >
          <motion.div
            aria-hidden={!questionOpen}
            key="1"
            className="sidebar"
            onDragEnd={toggleQuestionOpen}
            id={'sidebar_' + currentQuestionId}
          >
            <Button innerRef={closeButton}
              onlyIcon
              type="button"
              aria-controls={'sidebar_' + currentQuestionId}
              aria-expanded={questionOpen}
              className="medium sidebar__button"
              onClick={() => {
                toggleQuestionOpen();
                if (lastInput) lastInput.focus();
              }}>
              <Icon icon="remove" color="primary" size="16" label={__('close')} />
            </Button>
            <div className="sidebar__content">
              <div className="center s-bottom-m">
                <Media query={`(min-width: ${$.mq.sm})`}>
                  {matches =>
                    <Icon
                      icon={"Question Circle by Streamlinehq"}
                      color="primary"
                      size={matches ? "24" : "16"}
                    />
                  }
                </Media>
              </div>
              {questionText && questionText}
              {questionContent !== <div></div> && questionContent}
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>

  );
};

export default Sidebar;
