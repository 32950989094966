/* eslint-disable react/forbid-elements */

import React, { Fragment } from 'react';

export const keys = (v = { test: true }) => ({

  // Customized strings for different markets - these will override the default STRINGS
  MARKET_STRINGS: {
    // Sweden
    SE: {
    },

    // Norway
    NO: {
    },

    // Finland
    FI: {
    }
  },

  // Default strings
  STRINGS: {
    // Registration dropdown values

    ///// Education ///////
    'no_education': 'Ingen utbildning',
    'college_degree': 'Högskoleexamen',
    'professional_degrees': 'Yrkesexamina',
    'licentiate_degree': 'Licentiatexamen',
    'doctor_degree': 'Doktorexamen',
    'other_education': 'Övrigt',
    /////////////

    'daycare_children_education': 'Barn och fritid',
    'construction_design_interior': 'Bygg-, Design- & Inredning',
    'electricity_and_energy': 'El och energi',
    'aesthetics_art_crafts': 'Estetik, konst och hantverk',
    'vehicle_transport': 'Fordon och transport',
    'humanism_staff_science': 'Humanism och personalvetenskap',
    'sports_health': 'Idrott och hälsa',
    'industry_plant': 'Industri och anläggning',
    'military_education': 'Militär utbildning',
    'environment_sustainability': 'Miljö och hållbarhet',
    'nature_science': 'Naturvetenskap',
    'resturant_food_production_hotel_turism': 'Resturang, livsmedelsframställning, hotell & turism',
    'language_journalism_media': 'Språk, journalistik och media',
    'education_pedagogy': 'Utbildning och pedagogik',
    'plumbing_real_estate': 'VVS och fastighet',

    /////////// Employment type ////////////
    'employed_company_less_then_10_employees': 'Anställd i bolag med färre än 10 anställda',
    'employed_company_between_10_and_100_employees': 'Anställd i bolag med mellan 10-100 anställda',
    'employed_company_more_then_100_employees': 'Anställd i bolag med fler än 100 anställda',
    'self_employed': 'Driver egen firma (enskild näringsidkare)',
    'self_employed_stock_trade_other_company': 'Driver eget aktiebolag, handelsbolag, annat',
    'full_time_study': 'Studerar på heltid',
    'part_time_study': 'Studerar på deltid',
    'looking_for_work': 'Arbetssökande',
    'financially_independent': 'Ekonomiskt oberoende ',
    'other_employment': 'Annan sysselsättning',
    //new for KYCemployment-dropDown-function
    'studying': 'Student',
    'employed': 'Anställd',
    'own_business': 'Driver eget företag',
    /////////////


    'construction': 'Byggverksamhet',
    'financial_insurance_holding_company': 'Finans- och försäkringsverksamhet & holdingbolag',
    'electricity_gas_heat_cold_supplier': 'Försörjning av el, gas, värme och kyla',
    'household_services': 'Förvärvsarbete i hushåll; hushållens produktion av diverse varor och tjänster för eget bruk',
    'trade_repair_vehicle_services': 'Handel; reparation av motorfordon och motorcyklar',
    'hotel_resturant_business': 'Hotell- och restaurangverksamhet',
    'data_communication_business': 'Informations- och kommunikationsverksamhet',
    'farming_forestry_fishing_business': 'Jordbruk, skogsbruk och fiske',
    'public_management_and_defending_mandatory_social': 'Offentlig förvaltning och försvar; obligatorisk socialförsäkring',
    'manufacturing': 'Tillverkning',
    'transport_warehousing_business': 'Transport och magasinering',
    'education': 'Utbildning',
    'rental_real_estate_travel_agency_other_support_services': 'Uthyrning, fastighetsservice, resetjänster och andra stödtjänster',
    'mining_business': 'Utvinning av mineral',
    'water_supplier_drain_cleaning_disposal_decontamination': 'Vattenförsörjning; avloppsrening, avfallshantering och sanering',
    'law_economic_science_engineering': 'Verksamhet inom juridik, ekonomi, vetenskap och teknik',
    'international_org_foreign_embassy': 'Verksamhet vid internationella organisationer, utländska ambassader o.d.',
    'healtcare_social_service': 'Vård och omsorg; sociala tjänster',
    'other_service_business': 'Annan serviceverksamhet',

    'none_working': 'Arbetar inte',
    'administration_economy_law': 'Administration, ekonomi, juridik',
    'sale_purchase_marketing': 'Försäljning, inköp, marknadsföring',
    'craft_trades': 'Hantverksyrken',
    'installation_operation_property_maintenance': 'Installation, drift, fastighetsunderhåll',
    'culture_media_design_pleasure_leisure': 'Kultur, media, design, nöje & fritid',
    'leading_position_public_sector': 'Ledande befattning inom offenltiga sektorn',
    'leading_position_private_services': 'Ledande befattning inom privatatjänstesektorn',
    'advertising_pr_market_research': 'Media, reklam, Pr & marknadsundersökning',
    'military': 'Militärt arbete',
    'pedagogical_work': 'Pedagogiskt arbete',
    'professional_athlete': 'Professionell idrottare',
    'social_worker': 'Socialt arbete',
    'games_and_betting': 'Spel och betting (Professionell kapacitet)',
    'safety_emergency': 'Säkerhetsarbete/räddningstjänst',
    'other_work': 'Övrigt',

    'transport_warehousing': 'Transport, taxi, åkeri & magasinering',
    'hair_beauty': 'Hår- & skönhetsvård',
    'manufacturing_industry': 'Industriell tillverkning',
    'agriculture_forestry_hunting_fishing': 'Naturbruk, jordbruk, skogsbruk, jakt & fiske',
    /////////// END WORK AND EDUCATION //////////////////

    'placeholder_org': 'xxxxxx-xxxx',
    'invest': 'Investera',
    'accept_and_proceed': 'Godkänn och gå vidare',
    save_and_proceed: 'Spara och gå vidare',
    'account_number': 'Kontonummer',
    // 'acting_principal': 'Verklig huvudman',
    'add_country': 'Ytterligare skattemässig hemvist',
    'address_error': 'Fyll i din address',
    'address_in_us': 'Jag har en port-, ”per”-, ”c/o”- eller hemadress eller en postbox i USA',
    'address': 'Adress',
    'advisor_name': 'Din rådgivares namn',
    'bachelor': 'Kandidatexamen',
    'bank_account_for_payment': 'Bankkonto för utbetalning',
    'bank_bic': 'Fyll i bankens BIC/SWIFT-kod',
    'bank_name': 'Bankens namn',
    'bank': 'Bank',
    'banking_finance_insurance': 'Bank, finans, försäkring',
    'business_services': 'Företagstjänster',
    'cancel_registration': 'Avsluta registreringen',
    'choose_company_sector': 'Välj bransch',
    'choose_country': 'Välj land',
    'choose_education_orientation': 'Välj inriktning',
    'choose_education': 'Välj utbildning',
    'choose_employment': 'Välj sysselsättning',
    'choose_occupation': 'Välj arbete',
    'choose_one_or_more': 'Välj ett eller flera alternativ som passar in',
    'citizenship_error': 'Välj ett land',
    'citizenship': 'Medborgarskap',
    'city_error': 'Fyll i din nuvarande ort',
    'city_of_birth_error': 'Fyll i namnet på din födelseort',
    'city_of_birth': 'Födelseort',
    'city': 'Ort',
    'civics': 'Samhäll',

    'construction_design_interior_design': 'Bygg-, design- & inredningsverksamhet',

    'contact.registration.advisor_name': 'Din rådgivares namn',
    'corporate_identity_number': 'Organisationsnummer',
    'corporate_sales': 'Företagsförsäljning',
    'country_of_birth_error': 'Välj ett land',
    'country_of_birth': 'Födelseland',
    'country_outside_eu': 'Land utanför EU',
    'crew_employment_services': 'Bemanning & arbetsförmedling',
    'culture_fun_leisure': 'Kultur, nöje & fritid',
    'customer_registration': 'Kundregistrering',
    'data_it_telecommunications': 'Data, it & telekommunikation',
    'diligence': 'Kundkännedom',
    'diplomat': 'Ambassadör, beskickningschef, eller hög officer i försvarsmakten',
    'dividend_associated_comp': 'Aktieutdelning från intressebolag',
    'dividend': 'Utdelning',
    'education_completed': 'Högsta avslutade utbildning',
    'education_orientation_settings': 'Utbildningens inriktning',
    'education_orientation': 'Vilket var din huvudsakliga inriktning på din utbildning',
    'education_research_development': 'Utbildning, forskning & utveckling',
    'email_error': 'Fyll i din giltiga epost address',
    'email': 'Epost',
    'embassies_international_org': 'Ambassader & internationella org',
    'employee': 'Anställd',
    'employment_education': 'Sysselsättning & utbildning',
    'employment': "Sysselsättning",
    //start
    'first_name_error': 'Fyll i ditt förnamn',
    'first_name': 'Förnamn',
    'food_production': 'Livsmedelsframställning',
    'foreign_bank': 'Utländsk Bank',
    'foreign_insurance_co': 'Utländskt Försäkringsbolag',
    'gdpr_check': 'Jag har läst, förstått och godkänner behandling av mina personuppgifter för de syften som framgår av Hubins AB och Quantum Leben AGs dokument för hantering av personuppgifter. Jag förstår och godkänner även att Hubins och Quantum Leben får dela mina personuppgifter med tredje part i syfte att underlätta eventuell kundetablering mellan mig och en tredje part som sker på mitt initiativ. Jag har vidare tagit del av både Hubins och Quantum Lebens kontaktuppgifter samt respektive bolags dataskyddsombuds kontaktuppgifter. Jag är även medveten om att jag när som helst kan återkalla mitt samtycke. Återkallandet kan ske genom telefonsamtal eller via mail till Hubins kundservice och utan att jag som kund behöver ange någon anledning.',
    'c_gdpr_check': 'Jag/vi har läst, förstått och godkänner behandling av mina/våra personuppgifter för de syften som framgår av Hubins och Quantum Leben AGs dokument hantering av personuppgifter. Jag/vi förstår och godkänner även att Hubins och Quantum Leben får dela mina/våra personuppgifter med tredje part i syfte att underlätta eventuell kundetablering mellan mig/oss och en tredje part som sker på mitt/vårt initiativ. Jag/vi har tagit del av både Hubins och Quantum Lebens kontaktuppgifter samt respektive bolags dataskyddsombuds kontaktuppgifter. Jag/vi är även medveten om att jag/vi när som helst kan återkalla mitt/vårt samtycke. Återkallandet kan ske genom telefonsamtal eller via mail till Hubins kundservice och utan att jag/vi som kund behöver ange någon anledning.',
    'general_conditions': 'Allmänna villkor',
    'general_terms_check': 'Jag har läst, förstår och godkänner de allmänna affärsvillkoren och de allmänna bestämmelserna för depåkontot',
    'giveoverthekey_imagealt': 'En person som överlämnar en nyckel till en annan person',
    'government_official': 'Stats- eller regeringschef, minister eller vice/biträdande minister',
    'green_card': 'Jag är i besittning av amerikanskt medborgarskap eller ”Green Card”',
    'group_company_info': <Fragment><p>Eftersom bolaget är ett koncernföretag eller en filial som regelbundet handlas på en etablerad värdepappersmarknad alternativt är ett offentligt organ, en internationell organisation eller en centralbank, så behöver du kontakta kundservice på <a target="_blank" rel="noopener noreferrer" className="link" href="tel:0317971936">031-797 19 36</a> för att registreringsprocessen ska godkännas.</p><p>Ni kan genomföra resterande del av registreringen men det kan komma att krävas ytterligare dokumentation från er.</p></Fragment>,
    'has_not_power_of_attorney': 'har inte gett fullmakt',
    'has_power_of_attorney': 'har gett fullmakt',
    'health_medical': 'Hälsa & sjukvård',
    'high_school_diploma': 'Gymnasieexamen',
    'highest_education_completed': 'Vilken är din högsta avslutade utbildning',
    'hotel_restaurant': 'Hotell & restaurang',
    'husband_wife_partner_50_and_children_50_heir': 'Make/maka/Sambo till hälften (50%) och barn till hälften (50%). Om sådana saknas, arvingar',
    'husband_wife_partner_children_heir': 'Make/maka/sambo eller, om sådan saknas, barn eller, om sådan saknas, arvingar',
    'income_source_settings': 'Källa till kapital',
    'individual_investments': 'Enstaka investeringar',
    'industry_employers_careers_organisation': 'Bransch-, arbetsgivar- & yrkesorg',
    'inheritance_gift': 'Arv/Gåva',
    'insurance_company': 'Försäkringsbolag',
    'insurance_payout': 'Försäkringsutbetalning',
    'investment_representative': 'Bolagsrepresentant',
    'known_associate': 'Känd medarbetare',
    'last_name_error': 'Fyll i ditt efternamn',
    'last_name': 'Efternamn',
    'law_economics_consulting': 'Juridik, ekonomi & konsulttjänster',
    'less_then_x_year': `Mindre än ${v.year || 1} år`,
    'livelihood': 'Försörjningsstöd',
    'loading': 'Laddar',
    'logging_in': 'Loggar in',
    'login_declined': 'Inloggning nekades',
    'master': 'Masterexamen',
    'masters_degree': 'Magisterexamen',
    'me': 'Jag',
    'media': 'Media',
    'money_origin_other': 'Annat',
    'money_transferred_from_zone': 'Välj land',
    'money_transferred_from': 'Varifrån kommer pengarna överföras?',
    'motor_vehicle_trade': 'Motorfordonshandel',
    'my_details_company': 'Uppgifter för bolag och investeringsrepresentant',
    'c_my_details_company': 'Uppgifter för bolag och bolagsrepresentanten',
    'my_details': 'Mina uppgifter',
    'my_pages': 'Mina sidor',
    'no_beneficiary': 'Ingen förmånstagare: Pengarna betalas ut enligt arvsordningen till försäkringstagarens arvingar.',
    'no': 'Nej',
    'none': 'Inget av nedanstående alternativ stämmer in på mig',
    'nordics': 'Norden',
    'ongoing_investments': 'Löpande investeringar',
    'other_bank': 'Annan bank',
    'other_code': 'Från vilket land kommer dina pengar överföras ifrån?',
    'other_consumer_services': 'Övriga konsumenttjänster',
    'other_eu_country': 'Annat land inom EU',
    'other_income_source': 'Annan inkomstkälla',
    'other_none_eu_country': 'Land utanför EU',
    'other': 'Annat',
    'own_money': 'Äger du pengarna själv?',
    'payments': 'Jag underhåller ett stående betalningsuppdrag för överföringar eller regelmässiga betalningar till amerikanskt konto',
    'pensioner': 'Pensionär',
    'per_or_co_adress_in_usa': 'Jag har en ”per”-/ ”c/o”-adress i USA',
    'phd': 'PhD',
    'phone_error': 'Fyll i ditt giltiga telefonnummer',
    'phone': 'Telefonnummer',
    'please_fill_in_tax_countries_co': 'Vänligen ange bolagets samtliga skattemässiga hemvister nedan',
    'please_fill_in_tax_countries': 'Vänligen fyll i alla länder du är skattepliktig i',
    'please_observe_no_autogiro': 'Vänligen observera att detta konto även kommer användas vid eventuella autogirobetalningar',
    'politicians': 'Ledamot i styrelse för ett politiskt parti',
    'prepare_for_autogiro': 'Vårt rekommenderade betalningsalternativ när du vill investera är via Autogiro. Önskar du använda Autogiro för betalningar?',
    'preparing_accounts_sections': 'Förberedelse för autogiro och depå',
    'preselected_bankaccount': 'Föranmält bankkonto',
    'previous_investments': 'Tidigare investeringar',
    'primary_degree': 'Grundskoleexamen',
    'primary_occupation_settings': 'Huvudsakligt arbetsområde',
    'primary_occupation': 'Vilket är ditt huvudsakliga arbetsområde?',
    'profits_daily_operations': 'Vinster från den dagliga verksamheten',
    'property_sale': 'Fastighetsförsäljning',
    'proxy_test': 'Fullmakt',
    'proxy': 'Jag har en giltig fullmakt eller befogenhet att underteckna till förmån för en person eller ett företag med adress i USA',
    'public_administration_society': 'Offentlig förvaltning & samhälle',
    'real_estate': 'Fastighetsverksamhet',
    'rental_leasing': 'Uthyrning & leasing',
    'repair_installation': 'Reparation & installation',
    'retail': 'Detaljhandel',
    'salary_pension': 'Lön/Pension',
    'science': 'Natur',
    'self-employed': 'Egenföretagare',
    'share_dividend_from_own_company': 'Aktieutdelning från eget bolag',
    'sign_bankid': 'Signera med BankID',
    'signatory': 'Firmatecknare',
    'specify_advisor_error': 'Fyll i namnet på din rådgivare',
    'specify_advisor': 'Rådgivarens namn',
    'specify_who_political_risk': 'Specificera vem som är politiskt utsatt',
    'ssn_error': 'Fyll i ett giltigt personnummer i formatet ååååmmddxxxx',
    'ssn_placeholder': 'ååååmmddxxxx',
    'ssn': 'Personnummer',
    'start_page': 'Startsidan',
    'start': 'Starta',
    'state_owned_enterprise_executive': 'Person som ingår i statsägd företagsförvaltnings-, lednings- eller kontrollorgan',
    'student': 'Student',
    'domestic_bank': 'Svensk Bank',
    'domestic_insurance_co': 'Svenskt Försäkringsbolag',
    'advisor_error': 'Vänligen välj JA eller NEJ',
    'tax_residence_code_co': 'TIN/Organisationsnummer',
    'tax_residence_code': 'TIN/Personnummer',
    'tax_residence_domestic_only': 'Är du enbart skatteskyldig i Sverige?',
    'tax_resident_1': 'Skattemässig hemvist',
    'technical_consultancy': 'Teknisk konsultverksamhet',
    'terms_and_conditions_pdf': 'Villkor',
    'terms_autogiro_regitration': 'Villkor för Autogiro registrering',
    'the_company': 'Bolaget',
    'transfer_from': 'Överföring från',
    'travel_agency_tourism': 'Resebyrå & turism',
    'us_phone': 'Jag har ett amerikanskt telefonnummer (fast eller mobil)',
    'waiting_signing': 'Inväntar signering',
    'wastewater_waste_electricity_water': 'Avlopp, avfall, el & vatten',
    'what_is_your_employment': 'Vilken är din sysselsättning?',
    'wholesale': 'Partihandel',
    'yes': 'Ja',
    'zip_error': 'Fyll i ditt giltiga postnummer',
    'zip': 'Postnummer',

    // Start signup
    'corporate_clients': 'Företagskund',
    'private_customer': 'Privatkund',
    'signup_description': 'Genom att bli kund hos oss får du tillgång till vår plattform och kan genomföra investeringar. Du kommer även få ett depåkonto - givetvis utan extra kostnad!',
    'signup_title': 'Bli Kund på bara några minuter',

    // Signup completed
    'you_are_registered': 'Du är registrerad!',
    'see_capital_insurance_application_below': 'Nedan har du Quantum Lebens ansökan för att öppna upp bolagets kapitalförsäkring. Vänligen fyll i och skicka in den till Kundservice@hubins.com ',
    'hubins_registration_completed': 'Du har nu genomfört företagsregistreringen hos Hubins.',

    'thanks': 'Tack!',
    'signatory_thanks_message': 'Vi har registrerat dina uppgifter. När alla parter har genomfört registreringen kommer ditt företag kunna göra investeringar hos oss.',

    // Signatory view
    'your_company_account_has_been_registered_p1': <>{v.name} angav ditt namn som bolagsperson i {v.companyName} och därför behöver även du fylla i några uppgifter samt skriva på en fullmakt som ger {v.name} rätt att genomföra investeringar för bolagets räkning.</>,
    'your_company_account_has_been_registered_principal_p1': <>{v.name} angav ditt namn som bolagsperson i {v.companyName} och därför behöver även du fylla i några uppgifter.</>,
    'your_company_account_has_been_registered_p2': 'Om du har några frågor kring varför du behöver göra detta är du varmt välkommen att höra av dig till oss.',




    //countrys - remove
    'sweden': 'Sverige',
    'norway': 'Norge',

    'ownership_in_percentage': 'Ägande i %',
    no_signatories: 'Inga firmatecknare',
    no_beneficials: 'Inga bolagspersoner',
    'sign_with_other_signatory': 'Har du rätt att teckna firman tillsammans med annan firmatecknare?',
    'type_of_acting_principal': 'Typ av verklig huvudman',
    'valid_org_number': 'Fyll i ett giltigt organisationsnummer i formatet xxxxxx-xxxx',
    'more_benefitial_owners_then_above': 'Utöver de personer som visas ovan, finns det ytterligare bolagspersoner?',
    'when_to_add_bo_info': 'Hubins använder sig av roaring.io för att hämta hem information om er och övriga bolagspersoner.',
    'add_bo_help_text': 'Vänligen notera att nedan bolagspersoner kommer få ett mail och behöver även logga in på plattformen. Väl inne kommer bolagspersonen svara på frågor i egenskap av att vara verklig huvudperson samt godkänna en fullmakt till bolagsrepresentanten. Er ansökan är inte komplett förrän samtliga bolagspersoner har loggat in och gått igenom deras delar.',
    'add_bo_bankid_note': 'Notera att det är är du som bolagsrepresentant som signerar med ditt personliga BankID i detta steg av registreringen.',

    // Company registration - Signup 2
    'company_information': 'Företagsuppgifter',
    'senior_executive_position': 'Har du en senior chefsposition i bolaget?',
    'specify_determing_influence_company': 'Vänligen specificera hur du har bestämmande inflytande över bolaget',
    'lei_title': 'LEI-nummer',
    'lei_label': 'LEI-Nummer',
    'please_observe_company_payment': 'Alla utbetalningar kommer gå till kontot du anger.',

    'principal': 'Huvudman',

    // Signatorys fullmakt
    'principal_1': 'Första huvudmannen',
    'principal_2': 'Andra huvudmannen',
    'principal_3': 'Tredje huvudmannen',
    'principal_4': 'Fjärde huvudmannen',
    'principal_5': 'Femte huvudmannen',
    'principal_6': 'Sjätte huvudmannen',
    'principal_7': 'Sjunde huvudmannen',

    'signatory_1': 'Första firmatecknaren',
    'signatory_2': 'Andra firmatecknaren',
    'signatory_3': 'Tredje firmatecknaren',
    'signatory_4': 'Fjärde firmatecknaren',
    'signatory_5': 'Femte firmatecknaren',
    'signatory_6': 'Sjätte firmatecknaren',
    'signatory_7': 'Sjunde firmatecknaren',

    //Banker - no translation needed
    'swedsess': 'Swedbank',
    'essesess': 'SEB',
    'handsess': 'Handelsbanken',
    'ndeasess': 'Nordea',

    'standard_user_error_message': 'Något har tyvärr gått fel',
    'standard_user_error_reason': 'Det kan vara så att ditt konto redan är registrerat, klicka i så fall på länken nedan för att logga in.',
    'standard_user_error_action': 'Om felet kvarstår ombeds du kontakta kundservice enligt nedan.',
    'login': 'Logga in',
    'has_created_a_company_account': <>{v.name} har skapat ett företagskonto hos oss.</>,
    policyholder: 'Försäkringstagare',
    insurance_currency: 'Försäkringsvaluta',
    issuing_date: 'Utfärdande datum',
    insurance_distributor: 'Försäkringsdistributör',
    ql_org_nr_label: 'Organisationsnummer:',
    ql_org_nr: 'FL-0002 124 995-9',
    hubins_org_nr: '559184-0003',
    hubins_org_nr_label: 'Organisationsnummer:',
    insurance_number: 'Försäkringsnummer',
    life_asured: 'Livförsäkrad', // heter life_assured - finns korrekt key nedan
    according_to_beneficiaries: 'Enligt förmånstagarförordnandet',
    "warning": "Varning",
    "wish_to_change_form": "Om du går tillbaka för att justera tidigare svar, kommer svaren du angett i på den här sidan inte att sparas.",
    'client_information': 'Kundinformation',
    "policy_application_&_kyc": 'Försäkringsansökan och Kundkännedom',
    'policy_application_imported_&_kyc': 'Försäkringsuppdatering och Kundkännedom',
    'usa_relation': 'Är du skattskyldig i USA eller har någon annan koppling till USA?',
    p_kt: 'Kunskapstest',
    p_da: 'Krav- och Behovsprövning',
    p_da_terms: 'Krav- och Behovsprövning',
    p_da_purpose: 'Syfte',
    p_da_transactions: 'Transaktioner',
    p_da_products: 'Tillgångsslag',
    p_da_target_return: 'Målavkastning',
    p_da_tax: 'Skatter & avgifter',
    p_da_beneficiaries: 'Förmånstagare',
    c_da_terms: 'Krav- och Behovsprövning',
    c_da_purpose: 'Syfte',
    c_da_transactions: 'Transaktioner',
    c_da_products: 'Tillgångsslag',
    c_da_target_return: 'Målavkastning',
    c_da_tax: 'Skatter & avgifter',
    c_da_beneficiaries: 'Förmånstagare',
    p_terms: 'Villkor & Godkännande',
    c_kt: 'Kunskapstest',
    c_da: 'Krav- och Behovsprövning',
    c_terms: 'Villkor & Godkännande',
    a_policy: 'Due Diligence Checks',
    a_policy_verdict: 'Due Diligence Checks',
    p_kt_done: 'Kunskapstest genomfört',
    approved: 'Godkänt',
    p_ins: 'Försäkringsansökan och Kundkännedom',
    c_ins: 'Försäkringsansökan och Kundkännedom',
    c_bi: 'Bolagsperson och Verklig Huvudperson',
    c_hm: 'Kundkännedom Bolagsrepresentant',
    p_roaring: 'Personuppgifter',
    p_kt_start: 'Kunskapstest',
    c_kt_start: 'Kunskapstest',
    p_presale: 'Förköpsinformation',
    c_presale: 'Förköpsinformation',
    p_da_so: 'Resultat Krav- och Behovsprövning',
    c_da_so: 'Resultat Krav- och Behovsprövning',
    p_aftersale: 'Efterköpsinformation',
    c_aftersale: 'Efterköpsinformation',
    s_kyc: 'Kundkännedom',
    s_signoff: 'Signering av fullmakt',
    b_signoff: 'Signering av fullmakt',
    funds_new_name: 'Värdepappersfonder (ex. aktiefond, räntefond eller blandfond från bank)',
    da_crypto_title: 'Kryptotillgångar',
    da_signoff_51g: 'Du har uppgett att du har behov av, eller önskemål om, att kunna hålla kryptotillgångar i din kapitalförsäkring.',
    c_da_signoff_51g: 'Ni har uppgett att bolaget har behov av, eller önskemål om, att kunna hålla kryptotillgångar i bolagets kapitalförsäkring.',
    da_signoff_51g_clari: 'Den kapitalförsäkring som Hubins distribuerar är förenlig med dina krav och behov avseende kryptotillgångar.',
    c_da_signoff_51g_clari: 'Den kapitalförsäkring som Hubins distribuerar är förenlig med bolagets krav och behov avseende kryptotillgångar.',
    da_crypto_text: 'Dina behov och önskemål om att hålla kryptotillgångar',
    c_da_crypto_text: 'Bolagets behov och önskemål om att hålla kryptotillgångar',
    da_crypto_check: <>
      <p>Kryptotillgångar är komplexa instrument och är i de flesta fall olämpliga för konsumenter att investera i. Kryptotillgångar är förknippade med mycket hög risk och kan leda till att ni förlorar era investerade pengar. Innan ni investerar i kryptotillgångar bör ni se till att ni förstår hur kryptotillgångar fungerar och se över om ni har råd att förlora hela det investerade beloppet. </p>
      <p>Det finns även andra risker kopplat till investeringar i kryptotillgångar. Det saknas i de flesta fall reglering och tillsyn av kryptotillgångar och det finns generellt sett inte några konsumentskyddande regler. Det kan vara svårt eller omöjligt att värdera kryptotillgångar. Risken för att förlora hela det investerade beloppet är mycket stor. </p>
      <p>Utvinning av vissa kryptotillgångar, såsom Bitcoin, innebär även ett hot mot klimatomställningen då det kräver en mycket hög elanvändning. </p>
      <p>För mer information om de risker förknippade med kryptotillgångar som Finansinspektionen uppmärksammat, se länken nedan.</p>
      <a className="link" rel="noreferrer" href="https://www.fi.se/sv/konsumentskydd/kryptotillgangar/" target="_blank">https://www.fi.se/sv/konsumentskydd/kryptotillgangar/</a>
      <br />
      <br />
      <p>Du bekräftade även att du har tagit del av informationen ovan och är medveten, förstår och accepterar riskerna förknippade med kryptotillgångar.</p>
    </>,
    c_da_crypto_check: <>
      <p>Kryptotillgångar är komplexa instrument och är i de flesta fall olämpliga för konsumenter att investera i. Kryptotillgångar är förknippade med mycket hög risk och kan leda till att ni förlorar era investerade pengar. Innan ni investerar i kryptotillgångar bör ni se till att ni förstår hur kryptotillgångar fungerar och se över om ni har råd att förlora hela det investerade beloppet. </p>
      <p>Det finns även andra risker kopplat till investeringar i kryptotillgångar. Det saknas i de flesta fall reglering och tillsyn av kryptotillgångar och det finns generellt sett inte några konsumentskyddande regler. Det kan vara svårt eller omöjligt att värdera kryptotillgångar. Risken för att förlora hela det investerade beloppet är mycket stor. </p>
      <p>Utvinning av vissa kryptotillgångar, såsom Bitcoin, innebär även ett hot mot klimatomställningen då det kräver en mycket hög elanvändning. </p>
      <p>För mer information om de risker förknippade med kryptotillgångar som Finansinspektionen uppmärksammat, se länken nedan.</p>
      <a className="link" rel="noreferrer" href="https://www.fi.se/sv/konsumentskydd/kryptotillgangar/" target="_blank">https://www.fi.se/sv/konsumentskydd/kryptotillgangar/</a>
      <br />
      <br />
      <p>Du/ni bekräftade även att du/ni har tagit del av informationen ovan och är medveten, förstår och accepterar riskerna förknippade med kryptotillgångar.</p>
    </>,
    easier_declaration: 'Enklare deklaration',
    company_personnel: 'Firmatecknare & Bolagspersoner',
    choose_person: 'Välj person',
  }
});

export default keys;
