import React from 'react';
import { __, __route } from 'localisation';
import { formatMoney, formatPercent, formatNumber } from 'helpers';
import Button from '@hubins/components/Button';

// const buySell = () => {
//   return {
//     Header: `${__('Buy')} | ${__('Sell')}`,
//     accessor: "toolsBeginning",
//     minWidth: 115,
//     maxWidth: 120,
//     className: 'rt-cell-button',
//     sortable: false,
//     Footer: <div className="flex-center--horizontal">{__('summary')}</div>
//   };
// };

const currency = () => {
  return {
    Header: __('the_currency'),
    accessor: "currency",
    maxWidth: 70,
  };
};


const security = () => {
  return {
    Header: __('investment'),
    accessor: "security",
    minWidth: 200,
  };
};

const isin = () => {
  return {
    Header: __('isin'),
    accessor: 'securityIsinCode',
    width: 120,
  };
};

const code = () => {
  return {
    Header: __('code'),
    accessor: 'securityIsinCode',
    width: 120
  };
};



const yearlyRate = () => {
  return {
    Header: __('yearly-rate'),
    accessor: "coupon",
    Cell: ({ row }) => (formatPercent(row.original.coupon)),
    width: 70
  };
};

const marketUnitPricePercent = () => {
  return {
    Header: __('latest_rate'),
    accessor: "marketUnitPrice",
    Cell: ({ row }) => formatPercent(row.original.marketUnitPrice),
    width: 100,
  };
};
const marketUnitPriceSEK = () => {
  return {
    Header: __('latest_rate'),
    accessor: "marketUnitPrice",
    Cell: ({ row }) => formatMoney(row.original.marketUnitPrice, row.original.currency, 2),
    width: 120
  };
};


const purchasePriceSEK = () => {
  return {
    Header: __('purchase_price'),
    accessor: "purchaseUnitPrice",
    Cell: ({ row }) => formatMoney(row.original.purchaseUnitPrice, row.original.currency, 2),
    width: 120,
  };
};

const purchasePricePercent = () => {
  return {
    Header: __('purchase_price'),
    accessor: "purchaseUnitPrice",
    Cell: ({ row }) => formatPercent(row.original.purchaseUnitPrice),
    width: 100,
  };
};

const amount = () => {
  return {
    Header: __('shares'),
    accessor: "amount",
    Cell: ({ row }) => formatNumber(row.original.amount, 0),
    width: 110,
  };
};

const decimalAmount = (numberOfDecimals) => {
  return {
    Header: __('shares'),
    accessor: "amount",
    Cell: ({ row }) => formatNumber(row.original.amount, numberOfDecimals, numberOfDecimals)
  };
};

const marketValue = (footer) => {
  return {
    Header: __('value'), // earlier market_value aa
    accessor: "marketValue",
    Cell: ({ row }) => formatMoney(row.original.marketValue, row.original.portfolioCurrency, 2),
    Footer: ({ row }) => {
      return formatMoney(footer.marketValue, row.data[0]._original.portfolioCurrency, 2);
    },
    width: 150,
  };
};

const accruedInterest = () => {
  return {
    Header: __('accrued_interest'),
    accessor: "accruedInterest",
    Cell: ({ row }) => (formatMoney(row.original.accruedInterest, row.original.portfolioCurrency, 2)),
    width: 120
  };
};

// const yieldInPercent = (footer) => {
//   return {
//     Header: __('yield_percent'),
//     accessor: "totalReturn",
//     Cell: (row) => (formatPercent(row.original.totalReturn * 100))
//   };
// };

const yieldInCurrency = (footer) => {
  return {
    Header: __('yield'),
    accessor: "totalNetProfitsInclAccruedInterest",
    Footer: ({ row }) => formatMoney(footer.totalNetProfitsInclAccruedInterest, row.data[0]._original.portfolioCurrency, 2),
    Cell: ({ row }) => formatMoney(row.original.totalNetProfitsInclAccruedInterest, row.original.portfolioCurrency, 2)
  };
};

const maturityDate = () => {
  return {
    Header: __('end_date'),
    accessor: "maturityDate",
    Cell: ({ row }) => (row.original.maturityDate),
    width: 100,
  };
};

const casePage = () => {
  return {
    Header: '',
    accessor: "page-link",
    sortable: false,
    minWidth: 60,
    maxWidth: 100,
    className: 'rt-cell-button',
    Cell: ({ row }) => (
      <Button className='transparent' href={`${__route('case')}/${row.original.id}`} onlyIcon icon="arrow-right" iconColor='primary' />
    )
  };
};

const roycPage = () => {
  return {
    Header: '',
    accessor: "royc-link",
    sortable: false,
    minWidth: 150,
    maxWidth: 150,
    className: 'rt-cell-button',
    Cell: () => (
      <Button className="transparent" external href="https://www.roycgroup.com/" size="small" iconSize={8} icon="arrow right">
        Gå till Platform
      </Button>
    )
  };
};



const yearlyRateColumns = (footer, report) => {
  return [
    // buySell(),
    ...report ? [] : [casePage()],
    currency(),
    security(),
    isin(),
    yearlyRate(),
    maturityDate(),
    marketUnitPricePercent(),
    purchasePricePercent(),
    amount(),
    marketValue(footer),
    accruedInterest(),
    yieldInCurrency(footer),
  ];
};

const stockColumns = (footer, report) => {
  return [
    // buySell(),
    ...report ? [] : [casePage()],
    currency(),
    security(),
    isin(),
    marketUnitPriceSEK(),
    purchasePriceSEK(),
    amount(),
    marketValue(footer),
    yieldInCurrency(footer),
  ];
};

const roycColumns = (footer, report) => {
  return [
    // buySell(),
    ...report ? [] : [casePage()],
    currency(),
    security(),
    isin(),
    marketUnitPriceSEK(),
    purchasePriceSEK(),
    amount(),
    marketValue(footer),
    roycPage(),
  ];
};

const cryptoColumns = (footer, report) => {
  return [
    ...report ? [] : [casePage()],
    currency(),
    security(),
    code(),
    marketUnitPriceSEK(),
    purchasePriceSEK(),
    decimalAmount(8),
    marketValue(footer),
    yieldInCurrency(footer),
  ];
};
const optionColumns = (footer, report) => {
  return [
    ...report ? [] : [casePage()],
    currency(),
    security(),
    code(),
    marketUnitPriceSEK(),
    purchasePriceSEK(),
    amount(),
    marketValue(footer),
    yieldInCurrency(footer),
  ];
};


export default  {
  "PE Fund": roycColumns,
  PROMNOTE: yearlyRateColumns,
  BOND: yearlyRateColumns,
  STOCK: stockColumns,
  LISTEDSTOCK: stockColumns,
  FUND: stockColumns,
  STRUCTURED: stockColumns,
  CRYPTOCURRENCY: cryptoColumns,
  OPTION: optionColumns,
  '*': optionColumns,
};
