import { getKey, getPlural, getLanguage } from '../localisationFunctions';
import LocalContainer from '../LocalContainer';
import { route } from '../route';
import SE from '../data/hubins/registration_se';
import c_SE from '../data/common/registration_se';

const LANGUAGE = getLanguage();

import EN from '../data/hubins/registration_en';
import c_EN from '../data/common/registration_en';

const languageObjects = {
  'SE': SE,
  'EN': EN,
};

const commonObjects = {
  'SE': c_SE,
  'EN': c_EN,
};

export const __ = (string, vars) => {
  return LocalContainer({ method: getKey, string: string, vars: vars, market: "SE", language: LANGUAGE, languageObjects, commonObjects, fallbackObject: c_EN });
};

export const __SE = (string, vars) => {
  return LocalContainer({ method: getKey, string: string, vars: vars, market: "SE", language: "SE", languageObjects, commonObjects, fallbackObject: c_EN });
};

export const __plural = (string, number, vars) => {
  return LocalContainer({ method: getPlural, string: string, vars: vars, number: number, market: "SE", language: LANGUAGE, languageObjects, commonObjects, fallbackObject: c_EN });
};

export const __route = (key, props) => route(key, props, LANGUAGE);

export default __;
