import React, { useState, useEffect, useRef } from 'react';
import BankIdModal from 'modules/BankIdModal';
import middleman, { csrf } from 'helpers/middleman';

// const returnUrl = encodeURIComponent(window.location.href);

const BankID = ({
  initBody,
  initEndpoint,
  statusEndpoint,
  callback,
  abortCallback,
  hubins,
  useCsrf,
}) => {

  const [reference, setReference] = useState(false);
  const [error, setError] = useState(false);
  const [signStatus, setSignStatus] = useState('init');

  const abortController = useRef(new AbortController());

  const processCallback = (res) => {
    // If there is a reference, post poll for status
    if (res.authReference) {
      setReference(res.authReference);
    }
  };

  const fetchData = async () => {

    if (useCsrf) {
      await csrf();
    }

    middleman
      .promiseKeepAlivePost(initEndpoint, initBody, abortController.current.signal)
      .then(res => processCallback(res))
      .catch(e => {
        setError(true);
        console.error('INITERROR: ', e.message);
      });
  };

  const resetBankID = async () => {
    abortController.current.abort();
    abortController.current = new AbortController();
    if (reference) {
      await middleman.promisePost('/bauth/abort', { authReference: reference });
    }
    setReference(false);
    setSignStatus('init');
  };

  useEffect(() => {
    if (signStatus === 'init') {
      setError(false);
      setReference(false);
      setSignStatus('pending');
      fetchData();
    }

  }, [signStatus]);

  const poll = () => {
    middleman
      .promiseKeepAlivePost(statusEndpoint, {
        authReference: reference
      }, abortController.current.signal)
      .then(response => {
        if (response.status === 'pending') {
          setTimeout(poll, 1000);
          return;
        }
        if (response.httpStatus === 201) {
          setSignStatus('complete');
          if (callback) {
            callback(response);
          }
          return;
        }
        return;
      }).catch(e => {
        setError(true);
        console.error('STATUSERROR', e);
      });
  };

  useEffect(() => {
    if (reference) {
      poll();
    }
  }, [reference]);

  const handleAbort = async () => {
    if (abortCallback) {
      abortCallback();
    }
    abortController.current.abort();
    abortController.current = new AbortController();
    middleman.promisePost('/bauth/abort', { authReference: reference });
    setReference(false);
  };

  return (
    <BankIdModal
      open={true}
      error={error}
      platform={hubins ? 'Hubins' : 'ql'}
      handleSubmit={() => { setReference(false); }}
      handleErrorSubmit={resetBankID}
      handleAbort={handleAbort}
    />
  );
};

BankID.defaultProps = {
  hubins: false
};

export default BankID;
