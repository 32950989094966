import React from 'react';
import Icon from '@hubins/components/IconNew';
import { formatNumberByThousands } from 'helpers';
import './index.scss';
import { keyToIcon } from 'helpers/sof';


const SourceItem = ({ amount, totalAmount, type }) => {
  const percentOfTotalAmount = Math.round((amount / totalAmount) * 100);
  return (
    <li
      className='source-progress__item'
      style={{ width: `${percentOfTotalAmount}%` }}
    >
      {/* TODO: dynamic icon based on type */}
      <Icon icon={keyToIcon[type]} size={16} />
    </li>
  );
};

const SourceProgress = ({
  totalAmount,
  sourceAmounts,
  sources,
  totalSelectedSum
}) => {
  return (
    <>
      <div className='source-progress__labels'>
        <div className='source-progress__title'>
        Beloppsmätare
        </div>
        <div className='source-progress__result'>
          {formatNumberByThousands(totalSelectedSum)} / {formatNumberByThousands(totalAmount)} SEK
        </div>
      </div>
      <ul className='source-progress'>
        {sourceAmounts.map((source, index) => (
          <SourceItem
            key={`source-${index}`}
            amount={source}
            totalAmount={totalAmount}
            type={sources[index].type}
          />
        ))}
      </ul>

    </>
  );
};

export default SourceProgress;
