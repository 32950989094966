import apiConnector from "api-connector";
import axios from "axios";

const middlemanURL = import.meta.env.VITE_MIDDLEMAN_URL;
const middleman = apiConnector(middlemanURL + "/api");


export const csrf = () => {
  const csrf_url = middlemanURL + "/sanctum/csrf-cookie";
  return axios.get(csrf_url, { withCredentials: true });
};

export default middleman;
