import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useCollapse } from 'react-collapsed';
import Icon from '@hubins/components/IconNew';
import './index.scss';

const Accordeon = ({ defaultOpen, buttonStyle, buttonNoStyle, iconSize, label, className, children }) => {

  const [ isExpanded, setExpanded ] = useState(defaultOpen);
  const handleClick = () => {setExpanded(!isExpanded);};
  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });

  return (
    <>
      <button
        {...getToggleProps({ onClick: handleClick })}
        className={'Accordeon ' + (buttonNoStyle ? buttonStyle : `button button--accordeon large withicon ${buttonStyle}`)}
      >
        {label}
        <Icon
          icon="arrow down 1"
          size={iconSize}
          ariaHidden
          anim={isExpanded ? 'active rotate-cw-180' : ''}
        />
      </button>
      <div {...getCollapseProps()}>
        <div className={className}>
          {children}
        </div>
      </div>
    </>
  );
};

Accordeon.propTypes = {
  className: PropTypes.string,
  isExpanded: PropTypes.bool,
  label: PropTypes.string,
  buttonStyle: PropTypes.string,
  buttonNoStyle: PropTypes.bool,
};

Accordeon.defaultProps = {
  className: '',
  isExpanded: false,
  buttonStyle: '',
  iconSize: 16,
};

export default Accordeon;
