import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import Placeholder from 'components/Placeholder';
import { TradeOrderCard, DepositCard, StockCard } from 'modules/EventCard';
import { __ } from 'localisation';
import LoadMore from 'modules/LoadMore';
import  ErrorBoundary  from "components/ErrorBoundary";

const EventCards = (props) => {

  const renderCards = (cardsData) => {
    return (
      <>
        <ErrorBoundary>

          {!cardsData &&
          <Placeholder image="/illustrations/events_empty.svg" className="s-bottom-m-womq placeholder--2columns event-cards__empty">
            <p>{__('no_events_at_the_moment')}</p>
            {/* <Button href={__route('invest')}>{__('invest')}</Button> */}
          </Placeholder>
          }
          {cardsData &&
          <ErrorBoundary>
            <LoadMore
              initialItemsVisible={3}
              numberOfItemsToExpand={3}
              cards={
                cardsData.map((entry) => {
                  switch (entry.type.typeCode) {
                    case 'PRE':
                      return (
                        <DepositCard
                          data={entry}
                          cardtype={props.type}
                          key={entry.id}
                          deleteCallback={() => props.deleteTradeOrder({
                            'o.parentPortfolio': entry.parentPortfolio.shortName,
                            'o.id': entry.id,
                            'o.extId': entry.extId,
                          })}
                        />
                      );
                    case 'S':
                    case 'B':
                    default:
                      if (entry.security && entry.security.type.code === 'STOCK') {
                        return <StockCard data={entry} />;
                      } else {
                        return (
                          <TradeOrderCard
                            data={entry}
                            cardtype={props.type}
                            key={entry.id}
                            deleteCallback={() => props.deleteTradeOrder({
                              'o.parentPortfolio': entry.parentPortfolio.shortName,
                              'o.id': entry.id,
                              'o.extId': entry.extId,
                            })}
                          />
                        );
                      }
                  }
                })
              }
            />
          </ErrorBoundary>
          }
        </ErrorBoundary>

      </>
    );
  };



  return (
    props.data || props.showemptystate ?
      renderCards(props.data) :
      false
  );

};

EventCards.propTypes = {
  data: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
  title: PropTypes.string,
  type: PropTypes.string,
};

export default EventCards;
