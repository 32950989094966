import React, { useEffect } from 'react';
import { useInView } from "react-intersection-observer";


const ScrollToEndTrigger = ({ children, callback, offset, triggerOnce }) => {

  const [ref, inView] = useInView({
    /* Optional options */
    threshold: 0.2,
    triggerOnce: triggerOnce,
  });

  useEffect(() => {
    callback(inView);
  }, [inView]);

  return (
    <>
      {children}
      <div style={{ position: 'relative', pointerEvents: 'none' }}>
        <div ref={ref} style={{ height: 1, position: 'absolute', top: offset || 0 }}/>
      </div>
    </>
  );
};

export default ScrollToEndTrigger;
