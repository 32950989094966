import React, { useState, useEffect, useRef } from 'react';
import __, { __route } from 'localisation';
import Heading from 'components/Heading';
import Main from 'components/Main';
import SourceProgress from 'components/SourceProgress';
import SourceCards from 'components/SourceCards';
import { Columns, Column } from 'components/Columns';
import { LoadingBox } from 'components/LoadingIndicators';
import Button from '@hubins/components/Button';
import ErrorMessage from 'components/ErrorMessage';
import { Redirect } from 'react-router-dom';
import SourceOfFundsSummary from '@hubins/components/SourceOfFundsSummary';
import { step3 } from './tutorialGuides';
import Tutorial from 'components/Tutorial';

import middleman from 'helpers/middleman';


const add = (current, value) => {
  current += value;
  return current;
};

const Source = ({
  transaction,
  portfolioUuid,
  sources,
  loading: propsLoading,
  isHighRisk,
  setSources,
  innerRef,
  setButtonLoading,
}) => {

  const [sourceAmounts, setSourceAmounts] = useState(false);
  const [error, setError] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const steps = useRef(step3());

  useEffect(() => {
    if (sources && !sourceAmounts) {
      setSourceAmounts(sources.map(() => 0));
    }
  }, [sources]);

  const submit = async () => {
    try {
      setButtonLoading(true);
      const usedSources = sources.map((source, index) => {
        return {
          'id': source.uuid,
          'amount': sourceAmounts[index]
        };
      }).filter(source => {
        return source.amount > 0;
      });

      const usedAmount = usedSources.reduce((sum, item) => {
        sum += Number(item.amount);
        return sum;
      }, 0);

      if (usedAmount !== transaction.amount / 100) {
        setButtonLoading(false);
        setError(__('insufficient_transaction_amount'));
        return;
      }

      // TODO: handle response? Show if source needs proof etc
      const response =  await middleman.promisePost(`/transactions/update/${portfolioUuid}`, {
        id: transaction.uuid,
        sources: usedSources
      }).then(res => res.data);

      if (response.status === 'pending') {
        setRedirect(`${__route("depositInstructions")}/s/${response.uuid}`);
        return;
      }

      setRedirect(__route('root'));
    } catch(e) {
      console;
      setError(__('common_error_validation'));
      setButtonLoading(false);
    }
  };

  if (redirect) return <Redirect to={redirect} />;

  if (sources === false || sourceAmounts === false || propsLoading) return <LoadingBox />;

  const totalSelectedSum = sourceAmounts.reduce(add, 0);

  return (
    <Main>
      <Tutorial
        steps={steps.current}
      />
      <Columns>
        <Column m="6" l="7" xl="9">
          <Heading size="3">
            Välj var dina pengar kommer ifrån
          </Heading>
          <ol className='s-top-xl'>
            <li>Välj de källor du kommer använda för just denna insättning. </li>
            <li>Se beloppsmätare fyllas upp när du lägger till källor.</li>
            <li>När beloppsmätaren är full, kan du gå vidare.</li>
          </ol>
          <p>Hittar du inte dina källor för denna insättning, kan du gå till steget “Lägg till och uppdatera källor” via statusfältet nedan. </p>
        </Column>
        <Column m="6" l="5" xl="3" className="s-bottom-xl">
          <SourceOfFundsSummary sources={sources} isHighRisk={isHighRisk} />
        </Column>
        <Column s="12">
          <SourceProgress
            totalSelectedSum={totalSelectedSum}
            totalAmount={transaction.amount / 100}
            sourceAmounts={sourceAmounts}
            sources={sources}
          />
        </Column>
        <Column s="12">
          <Heading size="4">Välj källa för insättning</Heading>
        </Column>
        <SourceCards
          totalAmount={transaction.amount / 100}
          sourceAmounts={sourceAmounts}
          sources={sources}
          setSources={setSources}
          setSourceAmounts={setSourceAmounts}
          premium={true}
          portfolioUuid={transaction.portfolio_uuid}
          totalSelectedSum={totalSelectedSum}
          isHighRisk={isHighRisk}
          canSetAmounts={true}
        />
        <Column s="12" className="center padding s-top-l">
          <Button
            className="hidden"
            onClick={submit}
            innerRef={innerRef}
          >
            Genomför insättning
          </Button>
          <ErrorMessage
            text={error}
            showError={error}
          />
        </Column>
      </Columns>
    </Main>
  );
};

export default Source;
