import { useMemo } from 'react';
import Validation from 'components/Validation';
import checkRules from 'modules/CreateForm/checkRules';
import RenderType from './RenderFunctions';
import CompactRenderType from './CompactRenderFunctions';
import methods from './renderMethods';

const fieldHandler = (field, props, section, compact = false) => {

  const RenderFunctions = compact ? CompactRenderType : RenderType;

  const optionsState = props.optionsState[field.id];

  if (!optionsState) return false;

  const fieldIsVisible = checkRules(optionsState.visibleConditions, props.optionsState);

  if (fieldIsVisible) {
    if (!RenderFunctions[field.type]) {
      console.error('Field type: ', field.type, 'does not exist');
      return false;
    }

    return RenderFunctions[field.type](props.rerenderKey, field, optionsState, props.callback[field.type], section.id);
  }
  return false;
};

const handleRenderLogic = (field) => {
  return field.renderLogic.reduce((newField, method) => {
    newField = methods[field.type][method.method](field, method.params);
    return newField;
  }, { ...field });
};

export const getSectionChilds = (section, props, compact = false) => {
  let children = section.fields.map((field) => {

    // this is needed for supporting visibleConditions
    const shouldNotUseMemo =
      (field.visibleConditions && field.visibleConditions.length > 0)
      // Since repeaters are using renderForm aswell they cant use useMemo
      // since the data is dynamic and would change order of hooks wich will result in an error
      || props.repeater;

    if (field.renderLogic) {
      field = handleRenderLogic(field);
    }

    if (shouldNotUseMemo) {
      return fieldHandler(field, props, section, compact);
    }

    return useMemo(() => {
      return fieldHandler(field, props, section, compact);
    }, [JSON.stringify(props.optionsState[field.id])]);
  });

  children = children.filter((child) => child);
  return children;
};

export const validateAll = (formState, setFormState) => {
  let state = { ...formState };
  let errors = [];

  //Loop through state
  for (let key in state) {

    const { value, validation, type, visibleConditions } = state[key];
    //First check if required and validate

    if (!checkRules(visibleConditions, formState)) {
      continue;
    }

    if (validation) {
      validation.some(val => {
        if (typeof Validation[val] !== 'undefined') {
          const result = Validation[val](value, type);
          state[key].error = result;
          if (result) {
            errors.push(key);
          }
          return result;
        }
        return false;
      });
    }
  }

  if (errors.length > 0) {
    setFormState(state);
    return errors;
  }
  return false;
};

export const scrollToError = (errors) => {
  const element = document.getElementById(errors[0]);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
};

export const checkValidKey = (formState, validKey, showButton) => {
  let checkValue = formState[validKey];
  if (!checkValue) { console.error(`Error in checkValidKey with values validKey: ${validKey}, showButton: ${showButton} and formState:`, formState); }
  if (showButton || checkValue.error === false) {
    return true;
  } else {
    return false;
  }
};


/**
 * Breaks object reflections of data received
 * @param {array} formData - the array with the createForm data
 * @returns {array} same as it received but without reflections
 */
export const breakFormDataReflection = (formData) => (
  formData.map(section => {
    let newSection = { ...section };

    newSection.fields = section.fields.map(field => {

      let newField = { ...field };

      if (field.options) {
        field.options = field.options.map(option => (
          { ...option }
        ));
      }

      return newField;

    });
    return newSection;
  })
);

// export const breakTestsFormDataReflection = (slides) => {
//   return [...slides.map(form => (
//     [...breakFormDataReflection(form)]
//   ))];
// };
