import React, { Component } from 'react';
import LoadingModal from 'components/Modal/LoadingModal';
import Main from 'components/Main';

import './index.scss';

class LoadingPage extends Component {
  render() {
    return (
      <Main>
        <LoadingModal />
      </Main>
    );
  }

}

export default LoadingPage;
