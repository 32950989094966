import React from 'react';
// More flags here (MIT licence): https://github.com/lipis/flag-icon-css/tree/master/flags/4x3
// Dont forget to add the title element
import './index.scss';

// Changed clipPath to class instead of id to prevent having the same id
// on different elements on the page for assesability reasons.
const Flag = ({ flag, label }) => {

  const flags = {
    'SE': <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 480" width="32" height="24">
      <defs>
        <clipPath className="flag_se">
          <path fillOpacity=".7" d="M-53.4 0h682.6v512H-53.4z"/>
        </clipPath>
      </defs>
      <title>{label}</title>
      <g clipPath="url(.flag_se)" transform="translate(50) scale(.9375)">
        <g fillRule="evenodd" strokeWidth="1pt">
          <path fill="#006aa7" d="M-121.1.3h256v204.8h-256zm0 306.9h256V512h-256z"/>
          <path fill="#fecc00" d="M-121.1 205h256v102.4h-256z"/>
          <path fill="#fecc00" d="M133.8 0h102.4v512H133.8z"/>
          <path fill="#fecc00" d="M233 205h460.8v102.4H233z"/>
          <path fill="#006aa7" d="M236.2 307.2H697V512H236.2zm0-306.9H697v204.8H236.2z"/>
        </g>
      </g>
    </svg>,
    'NO': <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 480" width="32" height="24">
      <title>{label}</title>
      <path fill="#ed2939" d="M0 0h640v480H0z"/>
      <path fill="#fff" d="M180 0h120v480H180z"/>
      <path fill="#fff" d="M0 180h640v120H0z"/>
      <path fill="#002664" d="M210 0h60v480h-60z"/>
      <path fill="#002664" d="M0 210h640v60H0z"/>
    </svg>,
    'FI': <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 480" width="32" height="24">
      <title>{label}</title>
      <path fill="#fff" d="M0 0h640v480H0z"/>
      <path fill="#003580" d="M0 174.5h640v131H0z"/>
      <path fill="#003580" d="M175.5 0h130.9v480h-131z"/>
    </svg>,
    'EN': <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 480" width="32" height="24">
      <defs>
        <clipPath className="flag_en">
          <path fillOpacity=".7" d="M-85.3 0h682.6v512H-85.3z"/>
        </clipPath>
      </defs>
      <title>{label}</title>
      <g clipPath="url(.lag_en)" transform="translate(80) scale(.94)">
        <g strokeWidth="1pt">
          <path fill="#012169" d="M-256 0H768v512H-256z"/>
          <path fill="#fff" d="M-256 0v57.2L653.5 512H768v-57.2L-141.5 0H-256zM768 0v57.2L-141.5 512H-256v-57.2L653.5 0H768z"/>
          <path fill="#fff" d="M170.7 0v512h170.6V0H170.7zM-256 170.7v170.6H768V170.7H-256z"/>
          <path fill="#c8102e" d="M-256 204.8v102.4H768V204.8H-256zM204.8 0v512h102.4V0H204.8zM-256 512L85.3 341.3h76.4L-179.7 512H-256zm0-512L85.3 170.7H9L-256 38.2V0zm606.4 170.7L691.7 0H768L426.7 170.7h-76.3zM768 512L426.7 341.3H503l265 132.5V512z"/>
        </g>
      </g>
    </svg>
  };

  return flags[flag];
};

export default Flag;
