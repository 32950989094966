
import {
  useQuery,
} from 'react-query';

import middleman from 'helpers/middleman';
import { tradeOrdersHandler } from './responseFormatter';

export const useTradeOrders = (portfolioUuid: string) => useQuery(['tradeOrders', portfolioUuid], () => {
  return middleman.get(
    `/v1/fa/tradeorder/index/${portfolioUuid}`
  ).then((response) => {
    return tradeOrdersHandler(response);
  });
}, { enabled: !!portfolioUuid, refetchOnWindowFocus: false});