import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';

const Info = function({ data, small }) {

  const rows = data.map( (row, index) =>
    <div key={index} className="info-row__column">
      <div className="info-row__label">{row.label}</div>
      <p className="info-row__content">
        {row.prefix && <span className="info-row__currency">{row.prefix} </span>}
        {row.value}
        { (row.suffix && row.value) && (
          <span className="info-row__currency">{row.suffix}</span>
        ) }
      </p>
    </div>
  );
  return (
    <div className={`info-row  ${small ? " info-row--small" : ""}`}>
      {rows}
    </div>
  );
};

Info.propTypes = {
  data: PropTypes.array.isRequired
  /* ex data array
  [
    { label: 'Ränta', value: '12%' },
    { label: 'Period', value: '10 mån' },
    { label: 'Max volym', value: '15M', suffix: 'SEK/NOK' },
  ]
  */
};

export default Info;
