// Unite Linked insureance vs Direct Investment
import React from 'react';
import Loadable from 'jaybe-react-loadable';
import { HubinsLoadingAnimation as LoadingAnimation } from "components/Icon/HubinsLoadingAnimation";
import { Colors } from 'settings';

import { formatMoney } from 'helpers';
import { gradientDefinitions } from 'components/Charts/ChartLine';
import { CustomTooltip, CustomLegend } from 'components/Charts/InsuranceVsDirect';
import { round, getMonthlyRate, profitTaxation, acuumulatedReturn, unitLinkTaxation, insuranceVariableFeeSum, expectedReturn } from 'helpers/calculations';
import PropTypes from 'prop-types';
import { Columns, Column } from 'components/Columns';

// const BarChart = React.lazy(() => import('recharts/es6/chart/BarChart'));
// const Bar = React.lazy(() => import('recharts/es6/cartesian/Bar'));
// const XAxis = React.lazy(() => import('recharts/es6/cartesian/XAxis'));
// const YAxis = React.lazy(() => import('recharts/es6/cartesian/YAxis'));
// const CartesianGrid = React.lazy(() => import('recharts/es6/cartesian/CartesianGrid'));
// const Tooltip = React.lazy(() => import('recharts/es6/component/Tooltip'));
// const Legend = React.lazy(() => import('recharts/es6/component/Legend'));

/**
 *
 * @param {number} amount - The original amount
 * @param {number} months - The numbers of months
 * @param {number} yearlyReturn - The yearly return
 * @param {object} staticValues - Fees and other static values
 * @returns {number} -
 */
export const generateData = (totalInsValue, totalDirValue, amount, targetReturn, staticValues) => {


  const netRetDir = totalDirValue - amount;
  const netRetIns = totalInsValue - amount;
  const targetReturnSek = (amount * (targetReturn / 100));
  const { GOVERNMENT_BORROWING_RATE, YIELD_TAXATION } = staticValues;
  const taxIns = amount * (GOVERNMENT_BORROWING_RATE + 0.01) * YIELD_TAXATION;


  const dataArray = [
    {
      name: 'Direktinvestering',
      netRetDir: netRetDir,
      taxDirInv: (amount * (targetReturn / 100)) - netRetDir,
      taxIns: 0,
      netRetIns: 0,
      feeIns: 0,
    },
    {
      name: 'Kapitalförsäkring',
      netRetDir: 0,
      taxDirInv: 0,
      taxIns: taxIns,
      netRetIns: netRetIns,
      feeIns: targetReturnSek - netRetIns - taxIns,
    },
  ];

  return dataArray;
};

export const InsuranceVsDirectBarsLabels = ({ small, data }) => {

  const media = small
    ? { s: '12', m: '6' }
    : { s: "4", l: "2" };

  return (
    <Columns className={small ? 's-md' : ''}>
      <Column {...media}>
        <CustomLegend
          name="Avkastning Direktinvestering"
          value={formatMoney(data[0] && data[0].netRetDir)}
          stroke={Colors.chartline4}
        />
      </Column>

      <Column {...media}>
        <CustomLegend
          name="Avgifter Direktinvestering"
          value={formatMoney(0)}
          stroke={Colors.success} strokeDasharray="4 4"
        />
      </Column>

      <Column {...media}>
        <CustomLegend
          name="Skatt Direktinvestering"
          value={formatMoney(data[0] && data[0].taxDirInv)}
          stroke={Colors.success} strokeDasharray="4 4"
        />
      </Column>

      <Column {...media}>
        <CustomLegend
          name="Avkastning Kapitalförsäkring"
          value={formatMoney(data[1] && data[1].netRetIns)}
          stroke={Colors.secondary}
        />
      </Column>

      <Column {...media}>
        <CustomLegend
          name="Avgifter Kapitalförsäkring"
          value={formatMoney(data[1] && data[1].feeIns)}
          stroke={Colors.warning} strokeDasharray="4 4"
        />
      </Column>

      <Column {...media}>
        <CustomLegend
          name="Skatt kapitalförsäkring"
          value={formatMoney(data[1] && data[1].taxIns)}
          stroke={Colors.chartline1}
        />
      </Column>
    </Columns>
  );
};

const StackedBarChartLoaded = ({
  data,
  recharts,
  small,
}) => {

  const {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    // Legend,
    ResponsiveContainer,
  } = recharts;

  const barWidth = small ? 100 : 140;

  return (
    <>
      <p className="chart-label">SEK</p>
      <div className="numbers chart-line chart-line--withlabels c-white" style={{ height: small ? 250 : 500 }}>
        <ResponsiveContainer>
          <BarChart
            data={data}
            margin={{ top: -1, right: -1, left: -1, bottom: 20 }}
          >
            {gradientDefinitions()}
            <CartesianGrid
              stroke={Colors.charlineTick}
              strokeWidth="1"
              strokeDasharray="2 3"
            />
            <XAxis
              dataKey="name"
              minTickGap={0}
              axisLine={false}
              mirror={true}
              tick={{ dy: -10, dx: -10, color: Colors.charlineTick }}
              tickLine={false}
              padding={{ left: 50 }}
            >
            </XAxis>
            <YAxis
              padding={{ top: 40, bottom: 60 }}
              type="number"
              domain={['dataMin', 'dataMax']}
              // domain={[10000, 'dataMax + 10000']}
              // tickCount={1}
              // domain={[0, 150]}
              allowDecimals={false}
              axisLine={false}
              mirror={true}
              tickFormatter={yValue => formatMoney(yValue, ' ', 0)}
              width={150}
              scale="linear"
              // tickFormatter={yValue => yValue - 100}
              // unit="%"
              tick={{ color: Colors.charlineTick }}
              tickLine={false}
            >
            </YAxis>
            <Tooltip
              // offset={-120}
              content={
                <CustomTooltip
                  barChart
                  textColors={[
                    Colors.primary,
                    Colors.primary,
                    'white',
                    Colors.primary,
                    'white',
                  ]}
                />}
            />
            {/* <Legend iconType="plainline" /> */}
            <Bar
              dataKey="netRetDir"
              name="Nettoavkastning Direktinvestering"
              stackId="a"
              stroke={Colors.chartline4}
              fillOpacity={1}
              fill="url(#gradient4)"
              maxBarSize={barWidth}
            />
            <Bar
              dataKey="netRetIns"
              name="Nettoavkastning Kapitalförsäkring"
              stackId="a"
              stroke={Colors.secondary}
              fillOpacity={1}
              fill="url(#gradient_secondary)"
              maxBarSize={barWidth}
            />
            <Bar
              dataKey="taxDirInv"
              name="Skatt Direktinvestering"
              stackId="a"
              stroke={Colors.success}
              trokeWidth="2"
              strokeDasharray="2 4"
              fillOpacity={0}
              maxBarSize={barWidth}
            />
            <Bar
              dataKey="taxIns"
              name="Skatt kapitalförsäkring"
              stackId="a"
              fill={Colors.chartline1}
              stroke={Colors.chartline1}
              maxBarSize={barWidth}
            />
            <Bar
              dataKey="feeIns"
              name="Avgifter Kapitalförsäkring"
              stackId="a"
              stroke={Colors.warning}
              strokeWidth="2"
              strokeDasharray="2 4"
              fillOpacity={0}
              maxBarSize={barWidth}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
      <div className='hidden' id="chart-loaded-marker-two" />
    </>
  );
};

StackedBarChartLoaded.defaultProps = {
  amount: 1000000,
  months: 12,
  yearlyReturn: 12,
};

StackedBarChartLoaded.propTypes = {
  amount: PropTypes.number,
  months: PropTypes.number,
  yearlyReturn: PropTypes.number,
};

const InsuranceVsDirectBars = Loadable({
  loader: () => import('recharts/es6' /* webpackChunkName: "recharts" */),
  loading: LoadingAnimation,
  timeout: 120000, // 2 min
  render(loaded, props) {

    return <StackedBarChartLoaded {...props} recharts={loaded} />;
  },
});

export default InsuranceVsDirectBars;
