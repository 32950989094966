
export const mergeEndowments = (endowment, transactionsEndowment) => {
  const endowmentYears = Object.keys(endowment);
  const transactionsEndowmentYears = Object.keys(transactionsEndowment);
  const allYears = [...new Set([...endowmentYears, ...transactionsEndowmentYears])];
  return allYears.reduce((merged, year) => {
    merged[year] = {
      ...endowment[year],
      ...transactionsEndowment[year],
    };
    return merged;
  }, {});
};
