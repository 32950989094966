import * as types from "states/auth/types";
import {
  userStatuses,
  // formStatuses
} from "states/utilities/statuses";
import { createReducer } from "states/utilities/independent";
import { combineReducers } from "redux";
import middleman from "../../../server";
import {
  initialUser,

  // AuthReducers
  authReducer_GET,
  authReducer_REGISTER,
  authReducer_REGISTER_BANKID,
  authReducer_COMPANYREG,
  authReducer_LOGIN,
  authReducer_LOGOUT,
  // authReducer_ABORT,
  authReducer_UPDATE,
  authReducer_REFRESHTOKEN,
  authReducer_COLLECTDATA,
  authReducer_INFO,
  authReducer_LANGUAGE,
  authReducer_CREATE,
  formSubmittedStatus_UPDATESTATUS,
  formStatusReducer, // Actions, // returns
  updateUser, // types.UPDATE
  refreshToken, // types.REFRESHTOKEN
  logoutUser, // types.LOGOUT
  clearUser, // types.LOGOUT
  submitUser,
  createProfile,
  createCompanyProfile,
  collectData, // types.COLLECTDATA
  collectPortfolioData,
  switchLanguage, // types.LANGUAGE
  lockTest, // types.UPDATE
  updateFormStatus,
  completeForm,
  resetFormStatus,
  sofCollectData,
  createAnswer,
  switchUser,
  registerRepresentative,
  // sofSubmit,
} from "states/auth";

// Register user flow old Signicat:
// Send data to jool
// Reducer receives signicat link and process id
// Open new window for signicat
// Start checkAuth

export const authReducer = createReducer(initialUser)({
  [types.GET]: authReducer_GET,
  [types.CREATE]: authReducer_CREATE,
  [types.REGISTER]: authReducer_REGISTER,
  [types.REGISTER_BANKID]: authReducer_REGISTER_BANKID,
  [types.COMPANYREG]: authReducer_COMPANYREG,
  [types.LOGIN]: authReducer_LOGIN,
  [types.LOGOUT]: authReducer_LOGOUT,
  // [types.ABORT]: authReducer_ABORT,
  [types.UPDATE]: authReducer_UPDATE,
  [types.REFRESHTOKEN]: authReducer_REFRESHTOKEN,
  [types.COLLECTDATA]: authReducer_COLLECTDATA,
  [types.INFO]: authReducer_INFO,
  [types.LANGUAGE]: authReducer_LANGUAGE,
});

export const statusReducer = createReducer({
  status: userStatuses.init,
  state: false,
})({});

export const formSubmittedStatusReducer = createReducer(false)({
  [types.UPDATESTATUS]: formSubmittedStatus_UPDATESTATUS,
});

// _____________ THE AUTH STATE ________________
// combine reducers for auth state
export const authReducerExport = combineReducers({
  User: authReducer,
  SofStatus: formSubmittedStatusReducer,
  FormStatus: formStatusReducer,
});

// * ----------------//
// * -> Actions <- * //
// * ----------------//
// * Moved to states/auth/index.js
const authOperations = {
  refreshToken,
  logoutUser,
  clearUser,
  submitUser,
  createProfile,
  createCompanyProfile,
  updateUser,
  collectData,
  collectPortfolioData,
  lockTest,
  switchLanguage,
  updateFormStatus,
  completeForm,
  resetFormStatus,
  sofCollectData,
  switchUser,
  registerRepresentative,
  createAnswer,
};
export { authOperations };
export { logoutUser, refreshToken };
export default authOperations;
