import React, { useRef, useState, useEffect } from 'react';
import __ from 'localisation';

const Init = ({ Template, ...props }) => {

  const [value, setValue] = useState(props.optionsState.value || '');
  const [focused, setFocused] = useState('untouched');

  const mounted = useRef(false);

  const sendToParent = () => {
    props.onChange(value, props.id);
  };

  useEffect(() => {
    if (mounted.current) {
      const onChangeTimeout = setTimeout(() => {
        sendToParent();
      }, 100);

      return () => {
        clearTimeout(onChangeTimeout);
      };
    } else {
      mounted.current = true;
    }

  }, [value]);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleFocus = (isBlur) => {
    setFocused(isBlur);
  };

  const untouched = focused === 'untouched';
  const hasErrorButHasntBeenTouched = props.optionsState.error && untouched;
  const throwError = hasErrorButHasntBeenTouched || ((props.optionsState.error) && (focused && !untouched));
  const isValidated = props.optionsState.error === false && focused;

  let errorMessage = false;
  if (throwError && props.error_message) {
    errorMessage = props.error_message;
  }

  if (typeof props.optionsState.error === 'string') {
    errorMessage = props.optionsState.error;
  }

  return <Template
    {...props}
    value={value}
    handleChange={handleChange}
    handleFocus={handleFocus}
    isValidated={isValidated}
    errorMessage={errorMessage}
    throwError={throwError}
  />;

};

Init.defaultProps = {
  error_message: __('input_error'),
};

export default Init;
