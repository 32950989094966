import React from 'react';
import Heading from "components/Heading";
import { getMoney } from 'helpers';

const Earnings = ({ hubins, direct, initial, increse }) => {

  const percentageChange = () => {
    const percentage = increse / direct * 100;
    return +Number(percentage.toFixed(2));
  };

  return (
    <section className='section-padding'>
      <Heading size="4" className="c-neutral-6">Hur mycket tjänar jag med Hubins?</Heading>
      <Heading size="5" className="c-neutral-5">Nettoavkastning</Heading>
      <Heading size="3">{getMoney(hubins - initial, "SEK", 2)}</Heading>
      <Heading size="5" className="c-neutral-5">Nettovärde på din investering</Heading>
      <Heading size="3">{getMoney(hubins, "SEK", 2)}</Heading>
      <Heading size="5" className="c-neutral-5">Ökad avkastning med Hubins</Heading>
      <Heading size="3">{getMoney(increse, "SEK", 2)} / {percentageChange()}%</Heading>
    </section>
  );
};

export default Earnings;
