/* eslint-disable react/forbid-elements */
// SWEDISH localisation file for default keys

import React from 'react';

export const keys = (v = {}) => ({

  // Customized strings for different markets - these will override the default STRINGS
  MARKET_STRINGS: {
    // Sweden
    SE: {

    },

    // Norway
    NO: {

    },

    // Finland
    FI: {

    }
  },

  // Default strings
  STRINGS: {
    app_name: 'Hubins',
    app_title: 'Hubins - A flexible unit-linked policy for all your investments',
    platform_email: 'kundservice@hubins.com',
    platform_tel: '031-797 19 36',

    legal_title: 'Legal',
    cookies_title: 'Cookies',
    back_button: 'Go back',
    privacy_title: 'Privacy',

    // Routes
    demand_assesment: 'Krav- och Behovsprövning',

    // Login
    welcome_text: 'Welcome to Hubins',
    insurance_distributor: 'Försäkringsdistributör',
    the_insurance_company: 'Försäkringsbolaget',
    the_insurance_company_desc: 'Kapitalförsäkringen tillhandahålls av försäkringsbolaget Quantum Leben AG i Liechtenstein. Hubins bolagsgrupp har haft ett aktivt samarbete med Quantum alltsedan den svenska Finansinspektionen underrättades om gränsöverskridande verksamhet i mars 2011.',
    the_insurance_company_desc_1: 'Fördelen med Liechtenstein är att landet kan erbjuda större flexibilitet än svenska försäkringsbolag men, som medlem i EES, följer Quantum ändå samma hårda EU-direktiv som svenska försäkringsbolag. Försäkringsbolaget är exempelvis förpliktat att följa Solvens II-regelverket vilket ställer höga krav på att försäkringsbolaget ska ha stora marginaler i sin ekonomi. Liechtenstein har även ett av de starkaste kundskydden när det gäller att skydda kundernas tillgångar i kapitalförsäkringar. Alla innehav i en kapitalförsäkring hålls helt segregerade från försäkringsbolagets egna tillgångar och till och med om försäkringsbolaget skulle gå i konkurs är innehavet i kapitalförsäkringen skyddat. Under flera år har Liechtenstein drivit ett omfattande arbete för att motverka penningtvätt och finansiering av terrorism och har en nolltolerans avseende dessa kriminella handlingar. Liechtenstein har även sedan flera år tillbaka ett samarbete för informationsutbyte med svenska Skatteverket.',
    // For debugging - don't translate
    test: `Test en ${v.test}`,
    test2: <i>I</i>,

    application_review: 'Application review',
    review_in_progress: 'We are currently reviewing your application. You will be notified when it’s done.',
    review_contact_support: 'If you have any questions, please contact our customer service.',
    review_steps_left: 'You have steps to complete',
    review_steps_left_text: 'To finalize your registration you need to provide us with more information.',
    continue_registration: 'Continue registration',

    kyc: 'Know your client',

    // Withdrawal
    demand_assesment_checkbox: 'I confirm that the answers I provided in Hubins demand assessment are still valid',
    deposit_money: 'Premium',
    deposit: 'Premium',
    deposits: 'Premiums',
    new_deposit: 'New premium',

    // Market order
    marketorder_terms: 'I allow and request Quantum Leben AG (The Company) to enter into the formal agreements needed to execute the instructions above. I agree that The Company will not be held responsible for any eventual losses that is derived from this instruction executed by The Company. The Company will neither be held responsible for lack of action from the asset managers side that give rise to loss of value regardless how they arise (this includes without limitation to failure from the trustee to create an appropriate return in correlation to the risk). Additionally I will compensate The Company against all claims against The Company in the matter of loss as mentioned earlier and also all costs and fees that arise due to the actions of the asset manager (this includes without limitation costs to defend The Company in any court of law).',
    marketorder_terms2: <>By checking the box, I confirm that I have obtained factsheet with key-information about my Quantum Leben policy, as well as specific factsheet for those investment I intend to do. Hereby, I confirm that I am informed that up-to-date factsheets about my policy and investments are accessible through <a href="https://www.hubins.com" target="_blank" rel="noopener noreferrer">www.hubins.com</a></>,
    marketorder_terms2_pdf: 'By checking the box, I confirm that I have obtained factsheet with key-information about my Quantum Leben policy, as well as specific factsheet for those investment I intend to do. Hereby, I confirm that I am informed that up-to-date factsheets about my policy and investments are accessible through www.hubins.com',

    buy_pdf_title_primary: 'Buy order primary market',
    buy_pdf_title_secondary: 'Buy order secondary market',
    sell_pdf_title: 'Sell order',
    emission_value: 'Emission value',

    // Invest
    invest_pre_text: 'The content of this page is a part of Quantums investment range. Current investments consists of ongoing issues and offers from various financial firms that Quantum Leben is cooperating with, but where Quantum do not act as arranger, financial advisor or similar.',
    disclaimer: 'Disclaimer',
    disclaimer_text_1: <>
      <h4>Own Responsibility</h4>
      <p>Quantum Leben only makes an appropriateness assessment. The investment is placed on your own initiative and it is therefore your responsibility to judge the suitablility of the investment. Quantum Leben do not carry out a suitability assessment.</p>
    </>,
    disclaimer_text_2: <>
      <h4>Buy and Sell</h4>
      <p>Quantum Leben do not provide a secondary market. Placed orders are sent to the proper market place and is carried out on a best effort basis. Quantum Leben do not decide on regards of allocation.</p>
    </>,

    fileupload_error: 'Please upload one or more files.',
    roaring_error_message: 'Something went wrong. Please try again or contact Hubins customer support if the problem remains.',

    create_account: 'Create account',
    private_account: 'Private account',
    private_account_info: false,
    corporate_account: 'Corporate Account',
    corporate_account_info: false,
    restart: 'Restart',

    exceeded_desc: 'Med denna insättningen kommer du överskrida ditt deklarerade kapital. Vänligen gå igenom källa till kapital.',
    go_to_sof: 'Gå till deklarationen',
    do_later: 'Do later',
    corporate_identity_number: 'Company registration number',
    fetch_corporate_data: false,
    'company_name': 'Company name',
    comp_reg_control_title: false,
    comp_reg_control_info: false,

    sof_needed_upload: 'Du först behöver deklarera vilken källa pengarna kommer ifrån',
    sof_done: 'Du har skickat in underlag som Hubins behöver gå igenom. När detta är klart kommer du att kunna slutföra din betalning',
    declare_now: 'Deklarera nu',

    too_many_premiums:
      <>
        <h3 className="smallheading">Du har redan en pågående premieinsättning igång.</h3>
        <p>Vänligen slutför eller ta bort din pågående insättningen. Det är endast tillåtet att ha en insättning igång.</p>
        <p>Du kan see dina pågående premieinsättningar i din översikt på startsidan.</p>
      </>,
    to_overview: 'Till översikt',
    abort_premium: 'Avbryt insättning',
    sof_checkbox: 'Jag bekräftar att min källa till kapital är oförändrad',
    sof_and_da_changed: 'Om behov eller källa har förändrats, vänligen kontakta hubins.',
    sof: 'Source of Funds',
    value_securities: "Value Holdings",
    cash: "Cash",
    just_total: "Total",
    portfolio_approval: 'Policy approval',
    sof_approval: 'Source of funds',
    deposit_approval: 'Premium',
    verdict_status_waiting_on_third_party: 'Waiting on third party',
    verdict_status_approved: 'Approved',
    verdict_status_denied: 'Rejected',
    verdict_status_comment: 'Comment',
    verdict_status_waiting_on_management: 'Waiting on management',
    verdict_status_processing: 'Processing',
    verdict_status_suspected: 'Suspected',
    policy_holder: 'Policy holder',
    risk_category: 'Risk category',
    premium_info: 'Premium info',
    user_sources_for_premium: 'Used sources for this transaction',
    sow: 'Source of wealth',
  }
});

export default keys;
