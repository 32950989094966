import {
  useQuery,
} from 'react-query';

import middleman from 'helpers/middleman';

export const useTransactions = (portfolioUuid: string, status: string | undefined = undefined, type: string | undefined = undefined ) => useQuery(['transactions', portfolioUuid, status, type], () => {
  return middleman.promisePost(`/transactions/get/${portfolioUuid}`, { status, type, })
    .then(res => res.data);
}, { enabled: !!portfolioUuid, refetchOnWindowFocus: false});

export const useBackofficeTransactions = (portfolioUuid: string, status: string | undefined = undefined, type: string | undefined = undefined) => useQuery(['bo_transactions', portfolioUuid, status, type], () => {
  return middleman.promisePost(`/ql/crm/transactions/get/${portfolioUuid}`, { status, type, })
    .then(res => res.data);
}, { enabled: !!portfolioUuid, refetchOnWindowFocus: false });

export const usePremiumTransactions = (portolfioUuid: string) => useQuery(['premiumTransactions', portolfioUuid], () => {
  return middleman.promisePost(`/transactions/show`, {
        portfolioUuids: [portolfioUuid],
        type: 'PRE'
      }).then(res => res.data);
}, { enabled: !!portolfioUuid, refetchOnWindowFocus: false });

export const useTofTransactions = (status?: string) => useQuery(['tofTransactions', status], () => {
  return middleman.promisePost(`/ql/crm/transactions/show?withSecurity=true`, {
        status,
        type: 'TO'
      }).then(res => res.data);
}, { enabled: !!status, refetchOnWindowFocus: false });
