export { calcRiskPremium, getMonthlyRate } from "@hubins/calculations";

import {
  daysBetween,
  yearFrac,
  formatPercent,
  getAccrualCalendarDays,
  getAccrualCalendar,

} from 'helpers';

import { __plural } from 'localisation';
/**
 * @param  {number} investmentAmount [Value to be validated]
 * @param  {number} issuePrice  [from FA API]
 * @param  {number} multiplier  [from FA API]
 * @param  {number} costPercentage  [from FA API]
 * @return {number} [Calculated value]
 */

// TODO: add tests

// Usage: getRemainingMaturity({maturity: '2017-08-20', accrualCalendar: 'EU30360', format: true })
// Calculation: ((Current Date-Förfallodag)/360)*12 | 360 = Euopean/360 standard (s_accrualCalendar)
export const getRemainingMaturity = (props) => {
  if (typeof props !== 'object' || !props.maturity || typeof props.maturity === 'undefined') {
    console.warn('getRemainingMaturity props is not an object. Expected ex:', { maturity: '2017-08-20', accrualCalendar: 'EU30360' }, 'Instead got:', props);
    return false;
  }
  const remainingMaturityValue = yearFrac(Date.now(), props.maturity, getAccrualCalendar(props.accrualCalendar)) * 12;

  // TODO: format the returned date to string with days or months depending on the value
  if (props.format) {
    return __plural('month_string_short', 10, { number: Math.round(remainingMaturityValue * 10 / 10) });
  } else {
    return Math.round(remainingMaturityValue * 10 / 10);
  }
};

// Usage: remainingAnnualRate({maturity: '2017-08-20', yearlyrent: 5, format: true, accrualCalendar: 'EU30360' })
// Calculation: ((Current Date-Förfallodag)/360)*årsränta | 360 = Euopean/360 standard
export const remainingAnnualRate = (props) => {
  if (typeof props !== 'object' || !props.maturity || typeof props.maturity === 'undefined' || (!props.yearlyrent && props.yearlyrent !== 0) || typeof props.yearlyrent === 'undefined') {
    console.warn('remainingAnnualRate props is not an object. Expected ex:', { maturity: '2017-08-20', yearlyrent: 5, accrualCalendar: 'EU30360' }, 'Instead got:', props);
    return false;
  }
  let remainingAnnualRateValue = yearFrac(Date.now(), props.maturity, getAccrualCalendar(props.accrualCalendar)) * props.yearlyrent;
  if (props.format) remainingAnnualRateValue = formatPercent(remainingAnnualRateValue);
  return remainingAnnualRateValue;
};

// Usage: getAvailableBonds({issuedate: '2020-08-20', maturity: '2017-08-20', yearlyrent: 5, format: true, accrualCalendar: 'EU30360' })
// Calculation: ((Emissionsdag-Förfallodag)/360)*årsränta | 360 = Euopean/360 standard
export const getAvailableBonds = (props) => {
  // TODO: IS THIS UNUSED?
  if (typeof props !== 'object' || typeof props.maturity === 'undefined' || typeof props.issuedate === 'undefined' || typeof props.yearlyrent === 'undefined') {
    console.warn('getAvailableBonds props is not an object. Expected:', { maturity: '2017-08-20', issuedate: '2020-08-20' }, 'Instead got:', props);
    return false;
  }
  let getAvailableBondsValue = (daysBetween(props.issuedate, props.maturity, true) / getAccrualCalendarDays(props.accrualCalendar)) * props.yearlyrent;
  if (props.format) getAvailableBondsValue = formatPercent(getAvailableBondsValue);
  return getAvailableBondsValue;
};

// Dropdown Effect Calculations

// Usage: getPrice(
//  amount: number that is nominal value for example number of bonds * block size,
//  rate: number from 1 to 100
//)
export const getPrice = (amount, rate) => {
  return amount * (rate / 100);
};

// Usage totalReturn(
//  amount: number that is nominal value for example number of bonds * block size,
//  couponPercentage: number that is yearly return in percent ex. 10
//  maturityDate: date object
//  issueDate: date object
//  price: use getPrice function
//)
export const getTotalReturn = (amount, couponPercentage, issueDate, maturityDate, price) => {
  if (!amount) return 0;

  const totalReturn = (((amount * (couponPercentage / 100) * yearFrac(issueDate, maturityDate)) + (amount - price)) / price) * 100;

  return totalReturn;
};


// Antagligen deprecated
export const getReturnReducedBy = (courtage, totalReturn) => {
  return (courtage / totalReturn) * 100;
};

// used in getReductionOfReturn
export const calculateReturn = (props) => {
  const { amount, yearlyReturn, issueDate, maturityDate, price } = props;

  const remaingMaturity = yearFrac(issueDate, maturityDate, 4); // EURO/360 TODO: get from security

  const reductionOfreturn = amount * (yearlyReturn / 100) * remaingMaturity + (amount - price);
  return reductionOfreturn;
};


/*
getReductionOfReturn({
  amount: 1100000,
  costPercentage: 1,
  rate: 100,
  yearlyReturn: 10,
  issueDate: '2018-01-01',
  maturityDate: '2019-10-11',
  format: true,
});

*/
export const getReductionOfReturn = (props) => {
// NY:
/*

D137/D136 = Totala avgifter för hela investeringsperioden / Total avkastning för hela investeringsperioden före avgifter

Totala avgifter för hela investeringsperioden = Total fees / price
[x] price

total fees = D109;D117 = Product related fees + Product brokerage fees + Other product fees + Insurance Related Fees
Product related fees =
Insurance Related Fees = D119+D123+D127+D130

[ ] total fees

*/

  const { amount, costPercentage, rate, yearlyReturn, issueDate, maturityDate } = props;

  const price = amount * (rate / 100);

  //
  const brokerage = price * (costPercentage / 100);

  // D137/D136 = Totala avgifter för hela investeringsperioden / Total avkastning för hela investeringsperioden före avgifter
  let reductionOfReturn = (brokerage /
    calculateReturn({
      amount,
      yearlyReturn,
      issueDate,
      maturityDate,
      price,
    })
  ) * 100;

  if (props.format) {
    return formatPercent(reductionOfReturn);
  }
  return reductionOfReturn;
};

// Isurance Vs Direct charts calculations:
export const round = (value) => +value.toFixed(2);
// const round = (value) => Number.parseFloat(value).toFixed(2);



/**
 * Start Value Without Fees (for Profit taxation)
 * If it's the first month, return the orign amount,
 * else take the amount from past month and add the montly rate
 *
 * @param {number} amount - The original amount
 * @param {number} month - The month to be calulated for
 * @param {number} r - The montly rate
 * @returns {number} -
 */
export const startValueWithoutFees = (amount, month, r) => {
  if (month <= 1) return amount;

  let value = amount;
  for (let i = 2; i < month; i++) {
    value = value * (1 + r);
  }
  return value * (1 + r);
};

/**
 *
 * @param {number} amount - The original amount
 * @param {number} month - The current month
 * @param {number} r - Effective monthly rate of return
 * @returns {number} -
 */
export const acuumulatedReturn = (amount, month, r) => {
  let AcuumulatedReturn = 0;

  for (let i = 1; i < month + 1; i++) {
    AcuumulatedReturn = AcuumulatedReturn + startValueWithoutFees(amount, i, r) * r;
  }
  return AcuumulatedReturn;
};

/**
 * Förväntad avkastning före avgifter
 *
 * @param {number} amount - The original amount
 * @param {number} month - The current month
 * @param {number} r - Effective monthly rate of return
 * @param {bool} noRound - Round the retrurned value or not
 * @returns {number} -
 */
export const expectedReturnBeforeFees = (amount, month, r, noRound = false) => {
  if (month <= 0) return round(amount);
  let AcuumulatedReturn = acuumulatedReturn(amount, month, r);

  if (noRound) return amount + AcuumulatedReturn;
  return round(amount + AcuumulatedReturn);
};

/**
 * Calculation: ((D9+1%) < 1.25%) ? 0.0125 * D10 : D10 * (D9 + 1%)
 * @param {object} staticValues - Fees and other static values
 * @returns {number} - 0.0035
 */
export const actualTaxation = (staticValues) => {
  // ((D9+1%) < 1.25%) ? 0.0125 * D10 : D10 * (D9 + 1%)
  const at = ((staticValues.GOVERNMENT_BORROWING_RATE + 0.01) < 0.0125)
    ? 0.0125 * staticValues.YIELD_TAXATION
    : staticValues.YIELD_TAXATION * (staticValues.GOVERNMENT_BORROWING_RATE + 0.01);
  return at;
};

/**
 *
 * @param {number} amount - The original amount
 * @param {number} month - The current month
 * @param {object} staticValues - Fees and other static values
 * @returns {number} -
 */
export const unitLinkTaxation = (amount, month, staticValues) => {
  const _actualTaxation = actualTaxation(staticValues);

  if (month <= 0) return 0;

  let past = 0;

  for (let i = 1; i < month; i++) {
    past += (amount * (_actualTaxation / 12));
  }

  return (amount * (_actualTaxation / 12)) + past;
};

/**
 *
 * @param {number} _value - Future value before cost and tax
 * @param {object} staticValues - Fees and other static values
 * @returns {number} -
 */
export const insuranceVariableFee = (_value, staticValues) => {
  // console.log('staticValues.INSURANCE_VARIABLE_FEE + staticValues.RISK_PREMIUM', staticValues.INSURANCE_VARIABLE_FEE, staticValues.RISK_PREMIUM);
  return _value * ( (staticValues.INSURANCE_VARIABLE_FEE + staticValues.RISK_PREMIUM) / 12);
};

/**
 *
 * @param {object} staticValues  - Fees and other static values
 * @returns {number} -
 */
export const insuranceFixedFee = (staticValues) => {
  return ((staticValues.INSURANCE_FIXED_FEE + staticValues.TRANSACTION_FEE) / 12); // TODO - take dynamic months
};

/**
 * Based on past months Future value before cost and tax, Insurance variable fee and Insurance fixed fee
 * @param {number} amount - The original amount
 * @param {number} month - The current month
 * @param {number} r - Effective monthly rate of return
 * @param {object} staticValues - Fees and other static values
 * @returns {number} -
 */
export const startValueBasisForFees = (amount, month, r, staticValues) => {
  // Month 1
  let _startValueBasisForFees = amount;
  let _return = amount * r;
  let _futureValueBeforeCostAndTax = amount + _return;
  let _insuranceVariableFee = insuranceVariableFee(_futureValueBeforeCostAndTax, staticValues);
  let _insuranceFixedFee = insuranceFixedFee(staticValues);

  // Calculate the next month based on past month's value
  for (let i = 1; i < month; i++) {
    // i = past month
    _startValueBasisForFees = _futureValueBeforeCostAndTax - _insuranceVariableFee - _insuranceFixedFee;
    _return = _startValueBasisForFees * r;
    _futureValueBeforeCostAndTax = _startValueBasisForFees + _return;
    _insuranceVariableFee = insuranceVariableFee(_futureValueBeforeCostAndTax, staticValues);
  }

  return _startValueBasisForFees;
};

export const futureValueBeforeCostAndTax = (amount, month, r, staticValues) => {
  const _startValueBasisForFees = startValueBasisForFees(amount, month, r, staticValues);

  let _return = _startValueBasisForFees * r;

  return _startValueBasisForFees + _return;
};

/**
 * Calulates the sum of the variable fees for a set of months
 * @param {number} amount - The original amount
 * @param {number} months - Number of months
 * @param {number} r -  Effective monthly rate of return
 * @param {object} staticValues - Fees and other static values
 * @returns {number} - Returns the sum
 */
export const insuranceVariableFeeSum = (amount, months, r, staticValues) => {
  let _amount = 0;
  for (let i = 1; i < months + 1; i++) {
    _amount += insuranceVariableFee(futureValueBeforeCostAndTax(amount, i, r, staticValues), staticValues);
  }
  return _amount;
};


/**
 * Kapitalförsäkring: Förväntad avkastning efter avgifter och skatter
 * @param {number} amount - The original amount
 * @param {number} month - The current month
 * @param {number} r - Effective monthly rate of return
 * @param {object} staticValues - Fees and other static values
 * @returns {number} -
 */
export const insuranceExpectedReturn = (amount, month, r, staticValues) => {
  if (month <= 0) return round(amount);

  // Start value after fees and tax
  //   Start value basis for fees - past month Unit-link taxation
  //     Start value basis for fees = past month Future value before cost and tax - Insurance variable fee - Insurance fixed fee
  //       Future value before cost and tax = current Start value basis for fees + Return
  //         Return = Start value basis for fees * Effective monthly rate of return
  //       Insurance variable fee = Future value before cost and tax * (1% / 12)
  //       Insurance fixed fee = 1800 / 12
  //     past month Unit-link taxation =


  // Start value after fees and tax
  //   {amount} - past month Unit-link taxation
  //     {amount} = past month Future value before cost and tax - Insurance variable fee - Insurance fixed fee
  //       Future value before cost and tax = current {amount} + Return
  //         Return = {amount} * {r}
  //       Insurance variable fee = Future value before cost and tax * (1% / 12)
  //       Insurance fixed fee = 1800 / 12

  // Start value after fees and tax
  return round(startValueBasisForFees(amount, month + 1, r, staticValues) - unitLinkTaxation(amount, month, staticValues));
};

export const profitTaxation = (amount, month, r, staticValues, _round = false) => {
  const pt = acuumulatedReturn(amount, month, r) * staticValues.DIRECT_INVESTMENT_TAX;
  if (_round) return round(pt);
  return pt;
};

/**
 * Direktinvestering: Förväntad avkastning efter skatter (utan avgifter)
 * @param {number} amount - The original amount
 * @param {number} month - The current month
 * @param {number} r - Effective monthly rate of return
 * @param {object} staticValues - Fees and other static values
 * @returns {number} -
 */
export const directInvestmentExpectedReturn = (amount, month, r, staticValues) => {
  if (amount <= 0) return round(amount);

  const futureValueWithoutFees = expectedReturnBeforeFees(amount, month, r, true);
  const _profitTaxation = profitTaxation(amount, month, r, staticValues, false);

  return round(futureValueWithoutFees - _profitTaxation);
};

/**
 * Förväntad avkastning för en kapitalförsäkring - changed in facit v3
 *
 * @param {number} amount - The original amount
 * @param {number} months - The amount of months
 * @param {number} r - Effective monthly rate of return
 * @param {object} staticValues - Fees and other static values
 * @returns {number} - Sum
 */
export const expectedReturn = (amount, months, r, staticValues) => {
  // Kapitalförsäkring: Förväntad avkastning efter avgifter och skatter
  const _expectedReturnAfterFees = insuranceExpectedReturn(amount, months, r, staticValues);
  // const _return = acuumulatedReturn(amount, months, r);
  // const _feeIns = insuranceVariableFeeSum(amount, months, r, staticValues) + (staticValues.INSURANCE_FIXED_FEE + staticValues.TRANSACTION_FEE);
  // const _taxes = unitLinkTaxation(amount, 12, staticValues);

  return round(_expectedReturnAfterFees - amount);
};


export default {
  getRemainingMaturity,
  getAvailableBonds,
  getReductionOfReturn,
};
