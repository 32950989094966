import React, { useState } from "react";
import { connect } from 'react-redux';
import { authOperations } from '../../../state/ducks/auth';
import { alertsOperations } from '../../../state/ducks/alerts';

import Terms from "./Terms";
import Summary from "./Summary";
import Assemble from "./Assemble";

const TofRegistrationIntro = ({
  authToken,
  submitUser,
  updateUser,
  updateAlerts,
  removeAlert,
  selectedInvestments,
  juridical,
  skipRegistration,
}) => {

  const [loading, setLoading] = useState(false);
  const [company, setCompany] = useState(null);

  const handleLoading = () => {
    setLoading(true);
  };

  if (skipRegistration || (authToken && loading)) {
    return (
      <Assemble
        selectedInvestments = {selectedInvestments}
      />
    );
  }

  if (authToken) {
    return (
      <Summary
        company={company}
        updateUser={updateUser}
        updateAlerts={updateAlerts}
        removeAlert={removeAlert}
        callback={handleLoading}
      />
    );
  }
  return (
    <Terms
      juridical={juridical}
      setCompany={setCompany}
      // Fix this with redux
      submitUser={submitUser}
    />
  );
};

function mapStateToProps(state) {
  return {
    registeredUser: state.auth.User.registeredUser,
    registered: state.auth.User.tags.registered,
    authToken: state.auth.User.authToken,
  };
}

const mapDispatchToProps = {
  submitUser: authOperations.submitUser,
  updateUser: authOperations.updateUser,
  updateAlerts: alertsOperations.updateAlerts,
  removeAlert: alertsOperations.removeAlert,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TofRegistrationIntro);
