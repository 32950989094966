/* Animation objects */
const hiddenProps = {
  y: -20,
  opacity: 0
};

export const illustrationVariants = {
  hidden: hiddenProps,
  visible: { y: 0, opacity: 1, transition: { delay: 0.1 }},
  waiting: { y: -30, opacity: 1 },
  success: { y: 0, opacity: 0, transition: { delay: 1.2 }},
  error: { y: 0, opacity: 0 },
};

export const headingVariants = {
  hidden: hiddenProps,
  visible: { y: 0, opacity: 1, transition: { delay: 0.2 }},
  waiting: hiddenProps,
  success: { y: 0, opacity: 0 },
  error: { opacity: 0 },
};

export const inputVariants = {
  hidden: hiddenProps,
  visible: { y: 0, opacity: 1, transition: { delay: 0.25 }},
  waiting: hiddenProps,
  success: { y: 0, opacity: 0 },
  error: { opacity: 0 },
};

export const submitVariants = {
  hidden: hiddenProps,
  visible: { y: 0, opacity: 1, transition: { delay: 0.3 }},
  waiting: hiddenProps,
  success: { y: 0, opacity: 0 },
  error: { opacity: 0 },
};

export const contentVariants = {
  visible: {
    y: 0,
    opacity: 1,
    transition: { duration: 0.4, ease: "easeOut", delay: 0.2 },
  },
  hidden: {
    y: 20,
    opacity: 0,
    transition: { duration: 0.4, ease: "easeIn" },
  },
};
