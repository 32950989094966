import React, { useState, useCallback } from 'react';
import Heading from 'components/Heading';
import { useCollapse } from 'react-collapsed';
import { getMoney } from 'helpers';
import __ from 'localisation';
import './index.scss';

const SourceOfFundsSummary = ({ sources, isHighRisk }) => {

  const calculateSummary = useCallback(() => {
    const defaultSummary = {
      sow: 0,
      sof: 0,
      usedAmount: 0,
      availableAmount: 0,
      provenAmount: 0,
    }

    if (!sources || sources.length === 0) return defaultSummary;

    const availableAmountKey = isHighRisk ? 'highRiskAvailableAmount' : 'availableAmount';
    const summary = sources.reduce((acc, source) => {

      const highRiskAvailableAmount = source?.highRiskAvailableAmount || 0;
      const usedAmount = source?.usedAmount || 0;
      const provenAmount = highRiskAvailableAmount + usedAmount;

      acc.sow += source?.source?.amounts?.source_of_wealth || 0;
      acc.sof += source?.source?.amounts?.source_of_fund || 0;
      acc.usedAmount += source?.usedAmount || 0;
      acc.availableAmount += source?.[availableAmountKey] || 0;
      acc.provenAmount += provenAmount || 0;
      return acc;
    }, defaultSummary);
    return summary;
  }, [sources]);

  const summary = calculateSummary();

  return (
    <div className='box-padding sof-summary'>
      <>
        <Heading size={5} className="s-bottom-md">{__('your_sources')}</Heading>
        <div className='flex flex--row space-between wide'>
          <p>{__('sow')}:</p>
          <p className='no-top-margin'><strong>{getMoney(summary.sow)}</strong></p>
        </div>
        <div className='flex flex--row space-between wide'>
          <p>{__('sof')}:</p>
          <p className='no-top-margin'><strong>{getMoney(summary.sof)}</strong></p>
        </div>
        <div className='flex flex--row space-between wide'>
          <p>{__('proven_sof')}:</p>
          <p className='no-top-margin'><strong>{getMoney(summary.provenAmount)}</strong></p>
        </div>
        <div className='flex flex--row space-between wide'>
          <p>{__('used_amount')}:</p>
          <p className='no-top-margin'><strong>{getMoney(summary.usedAmount)}</strong></p>
        </div>
        <div className='flex flex--row space-between wide'>
          <p>{__('available_amount')}:</p>
          <p className='no-top-margin'><strong>{getMoney(summary.availableAmount)}</strong></p>
        </div>
      </>
    </div>
  );

};

export default SourceOfFundsSummary;