import {
  required,
} from 'components/Validation';
import __ from "localisation";
import {
  scrollTo,
} from "helpers";

// Primary market default validation, this one is used instead of validInput
// Also used for blocksale
export const primaryValidation = ({
  amountInputField,
  security,
  userAccounts,
  selectedPortfolio,
  blocksale,
  minimumInvestment,
  monetaryAmount,
  minBonds,
  setAmountInputField,
}) => {
  let newAmountInputField = amountInputField;

  const inputEmpty = required(newAmountInputField.value, "input"); //Check so that input is not empty
  const { blockSale_unitPrice, blockSize } = security;
  const { blockSale_blockSize } = security.profileAttributes;
  const currentSelectedPortfolio = userAccounts[
    selectedPortfolio
  ];

  // Calculate the minimum accepted investment based on if is a blocksale or not
  // If blocksale, Hubins can set a blockSale_blockSize parameter in FA-back to modify minimum investment value
  // If STOCK, Hubins can set stock_minimumInvestment in FA Back
  const minInvestment = blocksale
    ? Number(blockSale_blockSize * Number(blockSize)) *
    (blockSale_unitPrice / 100)
    : Number(minimumInvestment(currentSelectedPortfolio, false));

  let invalidAmount = minInvestment > Number(monetaryAmount); //check so that investmentAmount is higher than the minimum accepted amount

  newAmountInputField.error = inputEmpty || invalidAmount ? true : false; //Set error value based on previous calculations

  if (invalidAmount) {
    //Set the proper error message based on what sort of error
    newAmountInputField.error_message = __("error_min_value", {
      number: minBonds(
        currentSelectedPortfolio,
        blockSize
      )
    });
  }
  newAmountInputField.error && scrollTo(500); //Scroll to error

  setAmountInputField(newAmountInputField);

  if (newAmountInputField.error) {
    return false;
  }
  return true;
};

// Validates to make sure you have correct values befor sending a trade order
// Currently not in use, uses regular validInput instead
export function stockValidation({
  security,
  amountInputField,
}) {
  const inputEmpty = required(amountInputField.value, "input"); //Check so that input is not empty

  const minInvestment = security.profileAttributes.stock_minimumInvestment;
  const lessThenMin = Number(amountInputField.value) < minInvestment; //check so that investmentAmount is higher than the minimum accepted amount

  const maxInvestment = security.profileAttributes.stock_maxInvestment;
  const moreThenMax = Number(amountInputField.value) > maxInvestment;

  amountInputField.error = inputEmpty || lessThenMin || moreThenMax ? true : false; //Set error value based on previous calculations

  return false;
}


export function termsValidation ({
  acceptTerms,
  setAcceptTerms
}) {
  let newAcceptTerms = acceptTerms;
  newAcceptTerms.error = required(newAcceptTerms.value, "checkbox"); // Check that terms are checked

  setAcceptTerms(newAcceptTerms);

  if (newAcceptTerms.error) {
    return false;
  }

  return true;
}

export const getPayOrders = (tradeOrders) => {
  const acceptedStatuses = ['4', '5', '10'];
  const acceptedTypes = ['PRE'];
  tradeOrders = tradeOrders.filter(item => {
    return (
      acceptedStatuses.includes(item.orderStatus)
      && acceptedTypes.includes(item.type.typeCode)
    );
  });
  return tradeOrders.reverse();
};
