import React from 'react';
// import { motion } from 'framer-motion';
import Heading from 'components/Heading';
import './index.scss';

export const MainTitle = ({ title, children, className = '' }) => (
  <div className={`main__title ${className}`}>
    <Heading element="h1" size="2">{ children || title }</Heading>
  </div>
);


const Main = ({
  children,
  className = '',
  noSpace = false,
  center = false,
  pusher = false,
}) => {

  return (
    <main
      className={`main${noSpace ? ' main--nospace' : ''}${center ? ' main--center' : ''} ${className}${pusher ? ' main--pusher' : ''}`}
      id="MainContent" // Used for accesability ship to content link
      data-shiftonsidebaropen // Used for animating the main to the left while open a sidebar, ex a question
    >
      {children}
    </main>
  );
};

export default Main;
