import React from 'react';
import HoldingsTable from './HoldingsTable';
// import Button from '@hubins/components/Button';
import { __, /*__route*/ } from 'localisation';
import { sumByKey } from 'helpers';
import holdingColumnsByType from './holdingsColums';

const WithHoldingType = ({ type, subType, holdings, title, report, exclude = []}) => {

  // const addTradeButtons = (array) => {
  //   array.forEach(holding => {
  //     holding.toolsBeginning = <>
  //       <Button href={`${__route('marketBuy')}/${holding.id}`}  className="inline-link c-succcess" >{__('buy')}</Button>
  //       <Button href={`${__route('marketSell')}/${holding.id}`}  className="inline-link c-info" >{__('sell')}</Button>
  //     </>;
  //   });
  //   return array;
  // };

  const generateFooter = (userHoldings) => {
    return {
      marketValue: sumByKey(userHoldings, 'marketValue'),
      totalReturn: sumByKey(userHoldings, 'totalReturn'),
      totalNetProfitsInclAccruedInterest: sumByKey(userHoldings, 'totalNetProfitsInclAccruedInterest'),
    };
  };


  const getFilteredHoldings = () => {
    const acceptAllTypes = type === '*';
    const newHoldings = holdings.filter(holding => {
      const typeStatement = (acceptAllTypes || holding.type === type) && !exclude.includes(holding.type);
      if (subType) {
        return typeStatement && subType.includes(holding.subType);
      }
      return typeStatement;
    });
    if (newHoldings.length < 1) return ([]);
    return newHoldings;
  };

  let filteredHoldings = getFilteredHoldings();

  if (!filteredHoldings || filteredHoldings.length === 0) return null;
  // if (!crm) {
  //   filteredHoldings = addTradeButtons(filteredHoldings);
  // }
  const footer = generateFooter(filteredHoldings);
  const columns = holdingColumnsByType[type](footer, report);

  return (<HoldingsTable
    type={type}
    heading={title ? title : __(type)}
    holdings={filteredHoldings}
    columns={columns}
  />);
};

export default WithHoldingType;
