export { currencyToNumber, formatPercent } from "@hubins/currency-helpers";
import { formatMoney as _formatMoney } from "@hubins/currency-helpers";
import React from 'react';
import { __plural } from 'localisation';
import { __ } from 'localisation';
import dayjs from 'dayjs';
import { getMarket } from './market';
// import customParseFormat from 'dayjs/plugin/customParseFormat';
// dayjs.extend(customParseFormat); // More day.js plugins here: https://github.com/iamkun/dayjs/blob/dev/docs/en/Plugin.md

export { getItem } from 'states/localStorage';
export { setItem } from 'states/localStorage';
export { loadState } from 'states/localStorage';
export { saveState } from 'states/localStorage';
export { clearState } from 'states/localStorage';
export { stateName } from 'states/localStorage';
export { removeItem } from 'states/localStorage';

export const toNegativeValue = (value) => {
  if (!value) return value;
  return -Math.abs(value);
};

export const pipe = (...functions) => x => functions.reduce((prev, f) => f(prev), x);

Number.prototype.formatMoney = function (c, d, t, currency) {
  let n = this,
    precision = isNaN(c = Math.abs(c)) ? 2 : c,
    decimal = typeof d === "undefined" ? "." : d,
    thousand = typeof t === "undefined" ? "," : t,
    negative = n < 0 ? "-" : "",
    i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c))),
    j = (i.length) > 3 ? i.length % 3 : 0;
  return negative + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousand) + (precision ? decimal + Math.abs(n - i).toFixed(precision).slice(2) : "") + ' ' + currency;
};

Number.prototype.formatNumber = function (c, d, t, pf) {
  let n = this,
    precision = isNaN(c = Math.abs(c)) ? 2 : c,
    decimal = typeof d === "undefined" ? "." : d,
    thousand = typeof t === "undefined" ? "," : t,
    negative = n < 0 ? "-" : "",
    postFix = typeof pf === "undefined" ? pf : '',
    i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c))),
    j = (i.length) > 3 ? i.length % 3 : 0;
  return negative + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousand) + (precision ? decimal + Math.abs(n - i).toFixed(precision).slice(2) : "") + '' + postFix;
};

export const firstDateThisYear = () => {
  return new Date(new Date().getFullYear().toString());
};

export const firstDateOfYear = (date) => {
  if (date instanceof Date) {
    date = date.toISOString();
  }
  return new Date(new Date(date).getFullYear().toString()).toISOString().substring(0, 10);
};

export const filterType = (data, arg) => {
  const filteredData = data.filter(item => {
    switch (item.type) {
      case arg:
        return true;
      default:
        return false;
    }
  });
  return filteredData;
};

export const filterSecurity = (data, arg) => {
  const filteredData = data.filter(item => {
    switch (item.security) {
      case arg:
        return true;
      default:
        return false;
    }
  });
  return filteredData;
};




/**
 *
 * @param {string} date // ex 2018-11-16T13:09:48+0000
 * @param {bool} time // Format the date either 1988-06-01 or 1988-06-01, 13:37
 * @param {number} dec // ex 2
 * @returns {string} Returns the formatted string
 */

export const formatDate = (
  date,
  time = false,
) => {
  if (!date) return false;

  let newDate = '';

  // If not the date is correct, assum it's a FA formated date,
  // so then we use datejs customFormat plugin
  if (dayjs(date).isValid()) {
    newDate = dayjs(date);
  } else {
    const faFormatedDate = date.slice(0, 19);
    newDate = dayjs(faFormatedDate);
    // Using custom format plugin:
    // newDate = dayjs(date, 'YYYY-MM-DD[T]HH:mm:ss'); // ex 2018-11-16T13:09:48+0000
  }

  const formatedDate = newDate.format(time ? 'YYYY-MM-DD, HH:mm' : 'YYYY-MM-DD');

  return formatedDate;
};

export const addDaysToDate = (date, days) => {
  return formatDate(date.setDate(date.getDate() + days));
};


// !!! Depricated
export const formatTime = (date) => {
  console.error('formatTime is depricated, use formatDate(date, true) instead');
  return formatDate(date, true);
};

export const formatNumber = (number, minDigits = 2, maxDigits = 2) => {
  if (typeof number !== 'number') return number;
  return Number.parseFloat(number).toLocaleString('en-GB', { minimumFractionDigits: minDigits, maximumFractionDigits: maxDigits }).replaceAll(',', ' ').replace('.', ',');
};

export const formatFileSize = (number) => {
  let stringSize = number;
  if (number < 1000) {
    stringSize = formatNumber(number, 0, 2) + ' byte';
  }
  else if (number < 100000) {
    stringSize = formatNumber(number / 1000, 0, 2) + ' K';
  }
  else if (number < 100000000) {
    stringSize = formatNumber(number / 1000000, 0, 2) + ' MB';
  }
  else {
    stringSize = formatNumber(number / 1000000000, 0, 2) + ' GB';
  }
  return stringSize;
};

// Create a UTC date to accomodate for 23 and 25h days
export const createUTCdate = (date) => {
  date = new Date(date);
  return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
};



// Calculate different between two dates
// and return the difference in ms
// Accounted for 23 and 25h days by using UTC dates
export const dateDiffInMs = (a, b) => {
  // Prevent daytime saving diffing
  const utcDayFrom = createUTCdate(a);
  const utcDayTo = createUTCdate(b);

  // calulate the diff in ms
  const diff = utcDayTo - utcDayFrom;
  return diff;
};

// Calculate the number of days between two dates.
// Use the earliest date first. Ex usage:
// daysBetween('2018-09-18T08:11:17.468Z', '2018-09-28T08:11:17.468Z')
// Accounted for 23 and 25h days by using UTC dates
export const daysBetween = (date1, date2) => {
  if (!date1 || !date2) return false;

  // 86400000ms = 1 day
  const difference = Math.round((createUTCdate(date2) - createUTCdate(date1)) / 86400000);

  return difference;
};

export const monthsBetween = (date1, date2) => {
  if (!date1 || !date2) return false;
  const difference = daysBetween(date1, date2);
  let months = Math.round(difference * 0.0328549112);
  months = months < 1 ? 1 : months;
  return months;
};

// Calculate different between two dates
// and returns days, months or years left depending on the value
// Use the earliest date first. Ex usage:
// daysBetween('2018-09-18T08:11:17.468Z', '2018-09-28T08:11:17.468Z')
export const timeBetween = (date1, date2, short = false) => {
  if (!date1 || !date2) return false;

  const difference = daysBetween(date1, date2);
  let timeString = __plural(short ? 'day_string_short' : 'day_string', difference, { number: difference });
  // return months
  if (difference < -29 || difference > '29') {
    const month = Math.round(difference * 0.0328549112);
    timeString = __plural(short ? 'month_string_short' : 'month_string', month, { number: month });
  }
  return timeString;
};

// Translate getAccrualCalendar to number of days
export const getAccrualCalendarDays = (accrualCalendar) => {
  const t = {
    'US30360': 360,
    'IT30360': 360,
    'EU30360': 360, //30E/360
    '30E/360': 360, // new from jooltest
    'Actual360': 360,
    'Actual366': 366,
    'Actual365Fixed': 365,
  };

  return t[accrualCalendar] ? t[accrualCalendar] : 360; // default to EU30360
};

export const getAccrualCalendar = (accrualCalendar) => {
  const t = {
    'US30360': 0,
    'ISDAActualActual': 1,
    'ISMAActualActual': 1,
    'AFBActualActual': 1,
    'EU30360': 4, //30E/360
    '30E/360': 4, // new from jooltest
    'IT30360': 4, // maybe not correct - Nothing used by JOOL
    'Actual360': 2,
    'Actual366': 3, // not correct - count with 366
    'Actual365Fixed': 3,  // maybe not correct
  };

  return t[accrualCalendar] ? t[accrualCalendar] : 4; // default to EU30360
};

export const yearFracCalendars = [
  'US30360',
  'IT30360',
  'EU30360', //30E/360
  'Actual360',
  'Actual366',
  'Actual365Fixed',
];

export const yearFracYear = (date1, calendar) => {
  let y = 360;
  switch (calendar) {
    case yearFracCalendars[0]:
    case yearFracCalendars[1]:
    case yearFracCalendars[2]:
    case yearFracCalendars[3]:
      y = 360;
      break;
    case yearFracCalendars[4]:
      y = 366;
    default:
      break;
  }
  return y;
};

export const yearFrac = (start_date, end_date, basis = 4) => {
  // Credits: David A. Wheeler [http://www.dwheeler.com/]
  start_date = new Date(start_date);
  end_date = new Date(end_date);

  // Initialize parameters
  let sdate = start_date.toISOString().slice(0, 10);
  let edate = end_date.toISOString().slice(0, 10);

  // Return error if either date is invalid
  if (!sdate.match(/(\d{4})-(\d{2})-(\d{2})/) || !edate.match(/(\d{4})-(\d{2})-(\d{2})/)) return '#VALUE!';

  // Return error if basis is neither 0, 1, 2, 3, or 4
  if ([0, 1, 2, 3, 4].indexOf(basis) === -1) return '#NUM!';

  // Return zero if start_date and end_date are the same
  if (sdate === edate) return 0;

  // Swap dates if start_date is later than end_date
  if (start_date.getTime() > end_date.getTime()) {
    edate = start_date.toISOString().slice(0, 10);
    sdate = end_date.toISOString().slice(0, 10);
  }

  // Lookup years, months, and daysf
  const syear = parseInt(sdate.split('-')[0]);
  const smonth = parseInt(sdate.split('-')[1]);
  let sday = parseInt(sdate.split('-')[2]);
  const eyear = parseInt(edate.split('-')[0]);
  const emonth = parseInt(edate.split('-')[1]);
  let eday = parseInt(edate.split('-')[2]);

  const availibleYearFracFunctions = ". Currently is only 2 and 4 availible";

  switch (basis) {
    case 0:
      // US (NASD) 30/360
      // Note: if eday == 31, it stays 31 if sday < 30
      console.error('No support added for YEARFRAC: ', basis, availibleYearFracFunctions);
      return false;
      /*
    if (sday === 31 && eday === 31) {
    sday = 30;
    eday = 30;
    } else if (sday === 31) {
    sday = 30;
    } else if (sday === 30 && eday === 31) {
    eday = 30;
    } else if (smonth === 1 && emonth === 1 && sdate.daysInMonth() === sday && edate.daysInMonth() === eday) {
    sday = 30;
    eday = 30;
    } else if (smonth === 1 && sdate.daysInMonth() === sday) {
    sday = 30;
    }
    return ((eday + emonth * 30 + eyear * 360) - (sday + smonth * 30 + syear * 360)) / 360;
    */

    case 1:
      // Actual/actual
      console.error('No support added for YEARFRAC: ', basis, availibleYearFracFunctions);
      return false;
      /*
    const feb29Between = function(date1, date2) {
    // Requires year2 == (year1 + 1) or year2 == year1
    // Returns TRUE if February 29 is between the two dates (date1 may be February 29), with two possibilities:
    // year1 is a leap year and date1 <= Februay 29 of year1
    // year2 is a leap year and date2 > Februay 29 of year2

    const mar1year1 = moment(new Date(date1.year(), 2, 1));
    if (moment([date1.year()]).isLeapYear() && date1.diff(mar1year1) < 0 && date2.diff(mar1year1) >= 0) {
    return true;
    }
    const mar1year2 = moment(new Date(date2.year(), 2, 1));
    if (moment([date2.year()]).isLeapYear() && date2.diff(mar1year2) >= 0 && date1.diff(mar1year2) < 0) {
    return true;
    }
    return false;
    };
    const ylength = 365;
    if (syear === eyear || ((syear + 1) === eyear) && ((smonth > emonth) || ((smonth === emonth) && (sday >= eday)))) {
    if (syear === eyear && moment([syear]).isLeapYear()) {
    ylength = 366;
    } else if (feb29Between(sdate, edate) || (emonth === 1 && eday === 29)) {
    ylength = 366;
    }
    return edate.diff(sdate, 'days') / ylength;
    } else {
    const years = (eyear - syear) + 1;
    const days = moment(new Date(eyear + 1, 0, 1)).diff(moment(new Date(syear, 0, 1)), 'days');
    const average = days / years;
    return edate.diff(sdate, 'days') / average;
    }
    */

    case 2:
      // Actual/360
      return ((end_date.getTime() - start_date.getTime()) / (24 * 60 * 60 * 1000)) / 360;


    case 3:
      // Actual/365
      // return edate.diff(sdate, 'days') / 365;
      console.error('No support added for YEARFRAC: ', basis, availibleYearFracFunctions);
      return false;

    case 4:
      // European 30/360
      if (sday === 31) sday = 30;
      if (eday === 31) eday = 30;
      // Remarkably, do NOT change February 28 or February 29 at ALL
      const years = ((eday + emonth * 30 + eyear * 360) - (sday + smonth * 30 + syear * 360)) / 360;

      return years;

    default:
      console.error('No support added for YEARFRAC: ', basis, availibleYearFracFunctions);
      return false;
  }
};


// export const daysToMonths = (days) => {
//   const months = days * 0.0328549112;
//   return `${months} ${__plural('month_string', months, { number: months })}`;
// };


// Get the current market based on user
// TODO: get the real market
export const getCurrency = () => {
  const currencies = {
    'SE': 'SEK',
    'NO': 'NOK',
    'FI': 'EUR',
  };
  const currency = currencies[getMarket()];
  return currency || 'SEK';
};

export const getRandomInt = (max) => {
  return Math.floor(Math.random() * Math.floor(max));
};

export const currencyMarket = {
  "SEK": "SE",
  "NOK": "NO",
  "EUR": "FI"
};

// SCSS VARIABLES
export const $ = {
  // Media queries
  mq: {
    xs: "0",
    sm: "576px",
    sm_max: "575px",
    md: "768px",
    md_max: "767px",
    lg: "1025px",
    xl: "1400px",
    xxl: "1900px",
    // Two column breakpoints
    sm2: "1152px",
    md2: "1536px",
    md2_max: "1535px",
    lg2: "2050px",
  },
  // Timing
  t: {
    base: 400,
    short: 200,
    long: 600,
  },
  // Colors -DEPRICATED. use settings instead
  c: {
    primary: '#F39200',
    i_purple_1: '#CF5486',
    danger: '#FF5A38',
    warning: '#ffc107',
    info: '#33A9D4',
    success: '#3FBB15',

  },
  // Spacing
  s: {
    xsmall: '7px',
    small: '14px',
    medium: '28px',
    large: '56px',
    xlarge: '112px'
  }
};

// Function to offset scrollIntoView to account for navbar
export const offsetScroll = (el = window, num = -50) => {
  const re = /html$/i;
  while (!re.test(el.tagName) && (1 > el.scrollTop)) el = el.parentNode;
  if (0 < el.scrollTop) el.scrollTop += num;
};

// If you want to scroll to a certain element, set top to 0, and el to a ref.current. Ex: RegisterCompany.jsx
export const scrollTo = (top = 0, el = window,) => {

  if (el !== window && el.scrollIntoView) {
    el.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    return;
  }

  window.scrollTo({
    top: top,
    behavior: 'smooth',
  });
};

export const transactiontypes = [
  'PNOTE',
  'BOND',
  'CASH',
  'STOCK'
];


// ARRAY HELPERS
export const flattenValues = (array) => {
  return array.reduce((previous, item) => {
    return [...previous, ...item.values];
  }, []);
};

export const mergeByKey = (key, array) => {
  return array.reduce((previous, item) => {
    if (!previous[item[key]]) {
      previous[item[key]] = item;
      return previous;
    }
    previous[item[key]] = { ...previous[item[key]], ...item };
    return previous;
  }, {});
};

// OBJECT HELPERS
export const objectToArray = (object) => {
  return Object.keys(object).map(key => {
    return object[key];
  });
};

// Check if an object is empty
// Usage isEmpty({}) = true
export function isEmpty(obj) {
  return Object.keys(obj).length === 0;
}


// return true if the provided id is in the provided array with privided key id
// Usage: checkIfUserOwnSecurity(this.props.userHoldings, 's_id', item.id); // ex from Market.jsx
export const checkIfUserOwnSecurity = (collection, keyId, idToCheck) => {
  const hasSecurity = collection.some(item => {
    return Number(item[keyId]) === Number(idToCheck);
  });
  return hasSecurity;
};

export const sumByKey = (array, key) => array.reduce((total, object) => object[key] + total, 0);
export const getAverageByKey = (array, key) => sumByKey(array, key) / array.length;



export const padIntWithZero = (int) => {
  return int.toString().length < 2 ? 0 + int.toString() : int.toString();
};

export const millisecondsToObject = (milliseconds) => {
  const dayInMilliSeconds = 24 * 3600 * 1000;
  const hourInMilliSeconds = 3600 * 1000;
  const days = parseInt(milliseconds / dayInMilliSeconds);
  const hours = parseInt(milliseconds / (hourInMilliSeconds) - (days * 24));
  const minutes = parseInt(milliseconds / (60 * 1000) - (days * 24 * 60) - (hours * 60));
  const seconds = parseInt(milliseconds / (1000) - (minutes * 60) - (days * 24 * 60 * 60) - (hours * 60 * 60));

  const daysToString = padIntWithZero(days);
  const hoursToString = padIntWithZero(hours);
  const minutesToString = padIntWithZero(minutes);
  const secondsToString = padIntWithZero(seconds);
  const daysLabel = __('days');
  const hoursLabel = __('hours');
  const minutesLabel = __('minutes');
  const secondsLabel = __('seconds');

  return {
    daysToString, daysLabel,
    hoursToString, hoursLabel,
    minutesToString, minutesLabel,
    secondsToString,
    secondsLabel,
  };
};

export const formatTelLink = string => {
  if (!string) return;
  const sweCode = /^(00|\+)(46)/;
  const sweRemoved = string.replace(sweCode, '');

  const allchars = /\+?\d+/g;
  const sweRemovedMatch = sweRemoved.match(allchars);

  if (!sweRemovedMatch) return string;

  const phoneLink = sweRemovedMatch.join('');
  const phoneLinkNoZero = phoneLink.replace(/^0/, '');
  const phoneLinkSwe = '0046' + phoneLinkNoZero;
  return phoneLinkSwe;
};

export const getBirthday = (ssn) => {
  if (typeof ssn !== 'string') {
    return new Error;
  }
  return new Date(ssn.substring(0, 4) + '-' + ssn.substring(4, 6) + '-' + ssn.substring(6, 8));
};

export const getAge = (ssn, date = new Date()) => {
  const birthday = getBirthday(ssn);
  const age = daysBetween(birthday, date) / 365.25;
  return Math.floor(age);
};

export const isOldEnough = (ssn, validAge = 18) => {
  const age = getAge(ssn);
  return age >= validAge;
};

// Depricated
export const getMoney = function (value = 0, currency, dec = 2) {
  currency = currency || getCurrency();
  // Transform the value to a number if its a string
  value = (typeof value === 'string') ? Number(value) : value;
  // Format if its a number,
  // if its an object or something else, just return the value
  const val = typeof value === 'number' ?
    (
      value.formatMoney(dec, ',', ' ', currency)
    ) : (
      value
    );

  return val;
};
// ? Replace getMoney med formatMoney? - Yes
/**
 *
 * @param {number} value - The amount
 * @param {string} currency - SEK, NOK etc. Provvide a string with a space inside to not show the currency
 * @param {number} dec - Defaults to 2
 * @return { string } - a formatted number with a currency suffix
 */
export const formatMoney = function (value = 0, currency, dec = 2) {
  currency = currency || getCurrency();
  return _formatMoney(value, currency, dec);
};

/**
 *
 * @param {number} value - The amount
 * @param {string} currency - SEK, NOK etc. Provvide a string with a space inside to not show the currency
 * @param {number} dec - Defaults to 2
 * @return { string } - a formatted number with a currency suffix
 */
export const formatMoneyInline = function (value = 0, currency, dec = 2) {
  return <span className="nowrap">{formatMoney(value, currency, dec)}</span>;
};


export const minimumInvestment = (account, format = true) => {

  // const market = getMarket();

  let mi = 1100000;

  // TODO: Temp removed this for Hubins release
  // switch (market) {
  //   case "SE":
  //     if (account.Type === 'INS') {
  //       mi = 10000;
  //     } else if (account === "FUND") { // TODO: This must be more flexible. Works just because getMoney returns SEK as default
  //       mi = 100000;
  //     }
  //     break;
  //   case "NO":
  //     mi = 1000000;
  //     if (account.Type === 'INS') {
  //       mi = 10000;
  //     } else if (account === "FUND") { // TODO: This must be more flexible. Works just because getMoney returns SEK as default
  //       mi = 100000;
  //     }
  //     break;
  //   case "FI":
  //     mi = 100000;
  //     if (account.Type === 'INS') {
  //       mi = 1000;
  //     } else if (account === "FUND") { // TODO: This must be more flexible. Works just because getMoney returns SEK as default
  //       mi = 10000;
  //     }
  //     break;
  //   default:
  // }
  if (format) {
    mi = getMoney(mi, account.customer_account_currency, 0);
  }
  return mi;
};

export const minBonds = (account, blocksize) => {
  let min = minimumInvestment(account, false) / Number(blocksize);
  if (min < 1) min = 1;
  return min;
};

export const blockMinBonds = (minimum, blocksize, unitPrice) => {
  let min = Number(minimum) / (Number(blocksize) * (unitPrice / 100));
  min = Math.ceil(min);
  if (min < 1) min = 1;
  return min;
};

/*
Usage:
setPageTitle(['Subsection page title', 'Page title'])
setPageTitle(['Page title'])
setPageTitle('Landing page title that just prints out this string', true)
*/
export const setPageTitle = (title = [], specialTitle = false) => {
  if (!document) return; // for test environments

  const theTitle = specialTitle
    ? title
    : title.reduce((prev, current) => (
      prev + current + ' - '
    ), '') + __('app_name');

  document.title = theTitle;
  return theTitle;
};


export const sanitizeSsn = (ssn) => {
  ssn = ssn.replace(/\D+/g, '');
  return ssn;
};

export const triggerScrollToTop = (smooth) => {
  if (smooth) {

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  } else {
    window.scrollTo(0, 0);
  }
};

const ScrollToTop = ({ trigger }) => {
  if (typeof (trigger) === 'function' ? trigger() : trigger) {
    // Provide a new prop to trigger re-render on each route change
    triggerScrollToTop();
  }
  return null;
};
export { ScrollToTop };

// Prettify URI's
export const createPrettyURI = (str) => {
  str = str.toLowerCase();
  str = str
    .replace(/å/g, "a")
    .replace(/ä/g, "a")
    .replace(/ö/g, "o")

    .replace(/,/g, "")
    .replace(/!/g, "")
    .replace(/\?/g, "")

    .replace(/['()]/g, escape)
    .replace(/[...]/g, "");

  return encodeURIComponent(str).replace(/%20/g, "-"); //space
};

export const formatNumberByThousands = (rawValue) => {
  let v = '';

  if (!rawValue) return '0';

  if (typeof rawValue === 'number') {
    rawValue = rawValue.toString();
  }

  // Check if string is 4, add space after every 3rd character from the end
  for (let i = rawValue.length - 1; i > -1; i--) {
    // 1: if the length of the string - the current character is a third - add a space
    // 2: If it's the last character, don't add a space
    if (
      (rawValue.length - i) % 3 === 0 // 1
      && i !== 0 // 2
    ) {
      v = ' ' + rawValue[i] + v;
    } else {
      v = rawValue[i] + v;
    }
  }
  return v;
};

const helpers = {
  getMoney,
  $,
  formatDate,
  daysBetween,
};

export default helpers;
