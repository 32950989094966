import React from 'react';
import ListButton from '@hubins/components/ListButton';
import Badge from '@hubins/components/Badge';

type Props = {
  signatories: any[],
  beneficialOwners: any[],
  profileId: number,
};

const CompanyPersonnelPowersOfAttorney = ({signatories, beneficialOwners, profileId}: Props) => {

  const checkIfPersonnelIsDone = (personnel, profile_id) => {
    return personnel.powers_of_attorney.some((power) => (
      power.profile_id === profile_id
      && power.signed_at
    ));
  };

  return (
    <>
      {(signatories.length > 0 || beneficialOwners.length > 0) && (
        <>
          <ListButton
            noBorder
            noIconBorder
            icon={'Multiple Users'}
            title="Status bolagspersoner"
            actionText=''
            text='Samtliga personer nedan behöver signera'
            actionIcon=''
            disabled
          />
          {signatories.map((item) => {
            const done = checkIfPersonnelIsDone(item, profileId);

            return (
              <div
                key={item.name}
                className="box-padding border flex flex-direction-row align-center space-between"
              >
                <div className='align-left'>
                  <p><strong>{item.name}</strong><br />
                    Firmatecknare
                  </p>
                </div>
                <Badge color={done ? 'success' : 'warning'}>
                  {done ? 'Genomförd' : 'Ej genomförd'}
                </Badge>
              </div>
            );
          })}
          {beneficialOwners.map((item) => {
            const done = checkIfPersonnelIsDone(item, profileId);

            return (
              <div
                key={item.name}
                className="box-padding border flex flex-direction-row align-center space-between"
              >
                <div className='align-left'>
                  <p><strong>{item.name}</strong><br />
                    Verklig huvudperson</p>
                </div>
                <Badge color={done ? 'success' : 'warning'}>
                  {done ? 'Genomförd' : 'Ej genomförd'}
                </Badge>
              </div>
            );
          })}
        </>
      )}
    </>
  )

};

export default CompanyPersonnelPowersOfAttorney;