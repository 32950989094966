import React from 'react';
import { motion } from 'framer-motion';
import { LoadingDots } from '../LoadingIndicators';

import './HubinsLoadingAnimation.scss';

export const HubinsLoadingAnimation = ({ bg, layoutId, white, text }) => (
  <motion.div className={`hla__wrap${bg ? ' hla__wrap--bg' : ''} ${white ? ' hla__wrap--white' : ''}`}>
    <motion.svg className="hla" xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100" layoutId={layoutId}>
      <rect fill="currentColor" className="hla_down" x="42" y="79" width="16" height="11" rx="1.04" />
      <rect fill="currentColor" className="hla_up" x="42" y="10" width="16" height="11.01" rx="1.04" />
      <path fill="currentColor" className="hla_body" d="M42 78v-9a1 1 0 011-1h14a1 1 0 011 1v9a1 1 0 001 1h18.73A1.23 1.23 0 0079 77.75v-55.5A1.23 1.23 0 0077.73 21H59a1 1 0 00-1 1v9a1 1 0 01-1 1H43a1 1 0 01-1-1v-9a1 1 0 00-1-1H22.23A1.23 1.23 0 0021 22.25v55.5A1.23 1.23 0 0022.23 79H41a1 1 0 001-1z" />
    </motion.svg>
    {text ? <LoadingDots text={text} /> : ''}
  </motion.div>
);

export default HubinsLoadingAnimation;
