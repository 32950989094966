import React, { useState, useEffect } from 'react';
import Icon from '../IconNew';
import { Link } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import './button.scss';

interface ButtonWrapProps {
  center?: boolean,
  end?: boolean,
  pushtosides?: boolean,
  noTopSpacing?: boolean,
  children: any,
  className?: string,
}

export const ButtonWrap = (props: ButtonWrapProps) => (
  <div
    className={`
    button__wrap
    ${props.className}
    ${props.center ? " button__wrap--center" : ""}
    ${props.end ? " button__wrap--end" : ""}
    ${props.pushtosides ? " button__wrap--pushtosides" : ""}
    ${props.noTopSpacing ? " button__wrap--noTopSpacing" : ""}
  `}
  >
    {props.children}
  </div>
);

interface ButtonProps {
  /**
   * How large should the button be?
   */
  size?: 'small' | 'medium' | 'large';
  /**
   * Optional click handler
   */
  onClick?: (e:any) => void;
  /**
   * Optional icon
   */
  icon?: string;
  /**
  * Optional Size of icon
  */
  iconSize?: 8 | 16 | 24;
  /**
  * Pass a ref to the button
  */
  innerRef?: any; // dunno how to set this type
  /**
  * Inverted
  */
  inverted?: boolean;
  /**
  * Set loading state of button
  */
  loading?: boolean;
  /**
  * Automaticly set button loading state on click
  */
  loadingOnClick?: boolean;
  /**
   * noStyle
  */
  noStyle?: boolean;
  /**
   * Set styling for only icon as content buttons
   */
  onlyIcon?: boolean;
  /**
   * set the html type attribute of button element
   * */
  type?: "button" | "submit" | "reset";
  /**
   * when using href determines if we should render react <Link> element or <a> tag
   */
  external?: boolean;
  /**
   * className
   */
  externalWindow?: boolean;


  className: string;
  /**
   * href
   */
  href?: string;
  /**
   *   download
   */
  download?: boolean;
  /**
   * children
   */
  children?: any;
  /**
   * disabled
   */
  disabled: boolean;
  /**
   * Place the icon on the left side
   */
  iconLeft?: boolean;
  /**
   * label for icon
   */
  label?: string,
  /**
   * data-title for GTM tracking
   */
  trackingId?: string,
  /**
   * iconColor
   */
  iconColor?: 'white' | 'primary',
  /**
   * id
   */
  id?: string,
  /**
   * LinkComponent
   * When used in for example a next js project the and need to implement another link component
   */
  LinkComponent?: any,
  /**
   * LinkComponentProps
  */
  linkComponentProps?: any,
}

/**
 * Primary UI component for user interaction
 */
export const Button = ({
  loading: propsLoading = false,
  className = '',
  externalWindow = true,
  download = false,
  type = 'button',
  size = "medium",
  iconLeft = false,
  disabled = false,
  label = "",
  iconSize = 16,
  iconColor = "primary",
  ...props
}: ButtonProps) => {

  const [loading, setLoading] = useState(propsLoading);

  useEffect(() => {
    setLoading(propsLoading);
  }, [propsLoading])

  if (props.icon) className += " withicon";
  if (iconLeft) className += " withicon--left";
  if (iconSize === 16) className += " withicon--medium";


  if (props.external || (props.href && download)) {
    const externalAttributes = (props.external && externalWindow)
      ? {
        target: "_blank",
        rel: "noopener noreferrer",
      }
      : {};

      return (
        <a
          ref={props.innerRef}
          id={props.id}
          className={
            props.noStyle
              ? className
              : `
            ${props.onlyIcon ? "button-icon " : "button "}
            ${className}

            ${props.inverted ? " button--inverted " : " "}
            ${size}
          `
          }
          href={props.href}
          download={download || null}
          data-title={props.trackingId}
          {...externalAttributes}
        >
          {props.children}
          {props.icon && (
            <Icon icon={props.icon} size={iconSize} color={disabled ? 'primary' : iconColor} label={"Icon title"} />
          )}
          <span className="active-effect" />
        </a>
      )
  } else if (props.href && !disabled) {
    const TheLinkComponent = props.LinkComponent ? props.LinkComponent : Link;
    const theClassName = props.noStyle
      ? className
      : `
            ${props.onlyIcon ? "button-icon " : "button "}
            ${className}
            ${props.inverted ? " button--inverted " : ""}
            ${size}
          `
    const TheLinkComponentProps = props.linkComponentProps || {
      ref: props.innerRef,
      id: props.id,
      to: props.href,
      onClick: props.onClick, // Used in modals to both navigate to a page && close the modal
      "data-title": props.trackingId,
  };
    return (
    <TheLinkComponent
      {...TheLinkComponentProps}
      className={theClassName}
    >
      {props.children}
      {props.icon && (
        <Icon icon={props.icon} size={iconSize} color={disabled ? 'primary' : iconColor} label={label} />
      )}
      <span className="active-effect" />
    </TheLinkComponent>
    )
  } else {
    return (<button
      type={type}
      id={props.id}
      ref={props.innerRef}
      disabled={disabled}
      // aria-label={this.props.onlyIcon && children.props.icon}
      className={
        props.noStyle
          ? className
          : `
            ${props.onlyIcon ? "button-icon " : "button "}
            ${className} ${loading ? " withicon" : ""}
            ${props.inverted ? " button--inverted " : ""}
            ${disabled && " disabled"}
            ${size}
          `
      }
      data-title={props.trackingId}
      onClick={(e) => {
        // Show a loading indicator if the prop loadingOnClick or loading is set
        // if you need to remove the loading indicator,
        // use the loading prop instead with a bool
        if (props.loadingOnClick) {
          setLoading(true);
        }
        props.onClick && props.onClick(e);
      }}
    >
      {props.children}
      {props.icon && !loading && (
        <Icon icon={props.icon} size={iconSize} color={disabled ? 'primary' : iconColor} label={"Icon title"} />
      )}
      <AnimatePresence>
        {(propsLoading || loading) ? (
          <motion.span
            initial={{ opacity: 0, width: 0 }}
            animate={{ opacity: 1, width: "auto" }}
            exit={{ opacity: 0, width: 0 }}
          >
            <Icon icon="loading" anim="spin" size={iconSize} color={disabled ? 'primary' : iconColor} />
          </motion.span>
        ) : (<></>)}
      </AnimatePresence>
      <span className="active-effect" />
    </button>)
  }
};

export default Button;