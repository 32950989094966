import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Media from 'react-media';

import __ from 'localisation';

import './index.scss';

import Icon from '@hubins/components/IconNew';
import { uiContext } from 'components/UiContext';
import ErrorBoundary from 'components/ErrorBoundary';
import { $, offsetScroll } from 'helpers';
import { motion } from 'framer-motion';
// import { spring } from 'popmotion';
// import posed from 'react-pose';

// const props = {
//   draggable: true,
//   dragEnd: {
//     transition: spring
//   }
// };

// const PoseButton = posed.div(props);


class ReportButton extends Component {
  constructor(props) {
    super(props);
    this.openButton = React.createRef();
  }
  render() {
    const { questionId, content, text, icon, id } = this.props;
    return (
      <ErrorBoundary>
        <uiContext.Consumer>
          {context =>
            <Media query={`(max-width: ${$.mq.sm_max})`}>
              {matches =>
                // <PoseButton>
                <motion.button
                  drag
                  dragConstraints={{ left: -window.innerWidth + 124, right: 0, top: 0, bottom: 0 }}
                  ref={this.openButton}
                  aria-controls={id}
                  aria-expanded={context.questionOpen}
                  type="button"
                  className={`report-button ${context.questionOpen ? "report-button--hidden" : ""}`}
                  onClick={
                    e => {
                      e.preventDefault();
                      context.updateState({
                        'currentQuestionId': questionId,
                        'questionContent': content,
                        'questionText': text,
                        'questionIcon': icon,
                        'lastInput': this.openButton.current,
                      });
                      if (matches && context.lastInput) {
                        context.lastInput.scrollIntoView();
                        offsetScroll(context.lastInput, -50);
                        context.lastInput.focus();
                      }
                      context.toggleQuestionOpen();
                    }
                  }>
                  <Icon size='24' icon='Question Circle by Streamlinehq' label={__('need_help')} />
                </motion.button>
                // </PoseButton>
              }
            </Media>
          }
        </uiContext.Consumer>
      </ErrorBoundary>
    );
  }
}

ReportButton.propTypes = {
  questionId: PropTypes.string,
  text: PropTypes.string,
  content: PropTypes.element,
  icon: PropTypes.string
};

ReportButton.defaultProps = {
  questionId: '0',
  text: '',
  content: React.createElement('div'),
  icon: 'info',
};

export default ReportButton;
