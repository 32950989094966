import React, { useState, useEffect, useRef } from 'react';
import BankIdModalV6 from 'modules/BankIdModal/BankIdModalV6';
import middleman, { csrf } from 'helpers/middleman';

// const returnUrl = encodeURIComponent(window.location.href);

const BankIDV6 = ({
  initBody,
  initEndpoint,
  statusEndpoint,
  callback,
  abortCallback,
  hubins,
  useCsrf,
}) => {

  const [reference, setReference] = useState(false);
  const [error, setError] = useState(false);
  const [signStatus, setSignStatus] = useState('init');
  const [autoStartToken, setAutoStartToken] = useState(false);
  const [hasScanned, setHasScanned] = useState(false);
  const [qrCode, setQrCode] = useState(false);

  const aborted = useRef(false);
  const abortController = useRef(new AbortController());

  const processCallback = (res) => {
    // If there is a reference, post poll for status
    if (res.authReference) {
      setReference(res.authReference);
    }
    if (res.autoStartToken) {
      setAutoStartToken(res.autoStartToken);
    }
  };

  const fetchData = async () => {
    aborted.current = false;

    if (useCsrf) {
      await csrf();
    }

    middleman
      .promiseKeepAlivePost(initEndpoint, initBody, abortController.current.signal)
      .then(res => processCallback(res))
      .catch(e => {
        setError(true);
        console.error('INITERROR: ', e.message);
      });
  };

  const resetBankID = async () => {
    abortController.current.abort();
    abortController.current = new AbortController();
    if (reference) {
      await middleman.promisePost('/bauth/abort', { authReference: reference });
    }
    setReference(false);
    setAutoStartToken(false);
    setSignStatus('init');
    setHasScanned(false);
  };

  useEffect(() => {
    if (signStatus === 'init') {
      setError(false);
      setReference(false);
      setAutoStartToken(false);
      setSignStatus('pending');
      setHasScanned(false);
      fetchData();
    }

  }, [signStatus]);

  const poll = () => {
    middleman
      .promiseKeepAlivePost(statusEndpoint, {
        authReference: reference
      }, abortController.current.signal)
      .then(response => {
        if (response.httpStatus === 200) {
          setQrCode(response.qrCode);
          setHasScanned(response.state === 'userSign');
          setTimeout(poll, 1000);
          return;
        }
        if (response.httpStatus === 201) {
          setSignStatus('complete');
          if (callback) {
            callback(response);
          }
          return;
        }

        if (response.httpStatus === 410 && !aborted.current) {
          fetchData();
        }

        return;
      }).catch(e => {
        setError(true);
        console.error('STATUSERROR', e);
      });
  };

  useEffect(() => {
    if (reference) {
      poll();
    }
  }, [reference]);

  const handleAbort = async () => {
    if (abortCallback) {
      abortCallback();
    }
    aborted.current = true;
    abortController.current.abort();
    abortController.current = new AbortController();
    setQrCode(false);
    setReference(false);
    setAutoStartToken(false);
    setHasScanned(false);
    middleman.promisePost('/bauth/abort', { authReference: reference });
  };

  return (
    <BankIdModalV6
      open={true}
      error={error}
      platform={hubins ? 'Hubins' : 'ql'}
      handleSubmit={() => { setAutoStartToken(false); setReference(false); }}
      autoStartToken={autoStartToken}
      handleErrorSubmit={resetBankID}
      handleAbort={handleAbort}
      qrCode={qrCode}
      hasScanned={hasScanned}
    />
  );
};

BankIDV6.defaultProps = {
  hubins: false
};

export default BankIDV6;
