import checkRules from './checkRules';
/**
 * [Collection of all the FilterMethods]
 */
/**
 * standard filterning method that is used in step1 of the signup process
 * returns a object filtered the way FA wants it
 * @param  {obj} state [parent state, this is the data to be filtered]
 * @return {obj}       [filtered obj that will be sent to FA]
 */

export const standard = state => {
  let filteredData = {};
  for (let key in state) {
    let currentValue = state[key].value;

    if (!state[key].value) {
      continue;
    }

    switch (state[key].type) {
      case "tableselect":
      case "select":
        let valueCollection = {};
        for (let opKey in currentValue) {
          let value = currentValue[opKey].value;
          if (currentValue[opKey].value && !state[key].multiple) {
            if (opKey === "yes" || opKey === "no") {
              switch (opKey) {
                case "yes":
                  valueCollection = value;
                  break;
                case "no":
                  valueCollection = !value;
                  break;
              }
            } else {
              valueCollection = currentValue[opKey].id;
            }
          } else if (state[key].multiple) {
            valueCollection = {
              ...valueCollection,
              [currentValue[opKey].id]: currentValue[opKey].value
            };
          }
        }
        filteredData[key] = valueCollection;
        break;
      case "dropdown":
        for (let opKey in currentValue) {
          if (currentValue[opKey].value) {
            filteredData = { ...filteredData, [key]: currentValue[opKey].id };
          }
        }
        break;
      case "input":
      case "textarea":
      case "inputautofill": // may become a bug
      case "checkbox":
        filteredData = { ...filteredData, [key]: currentValue };
        break;
      default:
        break;
    }
  }
  return filteredData;
};

export const faImportString = (key, value, type) => {
  return `${key}=${value}:${type}#`;
};

export const transformImportFormStateToString = state => {
  return Object.keys(state).reduce((string, key) => {
    return (string += faImportString(key, state[key].value, state[key].type));
  }, "");
};

const importState = (key, value, type) => {
  const v = {};
  v[key] = { type, value };
  return v;
};

export const invalidOption = (key, value) => {
  if (key === "yes" || key === "no") {
    if (!value) return true;
    return false;
  }
  return false;
};

export const getOptionsType = (multiple, key) => {
  const m = multiple ? true : false;
  if (m) return "boolean";
  if (key === "yes" || key === "no") return "boolean";
  return "string";
};

export const getOptionsKey = (multiple = false, parentKey, valueKey = "") => {
  return multiple ? `${parentKey}_${valueKey}` : parentKey;
};

export const getOptionsValue = (key, value, multiple = false) => {
  if (multiple) return value;
  if (key === "yes") return true;
  if (key === "no") return false;
  if (value) return key;
  return;
};

const getFiles = (key, values) => {
  const valuesArray = values.map(file => (file.name));
  if (valuesArray.length === 0) {
    return false;
  }
  return {
    [key]: {
      type: 'string',
      value: valuesArray.toString(),
    }
  };
};

const getOptions = (options, stateKey, multiple = false) => {

  const v = Object.keys(options).reduce((values, optionKey) => {
    // Check if option should be returned.
    if (invalidOption(optionKey, options[optionKey].value)) return values;
    const value = getOptionsValue(
      optionKey,
      options[optionKey].value,
      multiple
    );

    const key = getOptionsKey(multiple, stateKey, optionKey);

    const type = getOptionsType(multiple, optionKey);

    // This check is needed to prevent overwrite of previous value
    // If multiple every key should be unique anyway
    if (!value && !multiple && optionKey !== "no") {
      return values;
    }

    values[key] = { type, value };
    return values;
  }, {});
  return v;
};
const getInput = (key, value) => {
  return importState(key, value, "string");
};

const getCheckbox = (key, value) => {
  return importState(key, value, "boolean");
};

const getSlider = (key, value) => {

  if (typeof value !== 'object') {
    return { ...importState(`${key}`, value, "string") };
  }

  const min = importState(`${key}_min`, value.min, "string");
  const max = importState(`${key}_max`, value.max, "string");
  return { ...min, ...max };
};

export const extractImportState = state => {
  const newState = Object.keys(state).reduce((values, key) => {
    // Check if state should be returned

    if (!state[key]) return values;

    switch (state[key].type) {
      case 'select':
      case 'tableselect':
      case 'dropdown':
        const options = getOptions(state[key].value, key, state[key].multiple);
        return (values = { ...values, ...options });

      case 'input':
      case "textarea":
      case 'inputautofill':
        const input = getInput(key, state[key].value);
        return (values = { ...values, ...input });

      case 'checkbox':
        const checkbox = getCheckbox(key, state[key].value);
        return (values = { ...values, ...checkbox });

      case 'slider':
        const slider = getSlider(key, state[key].value);
        return (values = { ...values, ...slider });
      case 'file':
        const file = getFiles(key, state[key].value);
        if (file) {
          return (values = { ...values, ...file });
        }
        return values;
      default:
        return values;
    }
  }, {});

  return newState;
};

/**
 * [step 2 filter method]
 * @param  {obj} state [parent state, this is the data to be filtered]
 * @param {bool} returnNotVisible [true = also return non visible values]
 * @return {string}    [string ex key1=value:type#key2=value:type]
 */

export const filterToString = state => {
  return transformImportFormStateToString(extractImportState(state));
};

export const noFilter = state => {
  return state;
};

export const sendToParent = state => {
  let filteredData = {};
  for (let key in state) {
    let currentValue = { ...state[key] };
    if (!checkRules(currentValue.visibleConditions, state)) {
      continue;
    }
    switch (state[key].type) {
      case "select":
      case "dropdown":
      case "tableselect":
        // Sanitized from all content
        filteredData[key] = {
          type: currentValue.type,
          valueType: currentValue.valueType,
          multiple: currentValue.multiple,
          validation: currentValue.validation,
          // options also needs to be sanitized
          value: Object.keys(currentValue.value).reduce((obj, oKey) => {
            const item = currentValue.value[oKey];
            obj[oKey] = {
              id: item.id,
              value: item.value
            };
            return obj;
          }, {}),
        };
        break;
      case "repeater":
      case "hidden":
      case "input":
      case "textarea":
      case "inputautofill":
      case "slider":
      case "checkbox":
      case "file":
        // Sanitized from all content
        filteredData[key] = {
          type: currentValue.type,
          valueType: currentValue.valueType,
          multiple: currentValue.multiple,
          value: currentValue.value,
          validation: currentValue.validation
        };
        break;
      default:
        break;
    }
  }
  return filteredData;
};

export default { standard, filterToString, sendToParent };
