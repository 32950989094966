import React from 'react';
import './index.scss';

const HoverTooltip = ({hoverContent, children, className}) => {

  return (
    <span className={`hover-tooltip ${className}`}>
      {children}
      <span className="hover-tooltip__text">
        {hoverContent}
      </span>
    </span>
  )

};

export default HoverTooltip;