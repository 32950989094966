import React from 'react';
import Flag from 'components/Flag';
import __ from 'localisation';
import './index.scss';
import { /*getLanguage,*/ setLanguage } from 'localisation/localisationFunctions';
import Button from '@hubins/components/Button';

const languageOptions = [
  {
    flag: "SE",
    title: __('lang_SE')
  },
  {
    flag: "EN",
    title: __('lang_EN')
  },
];
export const PageFooter = ({}) => {

  // languageSwitch = {
  //   "SE": [
  //     {
  //       flag: "SE",
  //       title: __('lang_SE')
  //     },
  //     {
  //       flag: "EN",
  //       title: __('lang_EN')
  //     },
  //   ],
  //   "FI": [
  //     {
  //       flag: "FI",
  //       title: __('lang_FI')
  //     },
  //     {
  //       flag: "SE",
  //       title: __('lang_SE')
  //     },
  //     {
  //       flag: "EN",
  //       title: __('lang_EN')
  //     },
  //   ],
  //   "NO": [
  //     {
  //       flag: "NO",
  //       title: __('lang_NO')
  //     },
  //     {
  //       flag: "EN",
  //       title: __('lang_EN')
  //     },
  //   ]
  // };

  const changeLanguage = (language, e) => {
    e.preventDefault();
    setLanguage(language);
    window.location.reload();
  };

  const renderLanguages = () => {
    // const selectedMarket = this.languageSwitch[this.props.market];

    return languageOptions.map((item, index) => (
      <Button key={index}
        type="button"
        onClick={(e) => changeLanguage(item.flag, e)}
        className="flag" onlyIcon
      >
        <Flag flag={item.flag} label={__(item.title)} />
      </Button>
    ));
  };



  return (
    <>
      <div className="flex-grow"></div>
      <div className="footer">
        <div className="column">
          {/* JOOL Markets AS */}
          <p className="copyright">Copyright {new Date().getFullYear()} Hubins</p>
        </div>
        <div className="column">
          <p className="c-neutral-6 tooltip select-all" data-tooltip={new Date(VITE_TIMESTAMP)}>
            {import.meta.env.VITE_MODE === 'beta' && 'BETA - '}v.{VITE_VERSION} - Build: {VITE_TIMESTAMP}
          </p>
        </div>
        {VITE_LANG_SUPPORT && (
          <div className="column change_footer_language">
            <p className="lang">{__('change_lang')}</p>
            {renderLanguages()}
          </div>
        )}
      </div>
    </>
  );

};

export default PageFooter;
