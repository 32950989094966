import checkRules from 'modules/CreateForm/checkRules';
// Default filter for formData that comes from createForm
export const formDataToCollection = (formData) => {
  const keys = Object.keys(formData);
  return keys.reduce((collection, key) => {
    const field = formData[key];

    const fieldIsVisible = checkRules(field.visibleConditions, formData);

    if (!fieldIsVisible) return collection;

    switch (field.type) {
      case 'select':
      case 'dropdown':
      case 'tableselect':
        const optionValues = Object.keys(field.value)
          .filter((optionKey) => (
            field.value[optionKey].value
          ));

        collection[key] = optionValues;
        break;
      case "repeater":
        const formattedKeys = Object.keys(field.value).map(repeaterKey => (repeaterKey.replace(/_{1}\d+$/, '')));
        const uniqueArrayOfKeys = [...new Set(formattedKeys)];
        const numberOfFields = uniqueArrayOfKeys.length;
        const numberOfIterations = formattedKeys.length / numberOfFields;
        const values = [];

        for (let i = 0; numberOfIterations > i; i++) {
          const valueSet = uniqueArrayOfKeys.reduce((obj, rKey) => {
            obj[rKey] = field.value[`${rKey}_${i + 1}`];
            return obj;
          }, {});
          const formattedValueSet = formDataToCollection(valueSet);
          values.push(formattedValueSet);
        }
        collection[key] = values;
        break;
      case "hidden":
      case "input":
      case "textarea":
      case "inputautofill":
      case "slider":
      case "checkbox":
      case "file":
        collection[key] = field.value;
        break;
    }
    return collection;
  }, {});
};
