import React, { useState } from "react";
import PropTypes from "prop-types";
import { useCookie } from "./CookieContext.jsx";
import Button, { ButtonWrap } from '@hubins/components/Button';
import Switch from 'components/Switch';
import { useCollapse } from 'react-collapsed';
import Accordeon from 'components/Accordeon';

import './CookieBanner.scss';

const Category = ({ cat, tempAcceptsCookies, setValue }) => {
  return (
    <div className="CookieBanner__category">
      <Switch
        disabled={cat.disabled}
        checked={cat.disabled ? true : tempAcceptsCookies[cat.id]}
        onChange={(e) => {
          e.stopPropagation();
          !cat.disabled && setValue(cat.id, e.target.checked);}
        }
      />
      <Accordeon
        buttonStyle="CookieBanner__category-button"
        isExpanded={false}
        label={cat.title}
      >
        <div className="CookieBanner__items">
          <div className="CookieBanner__description">{cat.description}</div>
          {cat.items.map((item, i) => (
            <p className="CookieBanner__item" key={"item_" + i}>
              <strong>{item.title}</strong><br/>
              {item.description}
            </p>
          ))}
        </div>
      </Accordeon>

    </div>
  );
};

const CookieBanner = ({
  text,
  readMoreText,
  closeText,
  acceptButtonText,
  saveText,
  categories,
  content,
}) => {
  const { checked, setChecked, acceptsCookies, setAcceptsCookies, expanded, setExpanded } = useCookie();
  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded: expanded });
  // const contentRef = useRef(null);
  const [tempAcceptsCookies, setTempAcceptsCookies] = useState(acceptsCookies);

  // useEffect(() => {
  //   const currentRef = contentRef.current;
  //   // if (!currentRef) return console.error('no ref');
  //   if (!currentRef) return;
  //   if(expanded) {
  //     disableBodyScroll(currentRef);
  //   }
  //   else {
  //     enableBodyScroll(currentRef);
  //   }
  //   return clearAllBodyScrollLocks;
  // }, [expanded]);

  const setValue = (category, value) => {
    setTempAcceptsCookies({ ...tempAcceptsCookies, [category]: value });
  };

  const saveValus = () => {
    setAcceptsCookies(tempAcceptsCookies);
    setChecked(true);
    // clearAllBodyScrollLocks();
  };

  const acceptAll = () => {
    const savedCookieData = {};
    categories.forEach(cat => {
      savedCookieData[cat.id] = true;
    });

    setAcceptsCookies(savedCookieData);
    setTempAcceptsCookies(savedCookieData);
    setChecked(true);
  };

  const expand = () => {
    setExpanded(!expanded);
  };

  if (!checked) {
    return (
      <>
        <div className={`CookieBanner ${checked ? '' : 'CookieBanner--visible'}`}>
          <div className="CookieBanner__main">
            <div className="CookieBanner__content">{text}
              <Button className="inline-link" {...getToggleProps({ onClick: expand })}>
                {readMoreText}
              </Button>
            </div>

            <Button
              className="cta"
              onClick={acceptAll}
              small
            >
              {acceptButtonText}
            </Button>
          </div>
        </div>
        <div className="CookieBanner__more">
          <div {...getCollapseProps()}>
            <div className="CookieBanner__options">
              {categories.map((cat, index) => (
                <Category cat={cat} key={"cat_" + index} tempAcceptsCookies={tempAcceptsCookies} setValue={setValue} />
              ))}
              <div className="CookieBanner__content">
                {content && content}
              </div>
            </div>
          </div>
        </div>
        {expanded && (
          <div className="CookieBanner__footer">
            <ButtonWrap pushtosides>
              <Button small className="cta" onClick={saveValus}>{saveText}</Button>
              <Button small className="link" onClick={expand}>{closeText}</Button>
            </ButtonWrap>
          </div>
        )}
      </>
    );
  }
  return false;
};

CookieBanner.propTypes = {
  text: PropTypes.node,
  readMoreText: PropTypes.string,
  closeText: PropTypes.string,
  acceptButtonText: PropTypes.string,
  saveText: PropTypes.string,
  categories: PropTypes.array.isRequired,
};

export default CookieBanner;
