import React, { useEffect, useState } from 'react';
import CreateCmsCase from 'modules/CreateCmsCase';
import LoadingPage from 'views/LoadingPage';
import NoMatch from 'views/NoMatch';
import { __plural, __route } from 'localisation';
import { getLanguage } from 'localisation/localisationFunctions';
import { setPageTitle, isEmpty } from 'helpers';
import middleman from 'helpers/middleman';
import {
  getRemainingMaturity,
  remainingAnnualRate
} from 'helpers/calculations';

const securityIds = {
  production: 1026,
  beta: 1026,
  stage: 918,
  local: 918,
  development: 918,
};
const securityId = securityIds[import.meta.env.MODE];

const SingleCase = ({
  id,
  title,
  hideFooter,
  admin = false,
}) => {
  const shouldHideFooter = hideFooter
    ? true
    : parseInt(securityId) === parseInt(id)
      ? false
      : hideFooter;

  const [security, setSecurity] = useState(false);
  const [content, setContent] = useState(false);
  const [loading, setLoading] = useState(true);


  const getData = async () => {
    try {
      const securityRes = middleman.get(`/securities/${id}/show`);
      const contentRes = middleman.get(`/v1/fa/securities/content/${id}/${getLanguage()}/SE`).catch(() => {
        return {
          data: false
        };
      });
      const responses = await Promise.all([securityRes, contentRes]);
      setContent(responses[1].data);
      setSecurity(responses[0].data);
      setLoading(false);
    } catch {
      setLoading(false);
    }

  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setPageTitle([security.name, title]);
  }, [security]);

  if (loading) return <LoadingPage />;

  if (!security || !content) return (
    <NoMatch
      location={`${__route('case')}/${id}`}
      editLink={admin ? `${__route('cmsEdit')}/${id}/SE` : false}
    />
  );

  const leftTime = getRemainingMaturity({
    maturity: security.maturityDate,
    accrualCalendar: security.accrualCalendar,
    isin: security.isinCode,
  });

  const leftRate = remainingAnnualRate({
    maturity: security.maturityDate,
    yearlyrent: security.coupon,
    format: false,
    accrualCalendar: security.accrualCalendar,
  });

  // check if the content/security status is set to publish or if the user is an Proxy
  return (
    (!isEmpty(content) && ((content.status === "publish") || admin) ) ?
      (
        <CreateCmsCase
          id={security.id}
          heroImage={content.hero.value ? content.hero.value : '/illustrations/investments_empty.svg'}
          heroAlt={content.hero.text}
          logo={content.logo.value}
          logoAlt={content.logo.text}
          heroTitle={security.name}
          interest={security.coupon}
          remainingRate={leftRate}
          period={__plural('month_string_short', false, { number: security.periodInMonths })}
          remainingPeriod={__plural('month_string_short', false, {
            number: leftTime })}
          maxVol={`${security.maxVolume}M`}
          security={security}
          description={content.description}
          caseData={content.content}
          type={security.type}
          securityCode={security.securityCode}
          currency={security.currency}
          ms_diff={security.msDiff}
          hideFooter={shouldHideFooter}
          new
        />
      ) : (
        <NoMatch location={`case/${id}`} />
      )
  );

};

export default SingleCase;
