// export { alertReducerExport as alerts } from "./alerts";
// export { authReducerExport as auth } from "./auth";
// export { documentsReducerExport as documents } from "./documents";
// export { messagesReducerExport as messages } from "./messages";
// export { securitiesReducerExport as securities } from "./securities";
// export { tradeOrderReducerExport as tradeOrder } from "./tradeOrder";
// export { userAccountsReducerExport as userAccounts } from "./userAccounts";
// export { userHoldingsReducerExport as userHoldings } from "./userHoldings";
// export { userReportsExport as userReports } from "./userReports";

import { alertReducerExport } from "./alerts";
import { authReducerExport } from "./auth";
// import { documentsReducerExport } from "./documents";
// import { messagesReducerExport } from "./messages";
// import { securitiesReducerExport } from "./securities";
// import { tradeOrderReducerExport } from "./tradeOrder";
// import { userAccountsReducerExport } from "./userAccounts";
// import { userHoldingsReducerExport } from "./userHoldings";
// import { userReportsExport } from "./userReports";

export default {
  alerts: alertReducerExport,
  auth: authReducerExport,
  // documents: documentsReducerExport,
  // messages: messagesReducerExport,
  // securities: securitiesReducerExport,
  // tradeOrders: tradeOrderReducerExport,
  // userAccounts: userAccountsReducerExport,
  // userHoldings: userHoldingsReducerExport,
  // userReports: userReportsExport,
};
