import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import MenuHeading from 'modules/Menu/MenuHeading';
import ErrorBoundary from 'components/ErrorBoundary';
import PageFooter from 'components/PageFooter';
import __, { __route } from 'localisation';
import { connect } from 'react-redux';
import { authOperations } from '../state/ducks/auth';
import SkipToContentLink from 'components/SkipToContentLink';

const WithHeaderMenu = ({ rootUrl = __route('root'), ...props }) => {

  useEffect(() => {
    if (document) {
      document.title = props.specialTitle ? props.title : props.title + ' - ' + __('app_name');
    }
  }, [props.title]);


  return (
    <>
      <SkipToContentLink
        target="MainContent"
        text={__('skip_to_main_content')}
      />
      <ErrorBoundary>
        <MenuHeading
          {...props}
          rootUrl={rootUrl}
          title={props.headerTitle}
          btnType={props.btnType}
          backBtn={props.backBtn}
        />
      </ErrorBoundary>
      <ErrorBoundary>
        <div className={`page-content ${props.stretch ? 'stretch' : ''}`}>
          <Route {...props} />
        </div>
      </ErrorBoundary >
      {!props.noFooter && (
        <PageFooter
          market={props.market}
          switchLanguage={props.switchLanguage}
        />
      )}
    </>
  );

};

function mapStateToProps(state) {
  return {
    market: state.auth.User.market,
  };
}

const mapDispatchToProps = {
  switchLanguage: authOperations.switchLanguage
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WithHeaderMenu);
