import React, { useState, useEffect } from 'react';
import __, { __route } from 'localisation';
import { motion } from 'framer-motion';
import Wrapper from '@hubins/components/Wrapper';
import { Columns, Column } from 'components/Columns';
import DualContent from 'components/DualContent';
import Button from '@hubins/components/Button';
import Heading from 'components/Heading';
import BankIDLogin from 'modules/BankID/Login';
import BankIDLoginQR from 'modules/BankID/LoginQR';
import { connect } from 'react-redux';
import { authOperations } from '../../state/ducks/auth';
import { Redirect } from 'react-router-dom';
import { useGrowthBook, useFeature } from '@growthbook/growthbook-react';

const Login = ({ updateUser, logoutUser, clearUser }) => {

  const [mounted, setMounted] = useState(false);

  const BANKID_V6 = useFeature('bankid-api-v6').on;

  const BankIDComponent = BANKID_V6 ? BankIDLoginQR : BankIDLogin;

  const gd = useGrowthBook();

  useEffect(() => {
    setTimeout(() => {
      setMounted(true);
    }, 1000);
  }, []);

  const [redirect, setRedirect] = useState(false);

  const handleSuccessfulLogin = (response) => {
    let user = response.user;
    user.registeredUser = response.registeredUser;
    updateUser(user);
    gd.setAttributes({
      id: user.id.toString(),
    });
    setRedirect(true);
  };

  if (redirect) {
    return <Redirect to={__route('root')} />;
  }

  return (
    <Wrapper>
      <Columns bg="primary" className="box-padding">
        <Column m="8" l="4" align="center">
          <Heading size="2" element={motion.h1} animate>{__('registered_user_title')}</Heading>
          <motion.p className="body-big" animate>{__('login_user_text')}</motion.p>
          <motion.p className="body-big" animate>Är du inte kund? Skapa konto snabbt, tryggt och gratis!</motion.p>
          <Button className='cta' size="large" icon="arrow right 1" iconSize={16} iconColor='white' href={__route('signup')}>Skapa konto</Button>
        </Column>
        <div id="loaded-marker" />
        <Column m="12" l="6" offsetL="1" align="center">
          <DualContent
            sectionColor="primary"
            sections={[
              <BankIDComponent
                logoutUser={logoutUser}
                clearUser={clearUser}
                callback={handleSuccessfulLogin}
                key="bankidLogin"
              />
            ]}
          />
        </Column>
      </Columns>
    </Wrapper>
  );

};


export default connect(null, {
  logoutUser: authOperations.logoutUser,
  clearUser: authOperations.clearUser,
  updateUser: authOperations.updateUser
})(Login);
