import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';


const ProgressBar = (props) => (
  <div className="progressbar">
    <div className="progressbar__meter" style={{
      transform: `scaleX(${props.progress / 100 })`
    }}></div>
  </div>
);

ProgressBar.propTypes = {
  progress: PropTypes.number
};

ProgressBar.defaultProps = {
  progress: 0
};

export default ProgressBar;
