import React from "react";
import __ from "localisation";
import Button, { ButtonWrap } from "@hubins/components/Button";
import Heading from "components/Heading";
import Modal from "components/Modal";

const SuccessModal = ({ confirmationModalOpen, setConfirmationModalOpen, callback, endOfInvestments }) => {

  return (
    <Modal
      className="animation-reveal narrow modal__overlay--centermobile"
      contentClass=""
      noWrapper={false}
      open={confirmationModalOpen === "success"}
    >
      <img
        src="/images/tof_investment_success.svg"
        alt="success illustration"
      />
      <Heading size="3" className="f-mono-weight-light s-top-md">
        {__("tof_add_investment_success_modal_title")}
      </Heading>
      <p>{__("tof_add_investment_success_modal_text")}</p>
      <ButtonWrap>
        <Button
          className="cta s-top-md"
          onClick={() => {
            callback();
            setConfirmationModalOpen("");
          }}
        >
          {endOfInvestments() ? "Gå vidare" : __("tof_add_investment_success_modal_button")}
        </Button>
      </ButtonWrap>
    </Modal>
  );
};

export default SuccessModal;
