

export const REFRESHTOKEN = "auth/REFRESHTOKEN";

export const GET = "auth/GET";

export const LOGIN = "auth/LOGIN";
export const LOGOUT = "auth/LOGOUT";
export const ABORT = "auth/ABORT";
export const REGISTER = "auth/REGISTER";
export const REGISTER_BANKID = "auth/REGISTER_BANKID";
export const UPDATE = "auth/UPDATE";
export const POLL = "auth/POLL";
export const POLLBANKIDSE = "auth/POLL_BANKID_SE";
export const COLLECTDATA = "auth/DATACOLLECT";
export const COMPANYREG = "auth/COMPANYREG";
export const INFO = "auth/INFO";
export const SETSTATUS = "auth/SETSTATUS";
export const LANGUAGE = "auth/LANGUAGE";
export const DELETE = "auth/DELETE";
export const RESETFORM = "auth/RESETFORM";
export const COMPLETEFORM = "auth/COMPLETEFORM";
export const UPDATESTATUS = "auth/UPDATESTATUS";
export const CREATE = "auth/CREATE";
