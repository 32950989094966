import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Button from '@hubins/components/Button';
import Countdown from 'components/Countdown';
import Info from 'components/Info';
import { __ } from 'localisation';

import FixedFooter from 'components/FixedFooter';

import './index.scss';

class CaseFixedFooter extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isShown: true,
      open: false,
      notice: false
    };
  }

  render() {
    const { open, notice } = this.state;
    const secondButtonExternal = this.props.secondButtonExternal ? {
      target: "_blank",
      rel: "noopener noreferrer"
    } : {};
    return (
      <FixedFooter
        className={`fixed-footer--case ${this.props.ms_diff && "withTimer"}`}
        col1={
          <div className="button__wrap">
            {(this.props.ms_diff || this.props.ms_diff === 0)
              ? (
                <Button
                  className={`responsive ${this.props.disabled ? "disabled" : (notice || open) && "cta"} gtm_single_case_invest_now_button`}
                  href={this.props.buttonUrl}
                  disabled={this.props.disabled}
                >
                  {open ? __('invest_now') : __('prepare_investment')}
                </Button>
              )
              : (
                <Button
                  href={!this.props.disabled ? this.props.buttonUrl : '#'}
                  onClick={this.props.onClick && this.props.onClick}
                  disabled={this.props.disabled}
                  className={`
                  gtm_single_case_invest_now_button
                  responsive ${!this.props.disabled
                  ? this.props.buttonStyle
                  : 'disabled'
                }`}
                >
                  {this.props.buttonText}
                </Button>
              )}
            {this.props.ms_diff > 0 && (
              <Countdown
                full={false}
                msDiff={this.props.ms_diff}
                callback={(isOpen, shouldNotice) => this.setState({ open: isOpen, notice: shouldNotice })}
                small={true}
                colorTheme="dark"
              />
            )}

            {this.props.secondButtonText && (
              <Button
                href={!this.props.secondDisabled && this.props.secondButtonUrl && this.props.secondButtonUrl}
                onClick={this.props.secondOnClick && this.props.secondOnClick}
                disabled={this.props.secondDisabled}
                {...secondButtonExternal}
                className={
                  !this.props.secondDisabled
                    ? this.props.secondButtonStyle
                    : 'disabled'
                }
              >
                {this.props.secondButtonText}
              </Button>
            )
            }
          </div>
        }
        col2={
          this.props.data && (
            <div className="fixed-footer__col2">
              <Info data={this.props.data} />
            </div>
          )
        }

      />


    );
  }

}

CaseFixedFooter.propTypes = {
  buttonText: PropTypes.string,
  buttonStyle: PropTypes.string,
  secondButtonText: PropTypes.string,
  secondButtonStyle: PropTypes.string,
  disabled: PropTypes.bool,
  ms_diff: PropTypes.oneOfType([PropTypes.bool, PropTypes.number, PropTypes.string]),
};

CaseFixedFooter.defaultProps = {
  buttonText: '',
  buttonStyle: '',
  secondButtonText: '',
  secondButtonStyle: '',
  disabled: false,
  ms_diff: false
};

export default CaseFixedFooter;
