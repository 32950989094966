import { __, __route } from 'localisation';
import { formatPercent } from 'helpers';
import {
  // getRemainingMaturity,
  remainingAnnualRate
} from 'helpers/calculations';


export const generateTableData = (entry) => {
  switch (entry.type) {
    case 'STOCK':
      return [
        { label: __('product'), value: __(`stock_${entry.subType}`) },
        { label: __('volume'), value: entry.profileAttributes.poster_volume, suffix: entry.profileAttributes.poster_volume_suffix },
        // { label: __('min_investment'), value: formatMoney(entry.profileAttributes.poster_min_investment, " ", 0), suffix: entry.profileAttributes.poster_min_investment_suffix },
      ];
    case 'CRYPTOCURRENCY':
      return [
        { label: __('product'), value: __(`${entry.type}`) },
        { label: __('volume'), value: entry.profileAttributes.poster_volume, suffix: entry.profileAttributes.poster_volume_suffix },
        // { label: __('min_investment'), value: formatMoney(entry.profileAttributes.poster_min_investment, " ", 0), suffix: entry.profileAttributes.poster_min_investment_suffix },
      ];
    default:
      if (entry.tagList && entry.tagList.includes('Block sale')) {
        return [
          { label: __('yearly-rate'), value: formatPercent(entry.coupon) },
          {
            label: __('rem_running_time'), value: remainingAnnualRate({
              maturity: entry.maturityDate,
              yearlyrent: entry.coupon,
              format: false,
              accrualCalendar: entry.accrualCalendar,
            })
            , suffix: __('month')
          },
          { label: __('max_volume'), value: entry.profileAttributes.max_volume, suffix: 'M' + entry.currency },
        ];
      }
      return [
        { label: __('yearly-rate'), value: formatPercent(entry.coupon) },
        { label: __('period'), value: entry.period_in_months, suffix: __('month') },
        { label: __('max_volume'), value: entry.profileAttributes.max_volume, suffix: 'M' + entry.currency },
      ];
  }
};

export const generateTableData_NEW = (entry) => {
  switch (entry.type) {
    case 'STOCK':
    case 'CRYPTOCURRENCY':
      return [
        { label: __('product'), value: __(`stock_${entry.subType}`) },
        { label: __('volume'), value: entry.posterVolume, suffix: entry.posterVolumeSuffix },
      ];
    default:
      if (entry.tags && entry.tags.includes('Block sale')) {
        return [
          { label: __('yearly-rate'), value: formatPercent(entry.coupon) },
          {
            label: __('rem_running_time'), value: remainingAnnualRate({
              maturity: entry.maturityDate,
              yearlyrent: entry.coupon,
              format: false,
              accrualCalendar: entry.accrualCalendar,
            })
            , suffix: __('month')
          },
          { label: __('max_volume'), value: entry.maxVolume, suffix: 'M' + entry.currency },
        ];
      }
      return [
        { label: __('yearly-rate'), value: formatPercent(entry.coupon) },
        { label: __('period'), value: entry.periodInMonths, suffix: __('month') },
        { label: __('max_volume'), value: entry.maxVolume, suffix: 'M' + entry.currency },
      ];
  }
};

export const getBlockSaleCaseUrl = (id) => {
  return `${__route('case_objectid_blocksale')}/${id}`;
};

export const getCaseUrl = (id) => {
  return `${__route('case')}/${id}`;
};

export const getBlockSaleInvestPageUrl = (type, id) => {
  return `${__route('blocksale')}/${id}`;
};

export const getInvestPageUrl = (type, id) => {
  switch (type) {
    case 'STOCK':
      return `${__route('subscription_stock')}/${id}`;
    case 'CRYPTOCURRENCY':
      return `${__route('subscription_crypto')}/${id}`;
    default:
      return `${__route('subscription')}/${id}`;
  }
};
