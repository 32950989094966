import React from 'react';
import PropTypes from 'prop-types';
import __ from 'localisation';
import './index.scss';

const Switch = ({ checked, onChange, disabled, label }) => {

  return (
    <label
      className="Switch"
    >
      <span className="visuallyhidden">{__(checked ? 'activated' : 'deactivated')} </span>
      <input type="checkbox" className="visuallyhidden" checked={checked} onChange={onChange} disabled={disabled} />
      <span className="Switch__wrap"></span>
      <span className="Switch__toggle"></span>
      {label && <span className="Switch__label">{label}</span>}
    </label>
  );
};

Switch.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.node,
};

Switch.defaultProps = {
  checked: false,
  disabled: false,
};

export default Switch;
