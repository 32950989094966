import React from 'react';
import Init from './init';
import Template from './template';
import './index.scss';

const FormSelect = (props) => (
  <div className="formselect-template">
    <Init {...props} Template={Template} />
  </div>
);

export { Template };
export default FormSelect;
