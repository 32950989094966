import React from 'react';
import Input, { InputProps } from './Input';
import useCurrency from './useCurrency';


const Currency = (props: InputProps) => {

  const { value: propValue, controlled, callback } = props;
  const [value, setValue] = useCurrency(propValue || '');

  const handleChange = (newValue: any) => {
    setValue(newValue);
    if (callback) {
      callback(newValue.replace(/ /g, ''));
    }
  }

  return (
    <Input
      {...props}
      controlled={true}
      value={controlled ? propValue : value}
      type="text"
      callback={handleChange}
    />
  )

};

export default Currency;