import React, { Fragment } from 'react';
import { Column } from 'components/Columns';

const CaseColumns = (props) => {

  return (
    <Fragment>
      <Column s="12" m="6" className="content section-padding body-big">
        <div dangerouslySetInnerHTML={{ __html: props.left }} />
      </Column>
      <Column s="12" m="6" className="content section-padding body-big">
        <div dangerouslySetInnerHTML={{ __html: props.right }} />
      </Column>
    </Fragment>
  );
};

export default CaseColumns;
