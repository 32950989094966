import { getTaxationByYear } from 'helpers/taxation';

/**
 * calcRiskPremium - Calulates the sum of the variable fees for a set of months
 * @param {number} age - The age of the person
 * @returns {number} - Returns %
 */
export const calcRiskPremium = (age) => {
  /*
  60: 0.0050%
  65: 0.0065%
  70: 0.0085%
  75: 0.0105%
  80: 0.0130%
  85: 0.0150%
  90: 0.0150%
  */
  let percent = 0;

  if (age >= 85) percent = 0.015;
  else if (age >= 80) percent = 0.013;
  else if (age >= 75) percent = 0.0105;
  else if (age >= 70) percent = 0.0085;
  else if (age >= 65) percent = 0.0065;
  else if (age >= 60) percent = 0.005;

  return percent / 100;
};


/**
 * Effective monthly rate of return
 * @param {number} yearlyReturn - Ex 12 (procent)
 * @returns {number} -
 */
export const getMonthlyRate = (yearlyReturn) => ( (Math.pow(1 + (yearlyReturn / 100), 1 / 12) - 1));


/**
 * getValuesPerMonth - A monster function to get all values for ex the InsuranceVsDirect graph
 * @param {object} args contains values to calculate graph data from
 * @param {number} args.initialValue - Size of investment, or initial planned investment
 * @param {number} args.numberOfMonths The number of months that you want to invest
 * @param {number} args.expectedReturn The return in percent the user expect to get each year
 * @param {number} args.riskPremium If the user is +65 years, add a risk premium
 * @param {number} args.fixedFee A fixed fee for the entire portofolio
 * @param {number} args.variableFee A variable fee for the entire portofolio
 * @param {number} args.feeBasedOnFirstMonth Additional direct investment tax for funds. Number is the percent fee based on the first month of the year (same calulation as Insurance taxation)
 * @returns {array} Return an array of objects
 */
export const getValuesPerMonth = (args) => {
  const defaultValues = {
    initialValue: 1000000,
    numberOfMonths: 12,
    expectedReturn: 0.1,
    riskPremium: 0.000,
    fixedFee: 1800,
    variableFee: 0.01,
    directInvestmentTax: 0.3,
    transactionFee: 300,
    feeBasedOnFirstMonth: 0,
  };
  const {
    initialValue,
    numberOfMonths,
    expectedReturn,
    riskPremium,
    fixedFee,
    variableFee,
    directInvestmentTax,
    transactionFee,
    feeBasedOnFirstMonth,
  } = { ...defaultValues, ...args };

  const totalFee = (variableFee + riskPremium) / 12;
  const monthlyFixedFee = fixedFee ? fixedFee / 12 : 0;
  const monthlyTransactionFee = transactionFee / numberOfMonths;

  const taxes = getTaxationByYear('current');

  const actualTaxation = taxes.STANDARD_TAX.value; // 0.00882;
  const effectiveMonthlyRateOfReturn = getMonthlyRate(expectedReturn);
  const monthlyActualTaxation = actualTaxation / 12;

  let returnValues = [];

  // Fake last months values
  returnValues[-1] = {
    futureValueBeforeFees: initialValue,
    monthlyInsuranceFeeRisk: 0,
    monthlyInsuranceFixedFee: 0,
    unitLinkTaxation: 0,
    feeBasedOnFirstMonthValue: 0,
  };

  for (let index = 0; index < numberOfMonths; index++) {

    const {
      futureValueBeforeFees: prevFutureValueBeforeFees,
      monthlyInsuranceFeeRisk: prevMonthlyInsuranceFeeRisk,
      monthlyInsuranceFixedFee: prevMonthlyInsuranceFixedFee,
      unitLinkTaxation: prevUnitLinkTaxation,
      feeBasedOnFirstMonthValue: prevFeeBasedOnFirstMonthValue,
    } = returnValues[index - 1];
    const startValue = prevFutureValueBeforeFees;

    const monthlyReturn = startValue * effectiveMonthlyRateOfReturn;

    const futureValueBeforeFees = startValue + monthlyReturn;

    const monthlyInsuranceFeeRisk = futureValueBeforeFees * totalFee + prevMonthlyInsuranceFeeRisk;

    const monthlyInsuranceFixedFee = monthlyFixedFee + monthlyTransactionFee + prevMonthlyInsuranceFixedFee;

    const firstMonthOfYear = returnValues[Math.floor(index / 12) * 12];

    const isJanuary = index % 12 === 0 ? true : false;
    const yearlyStartValue = isJanuary ? startValue : firstMonthOfYear.startValue;


    // console.log(firstMonthOfYear, yearlyStartValue, monthlyActualTaxation, prevUnitLinkTaxation, index, 'index');
    // same as Insurance Taxation I guess / Jonas
    const unitLinkTaxation = (yearlyStartValue * monthlyActualTaxation) + prevUnitLinkTaxation;

    // used for fonds
    const feeBasedOnFirstMonthValue = (yearlyStartValue * (feeBasedOnFirstMonth / 12) + prevFeeBasedOnFirstMonthValue);

    const prevTotalFees = prevMonthlyInsuranceFeeRisk + prevMonthlyInsuranceFixedFee;
    const prevTotalFeesAndTax = prevTotalFees + prevUnitLinkTaxation;
    const returnAfterFees = index === 0 ? initialValue : prevFutureValueBeforeFees - prevTotalFeesAndTax;

    const directFees = (prevFutureValueBeforeFees - initialValue) * directInvestmentTax + prevFeeBasedOnFirstMonthValue;
    const directInvestmentReturn = index === 0 ? initialValue : prevFutureValueBeforeFees - directFees;

    const obj = {
      month: index + 1,
      startValue,
      monthlyReturn,
      futureValueBeforeFees,
      monthlyInsuranceFeeRisk,
      monthlyInsuranceFixedFee,
      unitLinkTaxation,
      feeBasedOnFirstMonthValue,

      directFees, // for Transfer of Assets
      prevTotalFees, // for Transfer of Assets
      prevUnitLinkTaxation, // for Transfer of Assets

      returnAfterFees, // the main value in the InvestmentVsDirect graph (Hubins)
      directInvestmentReturn, // the second value in the graph but for Direct Investment (Not Hubins)
    };

    returnValues.push(obj);

  }

  returnValues[-1] = undefined;

  return returnValues;
};
