import FF from "features";

export const bypass = () => {
  // If process does not allow it return false …
  if (
    import.meta.env.VITE_MODE === "production" ||
    import.meta.env.VITE_MODE === "beta"
  )
    return false;
  // … otherwise use Feature flag setting
  return FF.bypassBankId;
};

export const useFakeRoaring = () => {
  if (
    import.meta.env.VITE_MODE === "production" ||
    import.meta.env.VITE_MODE === "beta"
  )
    return false;
  return FF.fakeRoaring;
};
