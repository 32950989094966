import React from 'react';
import './index.scss';

interface WrapperContentProps {
  className: string,
  wide: boolean,
  center: boolean,
  children: JSX.Element,
}

export const WrapperContent = ({
  children,
  className = 'section-padding',
  wide = false,
  center = false,
}: WrapperContentProps) => (
  <div className={`Wrapper__content ${className}${wide ? ' Wrapper__content--wide' : ''}${center ? ' flex-center' : ''}`}>
    {children}
  </div>
);

interface WrapperProps {
  className: string,
  center: boolean,
  children: JSX.Element,
  stretch: boolean,
  noSpaceOnMobile: boolean,
  noBg: boolean,
  noShadow: boolean,
  rounded: boolean,
  noSpacing: boolean,
}

export const Wrapper = ({
  children,
  className = '',
  stretch = false,
  noSpaceOnMobile = false,
  noBg = false,
  noShadow = false,
  rounded = false,
  noSpacing = false,
}: WrapperProps) => {
  let variants = className;
  if (stretch) variants += ' Wrapper--stretch';
  if (noSpaceOnMobile) variants += ' Wrapper--no-space-mobile';
  if (noBg) variants += ' Wrapper--no-bg';
  if (noShadow) variants += ' Wrapper--no-shadow';
  if (rounded) variants += ' Wrapper--rounded';
  if (noSpacing) variants += ' Wrapper--no-spacing';

  return (
    <div
      className={`Wrapper ${variants}`}
    >
      {children}
    </div>
  );
};

export default Wrapper;
