import React, { useCallback } from 'react';
import SimpleTable from 'modules/Tables/SimpleTable';
import LoadingBar from 'components/LoadingIndicators';
import { getMoney, formatPercent } from 'helpers';
import SidebarTrigger from 'components/Sidebar/SidebarTrigger';
import ReadMore from 'components/Accordeon/ReadMore';
import Heading from 'components/Heading';
import Box from 'components/Box';
import __ from 'localisation';
import { mergeEndowments } from 'helpers/endowment';
import { getTaxationByYear } from 'helpers/taxation';

const loadingDataState = [
  {
    type: __('insurance_value_start'),
    value: <LoadingBar />,
  },
  {
    type: __('premiums_start'),
    prefix: '(+)',
    value: <LoadingBar />,
  },
  {
    type: __('premiums_half_end'),
    prefix: '(+)',
    value: <LoadingBar />,
  },
  {
    type: __('capital_base'),
    prefix: '(=)',
    value: <LoadingBar />,
  },
  {
    type: __('interest_rate'),
    prefix: '(*)',
    value: <LoadingBar />,
  },
  {
    type: __('tax_base'),
    prefix: '(=)',
    value: <LoadingBar />,
  },
  {
    type: __('tax_rate'),
    prefix: '(*)',
    value: <LoadingBar />,
  },
  {
    type: __('actual_tax_rate_before'),
    prefix: '(=)',
    value: <LoadingBar />,
  },
  {
    type: __('total_paid_tax_during_year'),
    value: <LoadingBar />,
  },
  {
    type: __('total_paid_tax_down'),
    prefix: '(-)',
    value: <LoadingBar />,
  },
  {
    type: __('total_tax_for_coming_year'),
    value: <LoadingBar />,
  },
];

const loadingDataState2 = [
  {
    type: __('table2_header_1', { year: 'xxxx' }),
    value: <LoadingBar />
  },
  {
    type: __('table2_header_2'),
    value: <LoadingBar />
  },
];

const YearlyTaxTable = ({ endowment, transactionsEndowment, isLoading, year, currency, portfolio }) => {

  const taxation = getTaxationByYear(year);

  const STATIC_VALUES = {
    interest_rate: taxation.CAPITAL_INSURANCE_TAX.value,
    tax_rate: 0.3
  };

  const policyHolder = (portfolio?.policy_holder && portfolio?.policy_holder.length > 0) ? portfolio?.policy_holder[0] : null;

  const isCompany = policyHolder && policyHolder?.type === 'COMPANY';

  const endowmentData = isLoading ? [] : mergeEndowments(endowment, transactionsEndowment);

  const generateTableData = useCallback(() => {

    let headers = [
      {
        Header: '',
        accessor: 'prefix',
        maxWidth: 50,
      },
      {
        Header: '',
        accessor: 'type'
      },
      {
        Header: '',
        accessor: 'value',
        maxWidth: 170,
      }
    ];

    let headers2 = [
      {
        Header: '',
        accessor: 'type'
      },
      {
        Header: '',
        accessor: 'value',
        maxWidth: 170,
      }
    ];

    const loadingState = {
      tableHeaders: headers,
      tableData: loadingDataState,
      tableHeaders2: headers2,
      tableData2: loadingDataState2
    };

    if (isLoading) {
      return loadingState;
    }

    const data = endowmentData[year];

    if (!data) {
      console.error('Year ', year, ' not found in endowment.');
      return loadingState;
    }

    headers.push({
      Header: '',
      accessor: 'tooltip',
      width: 10,
      Cell: ({ row }) => (
        <div className="relative">
          {row.original.tooltip && (
            <SidebarTrigger
              id={row.original.tooltip}
              icon="question"
              className="relative"
              text={row.original.tooltip}
            />
          )}
        </div>
      )
    });

    const capital_base = data.marketValueStart + data.janToJun + (data.julToDec / 2);

    const actual_tax_base = capital_base * STATIC_VALUES.interest_rate;

    const tax_base = actual_tax_base ? (Math.floor(actual_tax_base / 100) * 100) : 0;

    const actual_tax_rate_before = tax_base * STATIC_VALUES.tax_rate;

    const total_paid_tax_down = actual_tax_rate_before > data.tax ? data.tax : actual_tax_rate_before;

    const tableData = [
      {
        type: __('insurance_value_start'),
        value: getMoney(data.marketValueStart, currency),
      },
      {
        type: __('premiums_start'),
        prefix: '(+)',
        value: getMoney(data.janToJun, currency),
        tooltip: __('premiums_start_tooltip')
      },
      {
        type: __('premiums_half_end'),
        prefix: '(+)',
        value: getMoney(data.julToDec / 2, currency),
        tooltip: __('premiums_half_end_tooltip')
      },
      {
        type: __('capital_base'),
        prefix: '(=)',
        value: getMoney(capital_base, currency),
        tooltip: __('capital_base_tooltip')
      },
      {
        type: __('interest_rate'),
        prefix: '(*)',
        value: formatPercent(STATIC_VALUES.interest_rate * 100),
        tooltip: __('interest_rate_tooltip')
      },
      {
        type: __('tax_base'),
        prefix: '(=)',
        value: getMoney(tax_base, currency),
        tooltip: __('tax_base_tooltip')
      }, // 16
      {
        type: __('tax_rate'),
        prefix: '(*)',
        value: formatPercent(STATIC_VALUES.tax_rate * 100),
      }, // 17
      {
        type: __('actual_tax_rate_before'),
        prefix: '(=)',
        value: getMoney(actual_tax_rate_before, currency),
        tooltip: __('actual_tax_rate_before_tooltip')
      }, // 18
      {
        type: __('total_paid_tax_during_year'),
        value: getMoney(data.tax, currency),
      }, // 19
      {
        type: __('total_paid_tax_down'),
        prefix: '(-)',
        value: getMoney(total_paid_tax_down, currency),
        tooltip: __('total_paid_tax_down_tooltip')
      }, // 20
      {
        type: __('total_tax_for_coming_year'),
        value: getMoney(data.tax - total_paid_tax_down, currency),
        tooltip: __('total_tax_for_coming_year_tooltip')
      },
    ];

    const tableData2 = [
      {
        type: __('table2_header_1', { year }),
        value: getMoney(tax_base, currency),
      },
      {
        type: __('table2_header_2'),
        value: getMoney(total_paid_tax_down, currency)
      },
    ];

    return {
      tableData,
      tableHeaders: headers,
      tableData2,
      tableHeaders2: headers2,
    };

  }, [endowment, transactionsEndowment, isLoading]);

  if (isCompany) {
    return <></>;
  }

  const { tableData, tableHeaders, tableData2, tableHeaders2 } = generateTableData();

  return (
    <>
      <Heading size="4" className="section-padding">
        {__('tax_table_title')}
      </Heading>
      <ReadMore
        visibleContent={
          <div className='maxwidth'>
            {__('tax_table_text')}
          </div>
        }
        hiddenContent={
          <>
            <div className='maxwidth'>
              {__('tax_table_text_1')}
              <Heading size="4" className="section-padding">
                {__('tax_table_title3')}
              </Heading>
            </div>
            <SimpleTable
              headers={tableHeaders}
              data={tableData}
            />
          </>
        }
      >
      </ReadMore>
      <Box size="large" bleed>
        <Heading size="4" className="section-padding">
          {__('tax_table_title2')}
        </Heading>
        <SimpleTable
          className="primary"
          headers={tableHeaders2}
          data={tableData2}
        />

      </Box>
    </>
  );


};

export default YearlyTaxTable;
