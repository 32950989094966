import React from 'react';
import { __ } from "localisation";
import { motion } from "framer-motion";
import RenderForm from "./RenderForm";
import ErrorBoundary from "components/ErrorBoundary";
import ErrorMessage from "components/ErrorMessage";
import { Columns, Column } from "components/Columns";
import Button from "@hubins/components/Button";

const Form = ({
  validKey,
  showButton,
  className,
  preview,
  submitRef,
  disabled,
  buttonStyle,
  buttonProps,
  submitText,
  secondaryButton,
  secondaryButtonStyle,
  secondaryText,
  error,
  submitClarification,
  loading,
  layoutId,
  animate,
  formErrors,
  submitted,
  submitForm,
  formStructure,
  formState,
  handleInputChangeMethod,
  rerenderKey,
  checkValidKey,
}) => {
  return (
    <motion.form
      layoutId={layoutId}
      className={`${className}`}
      onSubmit={submitForm}
    >
      <ErrorBoundary>
        <RenderForm
          data={formStructure}
          optionsState={formState}
          callback={handleInputChangeMethod}
          rerenderKey={rerenderKey}
          key={rerenderKey}
          animate={animate}
        />
      </ErrorBoundary>

      <Columns className={"basic-padding-small"}>
        <Column s="12">
          <div className="button__wrap">
            {(!preview && !validKey) ||
              (validKey && checkValidKey(formState, validKey, showButton))
              ? (
                <>
                  {secondaryButton && (
                    <Button
                      loading={submitted && loading && !error}
                      disabled={submitted && disabled && !error}
                      className={`${secondaryButtonStyle} ${validKey ? "a-fadein" : ""
                      }`}
                      // Second param in submitForm is true to notice that this is the secondarySubmit
                      onClick={(e) => {
                        submitForm(e, true);
                      }}
                    >
                      {secondaryText}
                    </Button>
                  )}
                  <Button
                    innerRef={submitRef}
                    loading={submitted && loading && !error}
                    disabled={submitted && disabled} // TODO: if loading === true, this should trigger the disabled too. But currenlty a lot of forms using loading true as a default value
                    className={`${buttonStyle} ${validKey ? "a-fadein" : ""}`}
                    type="submit"
                    iconColor="white"
                    size="large"
                    {...buttonProps}
                  >
                    {submitText}
                  </Button>
                </>
              )
              : (
                <span className="buttonheightfiller"></span>
              )}
          </div>
          {buttonStyle !== 'hidden' && (
            <ErrorMessage
              text={error ? error : __("error_form")}
              showError={formErrors || error}
            />
          )}
          {submitClarification && (
            <div className="clarification center">{submitClarification}</div>
          )}
        </Column>
      </Columns>
    </motion.form>
  );
};

export default Form;
