import React from 'react';
import __ from 'localisation';
import DownloadLink from 'components/DownloadLink';
import LoadingBar from 'components/LoadingIndicators';
import ExpandableTable from 'components/ExpandableTable';
import Heading from 'components/Heading';
import './index.scss';

const DocumentsList = ({ documents, auth, placeholders, table, title, children, compact, className }) => {

  const generatePlaceHolders = () => {
    let rows = [];
    for (let i = 0; i < placeholders; i++) {
      rows.push({ label: <LoadingBar medium />, href: '#', icon: 'doc' });
    }
    return rows;
  };

  const renderList = () => {
    if (!documents || documents.length === 0) {
      return <p className="clarification">{__('no_documents')}</p>;
    }

    return documents.map((document, index) => {
      return (
        <DownloadLink
          auth={auth}
          secured={auth}
          key={index}
          name={document.fileName}
          path={document.fullPath.substring(1)}
          icon="Common File Stack by Streamlinehq"
        />
      );
    });
  };

  // Table mode
  if (table) {
    return (
      <ExpandableTable
        emptyRowLabel={__('no_documents')}
        title={title}
        rows={
          documents ? (
            documents.map((document) => {
              return {
                label: document.fileName,
                href: document.fullPath.substring(1),
                icon: 'doc',
                download: true,
              };
            })
          ) : generatePlaceHolders()
        }
      >
        {children}
      </ExpandableTable>
    );
  }

  // Default list mode

  const classModifier = documents.length > 3 ? 'documents--has-columns' : '';

  return (
    <div className={compact ? '' : `documents ${className}`}>
      {title &&
        <Heading size="3" className="s-bottom-m">
          {title}
        </Heading>
      }
      {children}
      <div className={`s-top-sm s-bottom-m documents__list button__wrap ${classModifier}`}>
        {documents ? renderList() : <LoadingBar large />}
      </div>
    </div>
  );
};

DocumentsList.defaultProps = {
  placeholders: 4,
  auth: true,
  compact: false,
  className: '',
  title: false,
  table: false,
};

export default DocumentsList;
