import React from 'react';
import CaseSlider from 'modules/CaseSlider';
import CaseColumns from 'modules/CaseColumns';
import CaseTable from 'components/CaseTable';
import CaseImage from 'components/CaseImage';
import CaseStatsTable from 'components/CaseStatsTable';
import CaseText from 'components/CaseText';
import Documents from 'components/Documents';
import CaseVideo from 'components/CaseVideo';
import { Column } from 'components/Columns';

const ColumnWrap = (props) => {
  // const columnObj = props.media ? { ...props.media } : { s: 12 };

  return (
    <Column>
      {props.children}
    </Column>
  );
};

const caseSlider = (index, props) => (
  <ColumnWrap key={'caseSlider_' + index} media={props.media}>
    <CaseSlider  {...props} />
  </ColumnWrap>
);

const textColumn = (index, props) => (
  <CaseColumns key={'textColumn_' + index} {...props} />
);

const terms = (index, props) => (
  <CaseTable key={'terms_' + index} {...props} />
);

const caseImage = (index, props) => (
  <ColumnWrap key={'caseImage_' + index} media={props.media}>
    <CaseImage {...props} />
  </ColumnWrap>
);

const stats = (index, props) => (
  <CaseStatsTable key={'stats_' + index} {...props} />
);

const text = (index, props) => (
  <ColumnWrap key={'text_' + index} media={props.media}>
    <CaseText {...props} />
  </ColumnWrap>
);

const documents = (index, props, securityCode) => (
  <ColumnWrap key={'documents_' + index} media={props.media}>
    <Documents {...props} path={`Securities/${securityCode}`} />
  </ColumnWrap>
);

const caseVideo = (index, props) => (
  <ColumnWrap key={'caseVideo_' + index} media={props.media}>
    <CaseVideo {...props} />
  </ColumnWrap>
);



export default {
  textColumn,
  terms,
  stats,
  ['detail-table']: terms,
  ['stats-table']: stats,
  text,
  documents,
  caseImage,
  caseSlider,
  caseVideo
};
