import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Icon from '@hubins/components/IconNew';
import __ from 'localisation';
import Button from '@hubins/components/Button';
import middleman from 'helpers/middleman';



let urls = new WeakMap();

let blobUrl = blob => {
  if (urls.has(blob)) {
    return urls.get(blob);
  } else {
    let url = URL.createObjectURL(blob);
    urls.set(blob, url);
    return url;
  }
};

class DownloadLink extends Component {

  constructor(props) {
    super(props);
    this.state = {
      downloading: false,
      downloaded: false,
      file: false
    };

    this.onClickDownload = this.onClickDownload.bind(this);
  }

  // Start downloading the raw data when clicking the link
  // e: event
  // name: this.props.name
  onClickDownload(e, path, filename) {
    e.preventDefault();

    this.setState({
      downloading: true
    });

    this.fetch(e, path, filename);
  }

  saveFile(url, filename) {
    var link = document.createElement('a');
    if (typeof link.download === 'string') {
      document.body.appendChild(link); //Firefox requires the link to be in the body
      link.download = filename;
      link.href = url;
      link.click();
      document.body.removeChild(link); //remove the link when done
    } else {
      location.replace(url);
    }
  }

  fetch(e, path, filename) {
    let file = '';
    const authUrl = this.props.auth ? '' : 'unauth/';
    const fileUrl = `/v1/fa/${authUrl}documents/download/${path}`;
    if (!this.state.downloaded) {
      middleman.getBlob(fileUrl).then((response) => {
        // file = new Blob([response.data], { type: 'application/pdf' });
        file = response.data;
        if (typeof file !== "undefined") {

          this.setState({
            downloaded: true,
            downloading: false,
            file: file
          });

          const url = blobUrl(file);
          this.saveFile(url, filename);
        }
      }).catch(err => {
        console.log("Error fetching file", err);
        this.setState({
          downloading: false,
        });
      });
    }
    else {
      this.saveFile(blobUrl(this.state.file), filename);
    }
  }

  render() {
    let { downloaded, downloading } = this.state;

    return (

      <Button
        onClick={(e) => this.onClickDownload(e, this.props.path, this.props.name)}
        // href='#'
        // download={this.props.name}
        className={`${!this.props.small && 'button withicon withicon--left spacing '}`}
      >
        {this.props.name}
        {!downloading && !downloaded &&
          <Icon
            icon="Download Bottom by Streamlinehq"
            size="16"
            // spacing="right"
            label={__('download_file') + ': '}
          />
        }
        {downloading && !downloaded &&
          <Icon
            icon="loading"
            anim="spin"
            size="16"
            // spacing="right"
            label={__('loading')}
          />
        }
        {downloaded &&
          <Icon
            icon={this.props.icon}
            fallback="doc"
            color="primary"
            size="16"
            // spacing="right"
            label={__('download_file') + ': '}
          />
        }
      </Button>

    );
  }
}

DownloadLink.propTypes = {
  name: PropTypes.string,
  path: PropTypes.string, // doc.fullPath.substring(1)
  icon: PropTypes.string,
  secured: PropTypes.bool, // If the Download link should use the security token or not
  small: PropTypes.bool
};

DownloadLink.defaultProps = {
  name: '',
  path: '',
  icon: 'Common File Stack by Streamlinehq',
  secured: false,
};

export default DownloadLink;
