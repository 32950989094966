import React from "react";
import __ from "localisation";
import Button, { ButtonWrap } from "@hubins/components/Button";
import Heading from "components/Heading";
import Modal from "components/Modal";
import Icon from "@hubins/components/IconNew";

const RejectedModal = ({ confirmationModalOpen, setConfirmationModalOpen, callback }) => {

  return (
    <Modal
      className="animation-reveal narrow modal__overlay--centermobile"
      contentClass=""
      noWrapper={false}
      open={confirmationModalOpen === "rejected"}
    >
      <Icon icon="Information Circle by Streamlinehq" size={16} className="s-top-md" />
      <Heading size="3" className="f-mono-weight-light s-top-md">
        {__("tof_add_investment_rejected_modal_title")}
      </Heading>
      <p>{__("tof_add_investment_rejected_modal_text")}</p>
      <ButtonWrap>
        <Button
          className="cta s-top-md"
          onClick={() => {
            setConfirmationModalOpen("");
          }}
        >
          {__("tof_add_investment_rejected_modal_button_edit")}
        </Button>
        <Button
          className="cta s-top-md"
          onClick={() => {
            callback();
            setConfirmationModalOpen("");
          }}
        >
          {__("tof_add_investment_rejected_modal_button_continue")}
        </Button>
      </ButtonWrap>
    </Modal>
  );
};

export default RejectedModal;
