import React from 'react';
import { __ } from 'localisation';
import { useTradeOrders } from 'queries/tradeOrders';
import Heading from 'components/Heading';
import { useReportContext } from '@hubins/components/ReportContext';
import { Columns, Column } from 'components/Columns';
import Wrapper from '@hubins/components/Wrapper';
import LoadingPage from '@hubins/components/LoadingPage';
import { getPayOrders } from 'helpers/tradeOrderHelpers';
import AccountSelector from '@hubins/components/AccountSelector';
import DepositWithdrawal from '@hubins/components/DepositWithdrawal';
import EventCards from 'modules/EventCards';
import { getMoney } from 'helpers';
import middleman from 'helpers/middleman';
import EmptyReport from '../EmptyReport';
import OverviewPremiumTransactions from '@hubins/components/OverviewPremiumTransactions';
import UnfinishedRegistrationsList from '@hubins/components/UnfinishedRegistrationsList';

import './index.scss';

const Overview = () => {

  const {
    ready,
    portfolios,
    setPortfolioUuid,
    portfolioUuid,
    noPortfolios,
    portfolio,
  } = useReportContext();

  // const startDate = (portfolio && portfolio.start_date) ? portfolio.start_date : '2020-01-01';
  // const endDate = new Date().toISOString().substring(0, 10);

  const { data, isFetched, refetch } = useTradeOrders(portfolioUuid);
  // const { data: reportData, isFetched: reportIsFetched } = useReport(startDate, endDate, portfolioUuid);
  // const { data: allReports, isLoading: allReportsLoading, isFetched: allReportsIsFetched } = useAllReports(startDate, endDate, portfolioUuid);

  const deleteTradeOrder = async (orderDetails) => {
    const url = `/v1/fa/tradeorder/delete/${portfolioUuid}`;
    await middleman.promisePost(url, orderDetails);
    refetch();
  };

  if (!ready) return <LoadingPage />;

  const payOrders = (isFetched && data) ? getPayOrders(data) : [];
  // const summariesGivenOk = (allReportsIsFetched && allReports.summaries && allReports.summaries.given);
  // const summariesYearOk = (reportIsFetched && reportData.summaries && reportData.summaries.year);

  if (noPortfolios) {
    return (
      <EmptyReport />
    );
  }

  return (
    <Wrapper>
      <Columns>
        <Column m="12">
          <Heading size="2" className="s-bottom-xl">
            Översikt
          </Heading>
        </Column>
        <Column m="6">
          <Heading size="4" className="s-bottom-m">
            Dina konton
          </Heading>
          <AccountSelector
            portfolios={portfolios}
            setPortfolioUuid={setPortfolioUuid}
            portfolioUuid={portfolioUuid}
          />
        </Column>
        <Column m="6">
          <DepositWithdrawal />
        </Column>
      </Columns>
      <Columns>
        <Column s="12" m="6">

          <Heading size="4" className="s-top-m">
            Totalt värde
          </Heading>
          <Heading size="2" className="s-bottom-xl">
            {getMoney(portfolio?.market_value)}
          </Heading>
          <OverviewPremiumTransactions />
          <Heading size="4" className="s-bottom-m">
            {__('current_events')}
          </Heading>
          <EventCards
            data={data}
            showemptystate
            deleteTradeOrder={deleteTradeOrder}
          />
        </Column>
        <Column s="12" m="6">
          <UnfinishedRegistrationsList />
        </Column>

      </Columns>
    </Wrapper>
  );
};

export default Overview;
