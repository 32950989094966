import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import Button from '@hubins/components/Button';
import HandleInputChange from "modules/CreateForm/HandleInputChange";
import { getInitialFormState } from "helpers/createForm";
import { appendValue } from 'helpers/formscms';
import RenderForm from "modules/CreateForm/RenderForm";
import ErrorMessage from 'components/ErrorMessage';
import Heading from 'components/Heading';
import './index.scss';

// import { Columns, Column } from 'components/Columns';
import { __ } from 'localisation';

const generateRowTitle = (title, index) => {
  return {
    id: `row_title_${index}`,
    type: 'subtitle',
    size: 5,
    visibleConditions: [],
    value: `${title} ${index}`,
    media: '1/1',
  };
};

const FormRepeater = ({
  id,
  callback,
  fields,
  max,
  min,
  buttonText = __('add_row'),
  value,
  locked = false,
  optionsState,
  error_message = __('repeater_error'),
  partId,
  title,
  existingValuesLocked, // Special option for locking all fields if there are existing values when form is rendered
  rowTitle, // Option for adding title to each row followed by index
}) => {

  const [index, setIndex] = useState(0);
  const [form, setForm] = useState([]);
  const [formState, setFormState] = useState({});

  const rerenderKey = useRef(Date.now().toString());
  const initFields = useRef([...fields]);
  const minValues = useRef(existingValuesLocked && value.length >= min ? value.length : min);

  // TODO once formstate is set
  const handleInputChange = useRef(false);

  const breakFieldReflections = (field) => {
    field = Object.assign({}, field);
    if (field.options) {
      field.options = field.options.map((option) => (
        { ...option }
      ));
    }
    return field;
  };

  const setNewFields = useCallback((numberOfFields, values, shouldBeLockedIfValueExist = false) => {
    const newFields = [];
    let newIndex = index;

    const currentLength =
      (rowTitle && form[0]?.fields?.length && initFields?.current?.length)
        ? (form[0]?.fields?.length / (initFields.current.length + 1))
        : 0;

    for (let i = 0; i < numberOfFields; i++) {
      if (rowTitle) {
        const rowIndex = i + 1 + currentLength;
        newFields.push(generateRowTitle(rowTitle, rowIndex));
      }
      const fieldIndex = index + i + 1;
      initFields.current.forEach((field) => {
        field = breakFieldReflections(field);
        if (values && (values[fieldIndex - 1] || values[fieldIndex - 1] === 0)) {
          field = appendValue({ ...field }, values[fieldIndex - 1]);

          if (shouldBeLockedIfValueExist) {
            field.locked = true;
            field.disabled = true;
          }

        }
        const newField = {
          ...field,
          visibleConditions: field.visibleConditions ? field.visibleConditions.map((rules) => {
            return rules.map((rule) => {
              return {
                ...rule,
                key: `${rule.key}_${fieldIndex}`
              };
            });
          }) : [],

          id: `${field.id}_${fieldIndex}`
        };
        newFields.push(newField);
      });
      newIndex = fieldIndex;
    }
    setIndex(newIndex);
    return newFields;
  }, [index]);

  useEffect(() => {
    let newFields = [];
    if (value && value.length > 0) {
      const numberOfFields = value.length;
      newFields = setNewFields(numberOfFields, value, existingValuesLocked);
    } else if (minValues.current > 0) {
      newFields = setNewFields(minValues.current);
    }

    const initForm = [{
      id: 'repeater',
      fields: newFields
    }];

    const initFormState = getInitialFormState(initForm);

    handleInputChange.current = new HandleInputChange(
      initFormState,
      setFormState
    );
    setForm(initForm);
    setFormState(initFormState);

  }, []);

  useEffect(() => {
    handleInputChange.current.setClassFormState(formState);
    callback(id, formState);
  }, [formState]);

  const addRow = useCallback((e) => {
    e.preventDefault();
    const newFields = setNewFields(1);

    let newForm = [...form];
    newForm[0].fields = [
      ...newForm[0].fields,
      ...newFields
    ];

    const newFormState = getInitialFormState([
      {
        id: 'repeater',
        fields: newFields
      }
    ]);

    setFormState({ ...formState, ...newFormState });
    setForm(newForm);
  }, [form, formState]);

  const removeRow = useCallback((e) => {
    e.preventDefault();
    let newForm = [...form];
    let newFormState = formState;
    if (rowTitle) {
      delete newFormState[`row_title_${index}`];
    }
    fields.forEach((field) => {
      delete newFormState[`${field.id}_${index}`];
    });
    const numberOfFieldsToRemove = rowTitle ? fields.length + 1 : fields.length;
    newForm[0].fields = newForm[0].fields.splice(0, (index * numberOfFieldsToRemove) - numberOfFieldsToRemove);

    setIndex(index - 1);
    setForm(newForm);
    setFormState(newFormState);
  }, [form, formState, fields, index]);


  const className = index >= max ? 'disabled withicon withicon--left spacing' : 'withicon withicon--left spacing';
  const removeClassName = index === 0 ? 'disabled withicon withicon--left spacing' : 'withicon withicon--left spacing';
  const disabled = index >= max;
  return (
    <div className='formrepeater'>
      {title && (
        <Heading size="4">{title}</Heading>
      )}
      <div id={partId}>
        {useMemo(() => (
          <RenderForm
            data={form}
            optionsState={formState}
            callback={handleInputChange.current}
            rerenderKey={rerenderKey.current}
            repeater
          />
        ), [formState, form])}
        <ErrorMessage text={error_message} showError={optionsState.error} />
        {!locked && (
          <div className="button__wrap button__wrap--pushtosides">
            <Button
              dataId="repeater"
              className={className}
              disabled={disabled}
              onClick={addRow}
              icon="add"
              iconLeft
            >
              {buttonText}
            </Button>
            <Button
              className={removeClassName}
              disabled={index === 0 || index === minValues.current}
              onClick={removeRow}
              icon="Bin by Streamlinehq"
              iconLeft
            >
              {__('remove_row')}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default FormRepeater;
