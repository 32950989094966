import React from 'react';
import __ from 'localisation';
import Modal from 'components/Modal';
import { Columns, Column } from 'components/Columns';
import Accordeon from 'components/Accordeon';
import DepositAndWithdrawalChart from 'components/Charts/ChartPie/DepositAndWithdrawalChart';
import DepositTable from 'modules/Tables/DepositTable';
import Heading from 'components/Heading';
import { getMoney } from 'helpers';
import ErrorMessage from 'components/ErrorMessage';

import {
  depositChartData,
  getPremiumDropdownTableValues,
} from 'components/DropDownEffect/dropDownCalculator';
import Button, { ButtonWrap } from '@hubins/components/Button';

const calculateAvailableAmountForInvestment = (transaction, portfolio) => {
  if (!transaction || !portfolio) return 0;

  const accountBalance = Number(portfolio.account_balance);
  const amount = Number(transaction.amount) / 100;
  const marketValue = Number(portfolio.market_value);

  return accountBalance + amount - (marketValue + amount) * 0.005;

};

const TransactionResult = ({ transaction, open, portfolio, proceed, close, loading, error }) => {
  const amount = transaction?.amount || 0;
  const tableData = portfolio ? getPremiumDropdownTableValues(portfolio.fees, (amount / 100)) : false;
  const plannedUsage = transaction ? transaction?.formResponses[0]?.response.planned_usage || false : false;
  const feeBuffert = plannedUsage ? plannedUsage.includes('fee_buffert') : false;

  return (
    <Modal open={open}>
      <Columns>
        <Column s="12">
          {!feeBuffert && (
            <>
              <Heading size="2" className="c-primary">
              Tillgängligt för investering
              </Heading>
              <p>
                Vänligen observera att ditt indikativa belopp tillgängligt för
                investeringar, med hänsyn till nuvarande kontobalans,
                premieinsättning samt eventuella avgifter,
                blir: </p>
              <Heading size="2" className="c-primary s-top-sm s-bottom-xl">
                {getMoney(
                  calculateAvailableAmountForInvestment(
                    transaction,
                    portfolio
                  ))}
              </Heading>
            </>
          )}
          <Heading size="4" className="c-primary">
            Avgifter
          </Heading>
          <p>Hur påverkas kontantpremien av ett års försäkringsavgifter?</p>
          <DepositAndWithdrawalChart data={portfolio ? depositChartData(portfolio.fees, 100000) : []} />
          <Heading size="5" className="c-primary">
            Försäkringsavgifter
          </Heading>
          <DepositTable
            data={tableData ? tableData.insurance : []}
          />
          <Heading size="5" className="s-top-m c-primary">
            Skatter
          </Heading>
          <DepositTable
            data={tableData ? tableData.tax : []}
          />
        </Column>
        <Column s="12">
          <Heading className="c-primary" element="h3" size="4">FAQ</Heading>
        </Column>
        <Column m="6" l="6">
          <Accordeon label={__("faq_q1")}>
            <div className="section-padding-small">
              <p>{__("faq_a1")}</p>
            </div>
          </Accordeon>
        </Column>
        <Column m="6" l="6">
          <Accordeon label={__("deposit_disclaimer_title")}>
            <div className="section-padding-small">
              {__("deposit_disclaimer")}
            </div>
          </Accordeon>
        </Column>
        <Column s="12">
          <ButtonWrap>
            <Button
              className='white'
              icon="arrow left 1"
              iconColor="primary"
              onClick={close}
              iconLeft
              loading={loading}
              disabled={loading}
            >
              Ändra belopp
            </Button>
            <Button
              className='secondary'
              icon="arrow right 1"
              iconColor="primary"
              onClick={proceed}
              loading={loading}
              disabled={loading}
            >
              Bekräfta och fortsätt
            </Button>
          </ButtonWrap>
          <ErrorMessage text={error} showError={!!error} />
        </Column>
      </Columns>
    </Modal>
  );
};

export default TransactionResult;
