import React, { useMemo } from 'react';

// Import React Table
import { useTable, useResizeColumns, useFlexLayout } from 'react-table';
import './index.scss';

const MAX_ITEMS_PER_PAGE = 10;
const EMPTY_PAGE_SIZE = 3;


const SimpleTable = (props) => {

  let pageSize = EMPTY_PAGE_SIZE;
  if (props.showPagination && props.data.length >= MAX_ITEMS_PER_PAGE) {
    pageSize = MAX_ITEMS_PER_PAGE;
  } else if (props.data.length < MAX_ITEMS_PER_PAGE) {
    pageSize = props.data.length;
  }

  const columns = useMemo(() => props.headers, [props.headers]);
  const data = useMemo(() => props.data, [props.data]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    footerGroups,
  } = useTable({
    columns,
    data,
  },
  useResizeColumns,
  useFlexLayout);

  return (
    <div className='table__wrapper'>
      <table {...getTableProps} className="table">
        <thead>
          {headerGroups.map((headerGroup, index) => (
            <tr key={`${index}-thead-tr`} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, subIndex) => (
                <th key={`${subIndex}-thead-tr-th`} {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, index) => {
            prepareRow(row);
            return (
              <tr key={`${index}-tbody-tr`} {...row.getRowProps()} className="align-items-center">
                {row.cells.map((cell, subIndex) => {
                  return (
                    <td key={`${subIndex}-tbody-tr-td`} {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
        {(props.footer) && (
          <tfoot>
            {footerGroups.map((footerGroup, index) => (
              <tr key={`${index}-tfoot-tr`} {...footerGroup.getFooterGroupProps()}>
                {footerGroup.headers.map((column, subIndex) => (
                  <td key={`${subIndex}-tfoot-tr-td`} {...column.getFooterProps()}>{column.render('Footer')}</td>
                ))}
              </tr>
            ))}
          </tfoot>
        )}
      </table>
    </div>
  );

};

export default SimpleTable;
