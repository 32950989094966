import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';

const Placeholder = (props) => (
  <div className={`placeholder ${props.className}`}>
    {props.image &&
      <div className="placeholder__image">
        <img src={props.image} alt="Placeholder image" width={props.width} height={props.height} />
      </div>
    }

    <div className="placeholder__content">
      {props.children}
    </div>
  </div>
);

Placeholder.propTypes = {
  image: PropTypes.string
};

export default Placeholder;
