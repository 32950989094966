import React, { useState, useEffect, useRef } from 'react';
import { __route } from 'localisation';
import { Redirect } from "react-router-dom";
import { bypass } from "states/utilities";
import LoginModalQR from 'modules/BankIdModal/LoginModalQR';

import middleman, { csrf } from 'helpers/middleman';

const LoginQR = ({
  // Redux
  logoutUser,
  clearUser,
  // Component
  abortCallback,
  callback,
}) => {



  const [signupRedirect, setSignupRedirect] = useState(false);
  const [reference, setReference] = useState(false);
  const [signStatus, setSignStatus] = useState('visible'); //animateState??
  const [pollFailed, setPollFailed] = useState(false);
  const [qrCode, setQrCode] = useState(false);
  const [autoStartToken, setAutoStartToken] = useState(false);
  const [hasPaused, setHasPaused] = useState(false);
  const [hasScanned, setHasScanned] = useState(false);
  const aborted = useRef(false);
  const paused = useRef(false);
  const abortController = useRef(new AbortController());

  useEffect(() => {
    clearUser();
    const url = new URL(window.location.href);
    const foundReference = url.searchParams.get('reference');
    if (foundReference) {
      setSignStatus('waiting');
      setReference(foundReference);
    }
  }, []);

  useEffect(() => {
    if (pollFailed) {
      if (signStatus !== 'visible') {
        setSignStatus('error');
      }
      setPollFailed(false);
    }
  }, [pollFailed, signStatus]);

  const processCallback = (res) => {
    // If there is a reference, post poll for status
    if (res.authReference) {
      setReference(res.authReference);
    }
    if (res.autoStartToken) {
      setAutoStartToken(res.autoStartToken);
    }
  };

  const fetchData = async () => {
    aborted.current = false;
    const initBody = {
      mobileBankId: false,
      isTest: bypass(),
      process: 'login',
    };
    await csrf();
    middleman
      .promiseKeepAlivePost('/bauth/init', initBody, abortController.current.signal)
      .then(res => processCallback(res))
      .catch(e => {
        setSignStatus('error');
        console.error('INITERROR: ', e.message);
      });
  };

  const poll = () => {
    middleman
      .promiseKeepAlivePost('/bauth/qrcode', {
        authReference: reference,
        platform: "hubins"
      }, abortController.current.signal)
      .then(response => {
        if (response.httpStatus === 200 && !paused.current) {
          setQrCode(response.qrCode);
          setHasScanned(response.state === 'userSign');
          setTimeout(poll, 1000);
          return;
        }
        if (response.httpStatus === 201) {
          if (!response.user) {
            setSignStatus('notFound');
            return;
          }
          setSignStatus('complete');
          if (callback) {
            callback(response);
          }
          return;
        }

        if (response.httpStatus === 410 && !aborted.current) {
          fetchData();
        }

        return;
      }).catch((e) => {
        // if (e.name !== 'AbortError') {
        //   setPollFailed(true);
        //   console.error('POLLERROR: ', e.message);
        // }
        setPollFailed(true);
        console.error('POLLERROR: ', e.message);
      });
  };

  useEffect(() => {
    if (reference) {
      poll();
    }
  }, [reference]);

  const handleAbort = async () => {
    if (abortCallback) {
      abortCallback();
    }
    aborted.current = true;
    abortController.current.abort();
    abortController.current = new AbortController();
    setQrCode(false);
    setReference(false);
    setAutoStartToken(false);
    setSignStatus('visible');
    setHasScanned(false);
    middleman.promisePost('/bauth/abort', { authReference: reference });
  };

  const redirectToSignup = () => {
    logoutUser();
    setSignupRedirect(true);
  };

  const handleSubmit = e => {
    e.preventDefault();
    // Sanitize ssn
    setSignStatus('waiting');
    fetchData();
  };

  const resetBankID = async () => {
    if (reference) {
      try {
        abortController.current.abort();
        abortController.current = new AbortController();
        await middleman.promisePost('/bauth/abort', { authReference: reference });
      } catch (e) {
        console.error(e.message);
      }
    }
    setReference(false);
    setAutoStartToken(false);
    setSignStatus('visible');
    setHasScanned(false);
  };

  const resumePoll = () => {
    paused.current = false;
    setHasPaused(false);
    poll();
  };

  const openOnSameDevice = () => {
    paused.current = true;
    setHasPaused(true);
    // abortController.current.abort();
    const url = `bankid:///?autostarttoken=${autoStartToken}&redirect=${encodeURIComponent(`${window.location.href}/?reference=${reference}`)}`;
    window.open(url, '_blank');
  };

  if (signupRedirect) {
    return (
      <Redirect
        to={{
          pathname: __route('signup'),
          search: "?from=login",
          state: { ssn: '1337' }
        }}
      />
    );
  }

  return (
    <LoginModalQR
      animateState={signStatus}
      handleSubmit={handleSubmit}
      handleAbort={handleAbort}
      redirectToSignup={redirectToSignup}
      reset={resetBankID}
      qrCode={qrCode}
      openOnSameDevice={openOnSameDevice}
      resumePoll={resumePoll}
      paused={hasPaused}
      hasScanned={hasScanned}
      autoStartToken={autoStartToken}
    />
  );
};

export default LoginQR;
