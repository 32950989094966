import React, {useState, useEffect} from 'react';
import { formatDate } from 'helpers';
import './index.scss';

type Props = {
  date: string,
  callback: (date: Date) => void,
}

const DatePicker = (props: Props) => {

  const [date, setDate] = useState(props.date ? new Date(props.date) : new Date());

  useEffect(() => {
    if (props.date !== date) {
      setDate(props.date);
    }
  }, [props.date]);

  const handleDateChange = (e) => {
    let newDate = null;

    if (e instanceof Date) {
      newDate = e;
    } else if (e && e.target && e.target.value) {
      newDate = new Date(e.target.value);
    }

    if (newDate) {
      setDate(newDate);
      if (props.callback) {
        props.callback(newDate);
      }
    }
  }

  return (
    <label className="date-picker">
      <span>{props.label}</span>
      <input className="native" type={'date'} value={formatDate(date)} onChange={handleDateChange} disabled={props.disabled} min={props.min} max={formatDate(new Date())} />
    </label>
  )

};

export default DatePicker;