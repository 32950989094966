const getPercentOfAmount = (percent, amount) => {
  return amount * (percent / 100);
};

const feesToCollection = (fees) => {
  return fees.reduce((collection, fee) => {
    collection[fee.type] = fee.value;
    return collection;
  }, {});
};

export const getPremiumDropdownValues = (fees, amount) => {

  const {
    AMFQL: quantumFeePercent = 0, //35
    AMFQL2: hubinsFeePercent = 0, //36
    AMFIFA: distributionFeePercent = 0, //37
    RISKPRE: riskPremiumPercent = 0, //39
  } = fees;

  if (!amount) amount = 0;

  if (typeof amount === 'string') {
    amount = Number(amount.replace(/\s/g, ''));
  }

  const stampDuty = 0.15;

  const policyFeePercent = quantumFeePercent + hubinsFeePercent + distributionFeePercent; // 34

  const variableChangeAmount = getPercentOfAmount(policyFeePercent, amount); //46

  const qlCompensationAmount = variableChangeAmount * (quantumFeePercent / policyFeePercent); //47

  const hubinsOutsourcedCompensationAmount = variableChangeAmount * (hubinsFeePercent / policyFeePercent); //48
  const hubinsInsuranceDistributionCosts = variableChangeAmount * (distributionFeePercent / policyFeePercent); //49

  const subportfolioRiskPremiumAmount = getPercentOfAmount(riskPremiumPercent, amount); //50

  const subportfolioRiskPremiumQLAmount = subportfolioRiskPremiumAmount; //51

  const stampDutyAmount = getPercentOfAmount(stampDuty, amount);

  //Final table values

  const variableChangeAmountData = -variableChangeAmount; //60
  const variableChangePercentData = variableChangeAmountData / amount; //60

  const qlCompensationAmountData = -qlCompensationAmount; //61
  const qlCompensationPercentData = qlCompensationAmountData / amount; //61

  const hubinsOutsourcedCompensationAmountData = -hubinsOutsourcedCompensationAmount; //62
  const hubinsOutsourcedCompensationPercentData = hubinsOutsourcedCompensationAmountData / amount; //62

  const hubinsInsuranceDistributionCostsAmountData = -hubinsInsuranceDistributionCosts; //63
  const hubinsInsuranceDistributionCostsPercentData = hubinsInsuranceDistributionCostsAmountData / amount; //63

  const subportfolioRiskPremiumAmountData = -subportfolioRiskPremiumAmount; //64
  const subportfolioRiskPremiumpercentData = subportfolioRiskPremiumAmountData / amount; //64

  const subportfolioRiskPremiumQLAmountData = -subportfolioRiskPremiumQLAmount; //65
  const subportfolioRiskPremiumQLPercentData = subportfolioRiskPremiumQLAmountData / amount; //65

  const stampDutyAmountData = -stampDutyAmount; //67
  const stampDutyPercentData = stampDutyAmountData / amount; //67
  const stampDutyAmountReductionData = stampDutyAmount; //68
  const stampDutyPercentReductionData = stampDutyAmountReductionData / amount; //68

  const totalFeeAmount = -(variableChangeAmount + subportfolioRiskPremiumAmount); //57
  const totalFeePercent = totalFeeAmount / amount; //57

  return {
    totalFeeAmount,
    totalFeePercent,
    variableChangeAmountData,
    variableChangePercentData,
    qlCompensationAmountData,
    qlCompensationPercentData,
    hubinsOutsourcedCompensationAmountData,
    hubinsOutsourcedCompensationPercentData,
    hubinsInsuranceDistributionCostsAmountData,
    hubinsInsuranceDistributionCostsPercentData,
    subportfolioRiskPremiumAmountData,
    subportfolioRiskPremiumpercentData,
    subportfolioRiskPremiumQLAmountData,
    subportfolioRiskPremiumQLPercentData,
    stampDutyAmountData,
    stampDutyPercentData,
    stampDutyAmountReductionData,
    stampDutyPercentReductionData,
  };

};

export const getPremiumDropdownTableValues = (fees, amount) => {

  fees = feesToCollection(fees);

  const {
    totalFeeAmount, //57
    totalFeePercent, //57
    variableChangeAmountData, //60
    variableChangePercentData, //60
    qlCompensationAmountData, //61
    qlCompensationPercentData, //61
    hubinsOutsourcedCompensationAmountData, //62
    hubinsOutsourcedCompensationPercentData, //62
    hubinsInsuranceDistributionCostsAmountData, //63
    hubinsInsuranceDistributionCostsPercentData, //63
    subportfolioRiskPremiumAmountData, //64
    subportfolioRiskPremiumpercentData, //64
    subportfolioRiskPremiumQLAmountData, //65
    subportfolioRiskPremiumQLPercentData, //65
    stampDutyAmountData,  //67
    stampDutyPercentData, //67
    stampDutyAmountReductionData, //68
    stampDutyPercentReductionData, //68
  } = getPremiumDropdownValues(fees, amount);



  const tableData = {
    insurance: [
      {
        type: 'Totala försäkringsavgifter för ett år',
        percent: totalFeePercent,
        sek: totalFeeAmount,
      },
      {
        type: 'Löpande avgifter: Rörlig avgift',
        percent: variableChangePercentData,
        sek: variableChangeAmountData,
      },
      {
        type: 'Varav ersättning till Quantum Leben AG',
        percent: qlCompensationPercentData,
        sek: qlCompensationAmountData,
        className: 'row-child'
      },
      {
        type: 'Varav ersättning till Hubins för utkontrakterade tjänster åt Quantums',
        percent: hubinsOutsourcedCompensationPercentData,
        sek: hubinsOutsourcedCompensationAmountData,
        className: 'row-child'
      },
      {
        type: 'Varav ersättning till Hubins för försäkringsdistribution',
        percent: hubinsInsuranceDistributionCostsPercentData,
        sek: hubinsInsuranceDistributionCostsAmountData,
        className: 'row-child'
      },
      {
        type: 'Löpande avgifter: Riskpremie',
        percent: subportfolioRiskPremiumpercentData,
        sek: subportfolioRiskPremiumAmountData,
      },
      {
        type: 'Varav ersättning till Quantum Leben AG',
        percent: subportfolioRiskPremiumQLPercentData,
        sek: subportfolioRiskPremiumQLAmountData,
        className: 'row-child'
      },
    ],
    tax: [
      {
        type: 'Engångsskatt: Stämpelskatt',
        percent: stampDutyPercentData,
        sek: stampDutyAmountData,
      },
      {
        type: 'Möjlig nedsättning av kapitalförsäkringsskatt pga. stämpelskatt',
        percent: stampDutyPercentReductionData,
        sek: stampDutyAmountReductionData,
      },
    ]
  };

  return tableData;

};
export const getWithdrawalDropdownTableValues = (fees, amount) => {

  fees = feesToCollection(fees);

  const {
    totalFeeAmount, //57
    totalFeePercent, //57
    variableChangeAmountData, //60
    variableChangePercentData, //60
    qlCompensationAmountData, //61
    qlCompensationPercentData, //61
    hubinsOutsourcedCompensationAmountData, //62
    hubinsOutsourcedCompensationPercentData, //62
    hubinsInsuranceDistributionCostsAmountData, //63
    hubinsInsuranceDistributionCostsPercentData, //63
    subportfolioRiskPremiumAmountData, //64
    subportfolioRiskPremiumpercentData, //64
    subportfolioRiskPremiumQLAmountData, //65
    subportfolioRiskPremiumQLPercentData, //65
    stampDutyAmountData,  //67
    stampDutyPercentData, //67
    stampDutyAmountReductionData, //68
    stampDutyPercentReductionData, //68
  } = getPremiumDropdownValues(fees, amount);

  const tableData = {
    insurance: [
      {
        type: 'Totala försäkringsavgifter för ett år',
        percent: totalFeePercent,
        sek: totalFeeAmount,
        className: 'test123'
      },
      {
        type: 'Löpande avgifter: Rörlig avgift',
        percent: variableChangePercentData,
        sek: variableChangeAmountData,
      },
      {
        type: 'Varav ersättning till Quantum Leben AG',
        percent: qlCompensationPercentData,
        sek: qlCompensationAmountData,
        className: 'row-child'
      },
      {
        type: 'Varav ersättning till Hubins för utkontrakterade tjänster åt Quantums',
        percent: hubinsOutsourcedCompensationPercentData,
        sek: hubinsOutsourcedCompensationAmountData,
        className: 'row-child'
      },
      {
        type: 'Varav ersättning till Hubins för försäkringsdistribution',
        percent: hubinsInsuranceDistributionCostsPercentData,
        sek: hubinsInsuranceDistributionCostsAmountData,
        className: 'row-child'
      },
      {
        type: 'Löpande avgifter: Riskpremie',
        percent: subportfolioRiskPremiumpercentData,
        sek: subportfolioRiskPremiumAmountData,
      },
      {
        type: 'Varav ersättning till Quantum Leben AG',
        percent: subportfolioRiskPremiumQLPercentData,
        sek: subportfolioRiskPremiumQLAmountData,
        className: 'row-child'
      },
    ],
    tax: [
      {
        type: 'Totala uttagsavgifter',
        percent: 0,
        sek: 0,
      },
      {
        type: 'Uttagsavgifter',
        percent: 0,
        sek: 0,
      },
      {
        type: 'Engångsskatt: Stämpelskatt',
        percent: stampDutyPercentData,
        sek: stampDutyAmountData,
      },
      {
        type: 'Möjlig nedsättning av kapitalförsäkringsskatt pga. stämpelskatt',
        percent: stampDutyPercentReductionData,
        sek: stampDutyAmountReductionData,
      },
    ],
    total: [
      {
        type: '',
        percent: 1,
        sek: typeof amount === 'string' ? Number(amount.replace(/\s/g, '')) : amount,
      }
    ]
  };

  return tableData;

};

export const depositChartData = (fees, amount) => {
  fees = feesToCollection(fees);
  const {
    totalFeePercent,
  } = getPremiumDropdownValues(fees, amount);
  const positiveTotalFeePercent = Math.abs(totalFeePercent);
  return [
    {
      name: 'Återstående kontantpremie',
      value: (1 - positiveTotalFeePercent) * 100,
    },
    {
      name: 'Reducering av kontantpremie',
      value: positiveTotalFeePercent * 100,
    },
  ];

};

export const withdrawalChartData = () => {
  return [
    {
      name: 'Återstående utbetalning',
      value: 100,
    },
    {
      name: 'Reducering av utbetalning',
      value: 0,
    }
  ];
};
