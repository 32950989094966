import React from 'react';

// Import React Table
import { formatMoney, formatNumber } from 'helpers';
import LoadingBar from 'components/LoadingIndicators';
import './index.scss';
import __ from 'localisation';
import SimpleTable from './SimpleTable';


const headers = (currency) => [
  {
    Header: __('date'),
    accessor: 'transactionDate',
    minWidth: 120
  },
  {
    Header: __('settlement_date'),
    accessor: 'settlementDate',
    minWidth: 120
  },
  {
    Header: __('Security'),
    accessor: 'security',
    minWidth: 370
  },
  {
    Header: __('ISIN'),
    accessor: 'isinCode',
    className: 'align-right'

  },
  {
    Header: __('Type'),
    accessor: 'type',
    Cell: ({ row }) => (
      __(row.original.type)
    )
  },
  {
    Header: __('Amount'),
    accessor: 'amount',
    className: 'align-right',
    Cell: ({ row }) => (formatNumber(row.original.amount, 0))
  },
  {
    Header: __('unit_price'),
    accessor: 'unitPrice',
    className: 'align-right',
    Cell: ({ row }) => (
      formatMoney(row.original.unitPrice, row.original.currency)
    )
  },
  {
    Header: __('cost_fee'),
    accessor: 'totalCost',
    className: 'align-right',
    Cell: ({ row }) => (formatMoney(row.original.totalCost, row.original.currency))
  },
  {
    Header: __('trade_amount_new'),
    accessor: 'tradeAmountInPortfolioCurrency',
    className: 'align-right',
    Cell: ({ row }) => (formatMoney(row.original.tradeAmountInPortfolioCurrency, currency))
  },
];

const TransactionsTable = ({ data, isLoading, currency }) => {

  if (isLoading) {
    const loadingObject = {
      original: { amount: <LoadingBar /> },
      transactionDate: <LoadingBar />,
      settlementDate: <LoadingBar />,
      type: <LoadingBar />,
      security: <LoadingBar />,
      amount: <LoadingBar />,
      unitPrice: <LoadingBar />,
      tradeAmount: <LoadingBar />,
      totalCost: <LoadingBar />,
      isinCode: <LoadingBar />,
    };
    data = [
      loadingObject,
      loadingObject,
      loadingObject,
      loadingObject,
      loadingObject,
    ];
  }

  return (
    <SimpleTable
      headers={headers(currency)}
      data={data}
    />
  );
};

TransactionsTable.defaultProps = {
  defaultPageSize: 4,
  showPagination: false,
};

export default TransactionsTable;
