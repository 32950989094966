import React, { useState } from 'react';
import Wrapper from '@hubins/components/Wrapper';
import { Columns, Column } from 'components/Columns';
import AccountSelector from '@hubins/components/AccountSelector';
import { useReportContext } from '@hubins/components/ReportContext';
import { useTransactions } from '../../../../packages/queries/report';
import TransactionDatePicker from 'modules/TransactionDatePicker';
import LoadingPage from '@hubins/components/LoadingPage';
import Heading from 'components/Heading';
import TransactionsTable from 'modules/Tables/TransactionsTable';
import NoPortfolios from '../NoPortfolios';

import {
  filterType,
  filterSecurity
} from 'helpers';

const Transactions = () => {

  const {
    ready,
    portfolios,
    portfolio,
    setPortfolioUuid,
    portfolioUuid,
    startDate,
    endDate,
    datePickerCallback,
    noPortfolios,
  } = useReportContext();

  const [arg1, setArg1] = useState('*');
  const [arg2, setArg2] = useState('*');

  const { data, isLoading, isFetched } = useTransactions(startDate, endDate, portfolioUuid);

  const handleChange = (from, to, argOne, argTwo) => {
    setArg1(argOne);
    setArg2(argTwo);
    datePickerCallback(from, to);
  };

  if (!ready) return <LoadingPage />;

  const filterData = (dataToFilter) => {
    if (arg1 === '*' && arg2 === '*') {
      return dataToFilter;
    } if (arg1 !== '*') {
      dataToFilter = filterType(dataToFilter, arg1);
    } if (arg2 !== '*') {
      dataToFilter = filterSecurity(dataToFilter, arg2);
    }
    return dataToFilter;
  };

  const result = isFetched ? filterData(data.transactions) : [];

  if (noPortfolios) {
    return (
      <NoPortfolios />
    );
  }

  return (
    <Wrapper>
      <Columns>
        <Column s="12">
          <Heading size="2" className="s-bottom-xl">
            Transaktioner
          </Heading>
        </Column>
        <Column m="6">
          <Heading size="4" className="s-bottom-m">Dina konton</Heading>
          <AccountSelector
            portfolios={portfolios}
            setPortfolioUuid={setPortfolioUuid}
            portfolioUuid={portfolioUuid}
          />
        </Column>
        <Column m="6">
          <TransactionDatePicker
            from={startDate}
            to={endDate}
            data={isFetched ? data.transactions : []}
            onClick={handleChange}
            isLoading={isLoading}
          />
        </Column>
        <Column s="12" className="s-top-xl">
          <TransactionsTable
            data={isFetched ? result : []}
            isLoading={isLoading}
            currency={portfolio.currency}
          />
        </Column>
      </Columns>
    </Wrapper>
  );

};

export default Transactions;
