/* eslint-disable react/forbid-elements */
// SWEDISH localisation file for default keys

import React from "react";

export const keys = (v = {}) => ({
  // Customized strings for different markets - these will override the default STRINGS
  MARKET_STRINGS: {
    // Sweden
    SE: {},
  },

  // Default strings
  STRINGS: {
    app_name: "Hubins",
    app_title: "Hubins - En kapitalförsäkring för alla investeringar",
    platform_email: "kundservice@hubins.com",
    platform_tel: "031-797 19 36",

    legal_title: "Legalt",
    cookies_title: "Cookies",
    back_button: "Gå tillbaka",
    privacy_title: "Personuppgifter",

    // Routes
    demand_assesment: "Krav- och Behovsprövning",

    // Login
    welcome_text: "Välkommen till Hubins",
    kyc: "Kundkännedom",

    // Withdrawal
    demand_assesment_checkbox:
      "Jag bekräftar att svaren som angavs i krav och behovsprövningen, samt alla övriga uppgifter som har lämnat till Hubins och försäkringsbolaget, fortfarande är gällande.",

    // Market order
    marketorder_terms:
      "Jag tillåter och begär Quantum Leben AG (”Bolaget”) att ingå de formella avtal som krävs för att kunna utföra ovanstående instruktion. Jag är överens om att Bolaget inte ska hållas ansvarig för eventuella förluster som härrör från den instruktion utförd av Bolaget. Bolaget skall inte heller hållas ansvariga för brist på agerande från kapitalförvaltarens sida som ger upphov till förlust av värde oavsett hur dessa uppstått (detta inkluderar utan begränsning misslyckande från förvaltaren att skapa en rimlig avkastning i förhållande till risken). Dessutom ska jag ersätta Bolaget mot alla anspråk mot Bolaget i fråga om förlust som tidigare nämnts samt alla kostnader och utgifter som uppstår på grund av kapitalförvaltarens agerande (detta inkluderar utan begränsning kostnader för att försvara Bolaget i någon domstol).",
    marketorder_terms2: (
      <>
        Genom att kryssa i rutan bekräftas härmed att jag har erhållit faktablad
        med nyckelinformation om min Quantum Leben-försäkring samt specifika
        faktablad för de investeringar jag avser att genomföra. Härmed bekräftas
        även att jag är informerad om att de aktuella faktablad för försäkringen
        eller investeringarna finns tillgängligt via min inloggning på{" "}
        <a
          href="https://www.hubins.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          www.hubins.com
        </a>
      </>
    ),
    marketorder_terms2_pdf:
      "Genom att kryssa i rutan bekräftar jag att jag har erhållit faktablad med nyckelinformation om min Quantum Leben-försäkring, samt specifika faktablad för de investeringar jag avser att genomföra. Härmed bekräftar jag att jag är informerad om att aktuella faktablad för försäkringen och investeringarna finns tillgängligt via min inloggning på www.hubins.com",

    buy_pdf_title_primary: "Köporder primärmarknaden",
    buy_pdf_title_secondary: "Köporder andrahandsmarknaden",
    sell_pdf_title: "Säljorder",
    emission_value: 'Emissionsvärde',

    // Invest
    invest_pre_text:
      "Innehållet på denna sida är en del av Quantums investeringsutbud. Aktuella investeringar består av pågående emissioner och erbjudanden från olika värdepappersbolag som Quantums samarbetar med, men där Quantum ej är arrangör, finansiell rådgivare eller dylikt.",
    disclaimer: "Disclaimer",
    disclaimer_title_1: 'Ditt/Ert ansvar',
    disclaimer_text_1: "Det är ditt/ert ansvar som investerare att bedöma hur bra en investering är samt kontrollera att ni har förstått bland annat tillgångsslagets egenskaper, eventuellt bolages affärsmodell samt tillhörande risker. Ni uppmanas att noggrant granska tillgängliggjord information om investeringen. Ni är medveten om att det inte finns några som helst garantier för investeringen och hela det investerade beloppet kan gå förlorat.",
    disclaimer_title_2: 'Hubins ansvar',
    disclaimer_subtitle_2: 'Som försäkringsdistributör',
    disclaimer_text_2: "Hubins kontrollerar om ni har behov av kapitalförsäkringen genom en behovsprövning. Detta innefattar bland annat att ni bör ha en högre förväntad nettoavkastning när ni använder en kapitalförsäkring i jämförelse med en direktinvestering.",
    disclaimer_subtitle2_2: 'Som plattform',
    disclaimer_text2_2: 'Hubins gör ingen bedömning av investeringen och har ingen formell roll i investeringen. Plattformen har utvecklats som en elektronisk anslagstavla och är ett tekniskt verktyg för att tillgängliggöra normalt svåråtkomliga investeringstyper. Detta kan exempelvis vara investeringar i onoterade aktier, VC-fonder, hedgefonder och P2P-lån där det vanligtvis krävs stora belopp för att investera.',
    disclaimer_text3_2: 'Hubins ska inte hållas ansvarig för att informationen är korrekt eller fullständig. Hubins friskriver sig ansvaret för eventuella förluster eller kostnader som följer av att uppgifter som har lämnats på plattformen är missvisande, felaktiga eller ofullständiga.',
    disclaimer_title_3: 'Försäkringsbolagets ansvar',
    disclaimer_text_3: 'Quantum Leben är utgivare av kapitalförsäkringen. När man genomför en investering via investeringssidan så genomförs investeringen utan en investeringsrådgivare, d.v.s. uteslutande på ditt/ert eget initiativ. Önskar ni rådgivning rekommenderas det att ni kontaktar en finansiell rådgivare. Det är Quantum Lebens ansvar att genomföra en passandebedömning av din kunskap om, och erfarenhet av, investeringstypen. Quantum agerar inte rådgivande eller bedömer om ni har en finansiell situation som tillåter en eventuell förlust.',
    disclaimer_title_4: 'Investeringsansvarig',
    disclaimer_text_4: 'Den part som är investeringsansvarig ansvarar för att all information i investeringsmaterialet är korrekt och att utfästelser efterlevs. Kom ihåg att bolaget som ni investerar i kan gå i konkurs vilket innebär att er investering riskerar att gå förlorad.',
    disclaimer_text2_4: 'Med investeringsansvarig menas exempelvis ett aktiebolag som man kan köpa aktier i, via en nyemission eller andrahandsaffär, eller låna ut pengar till genom en obligation eller ett direktlån. Det är generellt sett den investeringsansvarige som tar emot pengarna ni investerar i direkt eller via kapitalförsäkring, men för ytterligare information kan ni läsa mer i investeringsmaterialet.',
    disclaimer_title_5: 'Extern investeringsrådgivare',
    disclaimer_text_5: 'En eventuell extern investeringsrådgivare är ansvarig för att kontrollera att investeringen är lämplig för er. Den som inte anses ha erfarenhet av investeringstyper bör alltid kontakta och redogöra med en finansiell rådgivare, legal rådgivare eller advokat, revisor eller liknande innan investeringen genomförs.',
    disclaimer_title_6: 'Primärmarknadsansvarig/investeringsansvarig',
    disclaimer_text_6: 'Med primärmarknadsansvarig/investeringsansvarig menas exempelvis ett aktiebolag som man kan köpa aktier i, via en nyemission eller andrahandsaffär, eller låna ut pengar till genom en obligation eller ett direktlån. Det är generellt sett den primärmarknads-/investeringsansvarige som tar emot pengarna du investerar i direkt eller via kapitalförsäkring, men för ytterligare information kan du läsa mer i investeringsmaterialet för den specifika investeringen.',
    "disclaimer_text_6.1": "Den part som är primärmarknads-/investeringsansvarig ansvarar för att all information i investeringsmaterialet är korrekt och att utfästelser efterlevs. Kom ihåg att bolaget som du investerar i kan gå i konkurs vilket innebär att din/er investering riskerar att gå förlorad. Investeringar på andrahandsmarknaden saknar investeringsansvarig.",
    disclaimer_title_7: 'Sekundärmarknadsansvarig/andrahandsmarknadsansvarig',
    disclaimer_text_7: 'När en investering genomförs på en sekundärmarknad/andrahandsmarknad, såsom en börs eller marknadsplats, finns ingen investeringsansvarig. Detta beror på att handel i den underliggande investeringen sker oberoende av det underliggande bolaget eller exempelvis kryptotillgången och de mottar generellt inte heller någon ersättning för handel som sker. Handel där flera parter kan handla direkt med varandra, via exempelvis en börs, eller liknande reglerad eller oreglerad marknadsplats, sker generellt sett utan den primärmarknads-/investeringsansvariges vetskap och inblandning. Således saknas någon som ansvarar för att investeringsmaterialet på sekundär-/andrahandsmarknaden är korrekt och/eller uppdaterat. Handel  sker helt på egen risk och allt ansvar ligger på er som investerare.',
    disclaimer_title_8: 'Tjänsteleverantörens ansvar',
    disclaimer_text_8: 'Tjänsteleverantören ansvarar för att tjänster som erbjuds till försäkringsbolaget genomförs i enlighet med gällande villkor och avtal. Detta kan exempelvis syfta till att administrera köp och sälj av tillgångar på relevant marknadsplats. Tjänsteleverantörer erbjuder ibland även tjänster relaterade till förvaring av tillgångar. För mer detaljerad beskrivning ombeds du/ni läsa mer om den specifika investeringen.',
    fileupload_error: "Vänligen ladda upp en eller flera filer",

    roaring_error_message:
      "Något gick fel. Försök igen eller kontakta Hubins kundservice om problemet kvarstår",

    sale_of_companies_modal: (
      <>
        Du har angett vinst från försäljning av bolag till en summa av{" "}
        <strong>{v.total}</strong>. Dina svar kring försäljning av bolag uppgår
        just nu till <strong>{v.sum}</strong>.{" "}
      </>
    ),
    sale_of_properties_modal: (
      <>
        Du har angett vinst från försäljning av fastigheter till en summa av{" "}
        <strong>{v.total}</strong>. Dina svar kring försäljning av fastigheter
        uppgår just nu till <strong>{v.sum}</strong>.{" "}
      </>
    ),
    shares_modal: (
      <>
        Du har angett insamlat kapital från emissioner till en summa av{" "}
        <strong>{v.total}</strong>. Dina svar kring insamlat kapital från
        emissioner uppgår just nu till <strong>{v.sum}</strong>.{" "}
      </>
    ),
    bonds_modal: (
      <>
        Du har angett insamlat kapital från obligationsemissioner till en summa
        av <strong>{v.total}</strong>. Dina svar kring insamlat kapital från
        obligationsemissioner uppgår just nu till <strong>{v.sum}</strong>.{" "}
      </>
    ),

    sof_warning_modal:
      " Vänligen ändra dina svar så att summan uppgår till angiven vinst. Om du uppdaterar dina tidigare svar kommer detta steg behöva göras om på nytt.",
    modal_warning_past: (
      <>
        Du har tidigare angett att företagets totala vinst under de senaste fem
        åren uppgår till en summa av <strong>{v.total}</strong>, men du har
        endast deklarerat värde till en summa av <strong>{v.sum}</strong>.{" "}
      </>
    ),
    modal_warning_before: (
      <>
        Du har tidigare angett att företagets totala vinst från en period
        tidigare än fem år sedan uppgår till en summa av{" "}
        <strong>{v.total}</strong>, men du har endast deklarerat värde till en
        summa av <strong>{v.sum}</strong>.{" "}
      </>
    ),

    create_account: "Skapa konto",
    private_account: "Privatkonto",
    private_account_info:
      "Att skapa ett konto tar bara några minuter – och är helt utan förbindelser. Du kan därefter logga in, läsa mer om kapitalförsäkringen samt skicka in en försäkringsansökan.",
    corporate_account: "Bolagskund",
    corporate_account_info:
      "Fyll i organisationsnummer för det bolag du önskar öppna kapitalförsäkring åt",

    restart: "Börja om",

    exceeded_desc:
      "Med denna insättningen kommer du överskrida ditt deklarerade kapital. Vänligen gå igenom källa till kapital.",
    go_to_sof: "Gå till deklarationen",
    do_later: "Gör detta senare",
    corporate_identity_number: "Organisationsnummer",
    fetch_corporate_data: "Hämta uppgifter om företaget",
    company_name: "Bolagsnamn",
    comp_reg_control:
      "Vi kommer nu börja lagra data om detta företag kopplat till ditt personnummer. Är detta korrekt företag?",
    comp_reg_control_info: (
      <>
        <p>
          Du kommer i nästa steg genomföra en behovsanalys för bolaget för att
          Hubins ska säkerställa att bolaget har ett behov av en
          kapitalförsäkring.
        </p>
        <p>
          Efter detta kommer Hubins att hämta hem information om bolaget,
          bolagets firmatecknare samt verkliga huvudmän. Bolagets firmatecknare
          och huvudmän kommer sedan ombes att logga in på och genomföra sin del
          av registreringen, där de svarar på frågor kring sin skattemässiga
          hemvist.
        </p>
      </>
    ),
    sof_needed_upload:
      "Du behöver först deklarera vilken källa pengarna kommer ifrån",
    sof_done: (
      <>
        Du har skickat in underlag som Hubins behöver gå igenom. När detta är
        klart kommer vi att kontakta dig och ge dig inbetalningsuppgifterna. Har
        du några frågor kan du kontakta oss på{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="mailto:kundservice@hubins.com"
        >
          kundservice@hubins.com
        </a>{" "}
        eller via telefon på{" "}
        <a target="_blank" rel="noopener noreferrer" href="tel:0317971936">
          031-797 19 36
        </a>
      </>
    ),
    declare_now: "Deklarera nu",

    too_many_premiums: (
      <>
        <h3 className="smallheading">
          Du har redan en pågående premieinsättning igång.
        </h3>
        <p>
          Vänligen slutför eller ta bort din pågående insättningen. Det är
          endast tillåtet att ha en insättning igång.
        </p>
        <p>
          Du kan see dina pågående premieinsättningar i din översikt på
          startsidan.
        </p>
      </>
    ),
    to_overview: "Till översikt",
    abort_premium: "Avbryt insättning",
    sof_checkbox: "Jag bekräftar att källan till kapitalet är oförändrat.",
    sof_and_da_changed: (
      <p className="body-small s-top-l">
        Om uppgifter har ändrats från det som angetts i krav och
        behovsprövningen eller övriga uppgifter som har lämnat till Hubins och
        försäkringsbolaget, vänligen kontakta{" "}
        <a
          href="mailto:kundservice@hubins.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          kundservice@hubins.com
        </a>
      </p>
    ),
    sof: "Källa till kapital",
    yield_tax: "Avgifter",
    "block sale": "BLOCK SALE",

    deposit_disclaimer_title:
      "Hur påverkas kontantpremien av ett års försäkringsavgifter?",
    deposit_disclaimer: (
      <>
        <p>
          Ovan pajdiagram har för avsikt att illustrera hur avgifter reducerar
          din premieinsättning. Tabellen nedanför visar vilka typer av avgifter
          denna insättning medför samt hur stor den återstående premien kommer
          vara efter ett år av löpande avgifter.
        </p>
        <p>
          Vänligen notera att beräkningarna som resulterade i tabellen och
          pajdiagrammet är baserade på ett antal antaganden samt begränsningar.
          Läs om dessa antaganden och begränsningar nedan. Hubins reserverar sig
          även för eventuella felräkningar.
        </p>
        <p>
          Din kapitalförsäkring hos Hubins löper 1 år i taget från
          utfärdande-datum, det vill säga en dag efter att första premien har
          inbetalats och godkänts. Om försäkringen avslutas i förtid behöver
          samtliga av årets resterande avgifter betalas. Den rörliga avgiften
          baseras på värdet i försäkringen vid avslutstillfället. I övrigt
          tillkommer inga avslutsavgifter. Kostnaderna kommer att dras från ditt
          kontantkonto i försäkringen.
        </p>
        <p>
          Här ser du ett exempel på hur mycket din premieinsättning kommer att
          påverkas av den rörliga avgiften samt riskpremien i försäkringen.
          Observera att i beräkningarna antas det att det inte tillkommer några
          transaktionsavgifter, fasta avgifter eller specifika produktavgifter
          förrän att premien faktiskt investeras i en viss investeringsprodukt.
          Försäkringens värde antas heller inte förändras förrän några
          investeringar genomförs.
        </p>
        <p>
          <strong>Försäkringsavgifter</strong>
        </p>
        <p>
          Löpande avgifter: Den rörliga avgiften syftar till den rörliga
          ersättningen som baseras på försäkringsvärdet vid slutet av varje
          månad och dras kvartalsvis. Hubins AB erhåller i sin roll som
          försäkringsdistributör 30% av den rörliga ersättningen som betalas
          till Quantum Leben AG. Hubins AB erhåller även ytterligare 35% av den
          rörliga ersättningen som betalas till Quantum Leben AG för outsourcing
          av Quantums tjänster som inkluderar men är inte begränsad till
          kundtjänstärenden, support avseende försäkringsadministration,
          förberedelse för hantering av försäkringsfall, IT-support etc.
        </p>
        <p>
          För kunder som är 65 år eller äldre tillkommer även en riskpremie
          enligt dokumentet “Kostnader och Avgifter - Hubins och Quantum Leben”
          för att täcka kapitalförsäkringens återbetalningsskydd. Vid
          inträffande försäkringsfall kommer denna avgift att finansiera den
          extra procenten utöver försäkringsvärdet som betalas ut vid dödsfall.
          I uträkningarna baseras riskpremien på den livförsäkrades ålder och i
          fallet där flera livförsäkrade finnas tillkommer riskpremien när den
          yngsta av dessa är 65 år eller äldre.
        </p>
        <p>
          <strong>Stämpelskatt</strong>
        </p>
        <p>
          Vänligen notera att det tillkommer en stämpelskatt om 0,15% på likvida
          medel och 0,3% på investeringar som inbetalas och utbetalas ur
          försäkringen. Det går dock att få nedsättning med motsvarande
          skattesumma mot kapitalförsäkringens svenska skatt. Detta ökar helt
          enkelt inte skatten som behöver betalas utan den blir i slutändan lika
          hög som för en svensk kapitalförsäkring. Mer information finns i
          länkarna till Skatteverket nedan.
        </p>
        <p>
          <a
            href="https://www4.skatteverket.se/rattsligvagledning/edition/2021.18/339082.html"
            target="_blank"
            rel="noreferrer"
          >
            Nedsättning på grund av utländsk skatt
          </a>
        </p>
        <p>
          <a
            href="https://skatteverket.se/privat/skatter/forsakringar/avkastningsskattpautlandskalivforsakringar/nedsattavkastningsskatt.4.1a098b721295c544e1f800027816.html"
            target="_blank"
            rel="noreferrer"
          >
            Nedsatt avkastningsskatt
          </a>
        </p>
        <p>
          Vänligen notera att du behöver själv ansöka om nedsättning på grund av
          utländsk stämpelskatt.
        </p>
        <p>
          Vänligen notera att dessa beräkningar kan stämma med verkligheten
          endast om alla förutsättningar och antaganden ovan uppfylls.
          Pajdiagramet och tabellen ovan är till för att hjälpa dig sätta de
          rörliga försäkringsavgifterna i relation till din premieinsättning.
        </p>
      </>
    ),
    withdrawal_disclaimer_title:
      "Hur påverkas utbetalningen av uttagsavgifter?",
    withdrawal_disclaimer: (
      <>
        <p>
          Ovan pajdiagram har för avsikt att illustrera att det inte kostar
          något att göra ett uttag och att stämpelskatten utgör ingen extra
          kostnad då kapitalförsäkringsskatten kan nedsättas med motsvarande
          belopp. Tabellen “Avgifter och ersättningar” visar vilka typer av
          avgifter som betalas på de kontanter som hålls i försäkringen om inget
          uttag genomförs och tabellen “Skatt” visar det belopp som
          kapitalförsäkringsskatten kan nedsättas med efter ett eventuellt
          uttag.{" "}
        </p>
        <p>
          Vänligen notera att beräkningarna som resulterade i tabellen och
          pajdiagrammet är baserade på ett antal antaganden samt begränsningar.
          Läs om antaganden och begränsningar nedan. Hubins reserverar sig även
          för eventuella felräkningar.
        </p>
        <p>
          Din kapitalförsäkring hos Hubins löper 1 år i taget från
          utfärdande-datum, det vill säga en dag efter att första premien har
          inbetalats och godkänts. Om försäkringen avslutas i förtid behöver
          samtliga årets resterande avgifter betalas. Den rörliga avgiften
          baseras på värdet i försäkringen vid avslutstillfället. I övrigt
          tillkommer inga avslutsavgifter. Kostnaderna kommer att dras från ditt
          kontantkonto i försäkringen.
        </p>
        <p>
          <strong>Försäkringsavgifter</strong>
        </p>
        <p>
          Löpande avgifter: Den rörliga avgiften syftar till den rörliga
          ersättningen som baseras på försäkringsvärdet vid slutet av varje
          månad och dras kvartalsvis. Hubins AB erhåller i sin roll som
          försäkringsdistributör 30% av den rörliga ersättningen som betalas
          till Quantum Leben AG. Hubins AB erhåller även ytterligare 35% av den
          rörliga ersättningen som betalas till Quantum Leben AG för outsourcing
          av Quantums tjänster som inkluderar men är inte begränsad till
          kundtjänstärenden, support avseende försäkringsadministration,
          förberedelse för hantering av försäkringsfall, IT-support etc.
        </p>
        <p>
          För kunder som är 65 år eller äldre tillkommer även en riskpremie
          enligt dokumentet “Kostnader och Avgifter - Hubins och Quantum Leben”
          för att täcka kapitalförsäkringens återbetalningsskydd. Vid
          inträffande försäkringsfall kommer denna avgift att finansiera den
          extra procenten utöver försäkringsvärdet som betalas ut vid dödsfall.
          I uträkningarna baseras riskpremien på den livförsäkrades ålder och i
          fallet där flera livförsäkrade finnas tillkommer riskpremien när den
          yngsta av dessa är 65 år eller äldre.
        </p>
        <p>
          <strong>Stämpelskatt</strong>
        </p>
        <p>
          Vänligen notera att det tillkommer en stämpelskatt om 0,15% på likvida
          medel och 0,3% på investeringar som inbetalas och utbetalas ur
          försäkringen. Det går dock att få nedsättning med motsvarande
          skattesumma mot kapitalförsäkringens svenska skatt. Detta ökar helt
          enkelt inte skatten som behöver betalas utan den blir i slutändan lika
          hög som för en svensk kapitalförsäkring. Mer information finns i
          länkarna till Skatteverket nedan.
        </p>
        <p>
          <a
            href="https://www4.skatteverket.se/rattsligvagledning/edition/2021.18/339082.html"
            target="_blank"
            rel="noreferrer"
          >
            Nedsättning på grund av utländsk skatt
          </a>
        </p>
        <p>
          <a
            href="https://skatteverket.se/privat/skatter/forsakringar/avkastningsskattpautlandskalivforsakringar/nedsattavkastningsskatt.4.1a098b721295c544e1f800027816.html"
            target="_blank"
            rel="noreferrer"
          >
            Nedsatt avkastningsskatt
          </a>
        </p>
        <p>
          Vänligen notera att du behöver själv ansöka om nedsättning på grund av
          utländsk stämpelskatt.
        </p>
        <p>
          Vänligen notera att dessa beräkningar kan stämma med verkligheten
          endast om alla förutsättningar och antaganden ovan uppfylls.
          Pajdiagramet och tabellen ovan är till för att hjälpa dig sätta de
          rörliga försäkringsavgifterna i relation till ditt uttag.
        </p>
      </>
    ),

    value_securities: "Värde investeringar",
    cash: "Kontanter",
    just_total: "Totalt",

    unregisteredcompanies: "Registrera dig som bolagsrepresentant",
    policy_application_imported: "Försäkringsuppdatering",
    waiting_for_hubins_approval: (
      <p>
        Du har skickat in underlag som Hubins behöver gå igenom. När detta är
        klart kommer vi att kontakta dig och ge dig inbetalningsuppgifterna. Har
        du några frågor kan du kontakta oss på{" "}
        <a
          href="mailto:kundservice@hubins.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          kundservice@hubins.com
        </a>{" "}
        eller via telefon på{" "}
        <a href="tel:0317971936" target="_blank" rel="noopener noreferrer">
          031-797 19 36
        </a>{" "}
        .
      </p>
    ),
    knowledge_test_fail_title: "Information om kapitalförsäkringar",
    knowledge_test_fail_intro:
      "En kapitalförsäkring är en livförsäkring där ett återbetalningsskydd ingår.Detta återbetalningsskydd utfaller om den, eller när samtliga livförsäkrade personer har avlidit, och uppgår till 101 % av försäkringsvärdet den dag då försäkringsbolaget, Quantum Leben AG, nås av informationen.",
    knowledge_test_fail_content: (
      <>
        <p>
          Utbetalningen sker i form av att värdepapper, övriga tillgångar och
          tillgängliga likvida medel i försäkringen överlåts enligt
          förmånstagarförordnandet. Därutöver utbetalas 1 % i form av likvida
          medel baserat på försäkringsvärdet den dag som Quantum Leben nås av
          informationen att den livförsäkrade avlidit.
        </p>
        <p>
          Det är alltså inte beloppet eller kapitalet som sätts in i
          kapitalförsäkringen som är försäkrat utan det är de försäkrade
          personens liv som är försäkrat. Värdet på de underliggande
          tillgångarna som ni väljer att ha i kapitalförsäkringen kan variera
          och följer utvecklingen på dessa investeringar. Försäkringsmomentet
          består av den extra 1 % som utbetalas vid försäkringsfall dvs. vid den
          livförsäkrades, eller den siste livförsäkrades dödsfall.
        </p>
        <p>
          Till kapitalförsäkringen finns möjlighet att förordna om ett
          förmånstagarförordnande. Ett förmånstagarförordnande anger vem som ska
          erhålla försäkringsersättningen vid den livförsäkrades dödsfall. Det
          utfallande beloppet styrs således utanför vanlig arvsordning (vissa
          undantag från detta finns dock). Det finns också möjlighet att
          bestämma att det som tillfaller förmånstagaren ska vara dennes
          enskilda egendom. Att något är mottagarens enskilda egendom innebär
          att det inte ingår i en eventuell bodelning på grund av
          äktenskapsskillnad.
        </p>
        <strong>
          En kapitalförsäkring innefattar huvudsakligen 3 roller enligt nedan:
        </strong>
        <ul>
          <li>
            <strong>Försäkringstagare:</strong> Detta är ägaren av
            kapitalförsäkringen.
          </li>
          <li>
            <strong>Livförsäkrad:</strong> Detta är den eller de personer vars
            liv är försäkrade.
          </li>
          <li>
            <strong>Förmånstagare:</strong> Detta är den eller de personer eller
            organisationer som får utbetalningen vid försäkringsfall, dvs när
            den sista livförsäkrade personen har avlidit.
          </li>
        </ul>
        <p>
          Kapitalförsäkringen har en rörlig kostnad på 1 % per år baserat på
          försäkringsvärdet. Värdepappersfonder är dock
          rabatterade med 50 % till 0,5 % per år.
        </p>
        <p>
          <strong>Exempel på tillgångsslag som endast medför rörlig avgift:</strong>
        </p>
        <ul>
          <li>Onoterade aktier</li>
          <li>Kryptotillgångar</li>
        </ul>
        <p>
          För värdepappersfonder samt noterade aktier är dock den rörliga avgiften rabatterad med 50 % till 0,5 % per år.
        </p>
        <p>
          <strong>Tillgångsslag som medför en rabatterad avgift är:</strong>
        </p>
        <ul>
          <li>Värdepappersfonder</li>
          <li>Noterade aktier</li>
        </ul>
        <p>
          Det tillkommer en fast avgift om 1 800 SEK per år i försäkringen som
          helhet om ni önskar hålla en eller flera investeringar i
          tillgångsslagen nedan. Det tillkommer även en transaktionskostnad på
          300 SEK för de köp eller försäljningar som genomförs i dessa
          tillgångsslag.
        </p>
        <p>
          <strong>
            Tillgångsslag som medför en fast avgift samt transaktionsavgift är:
          </strong>
        </p>
        <ul>
          <li>Onoterade obligationer och direktlån</li>
          <li>Noterade obligationer</li>
        </ul>
        <p>
          Anledningen till den högre kostnaden är att dessa investeringar är mer
          administrativt krävande än övriga tillgångsslag. Från det år som den
          yngsta livförsäkrade personen fyller 65 år tillkommer även en så
          kallad riskpremie om 0,1 % per år.
        </p>
        <p>
          Försäkringen har ingen bindningstid eller löptid utan kan avslutas när
          som helst kostnadsfritt. Ett eventuellt avslut kan dock fördröjas om
          illikvida investeringar hålls i kapitalförsäkringen.
          Kapitalförsäkringen är en livförsäkring som löper tills den
          livförsäkrade avlider. Försäkringen upphör antingen vid helt återköp
          (avslut) eller vid utbetalning på grund av försäkringsfall. Vid avslut
          av försäkringen betalas kostnader fram tills dagen för avslut vilket
          dras från likvidkontot i försäkringen. Eventuella skatter kommer också
          att dras från likvidkontot innan utbetalning. För mer information om
          kostnader se faktabladet ”Kostnader och Avgifter - Hubins och Quantum
          Leben”.
        </p>
        <p>
          Det är försäkringsbolaget som är registrerat som ägare till de
          tillgångar som ni väljer att hålla inom kapitalförsäkringen, ni som
          försäkringstagare har däremot fullständig kontroll över
          kapitalförsäkringen som sådan. Eftersom ni inte är ägare direkt till
          tillgångarna innebär det att ni exempelvis inte kommer att bli
          personligen kallade på bolagsstämmor eller liknande utan det är
          försäkringsbolaget som kommer få dessa kallelser. Ni kommer dock att
          kunna finna denna information via inloggat läge på Hubins plattform.
        </p>
        <p>
          Den kapitalförsäkring som Quantum Leben erbjuder har ett mer flexibelt
          investeringsunivers än vad kapitalförsäkringar vanligen har, vilket
          innebär att ni kan placera i flera olika värdepapper och tillgångar i
          kapitalförsäkringen. Det är Quantum Leben själva som styr vilka
          tillgångar som ingår i dess investeringsunivers. Idag är allt från
          onoterade obligationer och direktlån till aktier och fonder godkänt av
          Quantum Leben. En tillgång som inte redan hålls hos försäkringsbolaget
          måste godkännas av försäkringsbolagets produktgodkännande kommitte
          innan investeringen kan accepteras inom kapitalförsäkringen.
        </p>
        <p>
          För kapitalförsäkringar gäller andra skatteregler än vad som gäller
          för investeringar i exempelvis en depå. En kapitalförsäkring
          schablonbeskattas vilket innebär att en räntesats, kopplad till
          statslåneräntan, multipliceras med kapitalunderlaget för att få fram
          skatteunderlaget. Kapitalunderlaget utgör summan av
          kapitalförsäkringens värde vid årets början samt värdet på
          insättningar under första halvåret och halva värdet på insättningar
          gjorda under andra halvåret. Räntesatsen baseras på statslåneräntan
          den 30 november föregående år med 1 procentenhets tillägg. Räntesatsen
          kan som lägst vara 1,25 % vilket innebär att om statslåneräntan
          understiger 0,25 % så kommer räntesatsen att höjas upp till 1,25 %.
          Skatteunderlaget avrundas nedåt till ett helt hundratal kronor.
          Skatten som slutgiltigen betalas är 30 % av skatteunderlaget.
        </p>
        <p>
          Via kapitalförsäkringar beskattas således inte vinster och förluster
          är inte avdragsgilla. Schablonbeskattningen genomförs enligt
          förklaringen ovan oavsett om de underliggande tillgångarna har gått
          upp eller ner i värde under beskattningsåret. Det är inte möjligt att
          begära avdrag vare sig för de kostnader och avgifter som löper på
          försäkringen eller förlustavdrag för placeringar som går ner och
          minskar i värde. När en investering, till skillnad från en
          kapitalförsäkring, istället hålls direkt i exempelvis en depå så
          beskattas vinsten som görs på investeringen och det finns även
          möjlighet att göra förlustavdrag.
        </p>
        <p>
          Den som har en utländsk kapitalförsäkring måste själv redovisa
          skatteunderlaget i sin inkomstdeklaration om detta inte är förtryckt i
          deklarationen. Detta kan exempelvis hända om försäkringsbolaget
          skickar in sina uppgifter till Skatteverket för sent. Ni kan även
          själva behöva begära nedsättning av stämpelskatt i er deklaration.
          Stämpelskatt betalas vid insättningar och uttag i Quantums
          kapitalförsäkring, där likvida medel beskattats med 0,15 % och flytt
          av investeringar med 0,3%. Detta så att ni inte behöver betala extra
          skatt.
        </p>
        <p>
          Hubins kommer självklart assistera er med all hjälp ni kan behöva.
          Vänligen se även länkar till Skatteverket för mer information och
          räkneexemplen nedan. Mer information finns i länkarna till
          Skatteverket nedan.
        </p>
        <p>
          Hubins kommer självklart assistera er med all hjälp ni kan behöva.
          Vänligen se nedan PDF eller kontakta Hubins kundservice om ni har
          några fler frågor.
        </p>
        <p>
          <strong>Epost:</strong>{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="mailto:kundservice@hubins.com"
          >
            kundservice@hubins.com
          </a>
          <br />
          <strong>Tel:</strong>{" "}
          <a target="_blank" rel="noopener noreferrer" href="tel:0317971936">
            031-797 19 36
          </a>
        </p>
      </>
    ),
    sof_success_modal_title: "Källan är godkänd och ni kan nu sätta in pengar",
    sof_success_modal_text:
      "Ert deklarerade belopp har uppdaterats och ni kan nu gå till insättningssidan för att genomföra er premieinsättning",
    to_company_dashboard: "Till företagets översikt",
    insight_company: "Insyn företag",

    // Transfer of funds

    // TOF DEFAULT
    tof_add: "Lägg till",
    tof_remove: "Ta bort",

    // TOF PROGRESS BAD
    tof_progress_bar_investments: "Investeringsuppgifter",
    tof_progress_bar_terms: "Villkor",
    tof_progress_bar_registration: "Snabbregistrering",

    // TOF INTRO
    tof_intro_hero_title: "Kom igång!",
    tof_intro_hero_text: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia sequi odit incidunt. Repellendus earum ut nisi delectus doloremque nam explicabo unde, ullam mollitia, aperiam fuga? Corporis rem distinctio accusamus alias.`,
    tof_intro_investment_type_question: "Hur håller du dina investeringar?",
    tof_intro_investment_type_question_text: "För att kunna ge så korrekta resultat som möjligt behöver vi i första steget få reda på hur du håller dina investeringar.",
    tof_intro_investment_type_private: "Privat",
    tof_intro_investment_type_company: "Företag",

    // TOF ADD INVESTMENT
    tof_add_investment: "Lägg till",
    tof_add_investment_title: "Lägg till dina investeringar",
    tof_add_investment_text: " Klicka på plusknappen och sök på investeringar. Om din investering inte skulle dyka upp i sökningen kan du lägga till den som en ny investering.",
    tof_add_investment_input_placeholder: "Investeringsnamn",

    tof_add_investment_confimed_title: "Investeringsutbud",
    tof_add_investment_confimed_text: "Om dina investeringar inte redan finns det kan du enkelt lägga till den som en ny investering.",
    tof_add_investment_confimed_show_more: "Visa mer",
    tof_add_investment_confimed_show_less: "Visa fler",

    tof_add_investment_added_investments_title: "Tillagda investeringar",
    tof_add_investment_added_investments_text:
      "Investeringar som ligger här kommer du gå vidare med till nästa steg.",

    tof_add_investment_modal_title: "Fyll i investeringens namn",

    // TOF Success Modal
    tof_add_investment_success_modal_title:
      "Vi kan sannolikt ta emot din investering",
    tof_add_investment_success_modal_text:
      "Enligt de uppgifter du fyllt i finns goda möjligheter för oss att ta emot din investering.",
    tof_add_investment_success_modal_button: "Nästa investering",

    // TOF Rejected Modal
    tof_add_investment_rejected_modal_title:
      "Vi kan inte ta emot din investering",
    tof_add_investment_rejected_modal_text:
      "Enligt de uppgifter du angivit så kommer vi inte kunna ta en eller flera av dina investeringar. De kommer automatiskt tas bort när du går vidare till nästa steg. Du har dock möjlighet att ändra uppgifterna om de skulle ha blivit något fel.",
    tof_add_investment_rejected_modal_button_edit: "Ändra uppgifter",
    tof_add_investment_rejected_modal_button_continue: "Gå vidare",

    // TOF REGISTRATION INTO
    tof_registration_intro_hero_title: "Din rapport är nu genererad",
    tof_registration_intro_hero_text: `Registrera dig snabbt och enkelt för att få en uppskattning på hur mycket mer du skulle kunna tjäna genom att hålla din investering hos oss.`,
    tof_registration_intro_info_title: "Du kommer utses till bolagsrepresentant",
    tof_registration_intro_info_text: `<p>För att öppna upp ett bolagskonto behöver ni utse en bolagsrepresentant som representerar ert bolag gentemot Hubins. Det är bolagsrepresentanten som kommer att vara ansvarig för bolagets konto hos Hubins och ansvara för att de uppgifter som lämnas är korrekta. De som är firmatecknare till bolaget kommer i efterhand behöva logga in med BankID och ge fullmakt till bolagsrepresentanten.
    <br/> Det är den utsedda bolagsrepresentanten som kommer att fatta samtliga beslut för bolagets räkning och registrera bolaget samt godkänna villkoren nedan.</p>`,

    // TOF Investment Form
    tof_investment_form_title: "Investeringsuppgifter",
    tof_investment_form_text: "Fyll i uppgifterna och få förhandsbesked om vi kan ta emot din investering.",

    // Securities
    listed_corporate_bonds: "Noterade företagsobligationer",
    unlisted_corporate_bonds: "Onoterade företagsobligationer",
    direct_loans: "Direktlån",
    listed_shares: "Noterade aktier",
    unlisted_shares: "Onoterade aktier",
    vc_funds: "VC Fonder",
    other_investments: "Övriga investeringar",
    faq_stock_order_title: "Disclaimer (Onoterade aktier)",
    faq_stock_order:
    <>
      <p>Nedan pajdiagram har för avsikt att illustrera hur avgifter påverkar och reducerar den förväntade avkastningen under investeringsperioden. Tabellen nedanför visar vilka typer av avgifter denna investering medför samt vad den totala förväntade avkastningen för hela investeringsperioden beräknas vara efter avgifter.</p>
      <p>Vänligen notera att beräkningarna som resulterade i tabellen och pajdiagrammet är baserade på ett antal antaganden samt begränsningar. Exempel där antaganden är gjorda är förväntad avkastning och investeringsperiod. Hubins reserverar sig även för eventuella felräkningar.</p>
      <p>När ni investerar i onoterade aktier förväntas investeringen  att öka eller minska i värde under placeringsperioden. Om ni dessutom håller dem inom en försäkringsbaserad investeringsprodukt förväntas ni kunna betala vissa avgifter. Vissa av dessa avgifter är relaterade till investeringsprodukten och vissa andra är relaterade till försäkringstjänsten. Dessa avgifter kan vara i form av både engångsavgifter, löpande avgifter, fasta eller rörliga avgifter. Här ser ni ett exempel på hur mycket den totala förväntade avkastningen kommer att påverkas av totala avgifter som kommer att tas ut i investeringsprodukten och/eller i försäkringen. Detta ska hjälpa er att sätta de totala avgifterna i relation till investeringens förväntade avkastning samt hjälpa dig att förstå hela avgiftsstrukturen.</p>
      <p>Vänligen notera att beräkningarna baseras på den enskilda investeringens förväntade avkastning och att investeringen behålls i 1 till 10 år. Den beräknade kapitaltillväxten uppnås därmed med hjälp av ränta-på-ränta-effekten där de onoterade aktierna ökar med en viss procentsats år efter år. Ränta-på-ränta-effekten resulterar sedan i en exponentiell utveckling av investeringen där ni även får avkastning på den avkastning som genererats föregående månad. Detta skiljer sig avsevärt från en linjär utveckling där avkastningen i kronor är lika stor varje månad.</p>
      <p>Observera att produktavgiften i form av engångsavgifter så som courtage och bankavgift baseras i exemplet på investeringens pris. Då investeringen växer med en viss förväntad avkastning per år i detta exempel växer även den rörliga försäkringsavgiften samt försäkringens riskpremie i samma takt under investeringsperioden. Dessa löpande försäkringsavgifter baseras här på investeringens värde vid slutet av varje månad.</p>
      <h5>Produktavgifter</h5>
      <p>Engångsavgifter: Ersättning till emissions eller avvecklingsinstituti form av courtage vid köp och sälj av investeringsprodukter kan utgå. Även diverse bankavgifter kan förekomma vid handel. Engångsavgifter tas vanligtvis ut i början eller slutet av er investering.</p>
      <h5>Försäkringsavgifter</h5>
      <p>Olika typer av avgifter kan belasta den försäkringsbaserade investeringsprodukten. Avgifterna kan exempelvis bestå av både engångsavgifter, löpande avgifter, fasta eller rörliga avgifter.</p>
      <p>Vänligen notera att förväntad avkastning inte ger garanti till framtida avkastning och att dessa beräkningar endast kan stämma med verkligheten om alla förutsättningar och antaganden uppfylls. Pajdiagramet och tabellen är till för att hjälpa er sätta de totala kostnaderna i relation till den förväntade avkastningen för hela investeringsperioden. Investerade pengar kan både öka och minska i värde och alla investeringar ni gör sker på egen risk.</p>
    </>,
    marketplace_title: 'Investeringar',
    marketplace_sub_title: 'Välkommen till Investeringssidan!',
    marketplace_text: 'Investeringssidan har utvecklats som en elektronisk anslagstavla och är ett tekniskt verktyg för att ge våra kunder tillgång till unika och annars svåråtkomliga investeringstyper. Detta kan exempelvis vara investeringar i onoterade aktier, VC-fonder, hedgefonder eller P2P-lån som normalt sett är svåra att få tillgång till och där det vanligtvis krävs stora belopp för att investera. Det är viktigt att notera att Hubins inte är involverade i erbjudanden på investeringssidan, varken som finansiell rådgivare eller selling agent, och Hubins tar inte på något annat sätt tar ansvar för investeringens utveckling.',
    marketplace_disclaimer_title: 'Vem ansvarar för vad på investeringssidan?',
    product: 'Produkt',
    price_per_stock: 'Pris per aktie',
    min_amount_of_stocks: 'Minst antal aktier',
    min_investment_value: 'Minsta investering',
    payment_receiver: 'Betalningsmottagare',
    portfolio_approval: 'Policy approval',
    sof_approval: 'Source of funds',
    deposit_approval: 'Premium',
    verdict_status_waiting_on_third_party: 'Waiting on third party',
    verdict_status_approved: 'Approved',
    verdict_status_denied: 'Rejected',
    verdict_status_comment: 'Comment',
    verdict_status_waiting_on_management: 'Waiting on management',
    verdict_status_processing: 'Processing',
    verdict_status_suspected: 'Suspected',
    policy_holder: 'Försäkringstagare',
    risk_category: 'Riskkategori',
    premium_info: 'Premieinformation',
    user_sources_for_premium: 'Valda källor för premie',
    sow: 'Källa till förmögenhet',
    'knowledge_result_info_success': 'Du svarade rätt på alla frågor. Du kan därmed gå vidare till nästa steg.',
    'c_knowledge_result_info_success': 'Du svarade rätt på alla frågor. Du kan därmed gå vidare till nästa steg.',
    'knowledge_result_info_text': 'Du svarade tyvärr inte rätt på alla frågor vilket krävs för att kunna gå vidare. Vänligen läs mer om kapitalförsäkringar i dokumentet samt länkarna nedan.',
    'c_knowledge_result_info_text': 'Ni svarade tyvärr inte rätt på alla frågor vilket krävs för att kunna gå vidare. Vänligen läs mer om kapitalförsäkringar i dokumentet samt länkarna nedan.',
    confirm_knowledge_learning_material: 'Jag bekräftar att jag har tagit del av utbildningsmaterialet',
    'knowledge_about_teaser': 'Vill du klara testet på första försöket? Då rekommenderar vi att du tar del av dokumentet nedan.',
    'knowledge_test_clarification': 'Om du inte klarar testet på första försöket får du självklart en chans till!',
    aptitude_tests: 'Passandeprövning',
  },
});

export default keys;
