import React from "react";
import DefaultFormTemplate from './DefaultFormTemplate';
import Init from './init';
export { ResponseMessage } from './ResponseMessage';

export const CreateForm = (props) => (
  <Init
    Form={DefaultFormTemplate}
    {...props}
  />
);

export default CreateForm;
