import React, { useEffect, useState } from 'react';
import middleman from 'helpers/middleman';
import { connect } from 'react-redux';
import Heading from 'components/Heading';
import Button from '@hubins/components/Button';
import __, { __route } from 'localisation';

const AptitudeTestResult = ({formKey, title, nationalIdentityNumber}) => {

  const [loading, setLoading] = useState(true);
  const [finishedWithTest, setFinishedWithTest] = useState(false);

  const fetchInitData = async () => {
    const response = await middleman.promisePost('/forms/show', { key: formKey, type: 'inv', userId: nationalIdentityNumber })
      .then(res => !!res?.data?.response)
      .catch(() => {
        return false;
      });

    console.log('response', response);
    setFinishedWithTest(response);
    setLoading(false);
  }

  useEffect(() => {
    fetchInitData();
  }, []);

  return (
    <>
      <Heading size="5" className="s-top-xl">{title}</Heading>
      <Button
        loading={loading}
        disabled={loading}
        className={`${finishedWithTest ? 'success' : ''} s-top-md`}
        icon={finishedWithTest ? 'check signle' : 'arrow right 1'}
        href={!finishedWithTest ? `${__route('intro_investment_knowledge_test')}/${formKey}` : undefined}
        iconColor={finishedWithTest ? 'white' : 'primary'}
      >
        {finishedWithTest ? __('approval_test_completed') : __('approval_test_not_completed')}
      </Button>
    </>
  )

};

const mapStateToProps = state => ({
  nationalIdentityNumber: state.auth.User.national_identity_number,
});

export default connect(mapStateToProps)(AptitudeTestResult);