import React from "react";

import {
  useLocation,
  BrowserRouter as Router,
  Route as ReactRoute,
  Switch,
} from "react-router-dom";
import { connect } from "react-redux";

import NoMatch from "views/NoMatch";

import TransferOfFunds from "./views/TransferOfFunds";
import RestrictedRoute from "./views/layouts/RestrictedRoute";
import LayoutDefault from "./views/layouts/RouteDefault";
import WithHeaderMenu from "./layouts/WithHeaderMenu";
import Success from "views/layouts/Success";
import Invest from "./views/Invest";
import Login from "./views/Login";
import LoggedOut from "./views/LoggedOut";
import DepositAndWithdrawal from "./views/DepositAndWithdrawal";
import Deposit from './views/Deposit';
import InitDeposit from './views/Deposit/Init';

import TransferInstructions from "./views/DepositAndWithdrawal/TransferInstructions";
import DepositInstructions from "./views/DepositInstructions";
import SingleCase from "views/SingleCase";
import { updateAlerts, removeAlert } from "./state/ducks/alerts";

import RegisterInsurance from "./views/RegisterInsurance";
import TutorialTestPage from "./views/TutorialTestPage";


import { __, __route } from "localisation";
import { ScrollToTop } from "helpers";

import { Logo } from "./settings";

//temp views

// Components for Dynamic lodable pages
import Loadable from "jaybe-react-loadable";
import LoadingPage from '@hubins/components/LoadingPage';

import { authOperations } from "./state/ducks/auth";

/* Dynamic loaded views ______ */

// Signup forms in order
import Signup from './views/Signup';
import Presale from "./views/Presale";
import CompanyPresale from "./views/Presale/Company";
import ExistingCompanyPresale from "./views/Presale/ExistingCompany";
import SignupSummary from "./views/SignupSummary";
import PersonnelSignupSummary from "./views/SignupSummary/Personnel";

import { useFeature } from '@growthbook/growthbook-react';
import InvestDisclaimer from "./views/InvestDisclaimer";


// Transfer
import Transfer from "./views/Transfer";
import TransferReports from "./views/Transfer/TransferReports";
import TransferInfo from "./views/Transfer/TransferInfo";

import ReportContext from '@hubins/components/ReportContext';
import Report from "./views/Report";
import Transactions from "./views/Transactions";
import Holdings from "./views/Holdings";
import YearlyReport from "./views/YearlyReport";
import Overview from "./views/Overview";
import IdleTimer from "components/IdleTimer";
import FormPreview from './views/FormPreview';

import AptitudeTests from './views/AptitudeTests';

const AfterSale = Loadable({
  loader: () => import("./views/SignupForms/AfterSale"),
  loading: LoadingPage
});

const PreviewAfterSale = Loadable({
  loader: () => import("./views/SignupForms/PreviewAfterSale"),
  loading: LoadingPage
});

const CompAfterSale = Loadable({
  loader: () => import("./views/CompanySignupForms/AfterSale"),
  loading: LoadingPage
});

const CompanySignupSuccess = Loadable({
  loader: () => import("./views/SignupForms/CompanySignupSuccess"),
  loading: LoadingPage
});

const SofTriggerSuccess = Loadable({
  loader: () => import("./views/SofTriggerSuccess"),
  loading: LoadingPage
});

const NewSOF = Loadable({
  loader: () => import("views/NewSourceOfFunds"),
  loading: LoadingPage
});

const SOW = Loadable({
  loader: () => import("./views/SourceOfWealth"),
  loading: LoadingPage
});

const Contact = Loadable({ loader: () => import('./views/Contact'), loading: LoadingPage });

// Company signup
// Company Signup
const RegisterCompany = Loadable({
  loader: () => import("./views/CompanySignupForms/RegisterCompany"),
  loading: LoadingPage
});

const CompanySignupBasicInfo = Loadable({ loader: () => import('./views/CompanySignupForms/CompanySignupBasicInfo'), loading: LoadingPage, delay: 0 }); // TODO: Add delay 0 on ALL loadable components to avoid footer not being in the footer place. Or redesign it

const CompanyPersonnelKYC = Loadable({ loader: () => import('./views/CompanySignupForms/PersonnelKYC'), loading: LoadingPage });

const BasicSignupTerms = Loadable({ loader: () => import('./views/SignupForms/BasicSignupTerms'), loading: LoadingPage });
const CompanyBasicSignupTerms = Loadable({ loader: () => import('./views/SignupForms/CompanyBasicSignupTerms'), loading: LoadingPage });


const NewDA = Loadable({
  loader: () => import("views/NewDA"),
  loading: LoadingPage
});

const DaTerms = Loadable({
  loader: () => import("views/NewDA/Terms"),
  loading: LoadingPage
});
const DaPurpose = Loadable({
  loader: () => import("views/NewDA/Purpose"),
  loading: LoadingPage
});
const DaTransactions = Loadable({
  loader: () => import("views/NewDA/Transactions"),
  loading: LoadingPage
});
const DaProducts = Loadable({
  loader: () => import("views/NewDA/Products"),
  loading: LoadingPage
});
const DaTargetReturn = Loadable({
  loader: () => import("views/NewDA/TargetReturn"),
  loading: LoadingPage
});
const DaTax = Loadable({
  loader: () => import("views/NewDA/Tax"),
  loading: LoadingPage
});
const DaBeneficiaries = Loadable({
  loader: () => import("views/NewDA/Beneficiaries"),
  loading: LoadingPage
});

const KYC = Loadable({
  loader: () => import("./views/SignupForms/KYC"),
  loading: LoadingPage
});
const InsuranceInformation = Loadable({
  loader: () => import("./views/SignupForms/InsuranceInformation"),
  loading: LoadingPage
});
const CompanyInsuranceInformation = Loadable({
  loader: () => import("./views/SignupForms/CompanyInsuranceInformation"),
  loading: LoadingPage
});
const NewDASignOff = Loadable({
  loader: () => import("views/NewDASignOff"),
  loading: LoadingPage
});

const NewDASignOffPDF = Loadable({
  loader: () => import("views/NewDASignOff/PuppeteerGenerator"),
  loading: LoadingPage
});

const NewDASignOffUpdatedSummary = Loadable({
  loader: () => import("views/NewDASignOff/UpdatedSummary"),
  loading: LoadingPage
});
const NewDASignOffInitSummary = Loadable({
  loader: () => import("views/NewDASignOff/InitSummary"),
  loading: LoadingPage
});

const Knowledge = Loadable({
  loader: () => import("./views/Tests/Knowledge"),
  loading: LoadingPage
});
const KnowledgeResult = Loadable({
  loader: () => import("./views/Tests/Knowledge/KnowledgeResult"),
  loading: LoadingPage
});

const Terms = Loadable({
  loader: () => import("./views/Tests/Terms"),
  loading: LoadingPage
});
const CompanyTerms = Loadable({
  loader: () => import("./views/Tests/CompanyTerms"),
  loading: LoadingPage
});

const YearlyCheckup = Loadable({
  loader: () => import("views/YearlyCheckup"),
  loading: LoadingPage
});

const StockOrder = Loadable({
  loader: () => import("./views/TradeOrder/StockOrder"),
  loading: LoadingPage
});

const CryptoOrder = Loadable({
  loader: () => import("./views/TradeOrder/CryptoOrder"),
  loading: LoadingPage
});

// Signatories
const SignatoryKYC = Loadable({ loader: () => import('./views/SignatorySignupForms/SignatoryKYC'), loading: LoadingPage });
const SignatorySignoff = Loadable({ loader: () => import('./views/SignatorySignupForms/SignatorySignoff'), loading: LoadingPage });
const SignatorySuccess = Loadable({ loader: () => import('./views/SignatorySignupForms/SignatorySuccess'), loading: LoadingPage });
const SignatorySignupResult = Loadable({ loader: () => import('./views/SignatorySignupForms/SignatorySignupResult'), loading: LoadingPage });

//Settings pages
const Settings = Loadable({
  loader: () => import("./views/Settings"),
  loading: LoadingPage
});

const Settings_OLD = Loadable({
  loader: () => import("./views/Settings_OLD"),
  loading: LoadingPage
});
const SavedDocuments = Loadable({
  loader: () => import("./views/SavedDocuments"),
  loading: LoadingPage
});

// InvestmentKnowledgeTest
const InvestmentKnowledgeTest = Loadable({
  loader: () => import("./views/InvestmentKnowledgeTest"),
  loading: LoadingPage
});
const InvestmentKnowledgeTestIntro = Loadable({
  loader: () => import("./views/InvestmentKnowledgeTest/Intro"),
  loading: LoadingPage
});
const InvestmentKnowledgeTestResult = Loadable({
  loader: () => import("./views/InvestmentKnowledgeTest/Result"),
  loading: LoadingPage
});

// Royc views
const RoycLogin = Loadable({
  loader: () => import("./views/RoycLogin"),
  loading: LoadingPage
});

const RoycRegistration_PLAN_A = Loadable({
  loader: () => import("./views/RoycRegistration/PlanA"),
  loading: LoadingPage
});
const RoycRegistration_PLAN_B = Loadable({
  loader: () => import("./views/RoycRegistration/PlanB"),
  loading: LoadingPage
});

// End Dynamic loaded views

/**
 * Usage of layouts:
 *
 * <RestrictedRoute layout={ logged in object } layoutLoggedOut={ logged out object } exact path="/" />
 * Use layoutLoggedOut if you wanna have different layouts for a logged in or a logged out use
 * You can also redirect the user whith a function with a <Redirect> inside (see functions startLoggedOut and startLoggedIn)
 *
 * <WithHeaderMenu logo={Logo}> take a title and a component
 *
 * If layoutLoggedOut is not supplied, the user will be redirected to a login screen
 */

// const startLoggedOut = () => <Redirect to={__route('login')} />;
// import {
//   // calculateReturn,
//   getReductionOfReturn
// } from 'helpers/calculations';

const Routes = reduxprops => {

  const TOF = useFeature('tof').on;
  const ROYC_PLAN_B = useFeature('royc-plan-b').on;
  const UPDATE_CUSTOMER_DATA = useFeature('update-customer-data').on;

  return (
    <>
      <IdleTimer
        authToken={reduxprops.token}
        tokenExpires={reduxprops.tokenExpires}
        logoutUser={reduxprops.logoutUser}
        refreshToken={reduxprops.refreshToken}
      />
      <Router>
        <ReportContext national_identity_number={reduxprops.national_identity_number}>
          {reduxprops.children}
          <ScrollToTop trigger={useLocation} />
          <Switch>
            {TOF && (
              <WithHeaderMenu
                logo={Logo}
                title="Transfer of funds"
                exact
                path={__route("registration_transfer")}
                noBgAnim
                component={TransferOfFunds}
              />
            )}

            <RestrictedRoute
              layout={WithHeaderMenu}
              title="ROYC Login"
              exact
              path={'/royc/login'}
              noBgAnim
              component={RoycLogin}
            />
            <WithHeaderMenu
              logo={Logo}
              title="Form preview"
              exact
              path={'/preview/:id'}
              noBgAnim
              component={FormPreview}
            />
            <WithHeaderMenu
              logo={Logo}
              title="Form preview"
              exact
              path={'/preview/form/:id'}
              noBgAnim
              component={(props) => (<FormPreview isFormId {...props} />)}
            />
            {/*
        -!-!- SHOULD BE RESTRICTED -!-!-
        */}
            {TOF && (
              <RestrictedRoute
                layout={WithHeaderMenu}
                title={__('type_presec')}
                exact
                path={__route("transfer")}
                noBgAnim
                component={Transfer}
              />
            )}

            {TOF && (
              <RestrictedRoute
                layout={WithHeaderMenu}
                title={__('type_presec')}
                exact
                path={__route("transfer_start")}
                render={(props) => (<TransferOfFunds skipRegistration {...props} />)}
              />
            )}

            {/* {TOF && ( */}
            <RestrictedRoute
              layout={LayoutDefault}
              noBgAnim
              title="Registrera Royc konto"
              exact
              path={"/royc/register"}
              component={ROYC_PLAN_B ? RoycRegistration_PLAN_B : RoycRegistration_PLAN_A}
            />
            <RestrictedRoute
              layout={LayoutDefault}
              noBgAnim
              title="Resultat"
              exact
              path={__route('transfer_report')}
              component={TransferReports}
            />
            {/* )} */}

            {TOF && (
              <RestrictedRoute
                layout={LayoutDefault}
                noBgAnim
                title="Extra info"
                exact
                path={__route('transfer_info')}
                component={TransferInfo}
              />
            )}

            <RestrictedRoute
              layout={LayoutDefault}
              clearUser={reduxprops.clearUser}
              exact
              path={__route("root")}
              title={__("app_title")}
              stretch
              component={Overview}
            />
            <RestrictedRoute
              layout={LayoutDefault}
              clearUser={reduxprops.clearUser}
              exact
              path={__route("overview")}
              stretch
              title={__("app_title")}
              component={Overview}
            />
            <RestrictedRoute
              layout={LayoutDefault}
              clearUser={reduxprops.clearUser}
              exact
              path={__route("report")}
              title={__("app_title")}
              stretch
              component={Report}
            />

            <RestrictedRoute
              layout={LayoutDefault}
              clearUser={reduxprops.clearUser}
              exact
              path={__route("transactions")}
              title={__("app_title")}
              stretch
              component={Transactions}
            />

            <RestrictedRoute
              layout={LayoutDefault}
              clearUser={reduxprops.clearUser}
              exact
              path={__route("holdings")}
              title={__("app_title")}
              stretch
              component={Holdings}
            />
            <RestrictedRoute
              layout={LayoutDefault}
              clearUser={reduxprops.clearUser}
              exact
              path={__route("yearly_report")}
              title={__("app_title")}
              stretch
              component={YearlyReport}
            />
            <WithHeaderMenu
              logo={Logo}
              title={"TEST TUTORIAL"}
              exact
              path={'/test-tutorial'}
              component={TutorialTestPage}
            />

            <WithHeaderMenu
              logo={Logo}
              title={__("login")}
              exact
              path={__route("login")}
              component={Login}
            />

            <WithHeaderMenu
              logo={Logo}
              title={__("contact")}
              exact
              path={__route("contact")}
              component={Contact}
              btnType="signup"
            />

            <RestrictedRoute
              layout={LayoutDefault}
              title={__("logging_out")}
              exact
              path={__route("logout")}
              component={LoggedOut}
            />

            <RestrictedRoute
              layout={LayoutDefault}
              title={__("invest")}
              exact
              path={__route("invest")}
              render={(props) => (
                <Invest {...props} />
              )}
            />

            <RestrictedRoute
              layout={LayoutDefault}
              stretch
              title={__("invest")}
              exact
              path={__route("invest_disclaimer")}
              render={(props) => <InvestDisclaimer {...props} private_persons={reduxprops.private_persons} />}
            />

            <RestrictedRoute
              layout={LayoutDefault}
              title={__("yearly_checkup")}
              exact
              path={__route("yearlyCheckup")}
              render={(props) => (
                <YearlyCheckup
                  {...props}
                />
              )}
            />

            <RestrictedRoute
              layout={LayoutDefault}
              title={__("yearly_checkup")}
              exact
              path={__route("yearlyCheckupWithoutIntro")}
              render={(props) => (
                <YearlyCheckup
                  skipIntro
                  {...props}
                />
              )}
            />

            <RestrictedRoute
              layout={LayoutDefault}
              title={__("case")}
              exact
              path={__route("case_objectid")}
              render={(props) => (
                <SingleCase
                  id={props.match.params.objectid}
                  title={__("case")}
                  hideFooter={true}
                  {...props}
                />
              )}
            />

            <RestrictedRoute
              layout={LayoutDefault}
              title={__("case")}
              exact
              path={__route("case_objectid_blocksale") + "/:objectid"}
              render={(props) => (
                <SingleCase
                  blocksale
                  id={props.match.params.objectid}
                  title={__("case")}
                  {...props}
                />
              )}
            />
            <RestrictedRoute
              layout={LayoutDefault}
              title="Teckningsanmälan Aktie"
              exact
              path={__route("subscription_stock_objectid")}
              render={(props) => (
                <StockOrder
                  id={props.match.params.id}
                  nationalIdentityNumber={reduxprops.national_identity_number}
                  {...props}
                />
              )}
            />

            <RestrictedRoute
              layout={LayoutDefault}
              title="Teckningsanmälan Krypto"
              exact
              path={__route("subscription_crypto_objectid")}
              render={(props) => (
                <CryptoOrder
                  id={props.match.params.id}
                  nationalIdentityNumber={reduxprops.national_identity_number}
                  {...props}
                />
              )}
            />

            {/* Deposit money */}
            <RestrictedRoute
              layout={LayoutDefault}
              title={__("deposit_money")}
              exact
              path={__route("deposit")}
              component={InitDeposit}
            />

            <RestrictedRoute
              layout={LayoutDefault}
              title={__("deposit_money")}
              exact
              path={`${__route("deposit")}/:portfolioUuid`}
              component={Deposit}
            />
            <RestrictedRoute
              layout={LayoutDefault}
              title={__("deposit_money")}
              exact
              path={__route("depositSuccessAg")}
              render={(props) => (
                <Success
                  {...props}
                  illustration="/illustrations/direct_debit.svg"
                  illustrationAlt={__("direct_debit_success_title")}
                  title={__("direct_debit_success_title")}
                  ingress={__("direct_debit_success_ingress")}
                  backurl="/"
                  button={__("my_accounts")}
                />
              )}
            />

            {/* Withdraw money */}
            <RestrictedRoute
              layout={LayoutDefault}
              title={__("withdraw_money")}
              exact
              path={__route("withdrawal")}
              render={(props) => (
                <DepositAndWithdrawal {...props} withdrawal />
              )}
            />
            <RestrictedRoute
              layout={LayoutDefault}
              title={__("withdraw_money")}
              exact
              path={__route("withdrawalSuccess")}
              render={(props) => (
                <Success
                  {...props}
                  illustration="/illustrations/money_deposit.svg"
                  illustrationAlt={__("withdrawal_success_title")}
                  title={__("withdrawal_success_title")}
                  ingress={__("withdrawal_success_ingress", {
                    app_name: __("app_name"),
                  })}
                  backurl={__route("root")}
                  button={__("my_accounts")}
                />
              )}
            />

            <RestrictedRoute
              layout={LayoutDefault}
              title={__("bankgiro") + " / " + __("bank_transfer")}
              exact
              path={__route("depositInstructions")}
              render={(props) => (
                <TransferInstructions
                  {...props}
                  accountsRoute={__route('root')}

                />
              )}
            />

            <RestrictedRoute
              layout={LayoutDefault}
              title={__("bankgiro") + " / " + __("bank_transfer")}
              exact
              path={`${__route("depositInstructions")}/q/:portfolioUuid/:orderId/`}
              component={TransferInstructions}
            />

            <RestrictedRoute
              layout={LayoutDefault}
              title={__("bankgiro") + " / " + __("bank_transfer")}
              exact
              path={`${__route("depositInstructions")}/s/:uuid`}
              render={(props) => (
                <DepositInstructions transactionUuid={props.match.params.uuid} {...props} />
              )}
            />

            {UPDATE_CUSTOMER_DATA ? (
              < RestrictedRoute
                layout={LayoutDefault}
                title={__("profile_settings")}
                exact
                path={__route("settings")}
                component={Settings}
              />
            ) : (
              <RestrictedRoute
                layout={LayoutDefault}
                title={__("profile_settings")}
                exact
                path={__route("settings")}
                component={Settings_OLD}
              />
            )}

            <RestrictedRoute
              layout={LayoutDefault}
              title={__('aptitude_tests')}
              exact
              path={__route("aptitude_tests")}
              component={AptitudeTests}
            />

            <RestrictedRoute
              layout={LayoutDefault}
              title={__("saved_documents")}
              exact
              path={__route("savedDocuments")}
              component={SavedDocuments}
            />

            {/* Buy trade orders -success */}
            <RestrictedRoute
              layout={LayoutDefault}
              title="Köp obligationer"
              exact
              path={__route("marketBuySuccess")}
              render={(props) => (
                <Success
                  {...props}
                  illustration="/illustrations/subscription_created.svg"
                  illustrationAlt={__("market_buy_success_title")}
                  title={__("market_buy_success_title")}
                  ingress={__("market_buy_success_ingress")}
                  backurl="/"
                  button={__("my_accounts")}
                />
              )}
            />
            {/* Buy trade orders -success */}
            <RestrictedRoute
              layout={LayoutDefault}
              title="ROYC"
              exact
              path={"/royc/success"}
              render={(props) => (
                <Success
                  {...props}
                  illustration="/illustrations/subscription_created.svg"
                  illustrationAlt={'royc'}
                  title={"Tack för din ansökan"}
                  ingress={"Vi behandlar just nu er ansökan och återkommer inom kort med instruktioner för att ni ska få åtkomst till ert konto hos ROYC."}
                  backurl="/"
                  button={__("my_accounts")}
                />
              )}
            />

            <RestrictedRoute
              layout={LayoutDefault}
              title={__("subscription_completed")}
              exact
              path={__route("subscriptionBuySuccess")}
              render={(props) => (
                <Success
                  {...props}
                  illustration="/illustrations/subscription_created.svg"
                  illustrationAlt={__("market_buy_success_title")}
                  title={__("subscription_completed")}
                  ingress={__("subscription_completed_info")}
                  backurl="/"
                  button="Mina konton"
                />
              )}
            />

            {/* Buy trade orders -success */}
            <RestrictedRoute
              layout={LayoutDefault}
              title="Sälj obligationer"
              exact
              path={__route("marketSellSuccess")}
              render={(props) => (
                <Success
                  {...props}
                  illustration="/illustrations/subscription_created.svg"
                  illustrationAlt={__("market_sell_success_title")}
                  title={__("market_sell_success_title")}
                  ingress={__("market_sell_success_ingress")}
                  backurl="/"
                  button={__("my_accounts")}
                />
              )}
            />

            {/* Company Registration */}

            {/* Signatorys - only access if you are a signatory */}
            <RestrictedRoute
              layout={WithHeaderMenu}
              title={__("company_customer")}
              btnType="logout"
              exact
              path={__route("companySignatory")}
              component={SignatorySignupResult}
            />
            <RestrictedRoute
              layout={WithHeaderMenu}
              title={__("Customer_Diligence_Account_Opening")}
              exact
              path={`${__route("s_kyc")}/:profileUuid`}
              component={SignatoryKYC}
              btnType="cancel"
              backBtn={__route("root")}
            />
            <RestrictedRoute
              layout={WithHeaderMenu}
              title={__("proxy")}
              exact
              path={`${__route("s_signoff")}/:profileUuid`}
              render={(props) => <SignatorySignoff signatories={reduxprops.signatories} {...props} />}
              btnType="logout"
              backBtn={__route("root")}
            />
            <RestrictedRoute
              layout={WithHeaderMenu}
              title={__("company_customer")}
              exact
              path={__route("companySignatorySignupSuccess")}
              component={SignatorySuccess}
            />
            <RestrictedRoute
              layout={WithHeaderMenu}
              title={__("Customer_Diligence_Account_Opening")}
              exact
              path={__route("companyPrincipalSignup1")}
              component={SignatoryKYC}
              btnType="cancel"
              backBtn={__route("root")}
            />
            <RestrictedRoute
              layout={WithHeaderMenu}
              title={__("proxy")}
              exact
              path={__route("companyPrincipalSignup2")}
              component={SignatorySignoff}
              btnType="logout"
              backBtn={__route("root")}
            />
            <RestrictedRoute
              layout={WithHeaderMenu}
              title={__("company_customer")}
              exact
              path={__route("companyPrincipalSignupSuccess")}
              component={SignatorySuccess}
            />

            <WithHeaderMenu
              logo={Logo}
              title={__("create_account")}
              exact
              path={__route("signup")} // http://localhost:8080/bli-kund
              component={Signup}
              btnType="cancel"
              backBtn={__route("logout")}
            />
            <RestrictedRoute
              layout={LayoutDefault}
              path={`${__route("register_insurance")}`}
              title={__("register_insurance")}
              exact
              component={RegisterInsurance}
            />
            <WithHeaderMenu
              logo={Logo}
              title={__('p_presale')}
              exact
              path={`${__route("p_presale")}/:profileUuid/:portfolioUuid?`} // http://localhost:8080/bli-kund
              component={Presale}
              btnType="cancel"
              backBtnExternal={__route("root")}
            />
            <WithHeaderMenu
              logo={Logo}
              title={__('p_presale')}
              exact
              path={`${__route("c_presale")}/new/:orgNum/:portfolioUuid?`} // http://localhost:8080/bli-kund
              component={CompanyPresale}
              btnType="cancel"
              backBtnExternal={__route("root")}
            />
            <WithHeaderMenu
              logo={Logo}
              title={__('p_presale')}
              exact
              path={`${__route("c_presale")}/:profileUuid/:portfolioUuid?`} // http://localhost:8080/bli-kund
              component={CompanyPresale}
              btnType="cancel"
              backBtnExternal={__route("root")}
            />
            <WithHeaderMenu
              logo={Logo}
              title={__('p_presale')}
              exact
              path={`${__route("c_presale_existing")}/:profileUuid`} // http://localhost:8080/bli-kund
              component={ExistingCompanyPresale}
              btnType="cancel"
              backBtnExternal={__route("root")}
            />
            <WithHeaderMenu
              logo={Logo}
              title={__('register_insurance')}
              exact
              path={`${__route("register_summary")}/:profileUuid/:portfolioUuid`} // http://localhost:8080/bli-kund
              component={SignupSummary}
              btnType="cancel"
              backBtnExternal={__route("root")}
            />
            <WithHeaderMenu
              logo={Logo}
              title={__('personnel_registration')}
              exact
              path={`${__route("personnel_summary")}/:profileUuid`} // http://localhost:8080/bli-kund
              component={PersonnelSignupSummary}
              btnType="cancel"
              backBtnExternal={__route("root")}
            />
            <WithHeaderMenu
              logo={Logo}
              title={`${__("registration")} - ${__(
                "customer_registration"
              )}`}
              exact
              path={__route("signupPersonnel") + '/:company?'}
              component={BasicSignupTerms}
              btnType="cancel"
              backBtn={__route("root")}
            />
            <WithHeaderMenu
              logo={Logo}
              title={`${__("registration")} - ${__(
                "customer_registration"
              )}`}
              exact
              path={__route("signupPersonnelNoTerms")}
              render={(props) => <BasicSignupTerms {...props} skipTerms={true} />}
              btnType="cancel"
              backBtn={__route("root")}
            />
            <WithHeaderMenu
              logo={Logo}
              title={`${__("registration")} - ${__(
                "customer_registration"
              )}`}
              exact
              path={__route("signupCompanyPersonnel") + '/:profile_id?'}
              component={CompanyBasicSignupTerms}
              btnType="cancel"
              backBtn={__route("root")}
            />
            <RestrictedRoute
              layout={WithHeaderMenu}
              logo={Logo}
              title={__("demand_assesment")}
              exact
              path={`${__route("p_da_terms")}/:profileUuid/:portfolioUuid`}
              render={(props) => <DaTerms profileUuid={props.match.params.profileUuid} portfolioUuid={props.match.params.portfolioUuid} />}
              btnType="cancel"
              backBtn={__route("settings")}
            />
            <RestrictedRoute
              layout={WithHeaderMenu}
              logo={Logo}
              title={__("demand_assesment")}
              exact
              path={`${__route("c_da_terms")}/:profileUuid/:portfolioUuid`}
              render={(props) => <DaTerms profileUuid={props.match.params.profileUuid} portfolioUuid={props.match.params.portfolioUuid} isCompany />}
              btnType="cancel"
              backBtn={__route("settings")}
            />
            <RestrictedRoute
              layout={WithHeaderMenu}
              logo={Logo}
              title={__("demand_assesment")}
              exact
              path={`${__route("p_da_purpose")}/:profileUuid/:portfolioUuid`}
              render={(props) => <DaPurpose profileUuid={props.match.params.profileUuid} portfolioUuid={props.match.params.portfolioUuid} />}
              btnType="cancel"
              backBtn={__route("settings")}
            />
            <RestrictedRoute
              layout={WithHeaderMenu}
              logo={Logo}
              title={__("demand_assesment")}
              exact
              path={`${__route("c_da_purpose")}/:profileUuid/:portfolioUuid`}
              render={(props) => <DaPurpose profileUuid={props.match.params.profileUuid} portfolioUuid={props.match.params.portfolioUuid} isCompany />}
              btnType="cancel"
              backBtn={__route("settings")}
            />
            <RestrictedRoute
              layout={WithHeaderMenu}
              logo={Logo}
              title={__("demand_assesment")}
              exact
              path={`${__route("p_da_transactions")}/:profileUuid/:portfolioUuid`}
              render={(props) => <DaTransactions profileUuid={props.match.params.profileUuid} portfolioUuid={props.match.params.portfolioUuid} />}
              btnType="cancel"
              backBtn={__route("settings")}
            />
            <RestrictedRoute
              layout={WithHeaderMenu}
              logo={Logo}
              title={__("demand_assesment")}
              exact
              path={`${__route("c_da_transactions")}/:profileUuid/:portfolioUuid`}
              render={(props) => <DaTransactions profileUuid={props.match.params.profileUuid} portfolioUuid={props.match.params.portfolioUuid} isCompany />}
              btnType="cancel"
              backBtn={__route("settings")}
            />
            <RestrictedRoute
              layout={WithHeaderMenu}
              logo={Logo}
              title={__("demand_assesment")}
              exact
              path={`${__route("p_da_products")}/:profileUuid/:portfolioUuid`}
              render={(props) => <DaProducts profileUuid={props.match.params.profileUuid} portfolioUuid={props.match.params.portfolioUuid} />}
              btnType="cancel"
              backBtn={__route("settings")}
            />
            <RestrictedRoute
              layout={WithHeaderMenu}
              logo={Logo}
              title={__("demand_assesment")}
              exact
              path={`${__route("c_da_products")}/:profileUuid/:portfolioUuid`}
              render={(props) => <DaProducts profileUuid={props.match.params.profileUuid} portfolioUuid={props.match.params.portfolioUuid} isCompany />}
              btnType="cancel"
              backBtn={__route("settings")}
            />
            <RestrictedRoute
              layout={WithHeaderMenu}
              logo={Logo}
              title={__("demand_assesment")}
              exact
              path={`${__route("p_da_target_return")}/:profileUuid/:portfolioUuid`}
              render={(props) => <DaTargetReturn profileUuid={props.match.params.profileUuid} portfolioUuid={props.match.params.portfolioUuid} />}
              btnType="cancel"
              backBtn={__route("settings")}
            />
            <RestrictedRoute
              layout={WithHeaderMenu}
              logo={Logo}
              title={__("demand_assesment")}
              exact
              path={`${__route("c_da_target_return")}/:profileUuid/:portfolioUuid`}
              render={(props) => <DaTargetReturn profileUuid={props.match.params.profileUuid} portfolioUuid={props.match.params.portfolioUuid} isCompany />}
              btnType="cancel"
              backBtn={__route("settings")}
            />
            <RestrictedRoute
              layout={WithHeaderMenu}
              logo={Logo}
              title={__("demand_assesment")}
              exact
              path={`${__route("p_da_tax")}/:profileUuid/:portfolioUuid`}
              render={(props) => <DaTax profileUuid={props.match.params.profileUuid} portfolioUuid={props.match.params.portfolioUuid} />}
              btnType="cancel"
              backBtn={__route("settings")}
            />
            <RestrictedRoute
              layout={WithHeaderMenu}
              logo={Logo}
              title={__("demand_assesment")}
              exact
              path={`${__route("c_da_tax")}/:profileUuid/:portfolioUuid`}
              render={(props) => <DaTax profileUuid={props.match.params.profileUuid} portfolioUuid={props.match.params.portfolioUuid} isCompany />}
              btnType="cancel"
              backBtn={__route("settings")}
            />
            <RestrictedRoute
              layout={WithHeaderMenu}
              logo={Logo}
              title={__("demand_assesment")}
              exact
              path={`${__route("p_da_beneficiaries")}/:profileUuid/:portfolioUuid`}
              render={(props) => <DaBeneficiaries profileUuid={props.match.params.profileUuid} portfolioUuid={props.match.params.portfolioUuid} />}
              btnType="cancel"
              backBtn={__route("settings")}
            />
            <RestrictedRoute
              layout={WithHeaderMenu}
              logo={Logo}
              title={__("demand_assesment")}
              exact
              path={`${__route("c_da_beneficiaries")}/:profileUuid/:portfolioUuid`}
              render={(props) => <DaBeneficiaries profileUuid={props.match.params.profileUuid} portfolioUuid={props.match.params.portfolioUuid} isCompany />}
              btnType="cancel"
              backBtn={__route("settings")}
            />
            <RestrictedRoute
              layout={WithHeaderMenu}
              logo={Logo}
              title={__("demand_assesment")}
              exact
              path={`${__route("c_da")}/:profileUuid/:portfolioUuid`}
              render={(props) => {
                return <NewDA national_identity_number={reduxprops} isCompany updateAlerts={reduxprops.updateAlerts} removeAlert={reduxprops.removeAlert} {...props} />;
              }}
              btnType="cancel"
              backBtn={__route("root")}
            />
            <RestrictedRoute
              layout={WithHeaderMenu}
              logo={Logo}
              title={__("demand_assesment")}
              exact
              path={`${__route("p_da")}/:profileUuid/:portfolioUuid`}
              render={(props) => {
                return <NewDA national_identity_number={reduxprops.national_identity_number} updateAlerts={reduxprops.updateAlerts} removeAlert={reduxprops.removeAlert} {...props} />;
              }}
              btnType="cancel"
              backBtn={__route("root")}
            />
            <RestrictedRoute
              layout={WithHeaderMenu}
              logo={Logo}
              title={__("demand_assesment")}
              exact
              path={`${__route("p_da_so")}/:profileUuid/:portfolioUuid`}
              component={NewDASignOff}
              btnType="cancel"
              backBtn={__route("root")}
            />
            <WithHeaderMenu
              noFooter
              logo={Logo}
              title={__("demand_assesment")}
              exact
              path={`${__route("puppeteer_signoff")}/:profileUuid/:portfolioUuid`}
              component={NewDASignOffPDF}
              btnType="cancel"
              backBtn={__route("root")}
            />
            <RestrictedRoute
              layout={WithHeaderMenu}
              logo={Logo}
              title={__("demand_assesment")}
              exact
              path={`${__route("da_so_updated")}/:profileUuid/:portfolioUuid`}
              component={NewDASignOffUpdatedSummary}
              btnType="cancel"
              backBtn={__route("root")}
            />
            <RestrictedRoute
              layout={WithHeaderMenu}
              logo={Logo}
              title={__("demand_assesment")}
              exact
              path={`${__route("da_so_init")}/:profileUuid/:portfolioUuid`}
              component={NewDASignOffInitSummary}
              btnType="cancel"
              backBtn={__route("root")}
            />
            <RestrictedRoute
              layout={WithHeaderMenu}
              logo={Logo}
              title={__("policy_application")}
              exact
              path={`${__route("p_kyc")}/:profileUuid/:portfolioUuid`}
              component={KYC}
              btnType="cancel"
              backBtn={__route("root")}
            />
            <RestrictedRoute
              layout={WithHeaderMenu}
              logo={Logo}
              title={__("policy_application")}
              exact
              path={`${__route("c_kyc")}/:profileUuid/:portfolioUuid`}
              render={(props) => {
                return <KYC isCompany {...props} />;
              }}
              btnType="cancel"
              backBtn={__route("root")}
            />
            <RestrictedRoute
              layout={WithHeaderMenu}
              logo={Logo}
              title={__("policy_application")}
              exact
              path={`${__route("p_ins")}/:profileUuid/:portfolioUuid`}
              component={InsuranceInformation}
              btnType="cancel"
              backBtn={__route("root")}
            />
            <WithHeaderMenu
              logo={Logo}
              title={`${__("company_customer")} - ${__(
                "customer_registration"
              )}`}
              exact
              path={__route("companySignup")}
              component={RegisterCompany}
              btnType="cancel"
              backBtn={__route("root")}
            />
            <RestrictedRoute
              layout={WithHeaderMenu}
              logo={Logo}
              title={__("policy_application")}
              exact
              path={`${__route("c_ins")}/:profileUuid/:portfolioUuid`}
              component={CompanyInsuranceInformation}
              btnType="cancel"
              backBtn={__route("root")}
            />
            <RestrictedRoute
              layout={WithHeaderMenu}
              logo={Logo}
              title={__("beneficial_owner")}
              exact
              path={`${__route("c_hm")}/:profileUuid/:portfolioUuid`}
              component={CompanyPersonnelKYC}
              btnType="cancel"
              backBtn={__route("root")}
            />
            <RestrictedRoute
              layout={WithHeaderMenu}
              title={__("sow")}
              exact
              path={`${__route("reg_sow")}/:profileUuid/:portfolioUuid`}
              component={SOW}
              btnType="cancel"
              backBtn={__route("root")}
            />
            <RestrictedRoute
              layout={WithHeaderMenu}
              title={__("sow")}
              exact
              path={`${__route("company_reg_sow")}/:profileUuid/:portfolioUuid`}
              render={props => <SOW {...props} isCompany />}
              btnType="cancel"
              backBtn={__route("root")}
            />
            <RestrictedRoute
              layout={WithHeaderMenu}
              title={`${__("sof")}`}
              exact
              path={`${__route("sof")}/:portfolioUuid/:type/premium/:sourceId?`}
              render={(props) => <NewSOF premium={true} {...props} />}
              btnType="cancel"
              backBtn={__route("deposit")}
            />
            <RestrictedRoute
              layout={WithHeaderMenu}
              title={`${__("sof")}`}
              exact
              path={`${__route("reg_sof")}/:profileUuid/:portfolioUuid/:type/:sourceId?`}
              render={(props) => <NewSOF registration {...props} />}
              btnType="cancel"
              backBtn={__route("deposit")}
            />
            <RestrictedRoute
              layout={WithHeaderMenu}
              title={`${__("sof")}`}
              exact
              path={`${__route("reg_company_sof")}/:profileUuid/:portfolioUuid/:type/:sourceId?`}
              render={(props) => <NewSOF isCompany registration {...props} />}
              btnType="cancel"
              backBtn={__route("deposit")}
            />
            <RestrictedRoute
              layout={WithHeaderMenu}
              title={`${__("sof")}`}
              exact
              path={`${__route("sof")}/:portfolioUuid/:type`}
              component={NewSOF}
              btnType="cancel"
              backBtn={__route("deposit")}
            />

            {/* Signup company */}

            <RestrictedRoute
              layout={WithHeaderMenu}
              logo={Logo}
              exact
              path={__route("sofTriggerSuccess")}
              component={SofTriggerSuccess}
              btnType="cancel"
              backBtn={__route("root")}
            />

            <RestrictedRoute
              layout={WithHeaderMenu}
              logo={Logo}
              title={__("demand_assesment")}
              exact
              path={`${__route("c_da_so")}/:profileUuid/:portfolioUuid`}
              render={(props) => {
                return (<NewDASignOff isCompany {...props} />);
              }}
              btnType="cancel"
              backBtn={__route("root")}
            />
            <RestrictedRoute
              layout={WithHeaderMenu}
              logo={Logo}
              title={__("company_customer")}
              exact
              path={`${__route('c_bi')}/:profileUuid/:portfolioUuid`}
              component={CompanySignupBasicInfo}
              btnType="cancel"
              backBtn={__route("root")}
            />

            <RestrictedRoute
              layout={WithHeaderMenu}
              logo={Logo}
              title={__("company_customer")}
              exact
              path={__route("companySignupSuccess")}
              component={CompanySignupSuccess}
              btnType="cancel"
              backBtn={__route("root")}
            />

            <RestrictedRoute
              layout={WithHeaderMenu}
              title={__("test_knowledge")}
              exact
              path={`${__route("p_kt")}/:profileUuid/:portfolioUuid`}
              component={Knowledge}
              btnType="cancel"
              backBtn={__route("root")}
            />
            <RestrictedRoute
              layout={WithHeaderMenu}
              title={__("aptitude_assessment")}
              path={`${__route("investment_knowledge_test")}/:type/:id?`}
              component={InvestmentKnowledgeTest}
              btnType="cancel"
              backBtn={__route("root")}
            />
            <RestrictedRoute
              layout={WithHeaderMenu}
              title={__("aptitude_assessment")}
              exact
              path={`${__route("intro_investment_knowledge_test")}/:type/:id?`}
              component={InvestmentKnowledgeTestIntro}
              btnType="cancel"
              backBtn={__route("root")}
            />
            <RestrictedRoute
              layout={WithHeaderMenu}
              exact
              title={__("aptitude_assessment")}
              path={`${__route("result_investment_knowledge_test")}/:type/:correct/:total/:id?`}
              component={InvestmentKnowledgeTestResult}
              btnType="cancel"
              backBtn={__route("root")}
            />
            <RestrictedRoute
              layout={WithHeaderMenu}
              title={__("test_knowledge")}
              exact
              path={`${__route("c_kt")}/:profileUuid/:portfolioUuid`}
              render={(props) => (<Knowledge isCompany {...props} />)}
              btnType="cancel"
              backBtn={__route("root")}
            />
            <RestrictedRoute
              layout={WithHeaderMenu}
              title={__("test_knowledge")}
              exact
              path={__route("companyKnowledgeTestResult") + "/:correct/:answers/:profileUuid/:portfolioUuid"}
              render={(props) => (
                <KnowledgeResult
                  correct={props.match.params.correct}
                  answers={props.match.params.answers}
                  isCompany
                  {...props}
                />
              )}
            />
            <RestrictedRoute
              layout={WithHeaderMenu}
              title={__("test_knowledge")}
              exact
              path={
                __route("knowledgeTestResult_correct_answer") +
                "/:correct/:answers/:profileUuid/:portfolioUuid"
              }
              render={(props) => (
                <KnowledgeResult
                  withdrawal
                  correct={props.match.params.correct}
                  answers={props.match.params.answers}
                  {...props}
                />
              )}
            />
            <RestrictedRoute
              layout={WithHeaderMenu}
              title={__("aftersale")}
              exact
              path={`${__route("p_aftersale")}/:profileUuid/:portfolioUuid`}
              btnType="logout"
              component={AfterSale}
            />
            <RestrictedRoute
              layout={WithHeaderMenu}
              title={__("aftersale")}
              exact
              path={`${__route("p_aftersale_preview")}/:profileUuid/:portfolioUuid`}
              component={PreviewAfterSale}
            />
            <RestrictedRoute
              layout={WithHeaderMenu}
              title={__("aftersale")}
              exact
              path={`${__route("c_aftersale_preview")}/:profileUuid/:portfolioUuid`}
              render={(props) => <PreviewAfterSale {...props} isCompany />}
            />
            <RestrictedRoute
              layout={WithHeaderMenu}
              title={__("test_terms")}
              exact
              path={`${__route("p_terms")}/:profileUuid/:portfolioUuid`}
              component={Terms}
              btnType="cancel"
              backBtn={__route("root")}
            />
            <RestrictedRoute
              layout={WithHeaderMenu}
              title={__("test_terms")}
              exact
              path={`${__route("c_terms")}/:profileUuid/:portfolioUuid`}
              component={CompanyTerms}
              btnType="cancel"
              backBtn={__route("root")}
            />
            <RestrictedRoute
              layout={WithHeaderMenu}
              title={__("aftersale")}
              exact
              path={`${__route("c_aftersale")}/:profileUuid/:portfolioUuid`}
              component={CompAfterSale}
              btnType="logout"
            />

            <ReactRoute exact path="/loading" component={LoadingPage} />

            <WithHeaderMenu logo={Logo} title="404" component={NoMatch} />
          </Switch>
        </ReportContext>
      </Router>
    </>
  );
};

function mapStateToProps(state) {
  const tags = state.auth.User.tags;
  const TERMSdone = tags ? tags.TERMSdone : false;

  return {
    authReference: state.auth.User.signicatProcessId,
    TERMSdone,
    token: state.auth.User.authToken,
    tokenExpires: state.auth.User.tokenExpires,
    national_identity_number: state.auth.User.national_identity_number,
    private_persons: state.auth.User.private_persons,
    signatories: state.auth.User.signatories,
  };
}

export default connect(mapStateToProps, {
  refreshToken: authOperations.refreshToken,
  logoutUser: authOperations.logoutUser,
  clearUser: authOperations.clearUser,
  updateUser: authOperations.updateUser,
  updateAlerts,
  removeAlert,
})(Routes);
