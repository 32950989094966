import React from 'react';
import * as types from './types';
import axios from 'axios';
import { createReducer } from 'states/utilities/independent';
import { combineReducers } from 'redux';
import LoadingBar from 'components/LoadingIndicators';
import store from '../../store';
import { getCurrency } from 'helpers';
import middleman from '../../../server';

// import { format } from 'util';
// BEGIN REDUCERS
/* State shape (Not actual data. This would be a good place to display example data format. Do that please.)
{
    details: product,
    list: [ product ],
}
*/

const getToken = () => {
  return store().getState().auth.User.authToken;
};

export const autoGiroStatuses = {
  'Directdebit-waiting,': 'waiting',
  'Directdebit-waitingMarkets,': 'waiting', // Markets portfolio waiting autogiro mandate request
  'Directdebit-waitingLife,': 'waiting', // Life portfolio waiting autogiro mandate request
  'Directdebit-pending,': 'waiting', // Mandate request in progress
  'Directdebit-approved,': 'approved', // Autogiro up and running
  'Directdebit-error,': 'error', // Error in autogiro mandate request
  'Directdebit-rejected': 'error', // Autogiro mandate rejected
  'Directdebit-cancelled,': 'cancelled', // Autogiro cancelled - The user has changed payment option. The mandate is still in use.
  '': false, //if user hasnt signed up for autogiro
};

const userAccountsInitialState = (id) => {
  return {
    // Old portfolio values
    External_ID: 'laddar',
    Name: <LoadingBar medium />,
    Portfolio_ID: <LoadingBar />,
    Reporting_currency: '',
    Tags: '',
    Type: 'INV',
    report_date: '2018-04-02T22:00:00+0000',
    ytd: 0,
    // New
    name: 'laddar',
    short_name: <LoadingBar />,
    currency: '',
    fa_connection: {
      tags: '',
      fa_db_id: ''
    },
    account_balance: <LoadingBar medium />,
    available_balance: <LoadingBar medium />,
    id,
    market_value: <LoadingBar />,
    start_date: '',
    placeholder: true,
  };
};

const formatAccount = (account) => {
  account.autogiroStatus = autoGiroStatuses[account.autogiro];
  return account;
};

const filterByType = (accounts) => {
  const allowedTyped = ['INV', 'INS']; // not allowed: EXTERNAL
  return accounts.filter(account => {
    return allowedTyped.includes( account.type_code );
  });
};

// Gets all user accounts (portfolios) - returns an array
export const userAccountsReducerInitialState = [userAccountsInitialState(false), userAccountsInitialState(false)];
export const userAccountsReducer = createReducer(userAccountsReducerInitialState)({
  [types.GET]: (state, action) => {
    if (!action.payload || action.error) { return userAccountsReducerInitialState; }
    const accounts = filterByType(action.payload.data);
    return accounts
      .map(account => formatAccount(account));
  }
});
// Gets all user accounts (portfolios) - returns an object
export const userAccountsCollection = createReducer({ 'placeholder': userAccountsInitialState('placeholder') })({
  [types.GET]: (state, action) => {
    if (!action.payload || action.error) { return { 'placeholder': userAccountsInitialState('placeholder') }; }
    const accounts = filterByType(action.payload.data);
    return accounts
      .reduce((previous, account) => {
        previous[account.id] = formatAccount(account);
        return previous;
      }, {});
  }
});

// Creates a summary of all accounts (portfolios)
export const userAccountsSummaryInitialState = <LoadingBar medium />;
export const userAccountsSummary = createReducer(userAccountsSummaryInitialState)({
  [types.GET]: (state, action) => {

    if (action.error) return userAccountsSummaryInitialState;
    // Filter out JOOL Life
    const summary = filterByType(action.payload.data)
      .reduce(function (sum, obj) {
        return sum + obj.market_value;
      }, 0);

    // const currency = action.payload.data[0].customer_account_currency;

    return (summary).formatMoney(2, ',', ' ', getCurrency());
  }
});

// Sets selected account from user accounts.
export const selectedAccount = createReducer(false)({
  // Set the first account to selected when fetching new account data
  [types.GET]: (state, action) => {
    if (!action.payload || action.error) return false;
    const accounts = filterByType(action.payload.data);
    if (accounts && accounts[0]) {
      return accounts[0].id;
    }
    return false;
  },
  [types.SELECT]: (state, action) => {
    return action.payload;
  }
});



export const accountStatuses = {
  init: 'userAccounts/INIT',
  polling: 'userAccounts/POLLING',
  success: 'userAccounts/SUCCESS',
  loading: 'userAccounts/LOADING',
  error: 'userAccounts/ERROR',
};

// Handle account/portfolio loading status from FA-API
export const statusReducer = createReducer(accountStatuses.init)({
  [types.GET]: (state, action) => {
    if (action.error) return accountStatuses.error;
    return accountStatuses.success;
  },
  [types.SELECT]: (state, action) => {
    if (action.error) return accountStatuses.error;
    return state;
  },
  [types.LOAD]: (state, action) => {
    if (action.error) return accountStatuses.error;
    return accountStatuses.loading;
  },
  [types.SETSTATUS]: (state, action) => {
    if (action.error) return accountStatuses.error;
    return action.payload.data;
  },
});

// Combine reducers for userAccounts
export const userAccountsReducerExport = combineReducers({
  Index: userAccountsReducer,
  Sum: userAccountsSummary,
  Collection: userAccountsCollection,
  Selected: selectedAccount,
  Status: statusReducer,
});

// -------------
// Begin actions
// -------------
export const graphPortfolios = () => {
  let token = getToken();
  var config = {
    headers: { 'Authorization': "Bearer " + token }
  };
  const res = axios.get(
    'http://localhost/api/v1/fa/graph/portfolios',
    config
  ).then(
    result => {
      console.log(result.data, 'is result');
    }
  );
  console.log(res, 'result');
  return res;
};

export const accountsRequest = () => {
  return middleman.get('/portfolios');
};

export const setAccountsStatus = (status) => {
  return {
    type: types.LOAD,
    payload: status
  };
};

export const fetchAccounts = () => {

  return (dispatch) => {
    dispatch(setAccountsStatus(accountStatuses.loading));
    const request = accountsRequest();
    return dispatch({
      type: types.GET,
      payload: request
    });

  };
};

export const setAccounts = (accounts) => (
  {
    type: types.GET,
    payload: accounts
  }
);


const changePayment = (formData) => {
  return (dispatch) => {
    const payload = middleman.post('/v1/fa/change/payment', { formData });
    dispatch({
      type: types.CHANGE,
      payload
    }).then(() => { 
      dispatch({
        type: types.LOAD,
        payload: accountStatuses.loading
      });
    }).then(() => {
      dispatch({
        type: types.GET,
        payload: accountsRequest()
      });
    });
  };
};

const selectAccount = (id) => {
  return {
    type: types.SELECT,
    payload: Number(id)
  };
};

const userAccountsOperations = {
  fetchAccounts,
  graphPortfolios,
  selectAccount,
  setAccountsStatus,
  changePayment
};
export {
  userAccountsOperations
};
export default userAccountsOperations;
