import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Media from 'react-media';
import __ from 'localisation';
import Button from '@hubins/components/Button';
import Icon from '@hubins/components/IconNew';
import { uiContext } from 'components/UiContext';
import { $, offsetScroll } from 'helpers';

class SidebarTrigger extends Component {
  constructor(props) {
    super(props);
    this.triggerButton = React.createRef();
  }

  componentWillUnmount() {
    // close sidebar if navigation to another page or remove it from dom
    this.props.questionOpen && this.props.toggleQuestionOpen();
  }
  render() {
    return (
      <uiContext.Consumer>
        {context =>
          <Media query={`(max-width: ${$.mq.sm_max})`}>
            {matches =>
              <Button
                innerRef={this.triggerButton}
                aria-controls={'sidebar_' + this.props.currentQuestionId}
                aria-expanded={this.props.questionOpen}
                type="button"
                onlyIcon
                className={`sidebartrigger small ${this.props.absolute ? 'button-icon--absolute' : ''} ${this.props.className ? this.props.className : ''}`}
                onFocus={() => {
                  context.updateState({ lastInput: this.triggerButton.current });
                }}
                onClick={
                  e => {
                    e.preventDefault();
                    if (!this.props.questionOpen) {
                    //closed
                      this.props.updateState({
                        'currentQuestionId': this.props.id,
                        'questionText': this.props.text,
                        'questionContent': this.props.content,
                        'lastInput': this.triggerButton.current
                      }, () => {
                        if (context.lastInput) {
                          if (matches) {
                            context.lastInput.scrollIntoView();
                            offsetScroll(context.lastInput, -50);
                          }
                          context.lastInput.focus();
                        }
                        this.props.toggleQuestionOpen();
                      });
                    }
                    else if (this.props.questionOpen && this.props.id === this.props.currentQuestionId) {
                    //open and id is the same
                      this.props.toggleQuestionOpen();
                    }
                    else {
                    //open and id is not the same
                      this.props.updateState({
                        'currentQuestionId': this.props.id,
                        'questionText': this.props.text,
                        'questionContent': this.props.content,
                        'lastInput': this.triggerButton.current
                      });
                    }
                    // Update the icon to be shown in the sidebar
                    this.props.updateState({ 'questionIcon': this.props.icon });
                  }
                }>
                <Icon icon="Question Circle by Streamlinehq" color="primary" size="16" label={__('help')} />
              </Button>
            }
          </Media>
        }
      </uiContext.Consumer>
    );
  }
}

SidebarTrigger.propTypes = {
  id: PropTypes.string,
  content: PropTypes.element,
  icon: PropTypes.string,
  absolute: PropTypes.bool,
};

SidebarTrigger.defaultProps = {
  id: '0',
  text: '',
  content: React.createElement('div'),
  icon: 'Question Circle by Streamlinehq',
  absolute: true,
};

const ForwardContext = (props) => (
  <uiContext.Consumer>
    {context =>
      <SidebarTrigger
        {...props}
        toggleQuestionOpen={context.toggleQuestionOpen}
        questionOpen={context.questionOpen}
        updateState={context.updateState}
        currentQuestionId={context.currentQuestionId}
      />}
  </uiContext.Consumer>
);
export default ForwardContext;
