import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PreCacheImg from 'components/PreCacheImg';
import './index.scss';

const Illustration = ({ src, className, alt, bg = '#02064C' }) => {
  const [imgLoaded, setImgLoaded] = useState(false);
  return (
    <>
      <PreCacheImg
        images={[src]}
        callback={[setImgLoaded]}
      />
      <div
        style={{ backgroundColor: bg }}
        className={`illustration s ${className} ${imgLoaded ? ' illustration--loaded' : '' }`}
      >
        <img
          width="600"
          height="600"
          src={src}
          alt={alt}
        />
      </div>
    </>
  );
};

Illustration.propTypes = {
  src: PropTypes.node.isRequired,
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
};

Illustration.defaultProps = {
  className: '',
};

export default Illustration;
