import React from 'react';
export const cookieCategories = [
  {
    title: "Nödvändiga cookies",
    id: "functional",
    disabled: true,
    description: <>
      <p>Detta är inga cookies, utan en lokal lagring som vår hemsida behöver för att fungera korrekt. Det innebär att de behövs för grundläggande funktioner, som inloggning, val av språk, transaktioner och grafer. Den här typen av lagring sparar inte dina personuppgifter. Det går inte att stänga av <em>Nödvändiga cookies</em> eftersom hemsidan då inte kommer att fungera.</p>
      <p>Så fort du loggar ut så tömms denna datan. Så sitter du på en offentlig dator, se till att komma ihåg att logga ut efter varje session.</p>
    </>,
    items: [
      { title: "language", description: "Ditt valda språk" },
      { title: "state_v2", description: "Tillfällig cachning (lagring) av data och appens state" },
      { title: "token", description: "Används för inlogg" },
      { title: "tokenExpires", description: "Används för inlogg" },
      { title: "consent_timestamp", description: "Sparar när du gjorde dina val för cookies" },
      { title: "consent_ux", description: <>Används för att spara valet om du vill ha kakorna för <em>Avancerad analys och målgrupp</em></> },
    ]
  },
  {
    title: "Avancerad analys och målgrupp",
    id: "ux",
    description: <p>Vi använder denna typ av cookies för att få bättre förståelse för hur besökaren upplever vår hemsida. Informationen som samlas in används för avancerad analys och målrelaterad annonsering. Hubins säljer aldrig data till tredje part utan den används bara för att förbättra besökarens användarupplevelse genom ett mer relevant innehåll.</p>,
    items: [
      {
        title: "Matomo",
        description: "Hubins använder sig av tjänsten Matomo för att samla data och skapa relevant och anpassad marknadsföring. Dessa cookies registrerar ett unikt anonymt id och samlar in statistik om hur du som besökare använder vår hemsida. De följer också användarna mellan enheter och marknadsföringskanaler, och samlar in data om användarnas enheter. Matomo lagrar ingen information som direkt kan identifiera en besökare, men den kan användas för att identifiera en besökare indirekt tillsammans med annan information Hubins har om dig."
      },
    ]
  },
];

// TODO: use cookie policy per application
export const cookieContent = <p>Läs hela vår Cookiepolicy <a href="https://hubins.com/cookies">här.</a></p>;
