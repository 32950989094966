import { appendValue } from 'helpers/formscms';

/**
 * Gets the field value in readable form, for frontend rendering
 * Returns an array with objects containing only "true" values.
 * Array is for unifying for multiple objects from select
 * @param {object} field CreateForm data field object
 * @param {bool} doNotFilter Set to true if you want to return all object, even if they not true. Used in initial state
 * @returns {array} with objects with values and stuff
 */

export const getFieldValue = field => {
  const { type, value, values, multiple, valueType } = field;

  switch (type) {
    case "input":
      return value;
    case "dropdown":
      const dropValue = values.find(option => option.value);
      if (dropValue && dropValue.title) return dropValue.title;
      return "";
    case "repeater":
      return true;
    case "checkbox":
      return value ? "X" : "";
    case "slider":
      if (value && value.min && value.max) {
        return `${value.min}${valueType} – ${value.max}${valueType}`;
      }
      if (typeof value !== 'object' && (typeof value === 'string' || typeof value === 'number')) {
        return `${value} ${valueType}`;
      }
      return "";
    case "hidden":
      return value;
    case "select":
    case "tableselect":
      if (multiple) {
        return Object.keys(value)
          .filter(key => value[key].value)
          .map(key => value[key].title);
        //.join(', ');
      }
      // check if any value has a truthy value. ifthe id is yes / no, return 0 or 1, else a value

      const trueObject = Object.keys(value).find(key => value[key].value);

      if (trueObject === "yes" || trueObject === "no")
        return trueObject === "yes" ? 1 : 0;
      return trueObject;
    default:
      return "";
  }
};

export const resetFieldValue = field => {
  switch (field.type) {
    case "input":
      field.value = "";
      break;
    case "checkbox":
      field.value = false;
      break;
    case "dropdown":
    case "select":
    case "tableselect":
      field.options = field.options.map(option => {
        option.value = false;
        return option;
      });

      if (field.value) {
        field.value = undefined;
      }
      break;
  }

  return field;
};


const getInitFIeldValue = (field) => {



  switch (field.type) {
    case 'info':
    case 'display':
      return 'info';
    case 'slider':
      if (field.singleValue) {
        return field.value;
      }
      if (
        typeof field.value === "object" &&
        field.value.max &&
        field.value.min
      ) {
        return field.value;
      }
      return {
        min: field.minValue,
        max: field.maxValue,
      };
    case 'repeater':
    case 'select':
    case 'tableselect':
    case 'dropdown':
      return field.options.reduce((value, option) => {
        value[option.id] = option;
        return value;
      }, {});
    default: return field.value;
  }
};

export const getInitialFormState = stateArray => {

  const repeaterFieldValue = (field) => {
    const numberOfFields = field.value.length;
    const newFields = [];
    for (let i = 1; i <= numberOfFields; i++) {
      field.fields.forEach((rField) => {
        const arrIndex = i - 1;
        const setValues = field.value[arrIndex];

        rField = appendValue({ ...rField }, setValues);

        let newField = {
          ...rField,
          ['id']: rField['id'] + '_' + i
        };

        newFields.push(newField);
      });
    }

    const fakeForm = [
      {
        id: 'section',
        fields: newFields
      }
    ];

    const repeaterState = getInitialFormState(fakeForm);

    return repeaterState;
  };

  let state = {};
  stateArray.forEach(section => {
    section.fields.forEach(field => {
      let fieldState = {};

      const visibleConditions = field.visibleConditions ? field.visibleConditions : false;
      const onChangeLogic = field.onChangeLogic ? field.onChangeLogic : false;
      const renderLogic = field.renderLogic ? field.renderLogic : false;
      const validation = field.validation ? field.validation : false;
      const value = field.type === 'repeater'
        // Needed to do it this way cos of declaration order and the need to recursive call this function
        ? repeaterFieldValue(field)
        : getInitFIeldValue(field);
      const error = "";
      const type = field.type;

      fieldState = {
        visibleConditions,
        onChangeLogic,
        renderLogic,
        validation,
        value,
        error,
        type,
      };

      if (
        field.type === "select" ||
        field.type === "tableselect"
      ) {
        fieldState.multiple = field.multiple;
      }

      if (field.type === "button") {
        fieldState.toggle = field.toggle;
      }

      if (field.type === "modal") {
        fieldState = { ...fieldState, open: false };
      }

      if ("expected" in field) {
        fieldState.expected = field.expected;
      }

      if (!field.id) {
        console.error("Field is missing an id", field);
      }

      state[field.id] = fieldState;
    });
  });

  return state;
};

export const mergeAllFiles = state => {
  return Object.keys(state).reduce((files, key) => {
    if (state[key].type === "file") {
      files = [...files, ...state[key].value];
    }
    return files;
  }, []);
};

export const filterFilesToUpload = (files) => (
  files.filter(file => file?.constructor?.name && file.constructor.name === 'File')
);

export const filterFormState = (id, newFormState) => {
  const stateKeys = Object.keys(newFormState);
  return stateKeys.reduce((filteredFormState, key) => {
    const field = newFormState[key];
    if (key.startsWith(id)) {
      filteredFormState[key] = field;
    }
    return filteredFormState;
  }, {});
};
