import React from 'react';
import PropTypes from "prop-types";

export default function Tooltip({ Element, ...props }) {
  if (!props["data-tooltip"]) return props.children;
  
  if ( Element) return <Element {...props} />;

  return (
    <span {...props} className={"tooltip " + props.className}  />
  );
}

Tooltip.propTypes = {
  Element: PropTypes.oneOfType([PropTypes.bool, PropTypes.node]),
  "data-tooltip": PropTypes.string.isRequired,
};
