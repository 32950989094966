// Usage:
/*
visible: false,
visibleConditions: [
  { key: 'test_conditonal_visibility_1', answer: ['yes'], value: true },
  { key: 'test_conditonal_visibility_2', answer: ['no'], value: true },
  { key: 'input_test', answer: 'alladin' }

  any: true means that this condition alone is enough to trigger this field
  { key: 'test_conditonal_visibility_3', answer: ['no'], value: true, any: true },

],
*/

const checkRuleSet = (conditions, dataValues) => {
  if (!conditions) return false;

  // returns a new array with correct answer
  // if new array.length === conditions.length = visible true
  let answersLength = 0;

  const matchedAnwsers = conditions.filter((item) => {

    answersLength += 1;

    const field = dataValues[item.key];

    if (!field) {
      console.error(`${item.key} does not excist in dataValues. Make sure the key in visibleConditionsid exsist as and id in the form data`);
      return;
    }

    // DEPRECATION SUPPORT, value should always be string
    const value = (typeof item.answer === 'string') ? item.answer : item.answer[0];

    if (!field.value[value]) {
      return;
    }

    if (field.value[value].value === item.value) {
      return true;
    }

    return false;

  });

  return answersLength !== 0 && matchedAnwsers.length === answersLength;
};

const checkRules = (rules, dataValues) => {
  if (!rules || rules.length === 0) return true;
  const correctRules = rules.filter((ruleSet) => {
    return checkRuleSet(ruleSet, dataValues);
  });
  return correctRules.length > 0;
};


export default checkRules;
