import React, { useState, useEffect } from 'react';
import middleman from '../../../server'

import __ui from 'localisation/hubins';
import __ from 'localisation/hubins/registration';

import { useFakeRoaring } from 'states/utilities';
import { LoadingBox } from 'components/LoadingIndicators';
import Main, { MainTitle } from 'components/Main';

import CreateForm from 'modules/CreateForm';
import { standard } from 'modules/CreateForm/FilterMethods';
import PreventUnload from 'modules/PreventUnload';

import { loadForm } from 'helpers/form';

export const Summary = ({
  company,
  updateUser,
  updateAlerts,
  removeAlert,
  submitMethods,
  filter,
  callback
}) => {

  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [form, setForm] = useState(false);

  const submit = async (filteredData) => {
    try {
      setLoading(true);
      const response = company
        ? await middleman.promisePost(
          `/v2/fa/register/quantum/company`,
          filteredData
        )
        : await middleman.promisePost(
          `/v2/fa/register/quantum/`,
          filteredData
        );
      updateUser(response.data);
      setSubmitted(true);
    } catch (e) {
      setError(true);
      setLoading(false);
    }

  };

  const methods = submitMethods ? submitMethods : submit;

  const companySubmit = async () => {
    console.log("Company Submit");
    try {
      const response = await middleman.promisePost('/v2/fa/register/company', {
        companyCode: company,
        isTest: useFakeRoaring()
      });
      updateUser(response.data);
      console.log("End of company Submit");
      callback();
    } catch (e) {
      setError(true);
      setLoading(false);
      setSubmitted(false);
    }
  };

  useEffect(() => {
    loadForm('p_roaring', 'register', setForm);
  }, []);

  useEffect(() => {
    if (submitted && company) {
      companySubmit();
    } else if (submitted) {
      callback();
    }
  }, [submitted]);


  useEffect(() => {
    if (error) {
      console.log('updating alerts');
      updateAlerts({
        id: 'signupFormsSummary',
        type: 'danger',
        closeButton: true,
        content: <>{__ui("standard_error_message", { email: __ui('platform_email'), tel: __ui('platform_tel') })}: {error}</>,
      });
    } else {
      removeAlert('signupFormsSummary');
    }
  }, [error]);

  return (
    <Main>
      {(!form || loading) ? (
        <LoadingBox />
      ) : (
        <>
          <PreventUnload />
          <MainTitle title={__(company ? 'c_summary_title' : 'summary_title')} />
          <CreateForm
            buttonStyle="wide cta"
            buttonProps={{ icon: "save" }}
            className="wrapper form wide"
            formData={form.content}
            submitType={methods}
            submitText={__ui('save_and_continue')}
            filter={filter ? filter : standard}
          />
        </>
      )}
    </Main>
  );
};

export default Summary;
