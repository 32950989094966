
export default {
  'select': {
    'reset': (args, id, formState, optionId) => {
      const field = { ...formState[id] };
      const selectedOption = field.value[optionId];
      const shouldResetValues = args.includes(optionId) && selectedOption.value;
      if (shouldResetValues) {
        Object.keys(field.value).forEach((key) => {
          if (key !== optionId) {
            const option = {
              ...field.value[key],
              value: false
            };
            field.value[key] = option;
          }
        });
      } else if (args.some(key => (field.value[key].value))) {
        args.forEach((arg) => {
          field.value[arg].value = false;
        });
      }
      formState[id] = field;
      return formState;
    },
  }
};
