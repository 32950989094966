import React, { useState, useEffect } from 'react';
import middleman from 'helpers/middleman';
import Button from '@hubins/components/Button';

const RedirectToRoycButton = ({className}) => {

  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState(null);

  useEffect(() => {
    if (redirect) {
      window.location.replace(redirect);
    }
  }, [redirect]);

  const doRedirect = async () => {
    setLoading(true);
    try {
      const redirectResponse = await middleman.get(`/v1/auth/saml-redirect-url`);
      const redirectUrl = redirectResponse.data;
      setRedirect(redirectUrl);
    } catch (err) {
      Bugsnag.notify(err);
      //console.error(err);
    }
    setLoading(false);
  };

  return (
    <Button
      className={`cta ${className}`}
      icon='arrow right 1'
      iconColor='white'
      onClick={doRedirect}
      loading={loading}
      disabled={loading}
    >
      Gå till ROYC
    </Button>
  )

};

export default RedirectToRoycButton;