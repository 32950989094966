import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { StandardValidation } from 'components/Validation';

class SelectDropdown extends Component {

  constructor(props){
    super(props);
    this.state = {
      value: 'value' in this.props ? this.props.value : ''
    };
    this.handleChange = this.handleChange.bind(this);
  }

  renderOptions(){

    return this.props.options(option => {

      return (
        <option disabled={option.value === 'false' ? 'disabled' : undefined} value={option.value}>{option.label}</option>
      );
    });
  }

  handleChange(e){
    const value = e.target.value;
    const error = StandardValidation('select', value, this.props.required);
    this.setState({
      value: value,
      error: error,
    });

    'onChange' in this.props && this.props.onChange(value, error, e);
  }

  componentDidUpdate(prevProps) {
    if(this.props.external && prevProps.value !== this.props.value) {
      this.setState({
        value: this.props.value
      });
    }
  }

  render() {

    const throwError = this.state.error || this.props.error;
    return (
      <label className={this.props.className}>
        <div className="label-wrapper">
          <div className="label">{this.props.label}</div>
        </div>
        <select
          required
          className="select"
          value={this.state.value}
          onChange={this.handleChange}
          aria-invalid={throwError ? true : false}
        >
          {this.props.children}
          {'options' in this.props && this.renderOptions()}
        </select>
        {(throwError) &&
          <div className="error_message__wrap error_message__wrap--visible">
            <span className="error_message" role="alert">
              {throwError}
            </span>
          </div>
        }
      </label>
    );

  }
}

SelectDropdown.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  options: PropTypes.array,
  label: PropTypes.string.isRequired,
  external: PropTypes.bool,
};

SelectDropdown.defaultProps = {
  external: false,
};

export default SelectDropdown;
