import React from 'react';
import { Route } from 'react-router-dom';
import LoggedinMenu from 'modules/Menu/LoggedinMenu';
import { Logo } from 'settings';
import ErrorBoundary from 'components/ErrorBoundary';
import __ from 'localisation';
import PageFooter from 'components/PageFooter';
import SkipToContentLink from 'components/SkipToContentLink';
import { authOperations } from '../../state/ducks/auth';
import { setAccounts } from '../../state/ducks/userAccounts';
import { connect } from 'react-redux';

const RouteDefault = ({ companies = [], private_persons = [], ...props }) => {

  return (
    <>
      <SkipToContentLink
        target="MainContent"
        text={__('skip_to_main_content')}
      />
      <ErrorBoundary>
        <LoggedinMenu
          name={props.name}
          path={props.path}
          logo={Logo}
        />
      </ErrorBoundary>
      <ErrorBoundary>
        <div className={`page-content ${props.stretch ? 'stretch' : ''}`}>
          <Route
            companies={companies}
            private_persons={private_persons}
            {...props}
          />
        </div>
      </ErrorBoundary>
      <PageFooter />
    </>
  );
};


function mapStateToProps(state) {

  return {
    name: state.auth.User.name,
  };
}

const mapDispatchToProps = {
  updateUser: authOperations.updateUser,
  setNewAccounts: setAccounts,
  // fetchMessages: messagesOperations.fetchMessages
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RouteDefault);
