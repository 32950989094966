/* eslint-disable react/display-name */
import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import Heading from 'components/Heading';
import SimpleTable from 'modules/Tables/SimpleTable';

export const HoldingsTable = ({ holdings, heading, columns, ...options }) => {
  return (
    <AnimatePresence key={options.type}>
      <motion.div className="s"
        initial={{ height: 0 }}
        animate={{ height: 'auto' }}
        exit={{ height: 0 }}
        key={heading + '_HoldingsTable'}
        transition={{ duration: 0.6 }}
      >
        <Heading size="4" className="s-top-xl s-bottom-m">{heading}</Heading>
        <SimpleTable
          data={holdings}
          headers={columns}
        />
      </motion.div>
    </AnimatePresence>
  );
};

export default HoldingsTable;
