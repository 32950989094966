import apiConnector from 'api-connector';
import apienv from './apienv';
export const middleman = apiConnector(apienv.base_url);

const uri = {
  abortBankId: '/bauth/abort',
  initAuthenticateBankId: '/bauth/init',
  statusAuthBankId: '/bauth/status',
};

export const middlemanTest = () => {
  const api = apiConnector(apienv.base_url);
  const abortBankId = () => { api.promisePost(uri.abortBankId); };

  const actions = {
    abortBankId,
    promisePost: api.promisePost
  };

  return actions;
};
export default middleman;
