import React from 'react';

import './index.scss';

export const CardHeader = (props) => {
  const { children, className = '', ...mods } = props;
  let modifiers = Object.keys(mods).map(prop => ' card__header--' + prop).join('');

  return(
    <div className={`card__header primary${modifiers} ${className}`}>
      {children}
    </div>
  );
};

export const CardContent = (props) => {
  const { children, className = '', ...mods } = props;
  let modifiers = Object.keys(mods).map(prop => ' card__content--' + prop).join('');

  return(
    <div className={`card__content${modifiers} ${className}`}>
      {children}
    </div>
  );
};

const Card = (props) => {
  const { children, className = '', ...mods } = props;
  let modifiers = Object.keys(mods).map(prop => ' card--' + prop).join('');

  return (
    <div className={`card ${modifiers} ${className}`}>
      {children}
    </div>
  );
};

export default Card;
