import React, { useState } from 'react';
import { Columns, Column } from 'components/Columns';
import Imgix from 'react-imgix';
import Heading from 'components/Heading';
import Button from '../Button';
import { getMoney } from 'helpers';
import './index.scss';
import Countdown from 'react-countdown';
import Badge from '../Badge';

const InvestmentCard = ({ msDiff, name, image, alt, tableData, caseUrl, investPageUrl, minimumInvestment, disabled }) => {

  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className='investment-card'>
      <Columns>
        <Column s="6" className="investment-card__hero">
          {image ? (
            <Imgix
              src={image}
              imgProps={{
                'alt': alt
              }}
              width={400}
              height={400}
              imgixParams={{ fit: "crop", auto: "format" }}
              sizes="(min-width: 768px) 16vw, 900vw"
            />
          ) : (
            <img src="/illustrations/investments_empty.svg" alt="" width={400}
            height={400} />
          )}

        </Column>
        <Column s="6" className="investment-card__content">
          <Badge color={isOpen ? "info" : "secondary"}>
            {isOpen ? 'Öppen' : (
              <Countdown
                date={Date.now() + msDiff}
                onComplete={() => setIsOpen(true)}
              />
            )}
          </Badge>
          <Heading size="3">
            {name}
          </Heading>
          {minimumInvestment && (
            <p>
              Minstainvestering <strong>{getMoney(minimumInvestment)}</strong>
            </p>
          )}
        </Column>
        <Column s="12">
          <div className='investment-card__table'>
            {tableData.map((item, index) => (
              <div className='investment-card__table__row' key={index}>
                <p>
                  <span className="investment-card__table__row--label">{item.label}</span>
                  <br />
                  <span className="investment-card__table__row--value">{item.value}</span>
                </p>
              </div>
            ))}
          </div>
        </Column>
        <Column s="12" className="investment-card__button-row">
          <Button
            className='cta'
            size="large"
            icon="arrow right 1"
            iconColor='white'
            href={isOpen && !disabled ? investPageUrl : caseUrl}
          >
            {isOpen && !disabled ? 'Investera nu' : "Läs mer" }
          </Button>
          {!disabled && (
            <Button
              inverted
              size="large"
              // icon="arrow right 1"
              href={isOpen ? caseUrl : investPageUrl}
            >
              {isOpen ? 'Läs mer' : 'Förbered investering'}
            </Button>
          )}
        </Column>
        {disabled && (
          <Column s="12">
            <p className="body-tiny">För att kunna lägga en order behöver du först teckna en försäkring.</p>
          </Column>
        )}
      </Columns>
    </div>
  )
};

export default InvestmentCard;