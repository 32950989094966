// README:
/*
TO add a new modules/component:
* Import it here in this file
* Create a const that you export in this file
* Check if you need some special optionState in CreateForm's function getInitialFormState()
* Add your component type name to HandleInputChange.jsx


*/

// This is a collection of functions needed to render Form Inputs
import React from 'react';
import FormSelect from 'components/FormSelect';
import FormDropdown from 'components/FormDropdown';
import Checkbox from 'components/Checkbox';
import FormInfo from '@hubins/components/FormInfo';
import ErrorBoundary from 'components/ErrorBoundary';
// import Input from 'components/Input';
import Input from '@hubins/components/Input';
import InputCurrency from '@hubins/components/Input/Currency';
import InputAutofill from 'components/InputAutofill';
import FormModal from 'modules/FormModal';
import MinMaxSlider from '@hubins/components/MinMaxSlider';
import Slider from '@hubins/components/Slider';
import FormRepeater from 'components/FormRepeater';
import SidebarTrigger from 'components/Sidebar/SidebarTrigger';
import Documents from 'components/Documents';
import { Column } from 'components/Columns';
import FileUpload from 'components/FileUpload';
import { formatMoney } from 'helpers';
import __ from 'localisation/hubins/registration';
import DateInput from '@hubins/components/DateInput';
import FormDocuments from '@hubins/components/FormDocuments/FormDocuments';

import Heading from 'components/Heading';
// import HiddenInput from 'components/HiddenInput';

// Optionsstate spread as props instead of single object // TODO:
const ColumnWrap = (props) => {

  const mediaOptions = {
    '1/3': { s: 12, m: 4, align: "end" },
    '1/2': { s: 12, m: 6, align: "end" },
    '1/1': { s: 12 }
  };

  const columnObj = props.media ? mediaOptions[props.media] : mediaOptions['1/1'];

  // Spacing will fallback to "true", beacuase it's undefined
  return (
    <Column key={"ColumnWrap_"} {...columnObj} >
      {props.children}
    </Column>
  );
};

/**
 * [all of the below functions]
 * @param  {obj} field [the manuallt assigned props from formData in CreateForm]
 * @param  {obj} optionsState [is set in getInitialFormState() in CreateForm.jsx]
 * @param  {Function} callback [description]
 * @param  {[type]} sectionId [description]
 * @return {[type]} [description]
 */

const hidden = () => (
  undefined
);

const select = (rerenderKey, field, optionsState, callback, sectionId) => (
  <ErrorBoundary key={'select_' + sectionId + field.id + rerenderKey}>
    <FormSelect {...field} rerenderKey={rerenderKey} optionsState={optionsState} callback={callback} partId={field.id} sectionId={sectionId} />
  </ErrorBoundary>
);

const dropdown = (rerenderKey, field, optionsState, callback, sectionId) => (
  <ErrorBoundary key={'dropdown_' + sectionId + field.id + rerenderKey}>
    <ColumnWrap media={field.media} spacing={field.spacing}>
      <FormDropdown {...field} optionsState={optionsState} callback={callback} partId={field.id} sectionId={sectionId} />
    </ColumnWrap>
  </ErrorBoundary>
);

const divider = (rerenderKey, field, sectionId) => (
  <ErrorBoundary key={'divider_' + sectionId + field.id + rerenderKey}>
    <ColumnWrap media={field.media} spacing={field.spacing}>
      <hr />
      {//<FormDropdown {...field} optionsState={optionsState} callback={callback} partId={field.id} sectionId={sectionId} />
      }
    </ColumnWrap>
  </ErrorBoundary>
);

const checkbox = (rerenderKey, field, optionsState, callback, sectionId) => (
  <ErrorBoundary key={'checkbox_' + sectionId + field.id + rerenderKey}>
    <ColumnWrap media={field.media} spacing={field.spacing}>
      <Checkbox {...field} optionsState={optionsState} createFormCallback={callback} partId={field.id} sectionId={sectionId} />
    </ColumnWrap>
  </ErrorBoundary>
);

const tooltip = (rerenderKey, field) => (
  <SidebarTrigger
    key={'tooltip_' + field.id + rerenderKey}
    id={field.id}
    icon="question"
    text={field.tooltip}
    className='button-icon--section'
  />
);

const subtitle = (rerenderKey, field) => (
  <ErrorBoundary key={'subtitle_' + field.id + rerenderKey}>
    <ColumnWrap media={field.media} spacing={field.spacing}>
      <Heading size={field.size || "4"} className={field.className}>
        {field.value}
        {field.tooltip && (
          <SidebarTrigger
            id={field.id}
            icon="question"
            text={field.tooltip}
          />
        )}
      </Heading>
      {'description' in field && (
        <p className="clarification">{field.description}</p>
      )}
    </ColumnWrap>
  </ErrorBoundary>
);

const info = (rerenderKey, field, optionsState) => (
  <ErrorBoundary key={'info_' + field.id + rerenderKey}>
    <ColumnWrap media={field.media} spacing={field.spacing || 's-top-md'}>
      <FormInfo {...field} optionsState={optionsState} />
    </ColumnWrap>
  </ErrorBoundary>
);



const input = (rerenderKey, field, optionsState, callback, sectionId) => {

  const standardProps = {
    ...field,
    type: field.inputType === "currency" ? "text" : (field.inputType || 'text'),
    value: field.value,
    validation: undefined,
    error: optionsState.error,
    callback: (value) => {
      callback(
        field.id,
        optionsState.validation,
        value,
      );
    },
  };

  return (
    <ErrorBoundary key={'input_' + sectionId + field.id + rerenderKey}>
      <ColumnWrap media={field.media} spacing={field.spacing}>
        {field.inputType && field.inputType === 'date' && !field.locked ? (
          <DateInput
            value={field.value}
            title={field.title}
            label={field.label}
            id={field.id}
            callback={
              (newVal) => {
                callback(field.id, optionsState.validation, newVal);
              }}
            error={optionsState.error}
          />
        ) :
          field.inputType && field.inputType === 'currency' && !field.locked ? (
            <InputCurrency  {...standardProps} />
          ) : (
            <Input {...standardProps} />
          )}
      </ColumnWrap>
    </ErrorBoundary>
  );

};

const inputautofill = (rerenderKey, field) => {
  const value = field.inputType && field.inputType === 'currency' ? formatMoney(field.value) : field.value; // without currency: formatMoney(field.value, ' ', 0)
  return (
    <ErrorBoundary key={'inputautofill_' + field.id + rerenderKey}>
      <ColumnWrap media={field.media} spacing={field.spacing}>
        <InputAutofill {...field} value={value} label={field.label} />
      </ColumnWrap>
    </ErrorBoundary>
  );
};

const repeater = (rerenderKey, field, optionsState, callback, sectionId) => (
  <ErrorBoundary key={'repeater_' + sectionId + field.id + rerenderKey}>
    <ColumnWrap media={field.media} spacing={field.spacing}>
      <FormRepeater {...field} optionsState={optionsState} callback={callback} partId={field.id} sectionId={sectionId} />
    </ColumnWrap>
  </ErrorBoundary>
);

const modal = (rerenderKey, field, optionsState, callback, sectionId) => (
  <ErrorBoundary key={'modal_' + sectionId + field.id + rerenderKey}>
    <FormModal {...field} callback={callback} open={optionsState.open} partId={field.id} sectionId={sectionId} />
  </ErrorBoundary>
);

const slider = (rerenderKey, field, optionsState, callback, sectionId) => {
  return (
    <ErrorBoundary key={'slider_' + sectionId + field.id + rerenderKey}>
      <ColumnWrap media={field.media} spacing={field.spacing}>
        {field.singleValue ? (
          <Slider
            id={field.id}
            step={field.interval}
            label={field.label}
            title={field.title}
            onChange={(value) => callback(field.id, value)}
            min={field.minValue}
            max={field.maxValue}
            value={field.value}
            suffix={field.valueType}
          />
        ) : (
          <MinMaxSlider
            id={field.id}
            step={field.interval}
            label={field.label}
            title={field.title}
            onChange={(value) => callback(field.id, value)}
            min={field.minValue}
            max={field.maxValue}
            value={field.value}
            suffix={field.valueType}
          />
        )}

      </ColumnWrap>
    </ErrorBoundary>
  );
};

const documents = (rerenderKey, field) => {
  return (
    <ErrorBoundary key={'documents_' + field.id}>
      <ColumnWrap media={field.media} spacing={field.spacing}>
        <Documents {...field} languageSupport={true} />
      </ColumnWrap>
    </ErrorBoundary>
  );
};

const file = (rerenderKey, field, optionsState, callback) => (
  <ErrorBoundary key={'file_' + field.id}>
    <ColumnWrap media={field.media} spacing={field.spacing}>
      <FileUpload
        className="maxwidth--center"
        optionsState={optionsState}
        accept="image/jpeg, image/png, image/gif, image/bmp, image/webp, application/pdf,"
        {...field}
        id={field.id}
        callback={callback}
        meta={__('fileupload_meta')}
      />
    </ColumnWrap>
  </ErrorBoundary>
);

const new_documents = (rerenderKey, field) => (
  <ErrorBoundary key={'new_documents_' + field.id}>
    <ColumnWrap media={field.media} spacing={field.spacing}>
      <FormDocuments files={field.files} title={field.title} />
    </ColumnWrap>
  </ErrorBoundary>
);






export default { select, divider, dropdown, checkbox, tooltip, info, input, inputautofill, subtitle, repeater, modal, slider, documents, hidden, file, new_documents };
