import React from 'react';
import Imgix from 'react-imgix';
import './index.scss';

const CaseDescription = (props) => {
  if (!props.logo && !props.text ) {
    return false;
  }
  return (
    <div className="case-description body-big">
      {props.logo &&
        <Imgix
          src={props.logo}
          alt="logo"
          className="case-description__logo"
          fit="clip"
          imgProps={{
            alt: props.alt
          }}
        />
      }
      <div className="case-description__text body-big" dangerouslySetInnerHTML={{ __html: props.text }}/>
    </div>
  );
};

export default CaseDescription;
