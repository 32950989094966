import React from 'react';
import { getMoney } from 'helpers';

const ResultTable = ({ data }) => {

  return (
    <section className='section-padding'>
      <table className="result-table numbers wide section-padding">
        <thead>
          <tr>
            <th className="result-table__header">
            </th>
            <th className="result-table__header">
                Hubins
            </th>
            <th className="result-table__header">
                Direktinvestering
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th className="result-table__header">
                Schablonskatt
            </th>
            <td className='result-table__content'>
              {getMoney(data[1].taxIns, "kr", 2)}
            </td>
            <td className='result-table__content'>
              {getMoney(data[0].taxDirInv, "kr", 2)}
            </td>
          </tr>
          <tr>
            <th className="result-table__header">
                Rörliga försäkringsavgifter
            </th>
            <td className='result-table__content'>
              {getMoney(data[1].feeIns, "kr", 2)}
            </td>
            <td className='result-table__content'>
              {getMoney(0, "kr", 2)}
            </td>
          </tr>
          <tr>
            <th className="result-table__header">
                Nettoavkastning
            </th>
            <td className='result-table__content'>
              {getMoney(data[1].netRetIns, "kr", 2)}
            </td>
            <td className='result-table__content'>
              {getMoney(data[0].netRetDir, "kr", 2)}
            </td>
          </tr>
        </tbody>
      </table>
    </section>
  );
};

export default ResultTable;
