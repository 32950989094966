// Unite Linked insureance vs Direct Investment
import React from 'react';
import Loadable from 'jaybe-react-loadable';
import PropTypes from 'prop-types';
import { HubinsLoadingAnimation as LoadingAnimation } from "components/Icon/HubinsLoadingAnimation";
import { gradientDefinitions } from 'components/Charts/ChartLine';
import { Colors } from 'settings';
import { formatMoney } from 'helpers';
import { __plural } from 'localisation';
import { Columns, Column } from 'components/Columns';

import { getValuesPerMonth } from "@hubins/calculations";

// export const INSURANCE_VARIABLE_FEE = 0.01; // TODO fetch from CRM
// export const INSURANCE_FIXED_FEE = 2100; // 1800, varav 300 är transatktionsavgift TODO fetch from CRM - Also different if the user wants a fixedFee or not
// export const YIELD_TAXATION = 0.3;

// staticValues
// export const STATIC_VALUES = {
//   INSURANCE_VARIABLE_FEE: 0.01,
//   INSURANCE_FIXED_FEE: 1800, // 1800, varav 300 är transatktionsavgift
//   TRANSACTION_FEE: 300,
//   YIELD_TAXATION: 0.3
// };

/**
 *
 * @param {number} amount - Planned size of policy during the next 12 month
 * @param {number} months - Then time period in months
 * @param {number} yearlyReturn - How much you will excpect to get in return each year
 * @param {object} staticValues - Fees and other static values
 * @param {function} setNoNeed - UseState function that takes a bool that triggers a noNeed modal
 * @returns {number} -
 */
export const generateData = (amount = 1000000, months = 12, yearlyReturn = 12, staticValues, setNoNeed) => {

  const data = getValuesPerMonth({
    initialValue: amount, // Size of investment, or initial planned investment
    numberOfMonths: months, //he number of months that you want to invest
    expectedReturn: yearlyReturn, //he return in percent the user expect to get each year
    riskPremium: staticValues && staticValues.RISK_PREMIUM || 0, //f the user is +65 years, add a risk premium
    fixedFee: staticValues ? (staticValues.INSURANCE_FIXED_FEE || 0) + (staticValues.TRANSACTION_FEE || 0) : 0, // fixed fee for the entire portofolio
    directInvestmentTax: staticValues.DIRECT_INVESTMENT_TAX,
    transactionFee: 0,
    variableFee: staticValues && staticValues.INSURANCE_VARIABLE_FEE || 0.01, // variable fee for the entire portofolio
  });

  let graphArray = [];

  const showYear = months > 25;
  // 12 24 36
  // If up to 24 months, samling rate should be the number of months,
  // if up to 10 years, it should be every quarter
  // else it should be on per year
  const samplingRate = months <= 24 ? 1 : 12; // Always have 12 samples

  for (let i = 0; i < data.length; i++) {
    // only calculate 12 samples in total, regardles of the number of months
    if (i % samplingRate !== 0) continue;
    // const x = expectedReturnBeforeFees(amount, i, montlyRate);
    const y = data[i].returnAfterFees;
    const z = data[i].directInvestmentReturn;

    if ((z > y) && setNoNeed) setNoNeed(true);

    const nameValue = showYear ? Math.round(i / 12 * 100) / 100 : i;

    graphArray.push({
      name: showYear
        ? __plural('year_string', nameValue, { number: nameValue })
        : __plural('month_string_short', nameValue, { number: nameValue }),
      // x,
      y, // Nettoavkastning Kapitalförsäkring
      z, // Nettoavkastning Direktinvestering
    });
  }

  return graphArray;
};

// const exampleData = [
//   { name: '0 mån', x: 1009488.79, y: 1008185.05, z: 1006642.16 },
//   { name: '1 mån', x: 1009488.79, y: 1008185.05, z: 1006642.16 },
//   { name: '2 mån', x: 1019067.62, y: 1016443.59, z: 1013347.34 },
//   { name: '12 mån', x: 1120000.00, y: 1103213.06, z: 1084000.00 },
// ];

export const CustomTooltip = (props) => {
  if (!props.label || !props.payload[0]) return null;
  let payload = props.payload;
  if (props.barChart) payload = payload.reverse();
  return (
    <div className="chart-line__tooltip">
      <p className="chart-line__tooltiplabel body-tiny">{props.label}</p>
      {payload.map((tooltip, index) => {
        if (props.barChart && tooltip.value === 0) return;
        return (
          //<strong className="c-white body-tiny">{tooltip.name}</strong><br/>
          <span key={index} className="chart-line__tooltipvalue" style={{
            background: props.barChart ? tooltip.stroke : tooltip.color,
            color: props.textColors ? props.textColors[index] : '#fff'
          }}>{formatMoney(tooltip.value)}</span>
        );
      })}
    </div>
  );
};

// Usage: <CustomLegend name="NettoAvkastning direktinvestering" value={formatMoney(103869.44)} />
export const CustomLegend = ({ name, value, ...svg }) => (
  <div>

    <svg width="14" height="4" viewBox="0 0 14 4" fill="none" xmlns="http://www.w3.org/2000/svg"
      // style={{ display: 'block', outline: "1px solid " + Colors.primary }}
      style={{ display: 'block' }}
    >
      <line y1="2" x2="14" y2="2" strokeWidth="4" {...svg} />
    </svg>

    <strong className="body-tiny s-top-sm">{name}</strong><br />
    <span className="clarification body-small">{value}</span>
  </div>
);

const StackedBarChartLoaded = ({
  Area,
  AreaChart,
  // LineChart,
  // Line,
  CartesianGrid,
  ResponsiveContainer,
  ReferenceDot,
  Tooltip,
  XAxis,
  YAxis,
  data,
  small,
  // amount, // The Planned size of policy during the next X month
  // months, // The time to compare the data for
  // yearlyReturn, // Expected yearly return
  // setNoNeed, // TODO: Set NO Need Modal when Y passes under Z
}) => {

  const legendMediaProps = small
    ? { s: '12' }
    : { s: '6' };

  return (
    <>
      <Columns>
        <Column s="12">
          <p className="chart-label">SEK</p>
          <div className="numbers chart-line chart-line--withlabels" style={{ height: small ? 250 : 500 }}>
            <ResponsiveContainer>
              <AreaChart
                data={data}
                margin={{ top: -1, right: -1, left: -1, bottom: -1 }}
              >

                {gradientDefinitions()}

                <CartesianGrid stroke={Colors.charlineTick} strokeDasharray="2 3" />
                <XAxis
                  dataKey="name"
                  minTickGap={2}
                  axisLine={false}
                  mirror={true}
                  ticks={['0 mån', '3 mån', '6 mån', '9 mån', '12 mån']}
                  tick={{ dy: -10, dx: -10, color: Colors.charlineTick }}
                  tickLine={false}
                  padding={{ left: 80, right: 14 }}
                >
                </XAxis>
                <YAxis
                  padding={{ top: 60, bottom: 60 }}
                  type="number"
                  domain={['dataMin', 'dataMax']}
                  // domain={[10000, 'dataMax + 10000']}
                  // tickCount={1}
                  // domain={[0, 150]}
                  allowDecimals={false}
                  axisLine={false}
                  mirror={true}
                  tickFormatter={yValue => formatMoney(yValue, ' ', 0)}
                  width={150}
                  scale="linear"
                  // tickFormatter={yValue => yValue - 100}
                  // unit="%"
                  tick={{ color: Colors.charlineTick }}
                  tickLine={false}
                >
                </YAxis>
                {/*<YAxis type="number" domain={['dataMin', 'dataMax']} />*/}
                <Tooltip
                  // offset={-40}
                  content={
                    <CustomTooltip
                      textColors={[
                        Colors.primary,
                        Colors.primary,
                        'white',
                      ]}
                    />
                  }
                />
                <ReferenceDot r={20} fill="red" stroke="none" />
                {/* <Area
                name="BruttoAvkastning"
                activeDot={{ stroke: Colors.info, color: Colors.info, fill: Colors.chartLineBg, strokeWidth: 2, r: 6 }}
                type='monotone'
                label={<span>Label</span>}
                dataKey="x"
                stroke={Colors.info}
                strokeDasharray="5 5"
                fillOpacity={0}
              /> */}
                <Area
                  name="Nettoavkastning Kapitalförsäkring"
                  activeDot={{ stroke: Colors.secondary, color: Colors.secondary, fill: Colors.chartLineBg, strokeWidth: 2, r: 6 }}
                  type='monotone'
                  label={() => <span>Label</span>}
                  dataKey="y"
                  stroke={Colors.secondary}
                  strokeWidth={4}
                  fillOpacity={1}
                  fill="url(#gradient_secondary)"
                />
                <Area
                  name="Nettoavkastning Direktinvestering"
                  activeDot={{ stroke: Colors.chartline4, color: Colors.chartline4, fill: Colors.chartLineBg, strokeWidth: 2, r: 6 }}
                  type='monotone'
                  label={() => <span>Label</span>}
                  dataKey="z"
                  stroke={Colors.chartline4}
                  strokeWidth={2}
                  // strokeDasharray="5 5"
                  fillOpacity={0}
                />

              </AreaChart>
            </ResponsiveContainer>
          </div>
          {!small && (
            <p className="chart-label center">Månader</p>
          )}
        </Column>
        {/* <Column {...legendMediaProps}>
        <CustomLegend
          name="Total värdeutveckling"
          value="Förväntad värdeutveckling före skatt och avgifter"
          stroke={Colors.info} strokeDasharray="4 4"
        />
      </Column> */}

        <Column {...legendMediaProps}>
          <CustomLegend
            name="Värdeutveckling Hubins"
            value="Förväntad värdeutveckling efter skatt och avgifter"
            stroke={Colors.secondary}
          />
        </Column>

        <Column {...legendMediaProps}>
          <CustomLegend
            name="Värdeutveckling direktinvestering"
            value="Förväntad värdeutveckling efter skatt (utan avgifter)"
            stroke={Colors.chartline4}
          />
        </Column>
      </Columns>
      <div className="hidden" id="chart-loaded-marker-one" />
    </>
  );
};

StackedBarChartLoaded.defaultProps = {
  amount: 1000000,
  months: 12,
  yearlyReturn: 12,
};

StackedBarChartLoaded.propTypes = {
  amount: PropTypes.number,
  months: PropTypes.number,
  yearlyReturn: PropTypes.number,
};

const StackedBarChart = Loadable({
  loader: () => import('recharts/es6' /* webpackChunkName: "recharts" */),
  loading: LoadingAnimation,
  timeout: 120000, // 2 min
  render(loaded, props) {

    return (
      <StackedBarChartLoaded {...props}
        AreaChart={loaded.AreaChart}
        Area={loaded.Area}
        // LineChart={loaded.LineChart}
        // Line={loaded.Line}
        XAxis={loaded.XAxis}
        YAxis={loaded.YAxis}
        CartesianGrid={loaded.CartesianGrid}
        Tooltip={loaded.Tooltip}
        ReferenceDot={loaded.ReferenceDot}
        ResponsiveContainer={loaded.ResponsiveContainer}
      />
    );
  },
});

export default StackedBarChart;
