import { getValuesPerMonth } from "@hubins/calculations";

export const __plural = (key, variable) => {
  const copy = {
    year_string: " år",
    year_string_plural: " år",
    month_string_short: "mån.",
    month_string_short_plural: " mån.",
  };
  return `${variable} ${copy[key + (variable > 1 ? "_plural" : "")]}`;
};

/**
 *
 * @param {number} amount - Planned size of policy during the next 12 month
 * @param {number} months - Then time period in months
 * @param {number} yearlyReturn - How much you will excpect to get in return each year
 * @param {object} staticValues - Fees and other static values
 * @param {function} setNoNeed - UseState function that takes a bool that triggers a noNeed modal
 * @returns {number} -
 */
export const generateData = (
  amount = 1000000,
  months = 12,
  yearlyReturn = 12,
  staticValues,
  setNoNeed
) => {
  const data = getValuesPerMonth({
    initialValue: amount, // Size of investment, or initial planned investment
    numberOfMonths: months, //he number of months that you want to invest
    expectedReturn: yearlyReturn, //he return in percent the user expect to get each year
    riskPremium: (staticValues && staticValues.RISK_PREMIUM) || 0, //f the user is +65 years, add a risk premium
    fixedFee: (staticValues && staticValues.INSURANCE_FIXED_FEE) || 0, // fixed fee for the entire portofolio
    transactionFee: (staticValues && staticValues.TRANSACTION_FEE) || 0,
    variableFee: (staticValues && staticValues.INSURANCE_VARIABLE_FEE) || 0.01, // variable fee for the entire portofolio
    feeBasedOnFirstMonth: (staticValues && staticValues.TAX_FOR_FUNDS) || 0,
    directInvestmentTax: staticValues && staticValues.DIRECT_INVESTMENT_TAX,
  });

  let graphArray = [];

  const showYear = months > 26;
  // 12 24 36
  // If up to 24 months, samling rate should be the number of months,
  // if up to 10 years, it should be every quarter
  // else it should be on per year
  const samplingRate = months <= 25 ? 1 : 12; // Always have 12 samples

  for (let i = 0; i < data.length; i++) {
    // only calculate 12 samples in total, regardles of the number of months
    if (i % samplingRate !== 0) continue;
    // const x = expectedReturnBeforeFees(amount, i, montlyRate);
    const hubins = data[i].returnAfterFees;
    const hubinsTax = data[i].prevUnitLinkTaxation;
    const hubinsFees = data[i].prevTotalFees;
    const direct = data[i].directInvestmentReturn;
    const directFees = data[i].directFees;

    if (direct > hubins && setNoNeed) setNoNeed(true);

    const nameValue = showYear ? Math.round((i / 12) * 100) / 100 : i;

    graphArray.push({
      name: showYear
        ? __plural("year_string", nameValue, { number: nameValue })
        : __plural("month_string_short", nameValue, { number: nameValue }),
      // x,
      hubins, // Nettoavkastning Kapitalförsäkring
      hubinsTax,
      hubinsFees,
      direct, // Nettoavkastning Direktinvestering
      directFees,
    });
  }

  return graphArray;
};
