import React from 'react';
import { useCookie } from 'components/CookieBanner/CookieContext';

const TrackingScriptsMatomo = () => {
  const { acceptsCookies } = useCookie();

  React.useEffect(() => {
    // SSR
    if (!document) return;

    if (typeof window._mtm !== 'undefined') {
      if (acceptsCookies.ux) {
        window._mtm.push({ 'consent_ux': true });
      } else {
        window._mtm.push({ 'consent_ux': false });
      }
    }

  }, [acceptsCookies]);

  return false;
};

export default TrackingScriptsMatomo;
