import React from 'react';

import './index.scss';


const Box = ({
  size = 'small',
  bg = 'white',
  className = '',
  bleed = false,
  children,
  borderRadius = true,
  ...props }) => {
  const boxClass = `box box--${size} ${bg} ${(bleed ? ' box--bleed' : '')} ${borderRadius ? 'box--radius' : ''} ${className}`;

  return (
    <div
      className={boxClass}
      {...props}
    >
      {children}
    </div>
  );
};

export default Box;
