import React from 'react';
import { Column, Columns } from 'components/Columns';
import Icon from '@hubins/components/IconNew';
import Button from '@hubins/components/Button';
import './index.scss';

const NotificationBanner = ({ text, icon, callback }) => {

  return (
    <div className='notification-banner'>
      <Columns>
        {icon && (
          <Column m="1" className="flex-center">
            <Icon
              icon={icon}
              size={24}
            />
          </Column>
        )}
        <Column m="9" className="flex flex-direction-row align-center">
          <p className='align-left'>{text}</p>
        </Column>
        <Column m="2" className="flex flex-center flex-direction-row align-center">
          <Button
            onClick={callback}
            icon="remove"
          >
            Stäng
          </Button>
        </Column>
      </Columns>
    </div>
  );
};

export default NotificationBanner;