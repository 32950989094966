// README:
/*
TO add a new modules/component:
* Import it here in this file
* Create a const that you export in this file
* Check if you need some special optionState in CreateForm's function getInitialFormState()
* Add your component type name to HandleInputChange.jsx


*/

// This is a collection of functions needed to render Form Inputs
import React from 'react';
import __ from 'localisation/hubins/registration';
import FormSelect from 'components/FormSelect/Compact';
import Textarea from 'components/Textarea';
import Input from '@hubins/components/Input';
import InputCurrency from '@hubins/components/Input/Currency';
import FileUpload from 'components/FileUpload';
import Icon from '@hubins/components/IconNew';

import ErrorBoundary from 'components/ErrorBoundary';

const ColumnWrap = (props) => {

  const depth = props.visibleConditions && props.visibleConditions.length > 0;

  return (
    <div className={`wide ${depth ? "neutral-2" : ''} flex flex-direction-row`}>
      {depth && (
        <div className="flex-center basic-padding-small">
          <Icon icon="arrow-down-right" size="small" spacing="right" />
        </div>
      )}
      <div className="wide">
        {props.children}
      </div>
    </div>
  );
};

/**
 * [all of the below functions]
 * @param  {obj} field [the manuallt assigned props from formData in CreateForm]
 * @param  {obj} optionsState [is set in getInitialFormState() in CreateForm.jsx]
 * @param  {Function} callback [description]
 * @param  {[type]} sectionId [description]
 * @return {[type]} [description]
 */

const hidden = () => (
  undefined
);

const input = (rerenderKey, field, optionsState, callback, sectionId) => {

  const standardProps = {
    ...field,
    type: field.inputType === "currency" ? "text" : (field.inputType || 'text'),
    value: field.value,
    validation: undefined,
    error: optionsState.error,
    callback: (value) => {
      callback(
        field.id,
        optionsState.validation,
        value,
      );
    },
  };
  return (
    <ErrorBoundary key={'input_' + sectionId + field.id + rerenderKey}>
      <ColumnWrap {...field}>
        {field.inputType && field.inputType === 'currency' && !field.locked ? (
          <InputCurrency {...standardProps} />
        ) : (
          <Input {...standardProps} />
        )}
      </ColumnWrap>
    </ErrorBoundary>
  );

};

const textarea = (rerenderKey, field, optionsState, callback, sectionId) => {

  return (
    <ErrorBoundary key={'input_' + sectionId + field.id + rerenderKey}>
      <ColumnWrap {...field}>
        <Textarea {...field} optionsState={optionsState} onChange={callback} partId={field.id} sectionId={sectionId} />
      </ColumnWrap>
    </ErrorBoundary>
  );

};

const select = (rerenderKey, field, optionsState, callback, sectionId) => (
  <ErrorBoundary key={'select_' + sectionId + field.id + rerenderKey}>
    <ColumnWrap {...field}>
      <FormSelect {...field} rerenderKey={rerenderKey} optionsState={optionsState} callback={callback} partId={field.id} sectionId={sectionId} />
    </ColumnWrap>
  </ErrorBoundary>
);

const file = (rerenderKey, field, optionsState, callback) => (
  <ErrorBoundary key={'file_' + field.id}>
    <ColumnWrap media={field.media} spacing={field.spacing}>
      <FileUpload
        className="maxwidth--center"
        optionsState={optionsState}
        accept="image/jpeg, image/png, image/gif, image/bmp, image/webp, application/pdf,"
        {...field}
        id={field.id}
        callback={callback}
        meta={__('fileupload_meta')}
      />
    </ColumnWrap>
  </ErrorBoundary>
);







export default { select, input, textarea, hidden, file };
