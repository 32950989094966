import React, { useState } from 'react';
import Wrapper from '@hubins/components/Wrapper';
import Terms from './Terms';
import Roaring from './Roaring';
import { useCallback } from 'react';


const Signup = () => {

  const [view, setView] = useState('terms'); // loading, terms or roaring
  const [formResponse, setFormResponse] = useState(false);

  const renderView = useCallback(() => {
    switch(view) {
      case 'terms':
        return (
          <Terms
            setView={setView}
            setFormResponse={setFormResponse}
            formResponse={formResponse}
          />
        );
      case 'roaring':
        return (
          <Roaring
            formResponse={formResponse}
          />
        );
    }
  }, [view]);

  return (
    <Wrapper>
      {renderView()}
    </Wrapper>
  );

};



export default Signup;
