import React from 'react';
import Wrapper from '@hubins/components/Wrapper';
import Heading from 'components/Heading';
import { Column, Columns } from 'components/Columns';

const NoPortfolios = () => {

  return (
    <Wrapper>
      <Columns reverseM>
        <Column m="6">
          <img src="/illustrations/empty_state.svg" alt="empty state" />
        </Column>
        <Column m="6">
          <Heading size={2}>
            Du har inga portföljer.
          </Heading>
          <p>Vänligen slutför registreringen för att öppna en portfölj.</p>
        </Column>
      </Columns>
    </Wrapper>
  );

};

export default NoPortfolios;
