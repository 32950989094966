import React from 'react';

export const step2 = (isHighRisk, hasNegativeSources) => {

  const steps = [
    {
      id: 'Step2AvailableAmount',
      target: ".sof-summary",
      title: 'Du har inte tillräckligt med tillgängligt belopp för din insättning',
      content: isHighRisk
        ? 'Din kundklassificering gör att du endast kan använda bevisade källor. För att få tillräckligt med tillgängligt belopp behöver du även bevisa de redan använda källorna.'
        : 'Du behöver lägga till nya källor eller uppdatera befintliga källor för att få tillräckligt med tillgängligt belopp.',
      disableBeacon: true,
    }
  ];

  if (isHighRisk) {

    steps.push({
      id: 'Step2ProveSources',
      target: '#tutorial-example-wrapper',
      title: 'Du behöver ladda upp bevis på samtliga källor som du vill använda',
      content: 'För att använda källor framöver behöver du först bevisa dessa.',
      disableBeacon: true,
    });

    if (hasNegativeSources) {
      steps.push({
        id: 'Step2NegativeAmountModal',
        target: '.source-card--danger',
        title: 'Du behöver bevisa dina använda källor',
        content: 'Du behöver bevisa tidigare använda källor markerade med röd ram.',
        disableBeacon: true,
      });
    }
  }

  steps.push({
    id: 'Step2AddSource',
    target: '.add_source',
    title: 'Du kan även lägga till nya och uppdatera källor',
    content: 'För att få tillräckligt med tillgängligt belopp för insättningen kan du lägga till eller uppdatera källor men du kan även behöva ladda upp bevis för dessa.',
    disableBeacon: true,
  });

  if (isHighRisk) {
    steps.push({
      id: 'Step2Status',
      target: '#tutorial-example-status',
      title: 'Bevisen granskas och godkänns',
      content: <>När du har laddat upp bevis på en källa kommer statusen att ändras till <span className="c-info">“Granskning pågår”</span> och när bevisen har granskats och godkänts kommer statusen att ändras till <span className="c-success">“Godkänd”.</span> Detta leder till att du får tillgängligt belopp att använda till din insättning.</>,
      disableBeacon: true,
    });
  }

  steps.push({
    id: 'Step2OldSources',
    target: '.old_source',
    title: 'Tidigare angivna källor',
    content:
      <>
        <p>Här ser du den totala summan som har använts eller är tillgänglig från dina tidigare angivna källor till kapital. Du kan använda det tillgängliga beloppet för insättningar och även lägga till nya källor om det behövs.</p>
        <p>För att uppdatera eller bevisa en tidigare angiven källa, vänligen kontakta oss via <a href="tel:+46317971936">031-797 19 36</a> eller genom att maila <a href="mailto:kundservice@hubins.com">kundservice@hubins.com</a>.</p>
      </>
    ,
    disableBeacon: true,
  });

  if (isHighRisk) {
    steps.push({
      id: 'Step2ProceedHighRiskButton',
      target: "#tutorial-proceed-button",
      title: 'När knappen blir blå kan du gå vidare',
      content: 'När du har tillräckligt med bevisat tillgängligt belopp för din insättning kan du gå vidare med hjälp av nästaknappen.',
      disableBeacon: true,
    });
  } else {
    steps.push(
      {
        id: 'Step2ProceedButton',
        target: "#tutorial-proceed-button",
        title: 'När knappen blir blå kan du gå vidare',
        content: 'När du har tillräckligt med tillgängligt belopp för din insättning kan du gå vidare med hjälp av nästaknappen.',
        disableBeacon: true,
      },
    );
  }

  return steps;
};

export const step3 = () => {
  return [
    {
      id: 'Step3Amount',
      target: '.source-progress',
      title: 'Välj källor nedan och fyll beloppsmätaren',
      content: 'Du kan välja de källor som har tillgängliga belopp.',
      disableBeacon: true,
    },
    {
      id: 'Step3ProceedButton',
      target: "#tutorial-proceed-button",
      title: 'Gå vidare för att få inbetalningsinstruktioner',
      content: 'När du har fyllt beloppsmätare kan du gå vidare med hjälp av nästaknappen.',
      disableBeacon: true,
    }
  ];
};




// Examples

// [
//   {
//     id: 'Step2AvailableAmount',
//     target: ".sof-summary",
//     title: 'Du har inte tillräckligt med tillgängligt belopp för din insättning',
//     content: 'Du behöver lägga till nya källor eller uppdatera befintliga källor för att få tillräckligt med tillgängligt belopp.',
//     disableBeacon: true,
//   },
//   {
//     id: 'Step2ProceedButton',
//     target: "#tutorial-proceed-button",
//     title: 'När knappen blir blå kan du gå vidare',
//     content: 'När du har tillräckligt med tillgängligt belopp för din insättning kan du gå vidare med hjälp av nästa knappen.',
//     disableBeacon: true,
//   },
//   {
//     id: 'TutorialIntro',
//     target: '#MainContent',
//     title: 'Intro',
//     content: 'Här kan du lägga till, uppdatera och bevisa dina källor till kapital',
//     disableBeacon: true,
//   },
//   {
//     id: 'Steps',
//     target: '#tutorial-steps',
//     title: 'Steg',
//     content: 'Här kan du se vilka steg du behöver gå igenom för att slutföra din insättning',
//     disableBeacon: true,
//   },
//   {
//     id: 'CardWrapper',
//     target: '#tutorial-example-wrapper',
//     title: 'Detta är en källa',
//     content: 'Här kan du se information om din källa',
//     disableBeacon: true,
//   },
//   {
//     id: 'CardUsedAmount',
//     target: '#tutorial-example-used',
//     title: 'Använt belopp',
//     content: 'Här kan du se hur mycket av källan du har använt',
//     disableBeacon: true,
//   },
//   {
//     id: 'CardAvailableAmount',
//     target: '#tutorial-example-available',
//     title: 'Tillgängligt belopp',
//     content: 'Här kan du se hur mycket av källan som är tillgängligt',
//     disableBeacon: true,
//   },
//   {
//     id: 'CardStatus',
//     target: '#tutorial-example-status',
//     title: 'Status',
//     content: 'Här kan du se källans nuvarande status',
//     disableBeacon: true,
//   },
//   {
//     id: 'ProceedButton',
//     target: '#tutorial-proceed-button',
//     title: 'Gå vidare',
//     content: 'När ditt tillgängliga belopp för samtliga källor överstiger insättningen blir knappen blå och du kan gå vidare',
//     disableBeacon: true,
//   }
// ]
