import React from 'react';
import AuthDownloadLink from '../AuthDownloadLink/AuthDownloadLink';

type Props = {
  id: string,
  title: string,
}

const FormDocument = ({id, title}: Props) => {

  return (
    <AuthDownloadLink
      url={`/v1/file/terms/${id}/download`}
      filename={title}
    >
      {title}
    </AuthDownloadLink>
  )
};

export default FormDocument;