import { combineReducers } from "redux";
import { createReducer } from "states/utilities/independent";

import * as types from "./types";

import * as authTypes from "states/auth/types";
import * as messagesTypes from "./../messages/types";
import { userStatuses } from "states/utilities/statuses";
import __, { __route } from "localisation";
import ReactDOMServer from "react-dom/server";
import { getItem } from "states/localStorage";

/*
If you need to add a temporary emergency alert, add it to AlertHandler,
or anywhere else using updateAlerts. Ex:

```js
props.updateAlerts({
  id: "temp-alert",
  type: "info",
  title: "Till Quantum-kunder som fått brev om att registrera sig på Hubins.com",
  content: <p>Vi arbetar för fullt med att anpassa plattformen för just er. Tyvärr behöver vi fortfarande lite mer tid för att bli färdiga, men kom gärna tillbaka och titta igen om en stund så är förhoppningsvis allt klart. Har du några frågor kan du alltid ringa oss på <a href="tel:031-797 19 36">031-797 19 36</a> eller maila oss på <a href="mailto:kundservice@hubins.com">kundservice@hubins.com</a> så hjälper vi dig.</p>
});
```

And then afterward remove it like this:
```js
props.removeAlert('temp-alert');
```
*/

const createMessage = (id, content, type = "danger", title, link) => {
  const alert = {
    id,
    type,
    content,
    title,
    link,
  };

  if (typeof alert.content !== "string") {
    alert.content = ReactDOMServer.renderToStaticMarkup(alert.content);
  }

  return alert;
};

const handleError = (
  actionType,
  responsePayload,
  errorMessage = "Error detected."
) => {
  if (responsePayload.payload.data || !responsePayload.error) {
    return false;
  }

  const acceptedStatuses = [200, 201];
  const { status } = responsePayload.payload;

  if (acceptedStatuses.includes(status)) {
    return false;
  }
  return createMessage(actionType + "_error", errorMessage);
};

// BEGIN REDUCERS
export const alertReducer = createReducer({})({
  [authTypes.SETSTATUS]: (state, action) => {
    if (action.payload === userStatuses.login_error) {
      const alert = createMessage(["loginErrorAlert"], __("login_error_alert"));
      return { ...state, ["loginErrorAlert"]: alert };
    }
    return state;
  },

  // Show an signatory alert if not all signatorys has signed
  [authTypes.GET]: (state, action) => {
    if (
      action.payload &&
      action.payload.data &&
      action.payload.data.active_profile.fa_connection.contact_juridical ===
        "CO" &&
      action.payload.data.active_profile.signatories
    ) {
      const unsignedSignatories =
        action.payload.data.active_profile.signatories.filter((signatory) => {
          if (signatory.relation.signed_at) {
            return false;
          }
          return true;
        });
      if (unsignedSignatories.length > 0) {
        const alert = createMessage(
          ["notSignedSignatories"],
          __("not_all_signatories_signed", {
            link: __route("settingsBeneficials"),
          }),
          "info"
        );
        return { ...state, ["notSignedSignatories"]: alert };
      }
    }
    return state;
  },

  [messagesTypes.GET]: (state, action) => {
    if (
      action.payload &&
      action.payload.data &&
      action.payload.data.alerts &&
      action.payload.data.alerts.length
    ) {
      const newAlerts = {};
      action.payload.data.alerts.forEach((item) => {
        const { id, message, ...rest } = item.data;
        newAlerts[id] = {
          ...rest,
          content: message,
        };
      });

      return { ...state, ...newAlerts };
    }
    return state;
  },

  [authTypes.COLLECTDATA]: (state, action) => {
    const error = handleError(
      authTypes.COLLECTDATA,
      action,
      "Could not submit information."
    );
    if (!error) return state;

    return { ...state, [error.id]: error };
  },

  [authTypes.ABORT]: (state, action) => {
    const error = createMessage(["bankid-abort"], "Login abort failed");
    if (action.payload === false) {
      return { ...state, [error.id]: error };
    }
    return state;
  },

  [types.UPDATEALERTS]: (state, action) => {
    // Return if no id provided
    if (!action.payload.id) {
      return state;
    }

    // Never show this modal again after user click close button
    if (action.payload.dismiss_type === "save") {
      if (getItem(action.payload.id)) {
        const newState = { ...state };
        delete newState[action.payload.id];
        return newState;
      }
    }

    const oldAlert = state[action.payload.id];
    // Add new action to object
    const formatedAlert = {
      ...oldAlert,
      ...action.payload,
      content: action.payload.content
        ? ReactDOMServer.renderToStaticMarkup(action.payload.content)
        : oldAlert.content,
    };
    const newAlerts = { ...state, [action.payload.id]: formatedAlert };

    return newAlerts;
  },
  [types.REMOVEALERT]: (state, action) => {
    if (action.payload === "all") {
      return {};
    }
    if (!state[action.payload]) {
      return state;
    }
    const newAlerts = { ...state };
    delete newAlerts[action.payload];

    return newAlerts;
  },

  [types.REMOVEALLALERTS]: () => {
    return {};
  },
});

// export const downloadReducer = createReducer(false)({
//   [types.DOWNLOAD]: (state, action) => {
//     return action.payload.data;
//   }
// });

// Compbine reducers for Alerts
export const alertReducerExport = combineReducers({
  Alerts: alertReducer,
});

// -------------
// Begin actions
// -------------

// update the alerts array in store with the provided alert object
export const updateAlerts = (alert = false) => {
  return {
    type: types.UPDATEALERTS,
    payload: alert,
  };
};

// Remove the provided id from the alerts object
export const removeAlert = (id = false) => {
  return {
    type: types.REMOVEALERT,
    payload: id,
  };
};

// Remove the provided id from the alerts object
export const removeAllAlerts = () => {
  return {
    type: types.REMOVEALLALERTS,
  };
};

export const alertsOperations = {
  updateAlerts,
  removeAlert,
  removeAllAlerts,
};
