import React, { useCallback } from 'react';
import __ from 'localisation';
import LoadingBar from 'components/LoadingIndicators';
import SimpleTable from 'modules/Tables/SimpleTable';
import { getMoney } from 'helpers';

const AccountsTable = ({
  currency,
  isLoading,
  accounts,
}) => {

  const getData = useCallback(() => {

    const headers = [
      {
        Header: '',
        accessor: 'currency.name',
        minWidth: 40,
        width: 0,
        Cell: ({ row }) => (
          <span className={Number(row.original.balanceAccCurr) < 0 ? 'c-danger' : ''}>
            {Number(row.original.balanceAccCurr) < 0 ? __('debt_account') : __('liquid_account')} {row.original.currency.name}
          </span>)
      },
      {
        Header: '',
        accessor: 'balanceAccCurr',
        Cell: ({ row }) => (
          <span className={Number(row.original.balanceAccCurr) < 0 ? 'c-danger' : ''}>
            {getMoney(row.original.balanceAccCurr, row.original.currency.name)}
          </span>
        ),
        Footer: (isLoading) ? <LoadingBar /> : getMoney(accounts.accountBalance, currency)
      },
    ];

    const loadingObj = {
      balanceAccCurr: <LoadingBar />,
      currency: {
        name: <LoadingBar />
      }
    };

    const data = isLoading ? [
      loadingObj,
      loadingObj,
    ] : (accounts && accounts.accountItems) ? accounts.accountItems : [];

    return {
      headers,
      data
    };

  }, [currency, isLoading, accounts]);

  const { headers, data } = getData();

  return (
    <SimpleTable
      className="ReactTable--tight"
      headers={headers}
      data={data}
      footer
    />
  );

};

export default AccountsTable;
