import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useCollapse } from 'react-collapsed';
import IconNew from '@hubins/components/IconNew';
import IconMessage from 'components/Icon/IconMessage';
import LanguageMenu from 'components/LanguageMenu';
import { __route } from 'localisation';
import MenuRaw from './Menu';
import MenuTitle from './MenuTitle';
import './index.scss';

export const SubMenu = ({ isExpanded, path, paths }) => {

  const { getCollapseProps } = useCollapse({ isExpanded });

  return (
    <div {...getCollapseProps()} className="submenu">
      <div className='submenu__container'>
        {paths.map((item, index) => (
          <MenuLink
            key={`${item.path}-${index}`}
            to={item.path}
            label={item.label}
            path={path}
            className="submenu-item"
          />
        ))}
      </div>
    </div>
  );
};

export const MainMenu = ({ children, mobileMenuOpen, setMobileMenuOpen, rootUrl, ...props }) => {
  return (
    <div className={`menu__mainmenu ${mobileMenuOpen ? 'menu__mainmenu--open' : ''}`}>
      <div className='menu__mobilemenuheader'>
        <Link to={rootUrl} className="menu__logo">
          <props.logo />
        </Link>
        <button
          onClick={() => setMobileMenuOpen(false)}
          className={`
            menu__link
            menu__dropdownbutton
            menu__mobilebutton
          `}
        >
          Close
          <IconNew icon="remove" size={16} className="s-left-s" />
        </button>
      </div>
      {children}
    </div>
  );
};

const MenuLink = ({ label, to, onFocus, onBlur, onClick, path, color, inDropdown, className = '', main = false, childPaths = false, selected = false, external = false }) => {
  if (external) {
    return (
      <a
        className={`menu__link ${(path === to || (childPaths && childPaths.includes(path)) || selected) ? 'menu__link--active' : ''} ${main ? 'menu__main' : ''} ${color ? color : ''} ${inDropdown ? 'menu__link--inDropdown' : ''} ${className}`}
        target="_blank"
        rel="noopener noreferrer"
        href={to}
      >
        <span className="menu__label">{label}</span>
      </a>
    );
  }

  const TheElement = to ? Link : 'button';

  return (
    <TheElement
      onClick={onClick}
      onFocus={onFocus}
      onBlur={onBlur}
      to={to}
      className={`menu__link ${(path === to || (childPaths && childPaths.includes(path)) || selected) ? 'menu__link--active' : ''} ${main ? 'menu__main' : ''} ${color ? color : ''} ${inDropdown ? 'menu__link--inDropdown' : ''} ${className}`}
    >
      <span className="menu__label">{label}</span>
    </TheElement>
  );
};

const MenuDropdown = (props) => {

  const timeout = useRef(false);

  const [ isExpanded, setExpanded ] = useState(false);

  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });

  useEffect(() => {
    return () => {
      clearTimeout(timeout.current);
    };
  }, []);

  const handleClick = () => setExpanded(!isExpanded);

  const blurHandler = () => {
    timeout.current = setTimeout(() => {
      setExpanded(false);
    }, 100);
  };

  const focusHandler = () => {
    clearTimeout(timeout.current);
  };

  return (
    <div className="menu__dropdownwrap menu__seperator">
      <>
        <button
          onClick={() => props.setMobileMenuOpen(true)}
          className={`
          menu__link
          menu__dropdownbutton
          menu__mobilebutton
          s-right-m
          `}
        >
          Meny
          <IconNew icon="navigation-menu" size={16} className="s-left-s" />
        </button>
        <button
          {...getToggleProps({ onClick: handleClick })}
          className={`
            menu__link
            menu__dropdownbutton
            ${isExpanded ? 'menu__link--active' : ''}
          `}
          onBlur={blurHandler}
          onFocus={focusHandler}
        >
          <IconNew icon="user" size={16} className="s-right-s" />
          <span className="menu__dropdownbuttontext">
            {props.name}
          </span>
          <IconNew
            icon="arrow down 1"
            size={16}
            ariaHidden
            anim={isExpanded ? 'active rotate-cw-180' : ''}
            className="s-left-s s-right-m"
          />
        </button>
        <div
          {...getCollapseProps()}
          className={`menu__dropdown ${isExpanded && 'menu__dropdown--expanded'}`}>
          {/* <div className="menu__dropdowncontent"> */}
          <div className='menu__dropdown__container'>
            <span className="menu__dropdown-name">
              {props.name}
            </span>
            {props.links.map((link, index) => {

              if (link.hidden === true) {
                return false;
              }

              if (typeof link.market === "undefined") {
                return <MenuLink key={"ml" + index}
                  to={link.to}
                  label={link.label}
                  onClick={handleClick}
                  onBlur={blurHandler}
                  onFocus={focusHandler}
                  color={link.color}
                  inDropdown={true}
                />;
              }

              return <LanguageMenu key={index}
                onClick={handleClick}
                onBlur={blurHandler}
                onFocus={focusHandler}
              />;
            }
            )}
            {props.children}
          </div>
        </div>
      </>
    </div>
  );
};

const MenuMessage = ({ unread }) => (
  <Link className="menu__link menu__seperator" to={__route('messages')}>
    <IconMessage size="medium" unread={unread} />
  </Link>
);

const Menu = (props) => {
  return (
    <MenuRaw
      {...props}
      rootUrl={props.rootUrl}
    />
  );
};

export { Menu, MenuLink, MenuDropdown, MenuMessage, MenuTitle };
