import React, { useState } from 'react';


const Init = ({ Template, ...props }) => {
  const setsSelectedItem = (initProps) => {
    let selectedItem = false;
    initProps.options.forEach((item) => {
      if (!initProps.multiple && initProps.optionsState.value[item.id].value) {
        selectedItem = initProps.optionsState.value[item.id].id;
      }
    });
    return selectedItem;
  };

  const [selectedItem, setSelectedItem] = useState(setsSelectedItem(props));

  const handleChange = (partId, itemId, prevState, event) => {
    // For checkboxes
    if (props.multiple) {
      // props.callback is in HandleInputChange:181 (select)
      props.callback(partId, itemId, prevState, event, props.resetOnResetProp);
    }
    // For radiobuttons
    else {
      const radioPrevState = selectedItem;
      setSelectedItem(itemId);
      props.callback(partId, itemId, radioPrevState, event);
    }
  };

  return (<Template
    handleChange={handleChange}
    {...props}
  />);

};

export default Init;
