/* eslint-disable react/forbid-elements */
// SWEDISH localisation file for default keys

import React from 'react';

export const keys = (v = {}) => ({

  // Customized strings for different markets - these will override the default STRINGS
  MARKET_STRINGS: {
    // Sweden
    SE: {

    },

    // Norway
    NO: {

    },

    // Finland
    FI: {

    }
  },

  // Default strings
  STRINGS: {

    // Registration - Terms
    terms_conditions_title: 'Terms & Conditions',
    terms_conditions_subtitle: 'Please read and review our terms of use before proceeding with your application',

    terms_of_use_check: 'I confirm that I have read and accept the information in the above documents.',
    gdpr_check: 'I have read, understood and accept the processing of my personal data for the purposes set out in the Hubins Privacy Statement. I also understand and accept that Hubins may share my personal data with third parties in order to facilitate the creation of any client relationship between me and a third party that takes place at my initiative. I have read the contact information for both Hubins and the Data Protection Officer at the company.',
    presale_check: 'By using BankID to confirm my identity, I accept the terms and conditions and agree to Hubins sending information and communicating with me via my given email address',

    bankid_signin: 'Sign using Bank ID',
    accept_and_signin: 'Accept and sign in',

    // Registration - Summary (Submit basic info)
    summary_title: 'Private client registration',
    c_summary_title: 'Company client registration',
    // summary_subtitle: 'Hubins will request information from you in line with the legal requirements. By providing the information that Hubins need, a client relationship will be establieshed.',
    // summary_desc: '',

    reg_title: 'Client information',
    reg_desc: <p>We use a connection to <a href="https://www.roaring.io/sv/projects/gdpr-2/" target="_blank" rel="noopener noreferrer">roaring.io</a> to fetch records about you.</p>,
    country: 'County',

    // Registration company
    // company_registration_title: 'Registrering - Bolagskund',
    inv_title: 'Bolagsrepresentant',
    pre_company_roaring_info: <>Hubins använder sig av <a href="https://www.roaring.io/sv/projects/gdpr-2/" target="_blank" rel="noopener noreferrer">roaring.io</a> för att hämta information om företaget och dess representanter.</>,
    fetch_company_data: 'Hämta uppgifter om företaget',
    fetch_company_error: 'Vänligen kontrollera att organisationsnummret är korrekt, och försök igen',
    inv_info: '',
    beneficial_owners: 'Verkliga huvudpersoner',
    company_roaring_info: <>
      <p>Hubins använder sig av <a href="https://www.roaring.io/sv/projects/gdpr-2/" target="_blank" rel="noopener noreferrer">roaring.io</a> för att hämta hem information om dig. Vi har hämtat hem följande information om bolaget:</p>
      <p>Vänligen notera att nedan bolagspersoner kommer att få ett mail och behöva logga in på plattformen och signera en fullmakten samt svara på frågor i egenskap av att vara verkliga huvudmän.</p>
    </>,
    // Registration - Demand assesment - TODO: Remove 1., 1.2 etc
    demand_assesment: 'Krav- och Behovsprövning',
    da_subtitle: 'Before we can continue, Hubins needs to ascertain if you have a need of a unit-linked insurance. We will do this through a couple of questions regarding your need of a unit-linked policy, and your knowledge & experience of unit-linked insurance.',
    da_desc: <p>Hubins is only a distributor of unit-linked insurances from Quantum Leben AG. Quantum Leben AG is an insurance company based in Liechtenstein and are operating in Sweden through cross-border license from the <a href="https://fi.se/sv/vara-register/foretagsregistret/details?id=98348">Swedish Financial Supervisory Authority</a>. Hubins is only working with non-independent insurance-distribution. Hubins also does not work with any investment advice or offers.</p>,
    da_s2_title: 'How does the taxes and fees work in the insurance?',
    da_s2:
      <>
        <p>Please see below for an explanation regarding taxes and charges. The following charges applies to the insurance. You will also find below the compensation amount that is given to Hubins AB in its role as insurance-distributor.</p>
        <ul>
          <li><strong>Annual fixed charge:</strong> 1800 SEK a year. The annual fixed charge is the same regardless of the insurance value. The charge is deducted quarterly by 450 SEK.</li>
          <li><strong>Annual management charge:</strong> 1% a year. The annual management charge is based on the insurance value by the end of every month and is deducted quarterly.</li>
          <li><strong>Transaction charge: </strong> 300 SEK when buying, selling and withdrawals.</li>
          <li><strong>Premium charge:</strong> 0%</li>
          <li><strong>Risk charge: </strong> 0%</li>
        </ul>
        <p><strong>Other: </strong>Charges connected to the underlaying investments in the insurance and any other currency exchange fees may apply.</p>
        <p><strong>Binding period:</strong> The policy is running one year at a time, starting from the issue-date of the policy. If the policy is surrendered in advance, it will be required to pay for the remaining costs as well. There are no other specific surrender-charges. The costs will be drawn from the given account in the policy.</p>
        <h3 className="smallheading">When do fees start to run?</h3>
        <p>Fees will start the day after you make your first premium deposit and that premium has been approved by Quantum Leben.</p>
        <h3 className="smallheading">How much does Hubins receive in its role as insurance-distributor?</h3>
        <p>
          Hubins receives in its role as insurance-distributor 30% of the Annual management charge paid to Quantum Leben AG as compensation. The compensation is retrieved on a running basis under the duration of the policy, and is paid out on a quarterly basis as part of the quarterly fees.
          Hubins AB also has an agreement with Quantum Leben AG to assist the insurance company with a range of services, this includes but is not limited to customer service, sale support, insurance administration support, preparation for insurance case management, IT support etc.
          <strong>Hubins AB does the above-mentioned services for all of Quantum Leben AG&apos;s insurance policies in the Swedish and Norwegian markets, regardless of which company that is the distributor of the insurance.</strong>
        </p>
        <p>For the above-mentioned work does Hubins collects the following compensation for the insurances that Hubins also distributes. Please note that Hubins AB receives both smaller and larger parts of the compensation that is not distributed by Hubins.</p>
        <ul>
          <li><strong>Part of the annual fixed charge:</strong> 35 %</li>
          <li><strong>Part of the annual management charge:</strong>  61 %</li>
          <li><strong>Andel av transaction charge:</strong> 50 %</li>
        </ul>
        <p><strong>Example:</strong> If you as a client deposits a premium of 100 000 SEK into your policy the 1st of January year 1, and the value of the policy is unchanged during the year, then Hubins, will receive the following:</p>
        <h3 className="smallheading">Costs and charges</h3>
        <ul>
          <li><strong>Annual fixed charge:</strong> 1 800 SEK</li>
          <li><strong>Part to Hubins due to outsourced services:</strong> 1 100 SEK</li>
          <li><strong>Annual management chareg:</strong> 1 000 SEK</li>
          <li><strong>Part to Hubins due to insurance distribution:</strong> 300 SEK</li>
          <li><strong>Part to Hubins due to outsourced services:</strong> 350 SEK</li>
          <li><strong>Transacation charge:</strong> 300 SEK</li>
          <li><strong>Part to Hubins due to insurance distribution:</strong> 150 SEK</li>
          <li><strong>Total:</strong> 3 100 SEK</li>
        </ul>
        <p>In Liechtenstein, a so-called stamp duty (stämpelskatt) is paid on the deposits and withdrawals made in the insurance. The stamp duty is 0.15% of the amount. When premiums or withdrawals consist of securities, is the stamp duty instead 0.30% of the value. It may be possible to request a reduction of this tax against the Swedish tax on investment income. Please find more information regarding the stamp duty on the <a href="https://skatteverket.se/privat/skatter/forsakringar/avkastningsskattpautlandskalivforsakringar/nedsattavkastningsskatt.4.1a098b721295c544e1f800027816.html">website for Swedish Tax Agency.</a></p>
      </>,
    da_s3:
      <>
        <h3 className="smallheading">How is a unit-linked insurance taxed?</h3>
        <p>A unit-linked insurance is an investment instrument subject to a standard tax, which means that you as the client is paying a specific percentage in tax each year. The tax is based on the value of the policy instead of the yield, as in the case of a securities account.
        In a unit-linked insurance you pay a variant of a tax called yield tax, although it is not based on the yield of the policy.
        The tax of the policy is based on the total value per the 1/1 of each year, added with the premiums issued to the policy during the year. Premiums issued during the first 6 months are added to 100% of the value, and premiums issued during the last 6 moths are added to 50% of their value.
        The sum, the capital base, is multiplied with the sum of the governmental borrowing rate per the 30th November of the previous year and added 1%.
        The lower limit, the tax-floor, is set at 1,25%. If the government borrowing rate plus the 1% is lower than 1,25%, the standard tax-rate will be 1,25%.
        The cumulative amount is taxed with 30%.
        The dividends received in your policy will not be taxed at 30% as it would be done in a securities account, as the policy is subject to a standard tax. The dividends is instead included in the value of the policy.
          In Liechtenstein the premiums and withdrawals made to and from respectively are also subject to a stamp-tax. The tax-rate is 0,15% of the premium or withdrawal value. Any eventual investments transferred into the policy are taxed with a stamp-tax of 0,30%. Any paid stamp-tax can be deducted in your tax-declaration, see the link to Skatteverket: <a href="https://www4.skatteverket.se/rattsligvagledning/edition/2021.18/339082.html">https://www4.skatteverket.se/rattsligvagledning/edition/2021.18/339082.html</a>
          . Please observe, you have to declare the yield tax yourself in your income-declaration.</p>
      </>,

    da_6: 'Knowledge and Experience',
    da_6_1: 'Do you have or have you had a unit-linked insurance in the past?',
    da_6_2: 'Do you work in a industry or have an educations that has given you knowledge regarding how unit-linked insurance, insurance fees, and insurance taxation works?',

    why_does_this_matter: 'Why does this question matter?',

    da_1: 'Standard taxation (Defined as "Schablonsbeskattning" in Swedish)', // 1 Needs small info text under title
    da_1_info: <>
      <p><strong>A unit-linked insurance is an investment instrument subject to a standard tax, which means that you as the client is paying a specific percentage in tax each year. The tax is based on the value of the policy instead of the yield, as in the case of a securities account.</strong></p>
      <p>In a unit-linked insurance you pay a variant of a tax called yield tax, although it is not based on the yield of the policy.</p>
      <p>The tax of the policy is based on the total value per the 1/1 of each year, added with the premiums issued to the policy during the year. Premiums issued during the first 6 months are added to 100% of the value, and premiums issued during the last 6 moths are added to 50% of their value.</p>
      <p>The sum, the capital base, is multiplied with the sum of the governmental borrowing rate per the 30th November of the previous year and added 1%.</p>
      <p>The lower limit, the tax-floor, is set at 1,25%. If the government borrowing rate plus the 1% is lower than 1,25%, the standard tax-rate will be 1,25%.</p>
      <p>The cumulative amount is taxed with 30%.</p>
      <p>The dividends received in your policy will not be taxed at 30% as it would be done in a securities account, as the policy is subject to a standard tax. The dividends is instead included in the value of the policy.</p>
      <p>In Liechtenstein the premiums and withdrawals made to and from the insurance policy are also subject to a stamp duty. The tax-rate is 0,15% of the premium or withdrawal value. If the premiums are transfers of investments the stamp duty is instead 0,30%. You can apply to get the paid stamp duty deducted from the swedish standard tax in your tax-declaration, <a href="https://www4.skatteverket.se/rattsligvagledning/edition/2021.18/339082.html">see the link to Skatteverket</a></p>
      <p>Please observe, you have to declare the yield tax yourself in your income-declaration.</p>
    </>,
    da_1_1: 'Do you require an investment instrument that is taxed on the value, also known as a standard tax, and not taxed on the yield of your savings?', // 1.1
    da_1_2: 'Do you have a need to be able to make eventual loss deductions?', // 1.2
    da_1_3: 'Do you consider your need of an investment instrument with a standard tax to be greater than your need to make any eventual loss deductions?', // 1.3

    c_da_2: 'Onoterat innehav',
    da_2: 'Appointment of Beneficiary or Beneficiaries', // 2.
    da_2_info: 'When you sign up for an insurance you can choose to add one or more beneficiaries to your insurance. That means that if you as policy holder (and life-insured) will be subject to an insurance-event, the value of your policy will be transferred to your chosen beneficiary or beneficiaries. You may choose a beneficiary apart from the legal heirs. You may choose to make the insurance-payout private property. For example, you may choose to make your kids the beneficiaries to your policy, with the purpose that the payout in the event of an insurance-event will be treated as private property.',
    da_2_1: 'Do you have a need to control who will receive the payout in the occurrence of an insurance-event?', // 2.1
    da_2_2: 'Do you have a need to classify the payout in the occurrence of an insurance-event as private property?', // 2.2

    da_3: 'Type of risk on the available investments', // 3.
    da_3_info: 'In general, there is a relation between the return and the risk of an investment. If you are willing to take a higher risk, the expected return is higher, but, the potential for loss increases as well. Below you will find information regarding how the different risk-levels differentiate from each other.',
    da_3_q: 'Choose the average risk that you are willing to accept, as well as the risk you are willing to take on the investments you are intending to place through your unit-linked insurance:',
    risktable: 'Risktable',
    risk_no: `Risk ${v.risk}`,
    expected_return_yearly: 'Expected return, yearly',
    procent_from_to: `${v.from}% to ${v.to}%`,
    example_investment: 'Example investment:',
    scenario: 'Scenario',
    year_no: `Year ${v.no}`,
    stressed_scenario: 'Stressed scenario',
    positive_scenario: 'Positive scenario',
    neutral_scenario: 'Neutral scenario',
    negative_scenario: 'Negative scenario',
    risk_levels: 'Risk levels',
    da_3_example_1: 'Statsobligation / bankkonto med statlig insättningsgaranti',
    da_3_example_2: 'Statsobligation / bankkonto med statlig insättningsgaranti',
    da_3_example_3: 'Statsobligation / bankkonto med statlig insättningsgaranti',
    da_3_example_4: 'Statsobligation / bankkonto med statlig insättningsgaranti',
    da_3_example_5: 'Statsobligation / bankkonto med statlig insättningsgaranti',
    da_3_example_6: 'Statsobligation / bankkonto med statlig insättningsgaranti',
    da_3_example_7: 'Statsobligation / bankkonto med statlig insättningsgaranti',
    accepted_risk_table: 'If you consider the table below, what is your risk preference for your overall investment portfolio?',

    da_4: 'Type of Assets', // 4.
    da_4_info: 'The types of investments you intend to invest can determine if Hubins will distribute a unit-linked policy to you. In the unit-linked policies from Quantum Leben distributed by Hubins, you can invest in listed assets, such as: stocks, funds, structured products and listed bonds. The previously mentioned assets are possible to hold in an ordinary unit-linked policy. The advantage with a Quantum Leben unit-linked policy is its flexibility which enables the possibility to hold a wide range of assets, such as unlisted stocks, unlisted bond, and direct loans.',
    da_4_1: 'What types of assets are you planning to invest through your unit-linked insurance? Please note that Hubins take no responsibility or hold any information regarding the investment you make.', // 4.1
    da_4_1_desc: 'What types of assets are you planning to invest through your unit-linked insurance? Please note that Hubins take no responsibility or hold any information regarding the investment you make.', // 4.1
    da_4_1_a: 'Listed stocks',
    da_4_1_b: 'Funds',
    da_4_1_c: 'Structured products',
    da_4_1_d: 'Unlisted assets, such as bonds, stock or direct loans',

    da_5: 'Size of the insurance', // 5.
    da_5_info: 'Quantum Lebens unit-linked insurances have some fixed costs. These fixed costs make it unprofitable for you as a client to use this insurance unless you have 250 000 SEK or more in the insurance. The cases where it would be profitable to use the unit-linked insurance with assets value lower than 250 000 SEK, would be if you hold assets with very high expected return, as it otherwise would be a risk that the fixed costs can negate any eventually obtained returns.',
    da_5_1: 'Do you intend to make a premium larger than 250 000 SEK to your unit-linked insurance at the time of opening your insurance?', // 5.1
    da_5_2: 'Do you intend to make additional premiums during the year that exceeds 250 000 SEK?', // 5.2

    da_modal_title: 'You don\'t seem to have a need for an insurance',
    da_modal_content: <><p>Based on the answers you have given our opinion is that you don&apos;t have a need for an unit-linked insurance.</p> <p>If you have any questions please contact <a href={`mailto:${v.email}`} target="_blank" rel="noopener noreferrer">{v.email}</a>.</p></>,

    da_signoff: 'Sign-off',
    da_signoff_title: 'Du har nu genomfört Hubins registrering och är nu framme vid det sista steget.',
    da_signoff_info: 'Vänligen läs igenom dokumenten nedan och signera genom att legitimera dig med BankID när du har tagit del av informationen.',
    da_signoff_subheading: 'Please read and review the following documentation and confirm with BankID that you have read the information.',
    da_signoff_confirm_everything_is_correct: 'I confirm that the information I have provided is correct and if any essential information changes, I will inform Hubins of the changes.',
    da_signoff_confirm_have_read_document: 'I confirm that I have read and accept the information in the above document',
    c_da_signoff_confirm_privacy: 'Jag har läst, förstår och godkänner behandling av mina personuppgifter samt bolagets uppgifter för de syften som framgår av Hubins behandling av personuppgifter. Jag förstår och godkänner även att Hubins får dela mina personuppgifter samt bolagets uppgifter med tredje part i syfte att underlätta eventuell kundetablering mellan mig och en tredje part som sker på mitt initiativ. Jag har tagit del av kontaktuppgifter till Hubins som till dataskyddsombudet på bolaget.',
    da_signoff_accept_terms_and_conditions: 'By using BankID to confirm my identity, I accept the terms and conditions and agree to Hubins sending information and communicating with me via my given email address.',

    // DA and Finicaial Situation TEMP RISK TABLE
    risk_level: 'Risk level:',
    risk_very_low: 'Very low',
    risk_low: 'Low',
    risk_medium_low: 'Medium low',
    risk_medium: 'Medium',
    risk_medium_high: 'Medium high',
    risk_high: 'High',
    risk_very_high: 'Very high',
    risk_category: 'Risk category:',
    risk_1_2: 'Very low to low',
    risk_3_5: 'Medium low to medium high',
    risk_6_7: 'High to very high',




    //Insurance information
    ii_title: 'Policy application',
    ii_subtitle: 'Den här försäkringen erbjuds av Quantum Leben AG i Liechtenstein',
    ii_info: 'You have now started your policy application at Quantum Leben. The information provided will be used to create your policy',
    c_ii_info: 'You have now started the policy application for companies at Quantum Leben. The information provided will be used to create the company policy.',

    ii_ql_gdpr_title: 'QL Privacy Statement',
    ii_confirm_gdpr_quantum: 'I have read, understood and accept the processing of my personal data for the purposes set out in the Quantum Leben AG Privacy Statement. I also understand and accept that Quantum Leben AG may share my personal data with third parties in order to facilitate the creation of any client relationship between me and a third party that takes place at my initiative. I have read the contact information for both Quantum Leben AG and the Data Protection Officer at the company.',
    ii_confirm_gdpr_quantum_co: 'I have read, understood and accept the processing of my personal data and information about the company for the purposes set out in the Quantum Leben AG Privacy Statement. I also understand and accept that Quantum Leben AG may share my personal data and company data with third parties in order to facilitate the creation of any client relationship between the company and a third party that takes place at my initiative. I have read the contact information for both Quantum Leben AG and the Data Protection Officer at the company.',
    ii_ql_gdpr_tooltip: <><p>You will be the policy holder of your unit-link policy</p><p>A unit- link insurance policy is a form of life insurance, and this means that there needs to be a life assured person in your policy.You will be the life assured person in your policy.</p></>,

    advisor: 'Do you have an existing investment advisor you wish to give an information proxy for your insurance?',
    advisor_co: 'Do the company have an existing investment advisor you wish to give an information proxy for the company´s insurance?',
    specify_advisor: 'Please specifiy the name of your investment advisor',
    specify_advisor_co: 'Please specifiy the name of the investment advisor',
    advisor_email: 'Email to your investment advisor',
    advisor_org: 'Investment advisor\'s organization/employer',
    advisor_change: 'Please note that if your investment advisor no longer is employed at the organisation, the information proxy will pass on to the investment advisors successor',
    advisor_change_co: 'Please note that if your investment advisor is no longer employed at the organisation, the information proxy will pass on the investment advisors organisation',

    ii_documents_title: 'Terms and Conditions',
    ii_doc_1: 'QL KID-document',
    ii_doc_2: 'Information reg. charges and costs Hubins-QL',
    ii_kid_check: 'I have read and understand the information in the KID -document',
    ii_policy_fee_check: 'I have read and understand the information in the Policy fee document',
    ii_terms_check: 'I confirm that I have read and accept the information in the Terms and Condition',

    beneficiaries_to_policy: <><p>You as the policyholder may appoint one or several beneficiaries to your insurance policy.</p><p>I want to appoint the following beneficiaries to my policy:</p></>,
    beneficiaries_to_policy_label: 'I want to appoint the following beneficiaries to my policy',
    beneficiaries_to_policy_tooltip: <><h2>What is beneficiaries?</h2><p>In a life insurance policy, such as a unit- linked insurance, you can decide who is legally entilted to receive the assets withing your policy in the occurence of an insurance event.If you choose legal heirs as beneficiaries, the assets within the policy will be divided according to the rules of inheritence.</p></>,
    single_option_only: 'You can only select one option',
    husband_wife_colivingpartner_or_child: 'Husband/Wife, Co-living Partner, or if such a person does not exist, Child, or if such a person does not exist, legal heirs',
    children_or_husband_wife_colivingpartner: 'Children, or if such a person does not exist, Husband/Wife, Co-livingpartner, or if such a person does not exist, legal heirs',
    children_or_husband_wife_colivingpartner_split_in_half: '50% to Husband/Wife, Co-livingpartner and 50% to Children. If any of mentioned persons does not exist, the full amount is given to the existing person. If none of the person exists, the amount is given to the legal heirs',
    no_beneficiaries: 'I do not wish to assign any beneficiaries',

    policy_beneficiaries_as_separate_source: 'I wish that the money and/or assets that the beneficiaries recieved from this policy in case of an insurance event is private property.',
    policy_beneficiaries_as_separate_source_tooltip: <><h2>What does private property mean?</h2><p>If you want, you can choose that in the case of an insurance event, that the beneficiaries receives the assets of your policy as private property. This means that the assets will not be part of the marital property incase of a divorce.</p></>,
    policy_deposit_info: 'Only you who is the policy holder may make a deposit in to your policy. Because of this it is important that the money is transfered from an account where you are the account holder. Please also note that transfers directly from your spouse or from your own company\'s bank account are not allowed.Please confirm that the money you transfer will come from an account that you own.',
    policy_deposit_info_co: 'Only the company, who is the policy holder, may make a deposit in to the policy. Because of this it is important that the money is transfered from an account where the company are the account holder. Please also note that transfers directly from a company within the company structure are not allowed. Please confirm that the money you transfer will come from an account that the company own.',
    confirm_transfer_from_own_account: 'I confirm that the money I deposit comes from an account that I am the owner of.',
    confirm_transfer_from_own_account_co: 'I confirm that the money the company deposit comes from an account that the company are the owner of.',
    initial_premium_payment: 'What will be the initial premium payment?',
    total_premium_payments_coming_year: 'What is the total amount of premiums you plan to do for the coming 12 months?',
    total_premium_payments_coming_year_co: 'What is the total amount of premiums you plan to do for the coming 12 months on behalf of the company?',
    policy_stamp_duty_info: <p>Please note that there is a 0.15% stamp duty on any premium payments or withdrawals done in your policy. You can apply to get the paid stamp duty deducted from the swedish standard tax in your tax-declaration, see the link to Skatteverket <a className="link" href="https://skatteverket.se/privat/skatter/forsakringar/avkastningsskattpautlandskalivforsakringar/nedsattavkastningsskatt.4.1a098b721295c544e1f800027816.html" target="_blank" rel="noopener noreferrer">Swedish tax authorities</a>.</p>,
    policy_stamp_duty_info_co: <p>Please note that there is a 0.15% stamp duty on any premium payments or withdrawals done in the company´s policy. You can apply to get the paid stamp duty deducted from the swedish standard tax in your tax-declaration, see the link to Skatteverket <a className="link" href="https://skatteverket.se/privat/skatter/forsakringar/avkastningsskattpautlandskalivforsakringar/nedsattavkastningsskatt.4.1a098b721295c544e1f800027816.html" target="_blank" rel="noopener noreferrer">Swedish tax authorities</a>.</p>,

    repayment_cover: 'Insurance Cover',
    repayment_info: 'You unit-linked insurance contract is a life insurance policy with a cover of 101% of the net asset value of your policy at the time Quantum Leben AG is notified about the insurance event. The insurance benefit will be paid to the beneficiary or the legal heirs (as defined in the policy) as either transfer of ownership of the assets you invested in or the available cash in the insurance policy. In addition Quantum Leben AG pays out in cash 1% of the value of the insurance policy at the time of the notification of the death of the insured life.',
    repayment_info_co: 'The unit-linked insurance contract of the company is a life insurance policy with a cover of 101% of the net asset value of your policy at the time Quantum Leben AG is notified about the insurance event. As the policyholder is a legal entity, there is no explicit appointment of beneficiaries, and the insurance benefit will be paid to the policyholder. If you still would like to appoint a beneficiary, please contact kundservice@hubins.com In the event of death of the life insured, an insurance benefit of 1% of the total policy value will be paid to the policyholder, at the time Quantum Leben AG is notified about the insurance event and has received all required documentation*. If there are multiple life insured persons for the policy, the insurance benefit will be paid out in the case of an insurance event of the last life insured of the unit- linked policy. ',
    ii_contact_person: 'Do you have a contact person that can be contaced in the case of an insurance event?',

    // Aptitude test
    aptitude_test: 'Appropriateness test',
    aptitude_test_intro_title: 'In this section you need to answer some questions about your knowledge and experience, what services you have used in the past and your investment preferences. The purpose of this to be able to offer you custom investment advice, based on your financial situation.',
    c_aptitude_test_intro_title: 'In this section you need to answer some questions about your knowledge and experience, what services you have used in the past and your investment preferences regarding the investments you are planning to make in the unit-linked policy of the company.',
    how_do_you_plan_using_insurance: 'How do you plan to use your unit-link policy',
    individual_investments: 'Single investments',
    ongoing_investments: 'Continous investments',
    suitability_client_classification: 'Quantum Leben is required to classify all investors/clients under different client categories depending on their level of professionalism. Clients should either be classified as non-professional clients or professional clients. Quantum Leben classifies all clients as non-professional. Non-professional clients have the highest level of investment protection.',
    suitability_investment_experience_bonds: 'How do you classify your knowledge of unlisted stocks, corporate bonds & direct loans?',
    suitability_investment_experience_shares: 'How do you classify your knowledge of funds and listed shares',
    suitability_investment_experience_unlisted_shares: 'How do you classify your knowledge of funds',

    expected_risk_return: 'Expected risk and return',
    relation_risk_and_return: <>
      <p>Generally there is a relationship between risk and return. If you are willing to take more risk with your investment, the expected return is higher. In the table below you see how the different risk classes differs in terms of return and possible losses</p>
    </>,

    expected_risk_return_info: 'Please note that you will only see investments that match your risk preferences',

    a_risk_table_title: 'What is the highest risk and expected return you are willing to accept and is willing to take on each individual investment that you plan to do in your policy?',
    a_risk_table_title_co: 'What is the highest risk and expected return you are willing to accept and is willing to take on each individual investment that you plan to do in your policy?',
    low_risk: 'Low risk',
    medium_risk: 'Medium risk',
    high_risk: 'High risk',
    a_risk_example_1: 'Statsobligation / bankkonto med statlig insättningsgaranti', // TODO: Add real example
    a_risk_example_2: 'Statsobligation / bankkonto med statlig insättningsgaranti', // TODO: Add real example
    a_risk_example_3: 'Statsobligation / bankkonto med statlig insättningsgaranti', // TODO: Add real example

    // Knowledge test
    meaning_of_flat_rate: 'What does it mean that a savings product is subject to standard taxation?',
    pay_tax_on_capital_gains: 'Tax is paid based on the capital gains',
    pay_tax_savings_not_gains: 'Tax is paid based on the whole value of the account and paid premiums through an annual one-off tax',
    savings_flat_rate_loss_deduction: 'If a savings product is standard-taxed, which one is the correct statement regarding any eventual loss deductions?',
    do_loss_deduction_when_saving: 'It is possible to make loss deductions',
    no_loss_deduction_when_saving: 'It is not possible to make loss deductions',
    insurance_with_beneficiary: 'What does it mean that a unit-linked insurance have beneficiaries?',
    c_insurance_with_beneficiary: 'Vem är juridiskt sett registrerad som ägare till de investeringar som hålls i en kapitalförsäkring?',
    beneficiary_determines_who_obtains_money: 'By appointing a beneficiary to your insurance, the policyholder can decide who will receives the assets in the insurance in the case of an insurance-event, disregarding the common inheritance law. The policyholder may also decide whether the underlying assets will be classified as private property or not',
    beneficiary_determines_who_obtains_money_but_not_as_single_property: ' By appointing a beneficiary to your insurance, the policyholder of the insurance may control the receiver of the returns of the investments made in the insurance',
    beneficiary_determines_who_obtains_money_but_priority_of_inheritance_rules: 'The beneficiary is the owner of the policy',
    insurance_costs_and_taxes: 'Which statement below is correct?',
    paid_on_loss_and_gains: 'Because your unit-link policy is an insurance product, the deposits made in to your policy is also insured and you cannot make any losses regardless of how your underlying investments perform',
    paid_on_whole_insurance_if_return: 'A unit-linked policy is a life insurance contract where you can appoint beneficiaries which is the section of the insurance. You decide how to invest your money in for example stock, funds and other types of securities.There is no guarante for your underlying investments',
    paid_on_only_yearly_return: 'If your underlying investments decrease more the 50% in value the insurance company will cover any potential losses if the value of the investments continue to decrease in value',
    math_questions: 'If you by the beginning of the year have 1 000 000 SEK in your unit-linked insurance, and you pay a premium for 500 000 SEK during the first half of the year - what is the capital base intended for taxation by the end of the year?',

    // Knowledge ny fråga
    savings_in_capital_insurance: 'Which statement below is correct?',
    insurance_cover_losses: 'Because your unit-link policy is an insurance product, the deposits made in to your policy is also insured and you cannot make any losses regardless of how your underlying investments perform.',
    no_guarante_for_savings: 'A unit-linked policy is a life insurance contract where you can appoint beneficiaries which is the section of the insurance. You decide how to invest your money in for example stock, funds and other types of securities. There is no guarante for your underlying investments.',
    c_no_guarante_for_savings: 'En kapitalförsäkring är ett sparande i en livförsäkring där ni inte behöver betala kapitalvinstskatt eller deklarera för de omplaceringar som görs inom kapitalförsäkringen.',
    decrease_over_50_is_covered: 'If your underlying investments decrease more the 50% in value the insurance company will cover any potential losses if the value of the investments continue to decrease in value.',

    // Company knowledge test
    c_beneficiary_determines_who_obtains_money: 'By appointing a beneficiary to your insurance, the policyholder can decide who will receive the assets in the insurance in the case of an insurance-event, disregarding the common inheritance law. The policyholder may also decide whether the underlying assets will be classified as private property or not',
    c_beneficiary_determines_who_obtains_money_but_not_as_single_property: ' By appointing a beneficiary to your insurance, the policyholder of the insurance may control the receiver of the returns of the investments made in the insurance',
    c_beneficiary_determines_who_obtains_money_but_priority_of_inheritance_rules: 'The beneficiary is the owner of the policy',
    c_paid_on_loss_and_gains: 'Because your unit-link policy is an insurance product, the deposits made in to your policy is also insured and you cannot make any losses regardless of how your underlying investments perform',
    c_paid_on_whole_insurance_if_return: 'A unit-linked policy is a life insurance contract where you can appoint beneficiaries which is the section of the insurance. You decide how to invest your money in for example stock, funds and other types of securities.There is no guarante for your underlying investments',
    c_paid_on_only_yearly_return: 'If your underlying investments decrease more the 50% in value the insurance company will cover any potential losses if the value of the investments continue to decrease in value',
    c_math_questions: 'If you by the beginning of the year have 1 000 000 SEK in your unit-linked insurance, and you pay a premium for 500 000 SEK during the first half of the year - what is the capital base intended for taxation by the end of the year?',

    // Tests modal / view
    tests_title_modal: 'Welcome to Hubins',
    tests_text_modal: 'You have a few more steps to complete. To open your policy you need to complete a few more steps.',
    tests_button_modal: 'Complete Later',
    tests_title_view: 'Complete your registration',
    tests_text_view: 'To finalize your registration you need to provide us with additional information.',

    policy_application: 'Policy application',
    policy_application_text: 'Complete the policy application and chose the features of your policy',
    know_your_client: 'Know your client',
    know_your_client_text: 'According to current rules, Quantum is obliged to get to know their clients to understand how they want to use the unit-linked insurance offered by Quantum Leben.',
    appropriateness_test: 'Appropriateness test',
    appropriateness_test_text: 'Here your will answer questions about your knowledge and previous investment experience',
    financial_situation: 'Goals and financial situation',
    financial_situation_text: 'The questions in the following section will focus on current and future financial situation',
    source_of_funds: 'Source of funds',
    source_of_funds_text: 'In this section there will be questions regarding the source of your funds. This section focuses on your historical earnings.',
    terms: 'Terms',
    terms_text: '',

    // Financial situation
    financial_situation_intro_title: 'In this section you will be asked questions about your income, your assets and your debts. These questions are meant to establish your current financial situation and future cash flows.',
    financial_situation_intro_title_co: '',

    // Source of funds
    sof_intro_title: 'In this section you will answer questions about the source of your funds. The questions is meant to establish your current assets and is back-wards looking. Quantum ask these questions to establish the source of fund and the platform will guide you through the questions.',
    pre_source_of_funds_info: <h3 className="smallheading section-padding">You have earlier stated that you will deposit <strong>{v.totalAmount}</strong> in the comming 12 months.</h3>,
    source_of_funds_intro_title: 'Source of funds intro text. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Nulla vitae elit libero, a pharetra augue. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Nullam id dolor id nibh ultricies vehicula ut id elit. Fusce dapibus.',
    source_of_funds_intro_text: 'Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Cras mattis consectetur purus sit amet fermentum.',
    step_no: `Step ${v.no}`,
    sof_annual_salary_past_5_years: 'What has been your average annual salary for the past 5 years including bonuses after taxes?',
    sof_annual_cost_past_5_years: 'What has been your average annual costs for the past 5 years?',
    sof_dividend_payouts_past_5_years: 'How much have you received in total dividend payouts during the past 5 years after taxes?',
    sof_source_options: 'Did you receive capital that you plan to invest from any of the below sources in the past 5 years? (multiple answers possible)',
    sof_source_options_option_1: 'Profits from investments',
    sof_source_options_option_2: 'Sale of properties',
    sof_source_options_option_3: 'Sale of Companies',
    sof_source_options_option_4: 'Insurance payout',
    sof_source_options_option_5: 'Other (Donation, Lottery etc)',
    sof_source_options_option_6: 'None of the above',
    sof_security_profits_past_5_years: 'How much profits have you accumulated during the past 5 years from securities trading after taxes?',
    sof_sale_of_properties_profits_past_5_years: 'How much money have you received from sale of properties during the past 5 years (net after taxes and repayment of mortgages)?',
    sof_sale_of_companies_profits_past_5_years: 'How much money have you received from sales of companies during the past 5 years?',
    sof_insurance_payouts_past_5_years: 'How much money have you received from insurance payouts during the past 5 years?',
    other_sources_question: 'Were does the money orginating from?',
    other_sources_past_5_years_description: 'Please specify were the money is orginating',
    sof_other_sources_past_5_years: 'How much money have you received from the above source during the past 5 years?',
    sof_other_sources_label: `Other sources in ${v.currency}`,
    sof_source_from_more_then_5_years_ago: 'Do the source of your planned deposits to your insurance also stem from cash received more than 5 years ago?',
    sof_deposit_source: 'Is the source of your deposit a combination of the above given sources, or do you have a specific source for this deposit?',
    sof_deposit_source_combination: 'It is a combination of sources',
    sof_deposit_source_specific: 'It is from a specific source',
    proof_funds_more_then_5_years_ago: 'Are you able to prove the source of your stated money?',
    source_options_more_then_5_years_ago: 'Please specify your sources below. You can chose multiple options.',
    surplus_from_salary: 'Surplus from salary',
    dividend: 'Dividend payouts',
    profit_from_investments: 'Profit from investments',
    profit_sale_of_properties: 'Sale of properties',
    profit_sale_of_companies: 'Sale of Companies',
    insurance_payout: 'Insurance payout',
    years_of_surplus: 'Between which years did you accumulate the surplus from salary?',
    choose_years: 'Choose years',
    salary_before_past_5_years: 'How much capital did you accumulate in total from surplus from salary during these years?',
    years_of_dividend: 'Between which years did you accumulate the dividend payouts?',
    dividend_before_past_5_years: 'How much capital did you accumulate in total from dividend payouts during these years?',
    years_of_securities: 'Between which years did you accumulate the profits from investments in securities?',
    security_profits_before_past_5_years: 'How much profits did you accumulate during these years from securities trading?',
    sale_of_properties_before_past_5_years: 'How much money have you received from sale of properties from a period over 5 years ago?',
    sale_of_companies_before_past_5_years: 'How much money have you received from sales of companies from a period over 5 years ago?',
    insurance_payouts_before_past_5_years: 'How much money have you received from insurance payouts from a period over 5 years ago?',
    other_sources_before_past_5_years_source: 'Please specify where the money you cannot attribute to sources stated above comes from?',
    other_sources_before_past_5_years: 'How much money have you received from other sources than above from a period over 5 years ago?',
    specific_source: 'Specific source',
    specify_source: 'Please specify what your source is below',

    from_past_5_years: 'from the past 5 years',
    from_more_5_years: 'earlier than 5 years ago',

    company_past_5_years: 'Which company or companies were you employed by during these past 5 years?',
    company_more_5_years: 'Which company or companies were you employed by?',
    company_address: 'What is the adress of the company?',
    company_main_field: 'What was the main field of business of your employer?',
    company_label: 'Name of company',
    company_sold_name: 'What was the name of the company you sold?',
    company_sold_when: 'When was the company sold?',
    company_ownage_in_percent: 'How much of the company in per cent did you own?',
    company_ownage: 'Company shares',
    company_ownage_in_percent_label: 'Shares in %',

    dividend_company: 'From which company did you receive the dividend payment?',
    dividen_company_ownage_past: 'How much of this company do you own?',
    dividen_company_ownage_before: 'How much of this company did you own at the time of payout?',
    dividend_company_signatory: 'Do you have signatory rights for this company',

    insurance_concern: 'Please specify what the insurance payout concerned',
    insurance_concern_label: 'What the insurance payout concerned',
    insurance_payout_when: 'When did you recieve the insurance payout?',

    properties_total_sale_price: 'How much was the total sales price of the property?',
    properties_when: 'When was the property sold?',
    properties_percentage: 'How much of the property in per cent did you own?',
    properties_address: 'What is the address of the property sold?',

    other_source: 'From what source did this money come from?',
    other_source_co: 'Vänligen specificera vad för andra källor pengarna kommer ifrån',
    other_source_when: 'When did you receive the source stated above?',

    // Money placement options
    placement_title_salary: 'What did you do with your money between now and when you received them?',
    placement_bank_account: 'I held them as cash on my bank account ever since I got them',
    placement_investments: 'I invested them in different securities',
    placement_other: 'Other (please specify below)',

    none_logical_past: 'Total assets latest ending year',
    none_logical_before: 'Total assets 5 years ago',
    none_logical_total_assets_before: 'What was your total assets 5 years ago?',
    none_logical_total_liabilities_before: 'What was your total liabilities 5 years ago?',
    none_logical_total_cash_holding: 'What was your total cash holdings 5 years ago?',
    none_logical_total_value_securities: 'What was the total value of all of your securities 5 years ago?',

    other: 'Other',

    // Proof of funds
    proof_of_funds: 'Proof of funds',
    proof_of_funds_intro_title: 'You need to provide us with proof of the origin of your funds. On the next page you will find out which documents you need to upload.',
    proof_of_funds_intro_text: 'Note that if you need to upload more than one document, all documents needs to be uploaded at the same time to be saved properly.',
    go_to_upload: 'Go to upload',
    your_answer: 'Your answer',

    // Upload documents texts

    mark_and_upload_documents: 'Please mark and then upload one or more of the below attachements as proof of the source. The number indicates how well the attachement proves the source, meaning that the attachement with number 1 is prefered to the attachement with number 2. Multiple attachements are encouraged in order to avoid necessary follow up questions.',

    tax_decision: <>Your final public tax decision (Slutskattebesked). Can be downloaded for the past 10 years from <a href="www.skatteverket.se">www.skatteverket.se</a> when logged in, “Beslutade skatteuppgifter/skattebesked”</>,
    tax_decision_title: 'Your final public tax decision',

    portfolio_report: 'Portfolio report over the applicable years or equivalent',
    portfolio_report_title: 'Portfolio report',

    sales_contract: 'Copy of the sales contract',
    sales_contract_title: 'Sales contract',

    paychecks: 'Annual salary and bonus document from employer/Employment contract/A recent payment slip',
    paychecks_title: 'Annual salary',

    bank_statement: 'Bank Statement, account extract and or receipt showing which shows the money coming in from this specific property sale',
    bank_statement_title: 'Bank Statement',

    bank_statement_company: 'Bank Statement, account extract and or receipt showing which shows the money coming in from this specific company sale',

    bank_statement_held: 'Bank Statement, account extract and or receipt showing which shows the money coming in to your bank account',
    bank_statement_held_title: 'Bank Statement',


    annual_statement: 'Yearly statements from the company that shows the dividend payout you recieved,  for the relevant years. ',
    annual_statement_title: 'Yearly statements',

    other_proof: 'Other',
    other_proof_title: 'Other',

    insurance__report: 'Report from the insurance company showing the insurance payout',
    insurance__report_title: 'Report',

    upload_bank_statement: 'Please upload an account report that shows that the money has been held as cash in a account owned by you since the money was received initially.',
    upload_investments: 'Please upload an portfolio investment report that shows that the money has been held as securities in a account owned by you since the money was received initially.',
    upload_other: 'Please upload a document that shows where the money has been held',

    other_sources: 'Other sources',

    signatory_proof: 'Please upload proof of your ownership in the company and the evidence requested below.',

    // SOF SIGNOFF

    signoff_title: 'Terms and approval',


    // KYC
    kyc_heading: 'Know your client',
    kyc_title: 'We need additional information from you',
    kyc_text: 'Quantum Leben is a part of the CRS- & FATCA-agreement, and thus needs to ask a few additional questions regarding you and you tax domicile. If you have any additional tax domicile beyond Sweden, Quantum Leben will report your information to respective responsible tax authorities.',
    what_is_your_employment: 'What is your primary occupation?',
    employment_education: 'Occupation and Education',
    tax_residence_domestic_only: 'Do you only have tax residency in Sweden?',
    please_fill_in_tax_countries: 'Please specify all your tax residencies',
    tax_residence_code: 'Please specify your Tax Identification Numer (Tin)',
    add_country: 'Add additional tax residence',
    political_risk_current: 'Are you still or do you have a close relative or knows employee who is still in a politically exposed position?',
    specify_who_political_risk: 'Specify who is/has been politically exposed',
    political_risk_position: 'What position does the person in question hold or held?',
    nationality: 'Citizenship',
    city_of_birth: 'City of birth',

    bank_account_for_payment: 'Account to receive withdrawals.',
    please_observe_no_autogiro: 'Please note that withdrawals can only be made to this account',
    own_account_someone_else: 'Do you own this bank account together with someone else?',

    shared_account_with: 'Please specify who this person is',
    shared_account_relation: 'Please specify the relationship you have with the person in question',
    own_money: 'I confirm that the money I plan to deposit is my own money and comes from an account where I am the account holder',

    // PreInvestModal
    preinvestmodal:
      <>
        <p>This page only contains information about investments available through a Quantum unit-link insurance and the investments presented is a part of Quantum&apos;s investment offering. To gain access to the entire platform, you need to complete the entire Quantum registration to open a unit-link-insurance.</p>
        <p><strong>Hubins AB do not offer any investment advice, and hence do not offer any suitability test or Appropriateness test for any of the investment that you do through your unit-link policy.</strong></p>
      </>,

    // For debugging - don't translate
    'test': `Test en ${v.test}`,
    'test2': <i>I</i>,

    // SOF SIGNOFF
    policy_intro_1: 'Your policy application is almost done.',
    policy_intro_2: 'One flexible unit-linked insurance for all your investments.',

    confirm_provided_information: 'I confirm that the information I have provided is correct and if any essential information changes, I will inform Quantum of the changes.',
    company_info_clar_contact: 'Contact Hubins if anything is incorrect.',

    approved: 'Approved',

    info_about_policy: 'Information about my capital insurance',

    policy_application_life_insurance:
      <p>
        <strong>Policyholder:</strong> {v.name}<br />
        <strong>Life-insured:</strong> {v.name}
      </p>,
    // policy_application_life_insurance_co: '',
    client_information: 'Client information',

    signoff_title_ql: 'Client registration - Quantum Leben',

    sof: 'Source of funds',

    knowledge_fufilled: 'Knowledge level',
    fufilled: 'Fufilled',

    policyholder: 'Policyholder',
    life_asured: 'Life-insured',
    months: 'months',
    years: 'years',

    your_account_is_ready: 'Your account is ready',

    // After sale
    as_title: 'Bekräftelse på din Kapitalförsäkring',
    as_ins_title: 'Om din försäkring',
    insurance_number: 'Försäkringsnummer',
    insurance_type: 'Försäkringstyp',
    life_insurance: 'Livförsäkring',
    insurance_name: 'Försäkringsnamn',
    insurance_currency: 'Försäkringsvaluta',
    as_ins_valid: 'Försäkringen giltig från',
    insurance_period: 'Försäkringsperiod',
    as_ins_period_value: 'Försäkringen är en livförsäkring som löper med 1 år i taget med automatisk förlängning eller till och med att den livförsäkrade avlider. Försäkringen upphör antingen vid helt återköp (avslut) eller vid utbetalning på grund av försäkringsfall.',
    as_about_title: 'Om dig som försäkringstagare',
    as_about_title_co: 'Om försäkringstagare',
    beneficiary: 'Förmånstagare',
    as_more_title: 'Mer om din försäkring',
    as_more_text: <p>Du har tecknat en kapitalförsäkring med ett livskydd. Det är du som försäkringstagare som också är den livförsäkrade. Din livförsäkring ger ett försäkringsskydd enligt de allmänna försäkringsvillkoren och påverkas direkt av investeringarnas värdeutveckling. Vid dödsfall under försäkringsperioden betalar vi ut 101 % av försäkringsvärdet till förmånstagarna.Utbetalning sker genom överföring av värdepapper, övriga tillgångar samt tillgängliga kontanter i försäkringen överlåts enligt förmånstagarförordnandet eller enligt arvsrätt om sådant saknas. Därutöver utbetalas 1% kontant baserat på försäkringsvärdet på den dag som Quantum nås av informationen om att den livsförsäkrade har avlidit.</p>,
    as_more_text_co: <p>Bolaget har tecknat en kapitalförsäkring med ett livskydd. Det är bolagets firmatecknare som är livförsäkrade. Då det finns flera firmatecknare och därmed livförsäkrade inträffar ett försäkringsfall först vid den sista livförsäkrades död (“Multiple life last survivor”). Livförsäkringen ger ett försäkringsskydd enligt de allmänna försäkringsvillkoren och påverkas direkt av investeringarnas värdeutveckling. Vid dödsfall under försäkringsperioden betalar vi ut 101 % av försäkringsvärdet till bolaget. Vid Multiple life last survivor bestäms försäkringsvärdet av sista dödsfallet när samtliga livförsäkrade har avlidit. Utbetalning sker genom överföring av värdepapper, övriga tillgångar samt överföring av tillgängliga kontanter i försäkringen. Därutöver utbetalas 1% kontant baserat på försäkringsvärdet på den dag som Quantum nås av informationen om att den livförsäkrade har avlidit.</p>,
    as_important_title: 'Nedan finns viktiga delar av försäkringsvillkorens innehåll listade. För fullständiga villkor se Allmänna villkor Quantum Leben.',
    as_important_subtitle: 'Kontantklausul',
    as_important_text: <>
      <p>Försäkringen träder i kraft dagen efter den dag då försäkringstagaren betalar Premie för försäkringen under förutsättning att Quantum Leben vid den tidpunkten godkänt ansökningshandlingen och premien. Avgifter kommer inte att dras innan premien har betalats.</p>
      <p>Vid återköp av försäkringen betalas försäkringsvärdet ut minus tillämpliga kostnader, avgifter och skatter som löper på försäkringen.</p>
      <p>Försäkringsvärdet består av värdet på de tillgångar (inklusive likvida medel) som länkats samman med försäkringen, inklusive värdet på eventuella försäkringsdepåer. Quantum Leben är ägaren av tillgångarna inom kapitalförsäkringen men försäkringstagaren har en fordran på Quantum Leben motsvarande vid varje tidpunkt gällande värde på kapitalförsäkringen minus tillämpliga kostnader, avgifter och skatter för kapitalförsäkringen.</p>
      <p>Quantum Leben har rätt att säga upp avtalet i förtid ifall försäkringstagaren bryter mot försäkringens angivna villkor eller ifall försäkringstagaren ej är villig att översända begärd tilläggsdokumentation i enlighet med Lagen om penningtvätt.</p>
    </>,
    as_change_title: 'Om du ändrar dig',
    as_change_title_co: 'Om ni ångrar er',
    as_change_text_co: 'Bolaget har rätt att säga upp försäkringen och få återbetalning av inbetalda pengar, dock med avdrag för det belopp (om något) med vilket värdet av investeringen har fallit. Ifall pengarna har investerats i illikvida tillgångar kan bolaget istället för pengar erhålla själva tillgångarna. Om bolaget beslutar att säga upp försäkringen måste bolaget skicka detta meddelande till oss inom 30 dagar från dagen för utfärdandet av denna Försäkring.',
    as_change_text: 'Du har rätt att säga upp försäkringen och få återbetalning av inbetalda pengar, dock med avdrag för det belopp (om något) med vilket värdet av investeringen har fallit. Ifall pengarna har investerats i illikvida tillgångar kan du istället för pengar erhålla själva tillgångarna. Om du beslutar att säga upp försäkringen måste du skicka detta meddelande till oss (se våra kontaktuppgifter nedan) inom 30 dagar från dagen för utfärdandet av denna Försäkring.',
    as_limitations_title: 'Viktiga begränsningar avseende utbetalning vid försäkringsfall',
    as_limitations_text: <>
      <p>I följande fall kan återköpsvärdet vid försäkringsfall påverkas</p>
      <ul>
        <li>Den Försäkrade avlider på grund av självmord inom två år från Försäkringens ikraftträdande, om det inte finns rimliga grunder för att anta att Försäkringen tecknades utan tankar på självmord.</li>
        <li>Den Försäkrade avled på grund av skada eller tillstånd som direkt eller indirekt orsakats av krig, fientlighet (deklarerad eller inte), invasion, uppror, revolt eller inbördeskrig.</li>
        <li>Förmånsvärdet är inte garanterat och är bestämt utifrån värdet på de investeringar som förvaras i Unit Linked-försäkringen och oberoende av om Försäkringen kategoriserats som en livränta.</li>
        <li>Vid dödsfall under försäkringsperioden betalar vi ut 101 % av försäkringsvärdet till förmånstagarna. Utbetalning sker genom överföring av värdepapper, övriga tillgångar samt    tillgängliga kontanter i försäkringen överlåts enligt förmånstagarförordnandet eller enligt arvsrätt om sådant saknas. Därutöver utbetalas 1% kontant baserat på försäkringsvärdet på den dag som Quantum nås av informationen om att den livsförsäkrade har avlidit.</li>
      </ul>
    </>,
    as_limitations_text_co: <>
      <p>I följande fall kan återköpsvärdet vid försäkringsfall påverkas</p>
      <ul>
        <li>Någon av de Försäkrade avlider på grund av självmord inom två år från Försäkringens ikraftträdande, om det inte finns rimliga grunder för att anta att Försäkringen tecknades utan tankar på självmord.</li>
        <li>Någon av de Försäkrade avlider på grund av skada eller tillstånd som direkt eller indirekt orsakats av krig, fientlighet (deklarerad eller inte), invasion, uppror, revolt eller inbördeskrig.</li>
        <li>Förmånsvärdet är inte garanterat och är bestämt utifrån värdet på de investeringar som förvaras i Unit Linked-försäkringen och oberoende av om Försäkringen kategoriserats som en livränta.</li>
        <li>Vid dödsfall under försäkringsperioden betalar vi ut 101 % av försäkringsvärdet till försäkringstagaren. Utbetalning sker genom överföring av värdepapper, övriga tillgångar samt överföring av tillgängliga kontanter i försäkringen. Därutöver utbetalas 1% kontant baserat på försäkringsvärdet på den dag som Quantum nås av informationen om att den sista livförsäkrade har avlidit.</li>
      </ul>
    </>,
    client_name: 'Client Name',
    please_upload_proof: 'Please upload atleast one or more files',
    fatca_crs: 'CRS & FATCA',
    fatca_crs_info:
      <>
        <p><strong>Quantum Leben is a part of the CRS- & FATCA-agreement, and thus needs to ask a few additional questions regarding you and you tax domicile. If you have any additional tax domicile beyond Sweden, Quantum Leben will report your information to respective responsible tax authorities.</strong></p>
        <p>CRS stands for Common Reporting Standard and is a standard format used globally for a automic exchange of information of financial accounts between states and juristictions.</p>
        <p>FATCA stands for Foreign Account Tax Compliance Act and is a seperate regulatory framework to handle persons with tax domicile in the United States</p>
      </>,
    fatca_crs_info_private:
      <>
        <p><strong>Quantum Leben is a part of the CRS- & FATCA-agreement, and thus needs to ask a few additional questions regarding you and you tax domicile. If you have any additional tax domicile beyond Sweden, Quantum Leben will report your information to respective responsible tax authorities.</strong></p>
        <p>CRS stands for Common Reporting Standard and is a standard format used globally for a automic exchange of information of financial accounts between states and juristictions.</p>
        <p>FATCA stands for Oreign Account Tax Compliance Act and is a seperate regulatory framework to handle persons with tax domicile in the United States</p>
      </>,
    policy_life_signatories: 'A unit-link insurance policy is a form of life insurance, and this means that there needs to be a life assured person in the company´s policy. You will be the life assured person in your policy.',
    next_question: 'Next question',
    save_test: 'Save test',
    save: 'Spara',

    // Comp SOF
    more_then_total: 'Om du vill deklarera för ett ytterligare belopp, vänligen skriv värdet nedan.',
    more_then_total_co: 'Om bolaget vill deklarera för ett ytterligare belopp, vänligen skriv värdet nedan.',
    more_then_total_desc: '',
    more_then_total_desc_co: '',
    more_then_total_trigger: 'Vill du deklarera för ett högre belopp än det överskridna beloppet?',
    new_total_amount_title: 'Vänligen fyll i det ytterligare belopp du vill deklarera',
    new_total_amount: 'Nytt belopp att deklarera',
    comp_total_profit: 'What has been the company´s total profit during the past 5 years?',
    comp_total_profit_before: 'What has been the company´s total profit earlier than 5 years ago?',
    profit_from_initial_share_issue: 'How much money has the company raised from an initial share issue?',
    profit_from_bond_issue_loan: 'How much money has the company raised from bond issue/loan?',

    dividend_name_org_nr: 'What is the name and org. nr. of the subsidiary?',
    dividend_subsidiary_percentage: 'How many percent of the subsidiary does the company owns?',
    add_subsidiaries: 'Add more companies',
    parent_company_title: 'Parent company funding',
    parent_company_org_name: 'What is the name and org. nr. of the parent company?',
    parent_company_percentage: 'How many percent of the company does the parent company owns?',
    company_org_label: 'The company org nr',
    interval_of_income: 'Between which "financial" years did the company recieved this amount?',
    sale_of_companies_org_name: 'What was the name and org. nr. of the sold company?',
    sale_of_companies_total_sales_price: 'What was the total sale price of the sold company?',
    amount: 'Amount',
    properties_address_co: 'What was the adress of the sold property',
    properties_percentage_co: 'How many percent of the property did the company owns?',
    add_property: 'Add property',
    insurance_payout_when_co: 'When was the payout recieved?',
    payment_when: 'When was the payment received?',
    insurance_payouts_number: 'What is the number of the policy?',
    insurance_payouts_number_label: 'Number of the policy?',

    shares_title: 'Share issue',
    share_issue_purpose_title: 'What was the purpose of the share issue?',
    share_issue_purpose_label: 'Purpose of share issue',
    share_issue_date: 'When was the money recieved?',
    share_issue_total_amount: 'What is the total amount of the share issue?',
    share_issue_investor_title: 'What is the name (and org. nr. if company) of the largest investers?',
    share_issue_investor_label: 'Name of investor',

    bonds_title: 'Bond issue',
    bonds_issue_purpose_title: 'What is the purpose of the loan?',
    bonds_issue_purpose_label: 'Purpose of the loan',
    bonds_issue_date: 'When was the loan recieved?',
    bonds_issue_total_amount: 'What is the total amount of the loan?',
    bonds_issue_investor_title: 'What is the name (and org. nr. if company) of the largest lender?',
    bonds_issue_investor_label: 'Name of the lender',

    total_profi: 'Total profit',
    source_of_deposit_b: "From which of the below sources, from earlier than 5 years ago, does the company's deposit stem from?",
    source_of_deposit: "From which of the below sources, from the past 5 years, does the company's deposit stem from?",
    active_income: 'Profits from active income',
    dividend_subsidiaries: 'Dividend from companies',
    parent_company: 'Parent company funding',
    securities_investment: 'Investments in securities',

    active_income_total: 'What has been the company´s total profit?',
    dividend_subsidiaries_total: 'How much has the company received in total dividend payout from companies',
    parent_company_total: 'How much has the company received in total funding from the parent company',
    sale_of_companies_total: 'How much has the company recieved from sale of companies',
    sale_of_properties_total: 'How much has the company received from sale of properties',
    securities_investment_total: 'How much profits has the company accumulated from security trading after taxes',
    insurance_payouts_total: 'How much has the company recieved from insurance payouts ',
    other_total: 'How much money has the company received from other sources',

    //Comp proof of funds
    company_annual_report: 'Proof can be found in the latest 5 years´ public annual reports of the company',
    company_annual_reports_title: 'Annual reports',
    shares_contract_title: 'Contract of the share issue',
    upload_bank_title: 'Bank account statement',
    upload_bank_label: 'Bank account statement of a bank in a country that exchanges tax information and has equivalent AML regulation as Liechtenstein',
    bonds_contract_title: 'Contract of the bond issue',
    copy_audited_financial_document: 'Copy of the relevant audited financial statements',
    copy_audited_financial_document_title: 'Financial statements',
    uploaded_tax_form_title: 'Tax declaration',
    uploaded_tax_form: 'Tax declaration form',
    both_company_annual_report: 'Proof can be found in the public annual reports of both companies',
    copy_both_audited_financial_document: 'Audited financial statements from both companies',
    copy_both_audited_financial_document_title: 'Financial statements',
    uploaded_sales_contract: 'Copy of the sales contract',
    uploaded_sales_contract_title: 'Sales contract',
    upload_bank_sales: 'Bank account statement with reference to sale of company/company shares and amount',
    uploaded_land_registry: <>Extract from <a href="www.lantmäteriet.se" target="_blank">land registry</a></>,
    uploaded_land_registry_title: 'Extract from land registry',
    properties_upload_bank_sales: 'Copy of sales contract',
    uploaded_portfolio: 'Portfolio report or equivalent',
    uploaded_shareholder_certificate: 'Shareholder’s certificate',
    surrender_statement: 'Surrender statement',
    insurance_policy_copy: 'Copy of the insurance policy',
    insurance_account_statement_title: 'Account statement',
    insurance_account_statement: 'Account statement from licenced insurance company',
    uploaded_transaction_receipt: 'Transaction reciept/confimration',
    uploaded_account_excerpt_receipt_title: 'Bank Statement, account extract and/or receipt',
    uploaded_account_excerpt_receipt: 'Bank Statement, account extract and/or receipt showing where the money is coming from into the company´s bank account',
    fileupload_meta: 'Allowed file formats: PDF, JPG, PNG, GIF, BMP',
    comp_reg_control_info: 'Lorem ipsum dolor. Is this the correct company?',

    trigger_sof_title: 'Ditt deklarerade belopp har överskridits',
    trigger_sof_desc: <>
      <p>För att kunna genomföra den premieinsättning du önskar måste du först deklarera för det överskridna beloppet.</p>
      <p>Nedan ser du ditt redan deklarerade belopp samt det nya belopp du behöver deklarera.</p>
    </>,
    declared_amount: 'Deklarerat belopp',
    amount_to_declare: 'Belopp att deklarera',
    none_matching_declared_amount: 'Det totala beloppet du angivit som källa till ditt kapital uppgår inte till det belopp du planerar att sätta in. Eftersom Quantum Leben AG enligt lag behöver säkerställa vart dina pengar kommer ifrån behöver du se över dina svar.',
    none_matching_declared_amount_co: 'Det totala beloppet bolaget har angivit som källa till ditt kapital uppgår inte till det belopp bolaget planerar att sätta in. Eftersom Quantum Leben AG enligt lag behöver säkerställa vart bolagets pengar kommer ifrån behöver ni se över bolagets svar.',
    planned_deposit: <>Du har angivit att du planerar att sätta in<br /> { v.totalAmount} i din kapitalförsäkring de kommande 12 månaderna.</>,
    planned_deposit_trigger: <>Dina källor måste minst uppgå till ett värde av {v.totalAmount} för att kunna slutföra premieinsättningen.</>,
    planned_deposit_trigger_co: <>Bolagets källor måste minst uppgå till ett värde av {v.totalAmount} för att kunna slutföra premieinsättningen.</>,

    trigger_sof_success_title: 'Ditt deklarerade belopp har uppdaterats',
    trigger_sof_success_desc:
      <>
        <p>Du har skickat in underlag som Hubins behöver gå igenom. När detta är klart kommer du att kunna slutföra din betalning.</p>
      </>,
    trigger_sof_title_co: 'Bolagets deklarerade belopp har överskridits',
    trigger_sof_desc_co: <>
      <p>För att kunna genomföra den premieinsättning bolaget önskar måste bolaget först deklarera för det överskridna beloppet.</p>
      <p>Nedan ser du bolagets redan deklarerade belopp samt det nya belopp bolaget behöver deklarera.</p>
    </>,

    // TAX
    tax: 'Tax',
    tax_information_pe_title: 'Som privatperson finns det två huvudsakliga beskattningsmetoder för dina investeringar.',
    tax_information_pe:
      <>
        <p><strong>1. Vinstbeskattning.</strong> När investeringarna ägs direkt av dig som privatperson beskattas du generellt sätt upp till 30% på vinsten. Du har även möjlighet att begära avdrag vid förluster.</p>
        <p><strong>2. Schablonbeskattning.</strong> I en schablonbeskattad sparform, så som en kapitalförsäkring,        beskattas du 2022 med 0.375% på kapitalunderlaget i din försäkring.   Det finns ingen möjlighet till förlustavdrag.</p>
      </>,
    need_of_flat_rate: 'Har du behov av kapitalförsäkringens schablonbeskattning?',
    taxation_assumptions: 'Vilket påstående angående beskattning stämmer bäst in på ditt behov?',
    assumption_noneed: 'Jag har inget behov av en skatteform framför någon annan och drabbas inte negativt av kapitalförsäkringsskatten',
    assumption_need: '',
    // Products
    products: 'Products',
    insurance_prefered_assets: 'Vilka typer av tillgångar har du behov eller önskar hålla i din försäkring?',
    unlisted_shares: 'Onoterade aktier',
    listed_shares: 'Börsnoterade aktier',
    unlisted_bonds_and_direct_loans: 'Onoterade obligationer samt direktlån',
    listed_bonds: 'Noterade obligationer',
    funds: 'Fonder',
    other_alternatives_products: 'Alternativa investeringar, robothandel, kryptotillgångar och strukturerade produkter m.m. ',
    confirm_shares_held_in_insurance: 'Jag bekräftar att det är mitt ansvar att aktier, eller andelar, som hålls i min försäkring inte är kvalificerade.',
    confirm_shares_held_in_insurance_desc: 'Vidare är det mitt fulla ansvar att kontrollera att det inte finns övriga förhinder, enligt avtal eller lag, som gör det otillåtet att hålla aktier eller andelar i kapitalförsäkring. Varken Hubins, som försäkringsdistributör, eller försäkringsbolaget bär något ansvar för detta. Jag bekräftar även att jag förstår att det alltid är försäkringsbolagets kommitté för produktgodkännande som bestämmer om en tillgång accepteras inom kapitalförsäkringen eller inte.',
    i_confirm: 'Jag bekräftar',
    i_dont_confirm: 'Jag bekräftar inte',
    dont_confirm: 'Bekräftar inte',
    need_of_faster_then_48h_trades: 'Har du behov av att transaktioner på en börs/marknadsplats utförs snabbare än 48 timmar från lagd order?',
    need_of_faster_then_48h_trades_tooltip: 'Det kan ta upp till 48 timmar innan ordern utförs på relevant marknadsplats.',
    confirm_hubins_only_distribute_insurance: 'Jag är införstådd med att Hubins enbart distribuerar själva försäkringen. Hubins ger inte rådgivning om de investeringar du köper, säljer och håller i din försäkring. Hubins gör heller inte någon passandeprövning eller tar på annat sätt ansvar för transaktioner du genomför i underliggande investeringar i din försäkring. Hubins har heller ingen ansvarsförsäkring som täcker investeringsrådgivning. Försäkringsbolaget, Quantum Leben AG, ansvarar för alla transaktioner i underliggande investeringar som görs på plattformen, i enlighet med gällande lagstiftning.',
    //Insurance
    insurance_policy: 'Försäkringspolicy',
    deposit_interval_of_cash_to_deposit: 'Hur ofta avser du göra insättningar av kontanter och/eller inflyttar i din försäkring?',
    '0_to_3_deposits': '0-3 insättningar per år',
    '4_to_8_deposits': '4-8 insättningar per år',
    '9_to_15_deposits': '9-15 insättningar per år',
    'more_then_15_deposits': 'Mer än 15 insättningar per år',
    '0_to_2_withdrawals': '0-2 uttag',
    '3_to_6_withdrawals': '3-6 uttag',
    '7_to_12_withdrawals': '7-12 uttag',
    'more_then_12_withdrawals': 'Mer än 12 uttag',
    da_0_to_2_withdrawals: '0-2 gånger',
    da_3_to_6_withdrawals: '3-6 gånger',
    da_7_to_12_withdrawals: '7-12 gånger',
    da_more_then_12_withdrawals: 'Mer än 12 gånger',
    //Target return
    target_return: 'Målavkastning',
    expected_yearly_target_return: 'Vad är din sammanlagda målavkastning per år, innan skatt och avgifter, för de investeringar du planerar att göra i kapitalförsäkringen?',
    investment_horizon: 'Vilken tidshorisont har du på ditt sparande i din kapitalförsäkring?',
    //Risk
    risk: 'Risk',
    target_return_risk_info: 'Generellt sett finns det en relation mellan avkastning och risk. Om du är villig att ta högre risk är din förväntade avkastning generellt högre men även den potentiella förlusten.',
    confirm_higher_risk_and_financial_situation: 'Jag bekräftar att jag förstått att högre risktagande generellt sett innebär en högre förväntad avkastning men även en högre potentiell förlust. Jag bekräftar även att jag har en finansiell situation som klarar av att bära förlusten av delar eller hela det belopp jag planerat att investera.',
    //Transfers
    premiums: 'Premier',
    transactions: 'Transaktioner',
    size_of_policy: 'Storlek på försäkring',
    planned_usage_of_insurance: 'Hur planerar du att  använda din kapitalförsäkring?',
    transfer_cash_and_do_investments: 'Enbart genomföra investeringar och sätta in likvida medel',
    transfer_securities_and_assets: 'Enbart flytta in befintliga investeringar',
    both_alternatives: 'Båda alternativen',
    transfers: 'Överföringar',
    transfer_info_section: 'När du flyttar in ett värdepapper som du äger till din kapitalförsäkring betraktas detta ur skattesynpunkt som en försäljning.Om marknadsvärdet på värdepappret ökat sedan du köpte det kan du komma att behöva betala skatt på upp till 30 % på den vinst som realiseras i samband med flytten.',
    financial_situation_covers_tax_of_profit: 'Har du en ekonomisk situation som tillåter dig att betala in eventuell skatt på de vinster du gjort utan att det påverkar dig märkbart negativt?',
    moving_securities_info: 'Hubins kommer inte ta betalt för den inflytt du planerar att göra men när man flyttar värdepapper från exempelvis banker eller försäkringsbolag kan det uppstå kostnader och oförmånliga kurser.',
    aware_of_moving_securities_costs: 'Är du medveten om vilka eventuella kostnader, samt oförmånliga kurser, som kan uppstå i samband med inflytt av investeringar och vill du fortfarande genomföra din inflytt?',
    //Beneficiaries
    beneficiaries: 'Förmånstagare',
    beneficiaries_info: <>
      <p>En kapitalförsäkring är en form av livförsäkring där det finns: </p>
      <ul>
        <li>
          <strong>Försäkringstagare.</strong>  Detta är ägaren av försäkringen
        </li>
        <li>
          <strong>Livförsäkrad.</strong> Detta är en person vars liv försäkringen beror på.
        </li>
        <li>
          <strong>Förmånstagare.</strong> Detta är den eller de personer eller organisationer som får utbetalningen vid dödsfall.
        </li>
      </ul>
      <p>Det är du som kommer vara försäkringstagare till försäkringen. Du kommer även vara livförsäkrad i försäkringen.</p>
      <p>Detta innebär att om du skulle dö kommer 101% av försäkringsvärdet att betalas ut till förmånstagarna.</p>
    </>,
    decide_beneficiary_of_insurance: 'Har du som krav eller önskemål att kunna bestämma vem kapitalförsäkringens försäkringsvärde tillfaller vid dödsfall?',
    add_beneficiaries_info:
      <>
        <p>Enligt villkoren så kommer investeringarna vid ditt dödsfall att utbetalas enligt förmånstagarförordnandet nedan:</p>
        <ul>
          <li><strong>Första hand:</strong> Make/maka</li>
          <li><strong>Andra hand:</strong> Barn <br /></li>
          <li><strong>Tredje hand:</strong> Legala arvingar <br /></li>
        </ul>
        <p>För att vidare säkerställa att pengarna inte tillfaller någon annan än ovan, kommer utbetalningen klassificeras som enskild egendom och därmed vara skyddad om förmånstagaren exempelvis skulle skilja sig.</p>
      </>,
    accept_beneficiaries_order_above: 'Önskar du förmånstagarförordnandet ovan?',
    download_beneficiaries_pdf_info: 'Ladda ner blankett för att ändra förmånstagarförordnandet',
    download_beneficiaries_pdf_info_clarification: <p className="clarification">Efter att du laddat ner blanketten kan du fortsätta med din behovsanalys.</p>,
    //Purpose
    purpose: 'Syfte',
    matching_alternatives_savings_insurance: 'Vilket eller vilka alternativ stämmer överens med ditt kommande sparande i kapitalförsäkring via Hubins?',
    saving_for_children: 'Sparande till mina barn',
    saving_for_pension: 'Pensionssparande',
    saving_for_increase_private_wealth: 'Sparande för att öka min egna förmögenhet',
    saving_for_specific_purpose: 'Sparande till något specifikt ändamål',
    saving_other: 'Övrigt sparande',
    specifie_saving_purpose: 'Vänligen specificera',
    c_specifie_saving_purpose: 'Vänligen ange vilket specifikt ändamål bolaget har med sparandet',
    specifie_saving_purpose_label: 'Sparande till något specifikt ändåmål',
    purpose_of_insurance: 'Vad är ditt syfte med att öppna en kapitalförsäkring via Hubins?',
    flex: 'Flexibilitet',
    kick_back_fonds: 'Kick-back på fonder ( Kommer inom kort )',
    gather_investments_on_flex_platform: 'Samla investeringar på en flexibel plattform',
    access_to_investments: 'Tillgång till investeringsutbudet',
    userfriendly_platform: 'Användarvänlig plattform',
    completion_to_existing_portfolio: 'Komplettering till befintlig investeringsportfölj',
    gather_shares_before_ipo: 'Hålla aktier inför marknadsnotering',
    anonymity: 'Anonymitet',
    etc: 'Övrigt',
    asset_types: 'Tillgångsslag',

    // NEW DA SIGNOFF
    new_da_signoff_title: 'Resultat från Krav- och Behovsprövningen',
    new_da_signoff_ingress: 'Hubins har genom sin prövning kommit fram till slutsatsen att du har behov av en kapitalförsäkring och att den kapitalförsäkring som Hubins distribuerar är förenlig med dina krav och behov.',
    new_da_signoff_text1: 'Du har precis genomgått Hubins prövning av dina krav och behov avseende kapitalförsäkring. Du kommer först få en sammanfattning samt Hubins helhetsbedömning och slutsats presenterad för dig. Efter detta kan du finna, baserat på de uppgifter du har lämnat, en specificering av dina krav och behov avseende de olika momenten som berör kapitalförsäkringen. Hubins har vidare klargjort huruvida dina krav och behov avseende de olika momenten är förenliga med den enda kapitalförsäkring som Hubins distribuerar. Resultatet från krav- och behovsprövningen, samt de svar du har givit, kommer sparas och finnas tillgängliga på plattformen om du skulle vilja ta del av dem senare.',
    new_da_signoff_title2: 'Sammanfattning',
    new_da_signoff_text2: 'Sammanfattningsvis så har du mottagit och accepterat all förköpsinformation. Du har tagit del av utbildningsmaterialet och även fått godkänt på kunskapstestet. Hubins bedömning är att du har tillräckliga kunskaper för att förstå de viktigaste egenskaperna med en kapitalförsäkring i allmänhet och även den kapitalförsäkring som Hubins distribuerar.',
    new_da_signoff_text3: 'Den kapitalförsäkring som Hubins distribuerar är förenlig med dina krav och behov avseende följande',

    knowledge_test_completed: 'Du har fått tillgång till vårt kunskapsmaterial om den försäkring som Hubins distribuerar samt information från Gilla Din Ekonomi för att få kunskap om kapitalförsäkringar i allmänhet.Du har även genomgått ett kunskapstest med godkänt resultat och anses därmed ha kunskap om den kapitalförsäkring som Hubins distribuerar. ',
    tax_completed: 'Du har ett behov av en schablonbeskattad försäkring vilket är större än ditt behov av att kunna göra förlustavdrag.Behovet tillgodoses med kapitalförsäkringen.',

    // New knowledge test
    tax_methods_title: 'För privatpersoner finns det två huvudsakliga beskattningsmetoder för investeringar.',
    tax_methods_texts:
      <>
        <p>
          <strong>Alternativ 1: </strong>
      Vinstbeskattning med möjlighet till förlustavdrag. När investeringarna ägs direkt av dig beskattas du generellt sätt upp till 30% på vinsten.
        </p>
        <p>
          <strong>Alternativ 2: </strong>
      Schablonbeskattning utan möjlighet till förlustavdrag. I en schablonbeskattad sparform, beskattas du år 2 med 0.375% på sparformens värde samt insättningar under året.
        </p>
      </>,
    tax_methods_question: 'Enligt vilket alternativ beskattas en kapitalförsäkring?',
    alt: 'Alternativ',
    what_is_insured: 'Vad är det som är försäkrat i en kapitalförsäkring?',
    the_investments_that_i_do: 'Det är de investeringar jag gör som är försäkrade, och kan därför aldrig minska i värde',
    the_life_assured_life: 'Det är den livförsäkrade personen liv som är försäkrat med 101% av försäkringens värde',
    the_deposited_capital: 'Det är det insatta kapitalet som är försäkrat, och du är därmed garanterad att få tillbaka minst detta belopp',
    insurance_three_roles: 'I en försäkring finns det tre olika roller som är involverade i försäkringen.',
    who_owns_insurance: 'Vem är det som tecknar och äger kapitalförsäkringen?',
    life_assured: 'Livförsäkrad',
    the_beneficial_owners: 'Förmånstagarna',
    the_policyholder: 'Försäkringstagaren',
    who_is_life_assured: 'Vems liv är försäkrat i en kapitalförsäkring?',
    who_receives_payment_in_case_of_insurance: 'Vem mottar utbetalningen vid ett försäkringsfall?',
    owner_of_assets: 'Vem är det som är står som ägare för de tillgångar jag håller i min försäkring?',
    policyholder_is_registered_as_owner: 'Försäkringstagaren är den registrerade ägaren',
    insurance_company_is_registered_as_owner: 'Försäkringsbolaget är den registrerade ägaren',
    i_can_decide_who_owns_assets: 'Jag kan själv bestämma vem som står som ägare',
    cancel_insurance_whenever: 'Kan du avsluta din försäkring när du vill?',
    yes_without_costs: 'Ja, jag kan avsluta den när jag vill helt kostnadsfritt',
    yes_but_cost_based_on_insurance_one_year_term: 'Ja, men försäkringen har ett års löptid och jag debiteras för den löptid som kvarstår',
    no_need_to_hold_insurance_one_year_at_a_time: 'Nej, jag måste hålla försäkringen ett år i taget',

    p_kt: 'Knowledge test',
    p_kyc: 'Know your client',
    p_presale: 'Presale',
    p_da_terms: 'Demand assessment - Terms',
    p_da_purpose: 'Demand assessment - Purpose',
    p_da_transactions: 'Demand assessment - Transactions',
    p_da_products: 'Demand assessment - Products',
    p_da_target_return: 'Demand assessment - Target return',
    p_da_tax: 'Demand assessment - Tax',
    p_da_beneficiaries: 'Demand assessment - Beneficiaries',
    p_da_so: 'Demand assessment - Sign off',
    p_ins: 'Insurance information',
    p_terms: 'Terms',
    p_aftersale: 'Aftersale',
    c_kyc: 'Know your client',
    c_presale: 'Presale',
    c_bi: 'Basic information',
    c_da_terms: 'Demand assessment - Terms',
    c_da_purpose: 'Demand assessment - Purpose',
    c_da_transactions: 'Demand assessment - Transactions',
    c_da_products: 'Demand assessment - Products',
    c_da_target_return: 'Demand assessment - Target return',
    c_da_tax: 'Demand assessment - Tax',
    c_da_beneficiaries: 'Demand assessment - Beneficiaries',
    c_da_so: 'Demand assessment - Sign off',
    c_ins: 'Insurance information',
    c_terms: 'Terms',
    c_aftersale: 'Aftersale',
    c_kt: 'Knowledge test',
    da_result: 'Demand Assessment - Result',
    da_so: 'Demand Assessment - Approval',
  }
});

export default keys;
