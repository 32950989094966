// module.exports.test = "Currency helpers works fine";

/**
 * currencyToNumber
 * @param {string | number} value // ex "1 000 000"
 * @returns {number} Returns the formatted string
 */
export const currencyToNumber = (value) => {
  if (typeof value === 'string') {
    value = Number(value.replace(/\s/g, ''));
  }
  return value;
};
// module.exports.currencyToNumber = currencyToNumber;

/**
 * formatNumber
 * @param {number} c // ex "1 000 000"
 * @param {string} d // The decimal symbol, defaults to .
 * @param {string} t // Render a space for thusands values
 * @param {string} pf // Add a prefix ex the currency to the returned string
 * @returns {number} Returns the formatted string
 */


Number.prototype._formatNumber = function (c, d, t, pf) {
  let n = this,
    precision = isNaN(c = Math.abs(c)) ? 2 : c,
    decimal = typeof d === "undefined" ? "." : d,
    thousand = typeof t === "undefined" ? "," : t,
    negative = n < 0 ? "-" : "",
    // postFix = typeof pf === "undefined" ? pf : '',
    i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c))),
    j = (i.length) > 3 ? i.length % 3 : 0;
  return negative + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousand) + (precision ? decimal + Math.abs(n - i).toFixed(precision).slice(2) : "") + '' + pf;
};
// Number.prototype.formatMoney = function (c, d, t, currency) {
//   let n = this,
//     precision = isNaN(c = Math.abs(c)) ? 2 : c,
//     decimal = typeof d === "undefined" ? "." : d,
//     thousand = typeof t === "undefined" ? "," : t,
//     negative = n < 0 ? "-" : "",
//     i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c))),
//     j = (i.length) > 3 ? i.length % 3 : 0;
//   return negative + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousand) + (precision ? decimal + Math.abs(n - i).toFixed(precision).slice(2) : "") + ' ' + currency;
// };

/**
 *
 * @param {number} value - The amount
 * @param {string} currency - SEK, NOK etc. Provvide a string with a space inside to not show the currency
 * @param {number} dec - Number of decimals. Defaults to 2
 * @return { string } - a formatted number with a currency suffix
 */
export const formatMoney = function (value = 0, currency = 'SEK', dec = 2) {
  // Transform the value to a number if its a string
  value = (typeof value === 'string') ? Number(value) : value;
  // Format if its a number,
  // if its an object or something else, just return the value
  const val = typeof value === 'number' ?
    (
      value._formatNumber(dec, ',', ' ', currency && ' ' + currency)
    ) : (
      value
    );

  return val;
};

// module.exports.formatMoney = formatMoney;


/**
 * formatPercent
 * @param {number} percent - The percent number to be formated
 * @param {number} dec - Number of decimals. Defaults to 2
 * @return {string} - a formated string
 */
export const formatPercent = function (percent, dec = 2) {
  if (typeof percent === 'undefined' || percent === null || isNaN(percent))return '–';
  percent = (typeof percent === 'string') ? Number(percent) : percent;
  if (typeof percent !== 'number') return '-';
  percent = percent._formatNumber(dec, ',', ' ', '%');
  return percent;
};
// module.exports.formatPercent = formatPercent;
