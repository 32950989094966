import React, { useState } from 'react';
import { __ } from 'localisation';
import Wrapper from '@hubins/components/Wrapper';
import { Columns, Column } from 'components/Columns';
import { useReportContext } from '@hubins/components/ReportContext';
import LoadingPage from '@hubins/components/LoadingPage';
import AccountSelector from '@hubins/components/AccountSelector';
import Heading from 'components/Heading';
import YearlyTaxTable from 'modules/Tables/YearlyTaxTable';
import { useReport, useTransactions, useHoldings } from '../../../../packages/queries/report';
import AccountsTable from 'modules/Tables/AccountsTable';
import DistributionTable from 'modules/Tables/DistributionTable';
import EndowmentTable from 'modules/Tables/EndowmentTable';
import HoldingsTable from 'modules/Tables/Holdings';
import InsuranceFees from 'modules/Tables/InsuranceFees';
import TransactionsTable from 'modules/Tables/TransactionsTable';
import NoPortfolios from '../NoPortfolios';
import YearlyReportDateSelector from '@hubins/components/YearlyReportDateSelector';
import PolicyCard from 'components/PolicyCard';

const YearlyReport = () => {

  const {
    ready,
    portfolios,
    portfolio,
    setPortfolioUuid,
    portfolioUuid,
    noPortfolios
    // startDate,
    // endDate,
  } = useReportContext();

  const [year, setYear] = useState(new Date().getFullYear() - 1);
  const [startDate, setStartDate] = useState(year + '-01-01');
  const [endDate, setEndDate] = useState(year + '-12-31');

  const { data: report, isLoading: reportLoading, isFetched } = useReport(startDate, endDate, portfolioUuid);
  const { data: transactions, isLoading: transactionsLoading, isFetched: transactionsIsFetched } = useTransactions(startDate, endDate, portfolioUuid);
  const { data: holdings, isLoading: holdingsLoading } = useHoldings(startDate, endDate, portfolioUuid);

  const handleDateChange = (newYear) => {
    setYear(newYear);
    setStartDate(newYear + '-01-01');
    setEndDate(newYear + '-12-31');
  };


  if (!ready) return <LoadingPage />;

  if (noPortfolios) {
    return (
      <NoPortfolios />
    );
  }

  return (
    <Wrapper>
      <Columns>
        <Column s="12">
          <Heading size="2" className="s-bottom-xl">Årsrapport</Heading>
        </Column>
        <Column m="6">
          <Heading size="4" className="s-bottom-m">Dina konton</Heading>
          <AccountSelector
            portfolios={portfolios}
            setPortfolioUuid={setPortfolioUuid}
            portfolioUuid={portfolioUuid}
          />
        </Column>
        <Column m="6">
          <YearlyReportDateSelector callback={handleDateChange} />
        </Column>


        <Column s="12" m="6" l="6">
          <PolicyCard
            portfolio={portfolio}
          />
        </Column>
        <Column m="6">
          <p className='maxwidth'>
            <strong>
              {__('important_information')}
            </strong>
            <br />
            {__('report_intro_text')}
          </p>
        </Column>
        <Column s="12" m="6" l="6">
          <Heading size="4" className="section-padding">
            {__('liquid_funds')}
          </Heading>
          <AccountsTable
            currency={isFetched ? report.accounts.currency : ''}
            isLoading={reportLoading}
            accounts={isFetched ? report.accounts : []}
          />
        </Column>
        <Column s="12">
          <Heading size="4" className="section-padding">
            {__('access_law')}
          </Heading>
          <DistributionTable
            data={isFetched ? report.distribution : []}
            isLoading={reportLoading}
            currency={isFetched ? report.accounts.currency : ''}
            marketValue={isFetched ? report.marketValue : 0}
          />
        </Column>
        <Column s="12">
          <Heading size="4" className="section-padding">
            {__('endowment_title')}
          </Heading>
          <EndowmentTable
            endowment={isFetched ? report.endowment : []}
            transactionsEndowment={transactionsIsFetched ? transactions.endowment : []}
            isLoading={reportLoading || transactionsLoading}
            currency={isFetched ? report.accounts.currency : ''}
          />
        </Column>
        <Column s="12">
          <HoldingsTable
            holdings={holdings}
            loading={holdingsLoading}
            currency={isFetched ? report.accounts.currency : ''}
            report
          />
        </Column>
        <Column s="12">
          <InsuranceFees
            endowment={isFetched ? report.endowment : []}
            transactionsEndowment={transactionsIsFetched ? transactions.endowment : []}
            isLoading={reportLoading || transactionsLoading}
            currency={isFetched ? report.accounts.currency : ''}
          />
        </Column>
        <Column s="12">
          <YearlyTaxTable
            year={year}
            endowment={isFetched ? report.endowment : []}
            transactionsEndowment={transactionsIsFetched ? transactions.endowment : []}
            isLoading={reportLoading || transactionsLoading}
            currency={isFetched ? report.accounts.currency : ''}
            portfolio={portfolio}
          />
        </Column>
        <Column s="12">
          <Heading size="4" className="section-padding">
            {__('Transactions')}
          </Heading>
          <TransactionsTable
            data={transactionsIsFetched ? transactions.transactions : []}
            isLoading={transactionsLoading}
          />
        </Column>
      </Columns>
    </Wrapper>
  );

};

export default YearlyReport;
