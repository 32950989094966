import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";

const CookieContext = React.createContext();

const CookieProvider = ({ children, categories }) => {
  const savedCookieData = {};
  categories.forEach(cat => {
    savedCookieData[cat.id] = JSON.parse(window.localStorage.getItem("consent_" + cat.id));
  });

  // set checked to true when user has clicked 'Accept' or 'Decline'
  // (true by default but is set to false if no 'consent' exist in localstorage,
  // this is to avoid CookieBanner flashing by before javascript detects localstorage. )
  const [checked, setChecked] = useState(JSON.parse(window.localStorage.getItem("consent_timestamp")));
  // set acceptsCookies to true when user has accepted cookies
  // ( when localstorage 'consent' === true )
  const [acceptsCookies, setAcceptsCookiesState] = useState({
    ...savedCookieData,
    functional: true, // should ALWAYS be true
  });

  const [consent, setConsent] = useState(false);

  const [ expanded, setExpanded ] = useState(false);

  const setAcceptsCookies = useCallback(
    (newState) => {
      categories.forEach(cat => {
        // console.log('consent_" + cat.id', cat.id, newState[cat.id]);
        window.localStorage.setItem("consent_" + cat.id, newState[cat.id] || false);
      });
      window.localStorage.setItem("consent_timestamp", JSON.stringify(Date.now()));

      setAcceptsCookiesState(newState);
      // console.log('newState', newState);
    },
    [],
  );

  return (
    <CookieContext.Provider
      value={{
        checked,
        setChecked,
        acceptsCookies,
        setAcceptsCookies,
        consent,
        setConsent,
        expanded,
        setExpanded,
      }}
    >
      {children}
    </CookieContext.Provider>
  );
};

const useCookie = () => React.useContext(CookieContext);

CookieProvider.propTypes = {
  children: PropTypes.node,
  categories: PropTypes.array.isRequired,
};

export { CookieProvider, useCookie, CookieContext };
export default CookieContext;

/* HOW TO USE

In your app.js, page, or other:
1. import { CookieProvider } from "path to CookieProvider";

2. wrap content in
<CookieProvider>
</CookieProvider>

In your component:
1. import { useCookie } from './CookieContext';
2. define the states you want to use: const { checked, setChecked, acceptsCookies, setAcceptsCookies } = useCookie();
3. use them like you would use states from components

*/
