window.global ||= window; // weird fix for vite not including the global object

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Joyride, { ACTIONS /* EVENTS, STATUS */ } from 'react-joyride';
import { getItem, setItem } from 'states/localStorage.js';
import __ from "localisation";
import './index.scss';

const DB_PREFIX = 'tutorial_';

const hideModalsIfRunning = (run) => {
  const displayValue = run ? 'none' : 'block';
  const modals = document.querySelectorAll('.ReactModalPortal');
  modals.forEach(modal => {
    modal.style.display = displayValue;
  });
};

// Docs for Joyride: https://docs.react-joyride.com/step
const Tutorial = ({ steps }) => {
  const [_steps, setSteps] = useState(false);
  const [run, setRun] = useState(false);
  const [doneSteps, setDoneSteps] = useState([]);
  const [windowInnerHeight, setWindowInnerHeight] = useState('100%');
  // Chech in locale storage if step with id exist;
  useEffect(() => {
    const filteredSteps = steps.filter(step => {
      if (!step.id) console.error('Missing id');
      const isInStorage = getItem(DB_PREFIX + step.id);
      return isInStorage ? false : step;
    });
    setWindowInnerHeight(`${document.documentElement.scrollHeight}px`);
    setSteps(filteredSteps);
    if (filteredSteps.length > 0) {
      setRun(true);
    }
  }, [steps]);

  const handleJoyrideCallback = ({ action, index, status, type }) => {

    if (action === ACTIONS.SKIP) {
      setRun(false);
    }
    if (action === ACTIONS.CLOSE && type === 'step:after') {
      setItem(DB_PREFIX + _steps[index].id, true);
      if (doneSteps.indexOf(_steps[index].id) === -1) {
        setDoneSteps([...doneSteps, _steps[index].id]);
      }
    }
  };

  useEffect(() => {
    hideModalsIfRunning(run);
  }, [run]);

  useEffect(() => {
    if (doneSteps.length === _steps.length) {
      setRun(false);
    }
  }, [doneSteps]);

  if (!_steps  || _steps.length === 0) {
    return false;
  }
  return (
    <Joyride
      run={run}
      steps={_steps}
      showSkipButton={true}
      callback={handleJoyrideCallback}
      // // All possible styles: https://github.com/gilbarbara/react-joyride/blob/3e08384415a831b20ce21c8423b6c271ad419fbf/src/styles.js
      styles={{
        overlay: {
          height: windowInnerHeight,
        },
        options: {
          primaryColor: 'var(--c-primary)',
          overlayColor: 'rgba(2, 6, 76, 0.5)',
          zIndex: 1000,
        },
        tooltip: {
          borderRadius: 8,
          fontSize: '1rem',
        },
        buttonClose: {
          color: 'var(--c-neutral-5)',
          strokeWidth: 2,
        },
        buttonNext: {
          padding: '10px 30px',
          borderRadius: 12,
          fontFamily: 'inherit',
          fontWeight: 800,
        },
        spotlight: {
          borderRadius: 68 / 2
        },
        tooltipTitle: {
          fontSize: '1rem',
          marginTop: 20,
        },
        tooltipContent: {
          padding: 10,
        },
        buttonSkip: {
          borderRadius: 12,
          fontFamily: 'inherit',
          fontWeight: 800,
        }
      }}
      // // Docs for react-floater https://github.com/gilbarbara/react-floater
      floaterProps={{
        disableAnimation: true
      }}
      locale={{ back: __('go_back'), close: 'Nästa', last: __('previous'), next: __('next'), skip: 'Hoppa över' }}
    />
  );
};

Tutorial.propTypes = {
  steps: PropTypes.array,
};

Tutorial.defaultProps = {
  steps: [],
};

export default Tutorial;
