import React, { useEffect, useState } from 'react';
import middleman from 'helpers/middleman';
import { LoadingBox } from 'components/LoadingIndicators';
import CreateForm from 'modules/CreateForm';
import { Columns, Column } from 'components/Columns';
import Wrapper from '@hubins/components/Wrapper';
import { formatContent as formContentHandler } from 'helpers/form';

const FormPreview = ({ match, isFormId }) => {
  const { id } = match.params;
  const [form, setForm] = useState(false);

  const fetchData = async () => {
    const res = isFormId
      ? await middleman.get(`/formscms/form/${id}`)
      : await middleman.get(`/formscms/revision/show/${id}`);
    const data = res.data;
    data.content = formContentHandler(data);
    setForm(data);
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (!form) {
    return <LoadingBox />;
  }

  console.log('form', form);

  return (
    <Wrapper>
      <Columns>
        <Column l="6">
          <CreateForm
            formData={form.content}
            submitType={() => {console.log('submit');}}
            submitText={"Spara"}
            buttonProps={{ icon: "arrow right 1", iconSize: 16 }}
            loading={false}
            disabled={false}
          />
        </Column>
      </Columns>
    </Wrapper>
  );

};

export default FormPreview;
