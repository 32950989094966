import __ from 'localisation/hubins/registration';

const bankList = () => ({
  'SE': [{
    id: 'SWEDSESS',
    title: 'Swedbank',
    value: false,
  },
  {
    id: 'ESSESESS',
    title: 'SEB',
    value: false,
  },
  {
    id: 'HANDSESS',
    title: 'Handelsbanken',
    value: false
  },
  {
    id: 'NDEASESS',
    title: 'Nordea',
    value: false
  },
  {
    id: 'other',
    title: __('other_bank'),
    value: false,
  }],
  'FI': [
    {
      id: 'HELSFIHH',
      title: 'Aktia Bank',
      value: false
    },
    {
      id: 'NDEAFIHH',
      title: 'Nordea Bank',
      value: false,
    },
    {
      id: 'AABAFI22',
      title: 'Bank of Åland',
      value: false,
    },
    {
      id: 'DABAFIHH',
      title: 'Danske Bank',
      value: false,
    },
    {
      id: 'HANDFIHH',
      title: 'Handelsbanken',
      value: false,
    },
    {
      id: 'other',
      title: __('other_bank'),
      value: false,
    },
  ],
  'NO': [],
});

export const marketBanks = {
  "SE": [
    {
      link: "https://www.seb.se/",
      name: "SEB",
      path: "/images/banks/sweden/seb.svg",
    },
    {
      link: "https://www.nordea.se/",
      name: "Nordea",
      path: "/images/banks/sweden/nordea.svg",
    },
    {
      link: "https://www.danskebank.se/",
      name: "Danske Bank",
      path: "/images/banks/sweden/danskebank.svg",
    },
    {
      link: "https://www.sbab.se/",
      name: "SBAB",
      path: "/images/banks/sweden/sbab.svg",
    },
    {
      link: "https://www.handelsbanken.se/",
      name: "Handelsbanken",
      path: "/images/banks/sweden/handelsbanken.svg",
    },
    {
      link: "https://www.lansforsakringar.se/",
      name: "Lansförsäkringar",
      path: "/images/banks/sweden/lansforsakringar.svg",
    },
    {
      link: "https://www.swedbank.se/",
      name: "Swedbank",
      path: "/images/banks/sweden/swedbank.png",
    }
  ],
  "NO": [
    {
      link: "https://www.bnbank.no/",
      name: "BN Bank",
      path: "/images/banks/norway/bnbank.png",
    },
    {
      link: "https://www.danskebank.no/",
      name: "Danske Bank",
      path: "/images/banks/norway/danskebank.svg",
    },
    {
      link: "https://www.dnb.no/",
      name: "DNB",
      path: "/images/banks/norway/dnb.svg",
    },
    {
      link: "https://www.handelsbanken.no/",
      name: "Handelsbanken",
      path: "/images/banks/norway/handelsbanken.svg",
    },
    {
      link: "https://www.nordea.no/",
      name: "Nordea",
      path: "/images/banks/norway/nordea.svg",
    },
    {
      link: "https://www.sbanken.no/",
      name: "SBanken",
      path: "/images/banks/norway/sbanken.svg",
    },
    {
      link: "https://www.seb.no/",
      name: "SEB",
      path: "/images/banks/norway/seb.svg",
    },
    {
      link: "https://www.sparebank1.no/",
      name: "SpareBank",
      path: "/images/banks/norway/sparebank.svg",
    }
  ],
  "FI": [
    {
      link: "https://www.aktia.fi/",
      name: "Aktia",
      path: "/images/banks/finland/aktia.svg",
    },
    {
      link: "https://www.alandsbanken.fi/",
      name: "Alandsbanken",
      path: "/images/banks/finland/alandsbanken.svg",
    },
    {
      link: "https://www.danskebank.fi/",
      name: "Danske Bank",
      path: "/images/banks/finland/danskebank.svg",
    },
    {
      link: "https://www.handelsbanken.fi/",
      name: "Handelsbanken",
      path: "/images/banks/finland/handelsbanken.svg",
    },
    {
      link: "https://www.nordea.fi/",
      name: "Nordea",
      path: "/images/banks/finland/nordea.svg",
    },
    {
      link: "https://www.op.fi/",
      name: "OP Financial Group",
      path: "/images/banks/finland/opfinancialgroup.svg",
    }

  ],
};

export default bankList;
