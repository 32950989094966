import React, { useState, useEffect } from 'react';
import __ from 'localisation';
import { Prompt } from 'react-router-dom';

const PreventUnload = ({ disableNav: propsDisableNav = false }) => {

  const [disableNav, setDisableNav] = useState(propsDisableNav);

  // If dev env, dont prevent unload
  if (import.meta.env.MODE === 'development') {
    return false;
  }


  const onNavigationEvent = (e) => {
    e.preventDefault();
    setDisableNav(true);
  };

  const onUnload = (e) => {
    e = e || window.event;
    if (e) {
      e.returnValue = __('leave_form_msg');
    }
    return __('leave_form_msg');
  };

  useEffect(() => {
    window.addEventListener("beforeunload", onUnload);

    window.onpopstate = onNavigationEvent;
    return () => {
      window.removeEventListener("beforeunload", onUnload);
    };
  }, []);

  useEffect(() => {
    setDisableNav(propsDisableNav);
  }, [propsDisableNav]);

  return (
    <Prompt
      when={disableNav}
      message={`${__('leave_form_msg')}`}
    />
  );


};

export default PreventUnload;
