import React from 'react';
import { __ } from 'localisation';
import Wrapper from '@hubins/components/Wrapper';
import { Columns, Column } from 'components/Columns';
import { useReportContext } from '@hubins/components/ReportContext';
import LoadingPage from '@hubins/components/LoadingPage';
import LoadingBar from 'components/LoadingIndicators';
import AccountSelector from '@hubins/components/AccountSelector';
import Heading from 'components/Heading';
import RangeDatePicker from 'modules/RangeDatePicker';
// import { ChartLine } from 'components/Charts';
// import ErrorBoundary from 'components/ErrorBoundary';
import { /*useChart,*/ useReport, useTransactions, useHoldings } from '../../../../packages/queries/report';
import AccountsTable from 'modules/Tables/AccountsTable';
// import ProceedsTable from 'modules/Tables/ProceedsTable';
import DistributionTable from 'modules/Tables/DistributionTable';
import EndowmentTable from 'modules/Tables/EndowmentTable';
import HoldingsTable from 'modules/Tables/Holdings';
import InsuranceFees from 'modules/Tables/InsuranceFees';
import TransactionsTable from 'modules/Tables/TransactionsTable';
import NoPortfolios from '../NoPortfolios';
import PolicyCard from 'components/PolicyCard';
import { getMoney } from 'helpers';

const Report = () => {

  const {
    ready,
    portfolios,
    setPortfolioUuid,
    portfolioUuid,
    startDate,
    endDate,
    status,
    datePickerCallback,
    noPortfolios,
    portfolio,
  } = useReportContext();



  // const { data: chart, isFetched: chartIsFetched, isLoading: chartLoading } = useChart(startDate, endDate, portfolioUuid);
  const { data: report, isLoading: reportLoading, isFetched } = useReport(startDate, endDate, portfolioUuid);
  const { data: transactions, isLoading: transactionsLoading, isFetched: transactionsIsFetched } = useTransactions(startDate, endDate, portfolioUuid);
  const { data: holdings, isLoading: holdingsLoading, isFetched: holdingsIsFetched } = useHoldings(startDate, endDate, portfolioUuid);


  if (!ready) return <LoadingPage />;

  if (noPortfolios) {
    return (
      <NoPortfolios />
    );
  }

  return (
    <Wrapper>
      <Columns>
        <Column s="12">
          <Heading size="2" className="s-bottom-xl">Rapport</Heading>
        </Column>
        <Column m="6">
          <Heading size="4" className="s-bottom-m">Dina konton</Heading>
          <AccountSelector
            portfolios={portfolios}
            setPortfolioUuid={setPortfolioUuid}
            portfolioUuid={portfolioUuid}
          />
        </Column>
        <Column m="6">
          <RangeDatePicker
            from={startDate}
            to={endDate}
            onClick={datePickerCallback}
            loading={status === 'loading'}
          />
        </Column>
        {/* <Column m="6" className="chart-container">
          <ErrorBoundary>
            <ChartLine
              legendX={__("Accrued_Interest")}
              legendY={__("Percent")}
              xDomain={chartIsFetched ? [chart.minValue, chart.maxValue] : undefined }
              data={chart && !chart.placeholder ? chart : false}
              loading={chartLoading}
            />
          </ErrorBoundary>
        </Column> */}
        {/* <Column m="6">

        </Column> */}
        <Column s="12" m="6" l="6">
          <PolicyCard
            portfolio={portfolio}
          />
          {/* <Heading size="4" className="section-padding">
            {__('Proceeds')}
          </Heading>
          <ProceedsTable
            data={isFetched ? report.proceeds : []}
            isLoading={reportLoading}
            currency={isFetched ? report.accounts.currency : ''}
          /> */}
        </Column>
        <Column s="12" m="6" l="6">
          <p className='maxwidth s-top-xl'>
            <strong>
              {__('important_information')}
            </strong>
            <br />
            {__('report_intro_text')}
          </p>
        </Column>
        <Column s="12">
          <Heading size="4" className="s-top-m">
            Totalt värde
          </Heading>
          <Heading size="2" className="s-bottom-m">
            {reportLoading ? <LoadingBar /> : getMoney(report?.marketValue)}
          </Heading>
        </Column>

        <Column s="12" m="6" l="6">
          <Heading size="4" className="section-padding">
            {__('liquid_funds')}
          </Heading>
          <AccountsTable
            currency={isFetched ? report.accounts.currency : ''}
            isLoading={reportLoading}
            accounts={isFetched ? report.accounts : []}
          />
        </Column>
        <Column s="12" m="6" l="6">
          <Heading size="4" className="section-padding">
            {__('access_law')}
          </Heading>
          <DistributionTable
            data={isFetched ? report.distribution : []}
            isLoading={reportLoading}
            marketValue={isFetched ? report.marketValue : 0}
            currency={isFetched ? report.accounts.currency : ''}
          />
        </Column>
        <Column s="12">
          <Heading size="4" className="section-padding">
            {__('endowment_title')}
          </Heading>
          <EndowmentTable
            endowment={isFetched ? report.endowment : []}
            transactionsEndowment={transactionsIsFetched ? transactions.endowment : []}
            isLoading={reportLoading || transactionsLoading}
            currency={isFetched ? report.accounts.currency : ''}
          />
        </Column>
        <Column s="12">
          <HoldingsTable
            holdings={holdings}
            loading={holdingsLoading}
            currency={isFetched ? report.accounts.currency : ''}
            report
          />
        </Column>
        <Column s="12">
          <InsuranceFees
            endowment={isFetched ? report.endowment : []}
            transactionsEndowment={transactionsIsFetched ? transactions.endowment : []}
            isLoading={reportLoading || transactionsLoading}
            currency={isFetched ? report.accounts.currency : ''}
          />
        </Column>
        <Column s="12">
          <Heading size="4" className="section-padding">
            {__('Transactions')}
          </Heading>
          <TransactionsTable
            data={transactionsIsFetched ? transactions.transactions : []}
            isLoading={transactionsLoading}
            currency={portfolio.currency}
          />
        </Column>
      </Columns>
    </Wrapper>
  );

};

export default Report;
