import React from 'react';
import middleman from 'helpers/middleman';
import Heading from 'components/Heading';
import { sanitizeSsn } from 'helpers';
import { useFakeRoaring } from 'states/utilities';
import __ from 'localisation/hubins/registration';
import __ui from 'localisation';

export const getRoaringData = async (
    orgNr, setData:any = false,
    setLoading:any = false,
    setError:any = false,
    minBeneficials:number = 4
  ) => {
  // Reset all states
  setData && setData(false);
  setLoading && setLoading(true);
  setError && setError(false);

  // Fetch company roaring data
  let formatedRoaringData = {};

  const body = {
    isTest: useFakeRoaring()
  };

  await middleman.promisePost(`/roaring/org/start/${sanitizeSsn(orgNr)}`, body)
    .then(response => {
      setLoading && setLoading(false);
      // Handling error responses
      if (response.status !== 200) {
        if (setError) {
          setError(<Heading size="4">Kunde inte hämta data. Vänligen försök igen</Heading>);
        }
        return false;
      }
      if (!response.data.companies[0] || !response.data.companies[0].companyName) {
        setError && setError(__('fetch_company_error'));
        return false;
      }

      // Format and send data
      const company = response.data.companies[0];
      company.companyOrgNo = orgNr; // we don't get back a formatted org number from roaring, so we use the one the user filled in from the front-end
      const signatories = response.data.signatories;

      const beneficials = response.data.beneficials;

      // Add some extra empty beneficials
      const bLength = beneficials.length;
      if (bLength < minBeneficials) {
        for (var x = 0; x < minBeneficials - bLength; x++) {
          beneficials.push({
            firstName: '',
            lastName: '',
            ssn: '',
            hide: true,
          });
        }
      }

      formatedRoaringData = { company, signatories, beneficials, beneficialsLength: bLength };
      setData && setData(formatedRoaringData);
    })
    .catch(err => {
      setLoading && setLoading(false);
      if (err.response && err.response.status === 400 && setError) {
        setError(
          <Heading size="4">En eller flera bolagspersoner eller firmatecknare saknar svenskt personnummer. Vänligen kontakta Hubins kundtjänst för hjälp med att registrera företaget.</Heading>
        );
        return;
      }
      const error = (err.response && err.response.data) ? `${__ui('roaring_error_message')}` : __ui("standard_error_message", { email: __ui('platform_email'), tel: __ui('platform_tel') });
      setError && setError(error);
      formatedRoaringData = error;
    });

  return formatedRoaringData;
};

export default getRoaringData;