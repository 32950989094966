import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { $, formatPercent } from 'helpers';
// import 'components/Charts/index.scss';
import Loadable from 'jaybe-react-loadable';
import { LoadingBox } from 'components/LoadingIndicators';
import './index.scss';

const percentLabel = chartProps => {
  return formatPercent(chartProps.value);
};

class ChartPieLoaded extends Component {
  render() {

    const { cells, valueType, colors, ResponsiveContainer, PieChart, Pie, Cell } = this.props;
    return (
      <ResponsiveContainer>
        <PieChart>
          <Pie
            outerRadius="70%"
            dataKey="value"
            data={cells}
            fill="#8884d8"
            label={valueType === 'percent' ? percentLabel : true}
            isAnimationActive={this.props.animation}
          >
            {cells.map((entry, index) => <Cell key={index} fill={colors[index % colors.length]} />)}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    );
  }
}
const ChartPie = Loadable({
  loader: () => import('recharts/es6/' /* webpackChunkName: "recharts" */),
  loading: LoadingBox,
  timeout: 120000, // 2 min
  render(loaded, props) {
    return <ChartPieLoaded {...props}
      PieChart={loaded.PieChart}
      Pie={loaded.Pie}
      Cell={loaded.Cell}
      ResponsiveContainer={loaded.ResponsiveContainer}
    />;
  },
});

export default ChartPie;

ChartPie.defaultProps = {
  cells: [],
  valueType: 'default',
  colors: [$.c.i_purple_1, $.c.primary], // Add more colours in case of more cells
  animation: true,

};

ChartPie.propTypes = {
  cells: PropTypes.array,
  valueType: PropTypes.oneOf(['default', 'percent']),
  colors: PropTypes.array
};
