import React from 'react';
import { useReportContext } from '@hubins/components/ReportContext';
import { usePremiumTransactions } from 'queries/transactions';
import { LoadingBox } from 'components/LoadingIndicators';
import WithdrawalAndPremiumCard from '../WithdrawalAndPremiumCard';
import Heading from 'components/Heading';

type Props = {
  portfolioUuid?: string;
};

const OverviewPremiumTransactions = ({portfolioUuid: propsPortfolioUuid}) => {

  const { portfolioUuid } = useReportContext();
  const { data, isLoading, isFetched } = usePremiumTransactions(
    propsPortfolioUuid
      ? propsPortfolioUuid
      : portfolioUuid
    );

  if (isLoading) return <LoadingBox />;

  return (
    <>
      {(isFetched && data.length > 0) && (
        <>
          <Heading size="4">Insättningar</Heading>
          {data?.map((transaction: any) => (
            <WithdrawalAndPremiumCard key={transaction.uuid} {...transaction} />
          ))}
        </>
      )}
    </>
  );
};

export default OverviewPremiumTransactions;