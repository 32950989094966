import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';

const Heading = ({
  children,
  element: DynamicElement = 'h2',
  size = 2,
  className = '',
  center,
  ...restProps
}) => {
  return (
    <DynamicElement className={`heading heading--${size} ${className}${center ? ' center' : ''}`} {...restProps}>
      {children}
    </DynamicElement>
  );
};

Heading.propTypes = {
  children: PropTypes.node.isRequired,
  sectionTitle: PropTypes.bool,
  // element: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'legend']),
  size: PropTypes.oneOf([1, 2, 3, 4, 5, "1", "2", "3", "4", "5", "hero-1", "hero-2"]),
  className: PropTypes.string,
  center: PropTypes.bool,
};

export default Heading;
