import React, { useState } from 'react';
import __ from "localisation/hubins";
import './index.scss';
import { Columns, Column } from 'components/Columns';
import { LoadingAnimation } from '../../settings';
import { Redirect } from "react-router-dom";
import FeaturedInvestments from 'modules/FeaturedInvestments';
import Heading from 'components/Heading';
import Main from 'components/Main';
// import TbaInvestments from 'modules/TbaInvestments';
import MarketPlaceValidation from 'modules/MarketPlaceValidation';
import Wrapper from '@hubins/components/Wrapper';
import { useOnSaleSecurities } from 'queries/securities';
import { useReportContext } from '@hubins/components/ReportContext';
import ReadMore from 'components/Accordeon/ReadMore';
import CooperationCards from '@hubins/components/CooperationCards/CooperationCards';
import { useFeatureIsOn } from '@growthbook/growthbook-react';


// import ErrorBoundary from 'components/ErrorBoundary';
// import Market from 'modules/Tables/Market';

export const Invest = () => {

  const royc = useFeatureIsOn('royc-allowed-users');

  const {
    ready,
    noPortfolios,
  } = useReportContext();

  const INVEST = true;
  const [marketPlaceValidated, setMarketPlaceValidated] = useState(false);

  const { data: securities, isLoading: securitiesLoading } = useOnSaleSecurities();

  if (!INVEST) {
    return <Redirect to="/" />;
  }

  if (!marketPlaceValidated) return <MarketPlaceValidation callback={setMarketPlaceValidated} />;

  return (
    <>
      <Main>
        {/* <main className="main main--no-top-padding" id="MainContent" data-shiftonsidebaropen> */}
        {/* <section className="wrapper wrapper--tight"> */}
        <Wrapper>
          <Columns>
            <Column l="6">
              <Heading size="2">
                {__('marketplace_sub_title')}
              </Heading>
              <p>
                {__('marketplace_text')}
              </p>
            </Column>
            <Column l="6">
              <img src='/illustrations/Investeringssidan.svg' alt="Investeringssidan" />
            </Column>
            {royc && (
              <Column s="12">
                <CooperationCards />
              </Column>
            )}
            {(securitiesLoading || !ready) ?
              (
                <div className="flex-center" style={{ height: '39rem' }}>
                  <LoadingAnimation active />
                  <p className="primary-market__clarification clarification">{__('fetching_securities')}</p>
                </div>
              ) :
              (
                <FeaturedInvestments entries={securities} noPortfolios={noPortfolios} />
              )}
            <div className='maxwidth s-top-xl'>
              <ReadMore
                visibleContent={
                  <>
                    <Heading size="4">
                      {__('marketplace_disclaimer_title')}
                    </Heading>

                    <Heading size="4">{__('disclaimer_title_1')}</Heading>
                    <p>{__('disclaimer_text_1')}</p>
                  </>
                }
                hiddenContent={
                  <>
                    <Heading size="4">{__('disclaimer_title_3')}</Heading>
                    <p>{__('disclaimer_text_3')}</p>

                    {/* <Heading size="4">{__('disclaimer_title_4')}</Heading>
                    <p>{__('disclaimer_text_4')}</p>
                    <p>{__('disclaimer_text2_4')}</p> */}

                    <Heading size="4">{__('disclaimer_title_6')}</Heading>
                    <p>{__('disclaimer_text_6')}</p>
                    <p>{__('disclaimer_text_6.1')}</p>

                    <Heading size="4">{__('disclaimer_title_7')}</Heading>
                    <p>{__('disclaimer_text_7')}</p>

                    <Heading size="4">{__('disclaimer_title_8')}</Heading>
                    <p>{__('disclaimer_text_8')}</p>

                    <Heading size="4">{__('disclaimer_title_2')}</Heading>

                    <p>
                      <strong>{__('disclaimer_subtitle_2')}</strong><br />
                      {__('disclaimer_text_2')}
                    </p>

                    <p>
                      <strong>{__('disclaimer_subtitle2_2')}</strong> <br />
                      {__('disclaimer_text2_2')}
                    </p>

                    <p>{__('disclaimer_text3_2')}</p>

                    <Heading size="4">{__('disclaimer_title_5')}</Heading>
                    <p>{__('disclaimer_text_5')}</p>
                  </>
                }
              />
            </div>
          </Columns>
        </Wrapper>
      </Main>
    </>
  );
};


export default Invest;
