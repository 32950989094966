const mod11OfNumberWithControlDigit = (input) => {
  let controlNumber = 2,
    sumForMod = 0,
    i = 0;

  for (i = input.length - 2; i >= 0; --i) {
    sumForMod += input.charAt(i) * controlNumber;
    if (++controlNumber > 7)
      controlNumber = 2;
  }
  let result = (11 - sumForMod % 11);

  return result === 11 ? 0 : result;
};


const norwegianOrganisation = (orgNumber) => {
  orgNumber += '';
  if (!orgNumber || orgNumber.length !== 9) {
    return false;
  }
  return parseInt(orgNumber.charAt(orgNumber.length - 1), 10)
    === mod11OfNumberWithControlDigit(orgNumber);
};

export default norwegianOrganisation;
