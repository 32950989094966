import Validation from 'components/Validation';
import methods from './onChangeMethods';

//Since all of these need to be binded to the parent, functions cannot be declared as es6 arrow functions

export function handleFiles(files, id) {
  console.log(files, id);
}

/**
 * [validationHandler runs every components Validations]
 * @param  {obj} currentState [replica of the current value in state that is being validated]
 * @return {obj}              [modified version where currentState.error has been modified]
 */

const handleOnchangeLogic = (id, formState, optionId) => {
  const field = { ...formState[id] };
  if (field.onChangeLogic) {
    field.onChangeLogic.forEach((method) => {
      const args = method.params;
      formState = methods[field.type][method.method](args, id, formState, optionId);
    });
    formState = {
      ...formState,
      [id]: field
    };
  }
  return formState;
};

function validationHandler(currentState) {
  const { validation, type, value } = currentState;
  if (validation) {
    validation.some(val => {
      if (typeof Validation[val] !== 'undefined') {
        const result = Validation[val](value, type);
        currentState.error = result;
        return result;
      }
      return false;
    });
  }
  return currentState;
}

/**
 * [handleInputChange is a collection of all the inputs different onChange handlers]
 */

class handleInputChange {

  /**
   * [
   * constructo receives parent this and binds all of is methods to that
   * in order to be able to handle the parent state
   * ]
   * @param {object} formState - current createForm state of form data
   * @param {function} setFormState - sets the createForm state of form data
   */

  constructor(formState, setFormState) {

    this.formState = formState;
    this.setFormState = setFormState;

  }

  setClassFormState = (formState) => {
    this.formState = formState;
  }

  /**
   * [All of the below methods has access to these props]
   * @param  {string} sectionId [the id of the section the components belongs to ]
   * @param  {partId} partId    [the components id]
   * @param  {string} itemId    [if the components such as dropdown/select has multiple options, this is the id of the changed option]
   * @param  {string} prevState [id of the previous selected itemId]
   * @param  {event} event      [the event passed by the onChange method on the input]
   * @return {}                 [updates state with the new value, dosnt return anything]
   */


  input = (partId, validation, value) => {

    const targetValue = value;
    let currentState = this.formState[partId];
    currentState.value = targetValue;
    currentState = validationHandler(currentState);

    this.setFormState({
      ...this.formState,
      [partId]: currentState
    });

  }

  textarea = (value, partId) => {
    const targetValue = value;
    let currentState = this.formState[partId];
    currentState.value = targetValue;
    currentState = validationHandler(currentState);

    this.setFormState({
      ...this.formState,
      [partId]: currentState
    });
  }

  //something wrong

  dropdown = (partId, itemId, prevState) => {
    const targetValue = true;
    let currentState = this.formState[partId];

    if (prevState) {
      currentState.value[prevState].value = false;
    }

    currentState.value[itemId].value = targetValue;

    currentState = validationHandler(currentState);

    this.setFormState({
      ...this.formState,
      [partId]: currentState,
    });

  }

  select = (fieldId, optionId, prevState, event) => {
    const targetValue = event.target.checked;
    let currentState = this.formState[fieldId];
    const currentObject = currentState.value[optionId];

    if (prevState) {
      currentState.value[prevState].value = false;
    }

    currentObject.value = targetValue;

    currentState = validationHandler(currentState);

    let newFormState = {
      ...this.formState,
      [fieldId]: currentState
    };

    newFormState = handleOnchangeLogic(fieldId, newFormState, optionId);


    this.setFormState(newFormState);

  }

  button = (sectionId, partId, toggleParts) => {

    toggleParts.map(part => {
      let newState = { ...this.formState[part] };
      newState.visible = !newState.visible;

      this.setFormState({
        ...this.formState,
        [part]: newState
      });
    });

    this.setFormState({
      ...this.formState,
      [partId]: {
        ...this.formState[partId],
        toggle: !this.formState[partId].toggle
      }
    });

  }

  checkbox = (sectionId, partId, itemId, prevState, event) => {
    const targetValue = event.target.checked;
    let currentState = this.formState[partId];
    currentState.value = targetValue;
    currentState = validationHandler(currentState);

    this.setFormState({
      ...this.formState,
      [partId]: currentState
    });
  }

  /**
   * [repeater only handles the visible data of the assigned keys]
   * @param  {array} keys  [collection of ids that should be handled by the repeater]
   * @param  {number} index [number of the current index of repeated items]
   * @return {}       [returns nothing only adjusts the parent state]
   */

  repeater = (partId, value) => {
    let currentState = this.formState[partId];
    currentState.value = value;

    this.setFormState({
      ...this.formState,
      [partId]: currentState
    });
  }

  modal = (id, value) => {
    let currentValue = this.formState[id];
    currentValue.open = value;

    this.setFormState({
      ...this.formState,
      [id]: currentValue,
    });

  }

  slider = (partId, values) => {

    let currentValue = this.formState[partId];
    currentValue.value = values;

    this.setFormState({
      ...this.formState,
      [partId]: currentValue
    });

  }

  file = (value, id) => {
    let currentValue = this.formState[id];
    currentValue.value = value;

    this.setFormState({
      ...this.formState,
      [id]: currentValue
    });
  }

  tableselect = (sectionId, partId, itemId, prevState) => {
    let currentState = this.formState[partId];

    if (prevState) {
      currentState.value[prevState].value = false;
    }

    currentState.value[itemId].value = true;

    currentState = validationHandler(currentState);

    this.setFormState({
      ...this.formState,
      [partId]: currentState,
    });
  }

}
export default handleInputChange;
