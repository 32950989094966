import React from 'react';
import { __, __route } from 'localisation';
import { formatMoney } from 'helpers';
import { LoadingBar } from 'components/LoadingIndicators';
import TableFooter from 'components/TableFooter';
import Thumbnail from 'components/Thumbnail';
import { Link } from 'react-router-dom';
import Badge from '@hubins/components/Badge';
import EventCard from 'modules/EventCard';

const statusToColor = {
  '5': 'info', // Accepted
  '4': 'info', // Open
  '8': 'warning', // Rejected
  '10': 'info', // Sent to execution
  '6': 'info',  // In execeution
  '2': 'success'  // Executed
};

const getStatusAndText = (statusCode) => {
  return {
    status: __(`stock_${statusCode}_status`),
    color: statusToColor[statusCode],
    text: __(`stock_${statusCode}_text`),
  };
};

const StockCard = ({ data }) => {
  const type = data.type ? data.type.typeCode : '';

  const tableData = [
    { label: __('amount'), value: data.amount },
    { label: type === 'S' ? __('sell_course') : __('buy_course'), value: formatMoney(data.price / data.amount, data.currencyCode) },
    { label: __('total_amount'), value: formatMoney(data.tradeAmount, data.currencyCode) }, // trade amount
  ];

  const { status, color, text } = getStatusAndText(data.orderStatus);


  return (
    <EventCard
      data={data}
      text={text}
      cardtype="TradeOrderCard"
      alt={name}
      thumbnail={
        <Link to={`${__route('case')}/${data.security ? data.security.id : ''}`} className="event-card__thumbnail">
          <Thumbnail
            src={data.thumbnail}
            fill
            alt={(data.hero && data.hero.text && data.hero.text !== null)
              ? data.hero.text :
              name || ''} />
        </Link>
      }
      meta={
        type
          ? `${__('type_' + type)} - ${data.transactionDate}`
          : <LoadingBar medium />
      }

      status={<Badge color={color}>{status}</Badge>}
      footer={
        <TableFooter
          data={data.placeholder ? undefined : tableData}
        />
      }
    />
  );
};

export default StockCard;
