import React, { useEffect, useState } from 'react';
import { __route } from 'localisation';
import LoadingPage from '@hubins/components/LoadingPage';
import { Redirect } from "react-router-dom";
import middleman from 'helpers/middleman';

const MarketPlaceValidation = ({ callback }) => {

  const [redirect, setRedirect] = useState(false);

  const validate = async () => {
    const response = await middleman.promiseGet('/marketplace/show')
      .then((res) => {
        return res.data;
      });
    if (response) {
      callback(true);
      return;
    }
    setRedirect(true);
  };

  useEffect(() => {
    validate();
  }, []);

  if (redirect) return <Redirect to={__route('invest_disclaimer')} />;

  return <LoadingPage />;
};

export default MarketPlaceValidation;
