import React, { useEffect, useState, useCallback } from 'react';
import { motion } from 'framer-motion';
import { getRoaringData } from '../../../projects/hubins/views/CompanySignupForms/GetCompanyRoaringData';
import Input from '@hubins/components/Input';
import __ from 'localisation';
import { orgNumber } from 'components/Validation';
import Button, { ButtonWrap } from '@hubins/components/Button';

const CompanyRoaring = ({ callback, reset }) => {

  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [data, setData] = useState(false);
  const [roaringError, setRoaringError] = useState(false);
  const [companyOrg, setCompanyOrg] = useState({
    value: import.meta.env.MODE === 'development' ? '559135-5614' : '',
    error: false
  });

  useEffect(() => {
    if (data && callback) {
      callback(data);
    }
  }, [data, callback]);

  useEffect(() => {
    if (submitted) {
      setData(false);
      setSubmitted(false);
    }
  }, [reset]);

  const submit = (e) => {
    e.preventDefault();
    const value = e.target.org_number.value;
    const orgNumberError = orgNumber(value);
    if (!orgNumberError) {
      getRoaringData(companyOrg.value, setData, setLoading, setRoaringError);
      setSubmitted(true);
    }
  };

  const handleChange = useCallback((value) => {
    setCompanyOrg({
      value, error: orgNumber(value)
    });
  }, [setCompanyOrg]);

  return(
    <>
      {roaringError ? (
        <>
          {roaringError}<br />
          <ButtonWrap>
            <Button onClick={() => {setRoaringError(false);}}>Försök igen</Button>
          </ButtonWrap>
        </>
      ) : (
        <form
          key="form"
          onSubmit={submit}
        >
          <motion.div>
            <Input
              disabled={submitted}
              name="org_number"
              placeholder="XXXXXX-XXXX"
              required
              type="text"
              label={__('corporate_identity_number')}
              value={companyOrg.value}
              error={companyOrg.error ? __('org_validation_error') : false}
              callback={handleChange}
            />
          </motion.div>
          {!data && (
            <Button
              loading={loading}
              type="submit"
              className="cta spacing s-top-xl"
              iconColor='white'
              size="large"
              icon="Download Dash Arrow 1"
            >
            Hämta uppgifter
            </Button>
          )}
        </form>
      )}

    </>
  );
};

export default CompanyRoaring;
