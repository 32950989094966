import React, { Component } from 'react';
import DatePicker from '@hubins/components/DatePicker';
import Button from '@hubins/components/Button';
// import { transactiontypes } from 'helpers';
import { __ } from "localisation";
import { Columns, Column } from 'components/Columns';
import './index.scss';

class TransactionDatePicker extends Component {

  constructor(props) {
    super(props);
    this.state = {
      from: new Date(this.props.from),
      to: new Date(this.props.to),
      transactionFilter: '*',
      securityFilter: '*'
    };
    this.handleToChange = this.handleToChange.bind(this);
    this.handleFromChange = this.handleFromChange.bind(this);
  }

  handleToChange(to) {
    this.setState({ to });
  }

  handleFromChange(from) {
    if (from > this.state.to) {
      this.setState({ from, to: from });
    } else {
      this.setState({ from });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.security !== this.state.security) {
      this.setState({ security: nextProps.security });
    }
  }

  render() {
    const { from, to, transactionFilter, securityFilter } = this.state;
    const modifiers = { start: from, end: to };
    const fromProps = { selectedDays: [from, { from, to }], disabledDays: { after: new Date() }, modifiers };
    const toProps = { selectedDays: [from, { from, to }], month: to, disabledDays: { before: from, after: new Date() }, modifiers };

    return (
      <form
        className={this.props.className}
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
          this.props.onClick(from, to, transactionFilter, securityFilter);
        }}>
        <Columns>
          <Column s="12" m="6">
            <DatePicker
              dayPickerProps={fromProps}
              callback={this.handleFromChange}
              date={this.state.from}
              label={__('From')}
            />
          </Column>

          <Column s="12" m="6">
            <DatePicker
              dayPickerProps={toProps}
              callback={this.handleToChange}
              date={this.state.to}
              min={this.props.from}
              label={__('To')}
            />
          </Column>

          <Column s="12" m="6">
            <label htmlFor="transaction_select">{__('Transaction')}</label>
            <select
              id="transaction_select"
              className="select"
              value={this.state.transactionFilter}
              onChange={e => { this.setState({ transactionFilter: e.target.value }); }}>
              <option key="*" value="*">{__('All')}</option>
              {
                [...new Set(this.props.data.map((item) => (
                  item.type
                )))
                ].map(item => (
                  <option key={item} value={item}>{__(item)}</option>
                ))
              }
            </select>
          </Column>

          <Column s="12" m="6">
            <label htmlFor="securities_select">{__('Securities')}</label>
            <select
              id="securities_select"
              className="select"
              value={this.state.securityFilter}
              onChange={e => { this.setState({ securityFilter: e.target.value }); }}>
              <option value={'*'}>{__('All')}</option>
              {
                [...new Set(this.props.data.map((item) => (
                  item.security
                )))
                ].map(item => (
                  <option key={item} value={item}>{item}</option>
                ))
              }
            </select>
          </Column>

          <Column s="12" align="end">
            <Button type="submit" loading={this.props.isLoading}>
              {__('update_report')}
            </Button>
          </Column>
        </Columns>
      </form>

    );
  }
}



export default TransactionDatePicker;
