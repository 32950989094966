import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import Collapse from 'react-collapsed';
import Button from '@hubins/components/Button';
import __ from 'localisation';
import { motion, AnimatePresence } from 'framer-motion';
// import { PayCard } from 'modules/EventCard';

// import posed, { PoseGroup } from 'react-pose';

import './index.scss';

/**
Pseudo code:
loop trough the first 3 cards
and a button to an accordeon

when you click the button
expand the next 3 cards and render a new button with 3 cards

 */

// const Item = {
//   enter: { opacity: 1, height: 'auto' },
//   exit: { opacity: 0, height: 0 }
// };
const config = {
  exit: {
    height: 0,
    opacity: 0,
  },
  enter: {
    height: 'auto',
    opacity: 1,
  }
};
class LoadMore extends Component {

  constructor(props) {
    super(props);
    this.state = {
      visible: props.initialItemsVisible,
      maxItems: false,
    };
  }

  handleMoreItems = () => {
    const newVisible = this.state.visible + this.props.numberOfItemsToExpand;

    this.setState({
      visible: newVisible,
      maxItems: newVisible >= this.props.cards.length ? true : false,
    });
  };

  handleMinimize = () => {
    this.setState({
      visible: this.props.initialItemsVisible,
      maxItems: false,
    });
  };

  handleShowAll = () => {
    this.setState({
      visible: this.props.cards.length,
      maxItems: true,
    });
  };

  render() {

    return (
      <div className="LoadMore">
        <>
          {this.props.cards.slice(0, this.state.visible).map((card, index) => (
            <AnimatePresence key={index}>
              <motion.div
                layoutId={index}
                className="LoadMore__item"
                initial={config.exit}
                animate={config.enter}
                exit={config.exit}
              >
                {card}
              </motion.div>
            </AnimatePresence>
          ))}
        </>
        {this.props.cards.length > this.props.initialItemsVisible &&
          <div className={`button__wrap ${this.state.maxItems ? 'button__wrap--end' : 'button__wrap--pushtosides'}`}>
            {!this.state.maxItems &&
              <Button className="small LoadMore__button withicon withicon--left" onClick={this.handleMoreItems} icon="add">
                {this.props.loadMoreText}
              </Button>
            }
            <Button
              className="small link LoadMore__button withicon"
              onClick={this.state.maxItems ? this.handleMinimize : this.handleShowAll}
              icon={this.state.maxItems ? 'subtract' : 'add'}
            >
              {this.state.maxItems ? this.props.minimzeAllText : this.props.showAllText}
            </Button>
          </div>
        }
      </div>

    );
  }
}

LoadMore.propTypes = {
  cards: PropTypes.array,
  initialItemsVisible: PropTypes.number,
  numberOfItemsToExpand: PropTypes.number,
  loadMoreText: PropTypes.string,
  minimzeAllText: PropTypes.string,
  showAllText: PropTypes.string,
};

LoadMore.defaultProps = {
  cards: [],
  initialItemsVisible: 3,
  numberOfItemsToExpand: 3,
  loadMoreText: __('show_more_items'),
  minimzeAllText: __('minimize'),
  showAllText: __('show_all'),

};

export default LoadMore;
