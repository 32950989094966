import SE from './data/routes/routes_se';
import EN from './data/routes/routes_en';

export const routes = {
  SE,
  EN,
};

export const route = (key, props, LANGUAGE) => {
  const routeString = routes[LANGUAGE](props)[key];
  if (!routeString) { console.error('Missing route key: ', key, ', with props:', props, ', in language:', LANGUAGE); }
  return routeString || EN(props)[key];
};

export default route;
