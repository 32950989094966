import './index.scss';
import Button from '@hubins/components/Button';
import Icon from '@hubins/components/IconNew';
import ErrorMessage from 'components/ErrorMessage';
import Heading from 'components/Heading';
import { motion, AnimatePresence } from 'framer-motion';
import { formatFileSize } from 'helpers';
import __ from 'localisation';
import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Easing } from 'settings';

const defaultAccept = 'image/jpeg, image/png, image/gif, image/bmp, image/webp, ' + // images - not svg for security reason
  'text/plain, text/csv, application/json, application/pdf, ' + // texts, not php or js or other code related files for security reason
  'application/x-rar-compressed, application/octet-stream, ' + // rar
  'application/zip, application/octet-stream, application/x-zip-compressed, multipart/x-zip, '; // zip

const FileUpload = ({
  accept = defaultAccept,
  error_message = __('fileupload_error'),
  controlled = false,
  ...props
}) => {
  const [files, setFiles] = useState(props.value ? props.value : []);
  const thefiles = controlled ? props.value : files; // controlled or not
  const _id = Math.random();

  // For uncontrolled component using a locale state to store files,
  // and a callback props to pass the upp to the parent
  const addFiles = filesToAdd => {
    // const newFiles = [...files, ...filesToAdd];

    const newFiles = [...thefiles, ...filesToAdd];

    setFiles(newFiles);

    'callback' in props && props.callback(newFiles, props.id);
  };

  const remove = (id, e) => {
    e.preventDefault();
    let deletedState = [...thefiles];
    deletedState.splice(id, 1);

    setFiles(deletedState);

    'callback' in props && props.callback(deletedState, props.id);
  };

  const handleDelete = (index, e) => {
    if (props.handleFiles) {
      props.handleFiles.remove(index, e);
    } else {
      remove(index, e);
    }
  };

  // For controlled component using handleFiles prop and dropzone
  const onDrop = useCallback(
    acceptedFiles => {
      if (props.callback) {
        props.callback(acceptedFiles, props.id);
      }
      addFiles(acceptedFiles);
    },
    [thefiles, props.callback, props.id, addFiles],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const error = props.optionsState ? props.optionsState.error : false;

  return (
    <>
      {props.title && <Heading size="4">{props.title}</Heading>}
      {props.description && (
        <p className={`clarification ${props.title ? 's-top-md' : ''}`}>
          {props.description}
        </p>
      )}
      <div className="section-padding flex wrap">
        <div
          className={`fileupload ${props.className ? props.className : ''} ${
            error ? 'fileupload--error' : ''
          } ${isDragActive ? 'fileupload--active' : ''}`}
          {...getRootProps({
            onClick: event => event.stopPropagation(),
          })}>
          <label>
            <input
              id={'fileupload' + _id}
              type="file"
              multiple
              autoComplete="off"
              className="visuallyhidden fileupload__input"
              {...getInputProps({ accept })}
              disabled={props.disabled}
            />
            <span
              className={`button withicon withicon--left small ${
                props.disabled ? 'disabled' : ''
              }`}>
              <Icon icon="Upload Bottom by Streamlinehq" ariaHidden /> {__('attach_file')}
            </span>
          </label>
          <p>{__('attach_file_desc')}</p>
          {thefiles && (
            <div className="fileupload__files">
              <AnimatePresence>
                {thefiles.map((file, index) => (
                  <motion.div
                    transition={Easing.inOut}
                    key={file.lastModified + index}
                    initial={{ opacity: 0, height: 0, scale: 1.05 }}
                    animate={{ opacity: 1, height: 'auto', scale: 1 }}
                    exit={{ opacity: 0, height: 0, scale: 0.85 }}>
                    <span className="fileupload__file">
                      <Icon icon="Common File Text by Streamlinehq" className="s-right" />
                      {file.name ? (
                        <>
                          {file.name} - {formatFileSize(file.size)}
                        </>
                      ) : (
                        <>{file.path}</>
                      )}
                      <Button
                        label={__('remove_file')}
                        iconColor='primary'
                        icon="remove"
                        onlyIcon
                        className="s-left"
                        onClick={e => handleDelete(index, e)}
                      />
                    </span>
                  </motion.div>
                ))}
              </AnimatePresence>
            </div>
          )}
          <div className="fileupload__drophelper">
            <Icon icon="Upload Bottom by Streamlinehq" size={16} />
          </div>
          {props.meta && <p className="clarification">{props.meta}</p>}
        </div>

        <ErrorMessage text={error_message} showError={error} />
      </div>
    </>
  );
};

export default FileUpload;
