import React from 'react';
import Placeholder from 'components/Placeholder';
import InvestmentCard from '@hubins/components/InvestmentCard';
import { __ } from "localisation";
import Heading from 'components/Heading';
import ErrorBoundary from 'components/ErrorBoundary';
import { Columns, Column } from 'components/Columns';
import {
  generateTableData,
  getCaseUrl,
  getInvestPageUrl,
} from 'helpers/investmentCards';
import './index.scss';


const FeaturedInvestments = ({ entries, noPortfolios }) => {
  const getFeaturedInvestmentHTML = () => {
    if (entries !== undefined && entries.length > 0) {
      return (
        <Columns>
          {entries.map(entry => (
            <ErrorBoundary key={entry.id}>
              <Column l="6">
                <InvestmentCard
                  msDiff={entry.blockSale ? Number(entry.blockSale_ms_diff) : Number(entry.ms_diff)}
                  name={entry.name}
                  image={entry.hero && entry.hero.value}
                  alt={entry.hero ? entry.hero.text : 'Thumbnail'}
                  disabled={noPortfolios}
                  tableData={generateTableData(entry)}
                  caseUrl={getCaseUrl(entry.id)}
                  investPageUrl={getInvestPageUrl(entry.type, entry.id)}
                />
              </Column>
            </ErrorBoundary>
          ))}
        </Columns>
      );
    } else {
      return (
        <Placeholder
          image="/illustrations/investments_empty.svg"
          className="s-bottom-m-womq placeholder--2columns featured-investments__empty"
        >
          <p>{__('no_investments_on_primary_market')}</p>
        </Placeholder>
      );
    }

  };

  return (
    <>
      <header className="section-padding">
        <Heading size="3" className="s-top-xl s-bottom-xl">
          {__('current_investments')}
        </Heading>
      </header>
      {getFeaturedInvestmentHTML()}
    </>
  );

};

export default FeaturedInvestments;
