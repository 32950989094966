import React, { useEffect, useState } from 'react';
import { __route } from 'localisation';
import __reg from 'localisation/hubins/registration';
import Wrapper from '@hubins/components/Wrapper';
import { Columns, Column } from 'components/Columns';
import Button from '@hubins/components/Button';
import Heading from 'components/Heading';
import middleman from 'helpers/middleman';
import { LoadingBox } from 'components/LoadingIndicators';

const PersonnelSignupSummary = ({ match }) => {

  const { profileUuid } = match.params;
  const [powerOfAttorney, setPowerOfAttorney] = useState(false);
  const [signedKyc, setSignedKyc] = useState(false);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    setLoading(true);
    try {
      await Promise.all([
        middleman.get(`/power-of-attorney/${profileUuid}`),
        middleman.get(`/user/tags`),
      ]).then(results => {
        setPowerOfAttorney(results[0].data);
        const userTags = results[1].data;
        console.log('userTags', userTags);
        setSignedKyc(userTags.includes('p_kyc'));
      });
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (loading) {
    return <LoadingBox />;
  }

  return (
    <Wrapper>
      <Columns>
        <Column m="6">
          {powerOfAttorney.signed_at ? (
            <>
              <Heading size="3">
                Tack
              </Heading>
              <p>
                Vi går nu igenom företagets försäkringsansökan och behandlar de uppgifter du har skickat in. Har du några frågor kan du kontakta oss på <a href="mailto:kundservice@hubins.com" target="_blank" rel="noreferrer">kundservice@hubins.com</a>  eller <a href="tel:0317971936" target="_blank" rel="noreferrer">031-797 19 36</a>.
              </p>
              <Button href="/">Mina konton</Button>
            </>
          ) : (
            <>
              <Heading size="3">
                Kundkännedom och signering av fullmakt
              </Heading>
              <p>
            Bolagsrepresentanten har angett dig som bolagsperson i nedanstående bolag och du har därför blivit ombedd att ge bolagsrepresentanten fullmakt att hantera bolagets kapitalförsäkring. Här får du en översikt av den pågående försäkringsansökan och de olika stegen som behöver genomföras för att kapitalförsäkringen ska öppnas.
              </p>
              <div className="flex">
                <Button href={`${__route('s_kyc')}/${profileUuid}`} disabled={signedKyc} className="spacing">
                  {__reg('s_kyc')}
                </Button>
                <Button href={`${__route('s_signoff')}/${profileUuid}`} disabled={!signedKyc} className="spacing">
                  {__reg('s_signoff')}
                </Button>
              </div>
            </>
          )}
        </Column>
        <Column m="6">
        </Column>
      </Columns>
    </Wrapper>
  );

};

export default PersonnelSignupSummary;
