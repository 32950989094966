
export const summariesHandler = (res) => {
  if (!res.data) return {};
  return res.data.summaries;
}

export const endowmentHandler = (res) => {
  return Object.keys(res.data.endowment)
    .reduce((newState, key) => {
      newState[key] = {
        ...res.data.endowment[key]
      };
      return newState;
    }, {});
}

const adjustName = (name) => {
  const adjustedNames = {
    'Cash Account SEK': 'Kontantkonto SEK',
    'Cash Account NOK': 'Kontantkonto NOK',
    'Debt Account SEK': 'Skuldkonto SEK',
    'Debt Account NOK': 'Skuldkonto NOK',
  };
  return adjustedNames[name] || name;
};

const formatTransactions = (transactions) => {
  return transactions.map((transaction) => {
    // Transaction names are hard to change in FA back.
    // That is why we change names that look strange here.
    transaction.security = adjustName(transaction.security);
    return transaction;
  });
};

export const transactionsHandler = (res) => {
  return formatTransactions(res.data)
}

export const getendowmentTransactionDefaultState = () => (
  {
    janToJun: 0,
    julToDec: 0,
    withdrawalAmount: 0,
    tax: 0,
    fees: {
      AMFOIFA: 0,
      TCQL1: 0,
      TCQL2: 0,
      FPCQL: 0,
      FPCQL2: 0,
      AMFQL: 0,
      AMFQL2: 0,
      AMFIFA: 0,
      RISKPRE: 0,
    }
  }
);

const extractCashTransactions = (transactions) => {

  return transactions.reduce((result, item) => {
    const transDate = new Date(item.transactionDate);
    const year = transDate.getFullYear();
    const month = transDate.getMonth();
    if (!result[year]) {
      result[year] = getendowmentTransactionDefaultState();
    }
    const type = item.typeCode.trim();
    const depositTypes = ['PRE', 'PRESEC', 'ADJPRE'];
    if (depositTypes.includes(type)) {
      if (month < 6) {
        result[year].janToJun += item.tradeAmountInPortfolioCurrency;
      } else {
        result[year].julToDec += item.tradeAmountInPortfolioCurrency;
      }
    }

    const withDrawalTypes = ['WD', 'WDSEC', 'SURR', 'SURRSEC', 'WDCORR',];
    if (withDrawalTypes.includes(type)) {
      result[year].withdrawalAmount += item.tradeAmountInPortfolioCurrency;
    }

    const feeTypes = ['AMFOIFA', 'TCQL1', 'TCQL2', 'FPCQL', 'FPCQL2', 'AMFQL', 'AMFQL2', 'AMFIFA', 'RISKPRE',];
    if (feeTypes.includes(type)) {
      result[year].fees[item.typeCode] += item.tradeAmountInPortfolioCurrency;
    }

    const taxTypes = ['TAX',];
    if (taxTypes.includes(type)) {
      result[year].tax += item.tradeAmountInPortfolioCurrency;
    }

    return result;
  }, {});
};

export const HISTORY_endowmentHandler = (res) => {
  const cashFlows = extractCashTransactions(res.data);
  const newState = {};
  Object.keys(cashFlows).forEach((year) => {
    const { withdrawalAmount, janToJun, julToDec, fees, tax } = cashFlows[year];
    newState[year] = {
      withdrawalAmount,
      janToJun,
      julToDec,
      fees,
      tax,
    };
  });
  return newState;
};

export const summariesHandler_ALL = (res) => {
  if (res.data.data.analytics.TOTALPERFORMANCE.grouppedAnalytics.length === 0) {
    return {};
  }

  const data = res.data.data.analytics.TOTALPERFORMANCE.grouppedAnalyticsTimePeriod;
  return data.reduce((newState, period) => {
    if (period.timePeriodCode !== 'GIVEN') return newState;
    newState['given'] = {
      startDate: period.startDate,
      money: period.totalNetProfitsInclAccruedInterest,
      percent: period.twr * 100
    };
    return newState;
  }, {});
}

export const chartHandler_ALL = (res) => {
  if (typeof res.data === "undefined") {
    return { placeholder: true };
  }

  const data = res.data.data.analytics.CHART.grouppedAnalytics;

  let minValue = 100;
  let maxValue = 100;

  const chartAccounts = data.map(item => {
    return {
      name: item.portfolio.name,
      id: item.portfolio.id,
      values: item.indexedReturnDataSelected.map(i => {
        const values = { x: ' ' + i.date + ' ' };
        const indexedValue = i.fields.indexedValue;
        minValue = indexedValue < minValue ? indexedValue : minValue;
        maxValue = indexedValue > maxValue ? indexedValue : maxValue;
        values['y' + item.portfolio.id] = indexedValue;
        return values;
      })
    };
  });

  const chartData = {
    accounts: chartAccounts,
    minValue: Math.round(minValue * 0.95),
    maxValue: Math.round(maxValue * 1.05),
  };

  return chartData;
};