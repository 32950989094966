import React, { useState } from 'react';
import SimpleTable from 'modules/Tables/SimpleTable';
import RedirectToRoycButton from '@hubins/components/RedirectToRoycButton';

const headers = [
  {Header: '', accessor: 'redirect'},
  {Header: 'Plattform', accessor: 'platform'},
]

const ConnectionsTable = ({roycConnection}) => {

  let data = [{
      platform: 'ROYC',
      redirect: <RedirectToRoycButton className="small" />
    }];

  return (
    <SimpleTable
      headers={headers}
      data={data}
    />
  )

};

export default ConnectionsTable;