import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
// import { isUserAdmin } from '../../state/utils/user';
import { connect } from 'react-redux';
import { __route } from 'localisation';
import __ from 'localisation/hubins';
import { Logo } from 'settings';


const RestrictedRoute = (props) => {

  useEffect(() => {
    if (document) {
      document.title = props.specialTitle ? props.title : props.title + ' - ' + __('app_name');
    }
  }, [props.title]);

  const {
    layout: TheLayout,
    layoutLoggedOut: LayoutLoggedOut, // ONLY USED ONCE
    logo = Logo,
    ...restProps
  } = props;

  return (
    props.authToken ?
      (
        <>
          <TheLayout logo={logo} {...restProps}  rootUrl="/" />
        </>
      ) :
      (
        LayoutLoggedOut ? <LayoutLoggedOut logo={logo} {...restProps} /> : <Redirect to={__route('login')} />
      )
  );
};

function mapStateToProps(state) {
  return {
    contact_type: state.auth.User.contact_type,
    user: state.auth.User,
    YearlyCheckup: state.auth.User.YearlyCheckup,
    authToken: state.auth.User.authToken
  };
}

export default connect(
  mapStateToProps,
)(RestrictedRoute);
