import { createStore, applyMiddleware, combineReducers, compose } from "redux";
// import { createStore, applyMiddleware, combineReducers } from "redux";
// import * as reducers from "./ducks"; // import all reducers from ducks/index.js
import thunk from "redux-thunk";
import reducers from "./ducks";
import promise from "redux-promise";

import { loadState, saveState, clearState } from "states/localStorage";
import { LOGOUT } from "states/auth/types";

export default function configureStore() {
  const appReducer = combineReducers(reducers);

  const rootReducer = (state, action) => {
    if (action.type === LOGOUT) {
      clearState();
      state = undefined;
    }

    return appReducer(state, action);
  };

  const persistedData = loadState();
  // const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
  //   window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 25 }) || compose;

  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  // const composeEnhancers = composeWithDevTools({ actions, trace: true, traceLimit: 25 });
  const store = createStore(
    rootReducer,
    persistedData,
    composeEnhancers(applyMiddleware(thunk, promise))
  );
  store.subscribe(() => {
    const state = store.getState();

    saveState(
      {
        auth: {
          User: state.auth.User,
        },
        alerts: state.alerts,
      },
      {
        authToken: state.auth.User.authToken,
        tokenExpires: state.auth.User.tokenExpires,
      }
    );
  });

  if (import.meta.hot) {
    // Enable Webpack hot module replacement for reducers
    import.meta.hot.accept("state/ducks", () => {
      store.replaceReducer(combineReducers(reducers));
    });
  }

  return store;
}
