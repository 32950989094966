import React from 'react';
import SimpleTable from 'modules/Tables/SimpleTable';
import { getMoney, formatPercent } from 'helpers';
import './index.scss';

const headers = [
  {
    Header: '',
    accessor: 'type',
    Cell: ({ row }) => <div className={row.original.className}>{row.original.type}</div>,
  },
  {
    Header: '%',
    accessor: 'percent',
    Cell: ({ row }) => formatPercent(row.original.percent * 100),
  },
  {
    Header: 'kr',
    accessor: 'sek',
    Cell: ({ row }) => getMoney(row.original.sek, 'SEK', 0),
  },
];

const DepositTable = ({ data }) => {

  return (
    <SimpleTable
      className="ReactTable--tight ReactTable--wordbreak"
      headers={headers}
      data={data} />
  );
};

export default DepositTable;
