import React, { Component } from 'react';
import './index.scss';

class CaseText extends Component {

  render() {
    return (
      // <div className="section section section--medium-padding section--dark columns columns--tight s-top-m-womq s-bottom-m-womq body-big">
      <div className={this.props.box ? 'section section--wide caseinfo section--dark body-big fc' : 'section section--no-border body-big fc'}>
        <div className="info-inner-content" dangerouslySetInnerHTML={{ __html: this.props.value }} />
      </div>
    );
  }

}

export default CaseText;
