import { checkExpected } from 'components/Validation';
import __ from 'localisation/hubins/registration';
import ReactDOMServer from 'react-dom/server';
import { formatMoney } from '.';

/**
 * @param {node} - A JSX object that you want to transform to a HTML string
 */

export const renderToStaticMarkup = ReactDOMServer.renderToStaticMarkup;


// Flatten formData into array of all fields
export const deconstructFormData = (data) => (
  data.map(section => (
    section.fields.map(field => (
      field
    ))
  ))
    .reduce((a, b) => (a.concat(b)), [])
  // .filter(item => item.type !== 'hidden' && item.type !== 'info' && item.type !== 'tooltip' && item.type !== 'subtitle')
);

// Flatten tests formData into array of all fields
export const deconstructTestsFormData = (data) => (
  deconstructFormData(
    data.reduce((a, b) => (a.concat(b)), [])
  )
);

export const deconstructForm = (data) => {
  if (Array.isArray(data[0])) {
    return deconstructTestsFormData(data);
  }
  return deconstructFormData(data);
};

export const deconstructSlides = (form) => {
  return form.reduce((a, b) => (a.concat(b)), []);
};

// Filters out each section based on array of ids
// data is array of ids that should be included
export const filterSection = (title, id, sectionFields, data) => {
  return {
    title,
    id,
    fields: sectionFields.filter(item => data.includes(item.id))
  };
};

const filterImportField = (item, data) => {
  switch (item.type) {
    case 'select':
    case 'tableselect':
    case 'dropdown':

      if (!item.multiple) {
        return data.includes(item.id);
      } else {
        const trueOptions = item.options.reduce((arr, option) => {
          const key = item.id + '_' + option.id;
          if (data.includes(key)) {
            arr.push(key);
          }
          return arr;
        }, []);
        if (trueOptions.length > 0) {

          return true;
        }
      }

    case 'file':
      return data.includes(item.id);
    case 'slider':
      if (item.singleValue) {
        return data.includes(item.id);
      }
      return data.includes(item.id + '_max') || data.includes(item.id + '_min');
    default:
      return data.includes(item.id);
  }
};

/**
 * filterImportSection
 * @param {string} title the title of the create form section
 * @param {sting} id a unique id of the section
 * @param {array} sectionFields array with the fields you want to display
 * @param {object} data object with the data you want to put into the fields
 * @returns {object} Returns an createForm section object with title, id and filtered fields array
 */
export const filterImportSection = (title, id, sectionFields, data) => {
  return {
    title,
    id,
    fields: sectionFields.filter((item) => filterImportField(item, data))
  };
};


// Helper for filtering out fields
export const displayStatement = (item) => {
  return item.type === 'hidden'
    || item.type === 'info'
    || item.type === 'tooltip'
    || item.type === 'subtitle'
    || item.type === 'modal'
    || item.type === 'documents';
};

// Format the create forms inputs value based on the fields inputType
// Should only be used in summary views or similar display only situations
export const formatInputValue = (value, inputType, suffix, option) => {
  switch (inputType) {
    case 'currency':
      return formatMoney(value, option);
    default:
      if (suffix) {
        return `${value} ${suffix}`;
      }
      return value;
  }
};

// Gets the field value in readable form, for frontend rendering
// If you want to format the valie according to the inputType, set format to true
export const getFieldValue = (field, format = false) => {
  const { type, options, multiple, valueType, text, suffix, inputType } = field;
  let value = field.value;

  switch (type) {
    case 'input':
    case 'textarea':
    case 'inputautofill':
      if (format) {
        return formatInputValue(value, inputType, suffix);
      }
      if (suffix) {
        return `${value} ${suffix}`;
      }
      return value;
    case 'dropdown':
      const dropValue = options.find(option => (option.value));
      if (dropValue && dropValue.title) return dropValue.title;
      return "";
    case 'repeater':
      return true;
    case 'checkbox':
      return value ? __('approved') : '';
    case 'slider':
      if (value && value.min && value.max) {
        return `${value.min} ${valueType} – ${value.max} ${valueType}`;
      }
      if (typeof value !== 'object' && (typeof value === 'string' || typeof value === 'number')) {
        return `${value} ${valueType}`;
      }
      return '';
    case 'hidden':
      return value;
    case 'file':
      return Array.isArray(value) ? value.toString() : '';
    case 'select':
    case 'tableselect':
      if (multiple) {
        return options.filter(option => (option.value))
          .map(option => (option.title))
          .join(', ');
      }
      const selValue = options.find(option => (option.value));
      if (selValue && selValue.title) return selValue.title;
    case 'info':
      return renderToStaticMarkup(text);
    default:
      return '';
  }
};

// Resets the field value to is default value
export const resetFieldValue = (field) => {

  switch (field.type) {
    case 'file':
      field.value = [];
    case 'input':
    case 'inputautofill':
    case 'textarea':
      field.value = '';
      break;
    case 'checkbox':
      field.value = false;
      break;
    case 'dropdown':
    case 'select':
    case 'tableselect':
      field.options = field.options.map(option => {
        option.value = false;
        return option;
      });

      if (field.value) {
        field.value = undefined;
      }
      break;
  }

  return field;

};

/**
 * When createform in initialised loop through data and make sure all conditions that should be visible is
 * @param {array} ids - Array with ids to filer out
 * @param {object} form - The original CreateForm data object
 * @returns {array} array with a CreateForm section object
 */
export const formatFormData = (ids, form) => {

  // For breaking references
  form = [...form];
  const allFields = deconstructFormData(form);
  const indexedFields = allFields.reduce((obj, field) => {
    obj[field.id] = field;
    return obj;
  }, {});

  // const fields = form.fields.filter(field => ids.indexOf(field.id) > -1);
  const fields = ids.length === 1 ? [allFields.find(field => ids[0] === field.id)] : allFields;

  if (ids.length === 1) {

    const fieldsToAdd = [];

    const recursiveAddVisibleConditions = (id) => {
      allFields.forEach((field) => {
        if (field.visibleConditions) {
          const fieldVisibleConditionIds = field.visibleConditions.reduce((conditionIds, condition) => {
            const myConditionIds = condition.map(item => item.key);
            return [...myConditionIds, ...conditionIds];
          }, []);
          if (fieldVisibleConditionIds.includes(id)) {
            fieldsToAdd.push(field.id);
            recursiveAddVisibleConditions(field.id);
          }
        }
      });
    };

    recursiveAddVisibleConditions(ids[0]);

    const uniqueFieldsToAdd = [...new Set(fieldsToAdd)];

    uniqueFieldsToAdd.forEach(id => {
      const field = indexedFields[id];
      fields.push(field);
    });
  }

  return [
    {
      id: 'edit',
      fields,
    }
  ];

};

export const formatAllFormData = (form) => (
  form.map(section => {
    const allIds = section.fields.map(field => field.id);
    return formatFormData(allIds, section)[0];
  })
);

// Appends previous answered question value if exists
export const appendValue = (field, user_info) => {

  const { id, type } = field;

  switch (type) {
    case 'input':
    case 'textarea':
    case 'inputautofill':
      const inputValue = (user_info[id] || user_info[id] === 0) ? user_info[id] : field.value;
      field.value = inputValue;
      break;
    case 'file':
      const fileValue = (user_info[id]) ? user_info[id] : [];
      field.value = fileValue;
      break;
    case 'checkbox':
      const checkValue = user_info[id] ? user_info[id] : false;
      field.value = checkValue;
      break;

    case 'repeater':
      if (user_info[id]) {
        field.value = user_info[id];
        break;
      }

      break;

    case 'slider':
      const slideValue = user_info[id];
      if (slideValue || slideValue === 0) {
        if (field.singleValue) {
          field.value = slideValue;
        } else if (typeof slideValue === 'object') {
          field.value = {
            min: slideValue.min || 0,
            max: slideValue.max || 100
          };
        }
        break;
      }



      const slideMaxValue = user_info[`${id}_max`];
      const slideMinValue = user_info[`${id}_min`];

      if (slideMaxValue && slideMinValue) {
        field.value = {
          min: slideMinValue,
          max: slideMaxValue
        };
      }
      break;
    case 'select':
    case 'tableselect':
    case 'dropdown':
      const selectedOptions = user_info[id] ? user_info[id] : false;
      if (selectedOptions) {
        const newOptions = field.options.map(option => {
          return { ...option, value: selectedOptions.includes(option.id) };
        });
        field = { ...field, options: newOptions };
      }
      break;
  }
  return field;
};

export const appendAndResetAllFieldValues = (questions, user_info) => {
  questions = [...questions];
  return questions.map(section => {
    if (!section.fields) { console.error(`section ${section.id} is missing a field object`, section); }

    section.fields = section.fields.map(field => {
      field = { ...resetFieldValue(field) };
      return appendValue(field, user_info);
    });
    return section;
  });
};

// Appends all field values for formData
export const appendAllFieldValues = (questions, user_info) => {
  questions = [...questions];
  return questions.map(section => {
    if (!section.fields) { console.error(`section ${section.id} is missing a field object`, section); }

    section.fields = section.fields.map(field => (
      appendValue(field, user_info)
    ));
    return section;
  });
};


/**
 * Filtered out info boxes and other non-supported field types
 * for the PDF generator in Middleman, using create form data with prefilled vaules
 * and an array with ids containg the keys you wanna have in the PDF
 * @param {array} form - The CreateForm data object
 * @param {array} ids - Array with the IDs you wanna send to the PDF
 * @param {bool} includeInfoFields - Set to true if you want to include and format info fields
 * @returns {array} Array with a filtered form data for PDF usage
 */
export const generatePDFData = (form, ids, includeInfoFields = false) => {
  const PDFData = form.map(section => {
    const formattedFields = formatFormData(ids, [{ fields: section.fields }]);
    const fields = formattedFields[0].fields
      .filter(field => (
        (field.type === 'input'
          || field.type === 'textarea'
          || field.type === 'inputautofill'
          || field.type === 'dropdown'
          || field.type === 'select'
          || field.type === 'tableselect'
          || field.type === 'slider'
          || field.type === 'checkbox'
          || field.type === 'file'
          || (includeInfoFields && field.type === 'info')
        )
      ))
      .map(fieldItem => {
        return (
          {
            title: fieldItem.summaryTitle || fieldItem.title || fieldItem.label || fieldItem.heading || '',
            value: getFieldValue(fieldItem),
            type: fieldItem.type,
          }
        );
      });

    const newSection = {
      title: section.title || '',
      fields,
    };
    return newSection;
  });
  return PDFData;
};

export const knowledgeTestValidation = (values) => {
  const amountOfQuestions = Object.keys(values).length;
  let correctAnswers = 0;
  for (const key in values) {
    if (checkExpected(values[key])) {
      correctAnswers++;
    }
  }
  return {
    amountOfQuestions,
    correctAnswers
  };
};

// Currently used when using sendToParent filter method
// Send the values in here and get the user simpleattributes structure in return
export const formValuesIntoFaValues = (values) => {
  const valueKeys = Object.keys(values);

  return valueKeys.reduce((attributes, key) => {
    const field = values[key];
    switch (field.type) {
      case 'input':
      case 'inputautofill':
      case 'textarea':
      case 'checkbox':
        attributes[key] = field.value;
        break;
      case 'slider':
        // This one is not tested a

        if (typeof field.value !== 'object' && (typeof field.value === 'string' || typeof field.value === 'number')) {
          attributes[key] = field.value;
          break;
        }

        attributes[key + '_max'] = field.value.max;
        attributes[key + '_min'] = field.value.min;
        break;
      case 'select':
      case 'dropdown':
      case 'tableselect':
        const optionKeys = Object.keys(field.value);
        const options = optionKeys.map((oKey) => (
          field.value[oKey]
        ));

        if (field.multiple) {
          const selectedOptions = options.filter(option => (
            option.value
          ));

          selectedOptions.forEach(option => {
            attributes[key + '_' + option.id] = true;
          });
          break;
        }
        const selectedOption = options.find(option => (option.value));
        if (selectedOption) {
          attributes[key] = selectedOption.id;
        }
        break;
    }
    return attributes;
  }, {});

};

export const generateAnswer = (value, valueType) => {
  return {
    "validation": "none",
    "required": true,
    "error": "",
    value,
    "visibleConditions": false,
    "multiple": false,
    "type": "hidden",
    valueType,
  };
};
