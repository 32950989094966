import React from 'react';
import { Colors } from 'settings';

export const Logo = ({ inverted }) => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" width="106" height="20" viewBox="0 0 1625.3 306.5"
    style={{ color: inverted ? Colors.secondary : Colors.primary }}
  >
    <g className="logo_ordbild">
      <path fill="currentColor" d="M835 262.8h-61.7a4 4 0 01-4-3.8l-.5-10.7c-4.9 4.9-21 18.4-45.3 18.4s-40.4-8.7-48.5-20.1c-8.1-11.3-12.3-24.3-12.3-48.5v-96.3a4 4 0 014-4h64.8a4 4 0 014 4v83.3c0 16.2 5.8 22 15.5 22s15.2-5.8 15.2-22v-83.3a4 4 0 014-4H835a4 4 0 014 4v157a4 4 0 01-4 4zM1147.9 262h-64.8a4 4 0 01-4-4V101a4 4 0 014-4h64.8a4 4 0 014 4v157a4 4 0 01-4 4zM1353.2 262.8h-64.8a4 4 0 01-4-4V182c0-16.2-1.6-28.5-17.1-28.5-15.2 0-16.8 12.3-16.8 28.5v76.9a4 4 0 01-4 4h-64.8a4 4 0 01-4-4v-157a4 4 0 014-4h64.6a4 4 0 014 4.2l-.8 16.8a52.3 52.3 0 0146.3-24.3c48.5 0 61.5 30.7 61.5 71.2v93c-.1 2.2-1.9 4-4.1 4zM1500.1 252.5c-12.9 9.7-30.1 14.6-53.4 14.6-22.4 0-49.5-3.9-73.5-20.8a4 4 0 01-1.1-5.2l20.7-35.9a4 4 0 015.5-1.5c21.8 13 46 15.3 50.3 15.3 4.5 0 9.4-.6 9.4-5.2 0-5.8-20.7-10.4-27.5-12.3a184 184 0 01-19.4-6.5c-6.5-2.3-30.7-11-30.7-45.3s30.1-55.3 69.5-55.3c35.6 0 56.4 10.8 66.8 17.2a4 4 0 011.5 5.2l-16.3 33.6a4 4 0 01-5.5 1.8 96.7 96.7 0 00-43.9-13.4c-6.5 0-9.1 1.9-9.1 5.5 0 3.9 8.1 6.1 17.8 8.7 9.7 2.9 15.2 4.2 23.3 7.4 8.1 3.2 17.1 8.1 23.6 14.6s13.6 18.4 13.6 36.2a50.1 50.1 0 01-21.6 41.3zM985.2 94.6c-34 0-47.5 17.8-47.5 17.8s.6-12.9.6-25.9V43.6a4 4 0 00-4-4h-64.8a4 4 0 00-4 4v215.2a4 4 0 004 4h60.7a4 4 0 004-4.2l-.7-13.6c11.3 14.6 30.7 21.7 51.8 21.7 46.9 0 76-37.5 76-86-.1-48.5-29.2-86.1-76.1-86.1zm7.6 84.3c0 18.4-12.3 28.1-25.9 28.1-13.6 0-25.9-9.7-25.9-28.1v-.4c0-18.4 12.3-28.1 25.9-28.1 13.6 0 25.9 9.7 25.9 28.1v.4zM1586.5 267c-23.3 0-38.6-13.8-38.6-34.8s15.5-34.6 38.8-34.6c23.6 0 38.6 13.8 38.6 34.8 0 21.1-15.2 34.6-38.8 34.6zM632.1 264.7H567a4 4 0 01-4-4v-80.1c0-2.2-1.8-3.9-3.9-3.9h-52.9a3.9 3.9 0 00-3.9 3.9v80.1a4 4 0 01-4 4h-65.1a4 4 0 01-4-4V46.4a4 4 0 014-4h65.1a4 4 0 014 4v70.3c0 2.2 1.8 3.9 3.9 3.9H559c2.2 0 3.9-1.8 3.9-3.9V46.4a4 4 0 014-4h65.2a4 4 0 014 4v214.3a4 4 0 01-4 4z" />
    </g>
    <path className="logo_down" fill="currentColor" d="M82.8 264.3H140c2.6 0 4.7 2.1 4.7 4.7v32.8c0 2.6-2.1 4.7-4.7 4.7H82.8a4.7 4.7 0 01-4.7-4.7V269c0-2.6 2.1-4.7 4.7-4.7z" />
    <path className="logo_up" fill="currentColor" d="M82.8 0H140c2.6 0 4.7 2.1 4.7 4.7v32.8c0 2.6-2.1 4.7-4.7 4.7H82.8a4.7 4.7 0 01-4.7-4.7V4.7c0-2.6 2.1-4.7 4.7-4.7z" />
    <path className="logo_h" fill="currentColor" d="M78.1 259.6v-32.8c0-2.6 2.1-4.7 4.7-4.7H140c2.6 0 4.7 2.1 4.7 4.7v32.8c0 2.6 2.1 4.7 4.7 4.7h68c2.6 0 4.7-2.1 4.7-4.7V46.9c0-2.6-2.1-4.7-4.7-4.7h-68a4.7 4.7 0 00-4.7 4.7v32.8c0 2.6-2.1 4.7-4.7 4.7H82.8a4.7 4.7 0 01-4.7-4.7V46.9c0-2.6-2.1-4.7-4.7-4.7H4.7A4.7 4.7 0 000 46.9v212.7c0 2.6 2.1 4.7 4.7 4.7h68.6c2.6 0 4.8-2.1 4.8-4.7z" />
  </svg>
);

export default Logo;
