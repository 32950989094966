import React, { useState, useRef, useEffect, useContext } from "react";
import "./questions.scss";
import { __ } from "localisation";
import handleInputChange from "./HandleInputChange";
import { sendToParent } from "./FilterMethods";
import "components/Columns/index.scss";
import { getInitialFormState } from "helpers/createForm";
import { triggerScrollToTop } from "helpers";
import { uiContext } from "components/UiContext";

import {
  validateAll,
  scrollToError,
  checkValidKey,
  breakFormDataReflection,
} from "./createFormMethods";

export const CreateForm = ({
  formData,
  filter = sendToParent,
  secondarySubmitType = () => {},
  submitType,
  validKey,
  showButton,
  className,
  preview = false,
  submitRef = undefined,
  disabled = true,
  buttonStyle = "cta",
  buttonProps = {},
  submitText = __("send"),
  secondaryButton,
  secondaryButtonStyle,
  secondaryText,
  error = false,
  submitClarification,
  loading = true,
  layoutId,
  animate,
  triggerScrollTop,
  wide,
  Form,
  submittedAt,
  title,
}) => {
  const { toggleQuestionOpen } = useContext(uiContext);

  const formStructure = useRef(null);
  const rerenderKey = useRef(null);
  const initialFormState = useRef(null);
  const handleInputChangeMethod = useRef(false);

  const mounted = useRef(false);

  // Code that only should run once and not on every rerender goes here
  if (!mounted.current) {
    formStructure.current = [...breakFormDataReflection(formData)];
    rerenderKey.current = Date.now().toString();
    initialFormState.current = getInitialFormState(formStructure.current);
    mounted.current = true;
    triggerScrollTop && triggerScrollToTop(false);
  }

  const [formState, setFormState] = useState(initialFormState.current);
  const [formErrors, setFormErrors] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  if (!handleInputChangeMethod.current) {
    handleInputChangeMethod.current = new handleInputChange(
      formState,
      setFormState
    );
  }

  // Update handleInputChangeMethods class formState in order to keep it up to date
  useEffect(() => {
    handleInputChangeMethod.current.setClassFormState(formState);
  }, [formState]);

  const submitForm = (e, isSecondary = false) => {
    toggleQuestionOpen(false); // Close previous opened questions
    e.preventDefault();

    const errors = validateAll(formState, setFormState);

    if (errors) {
      scrollToError(errors);
      setFormErrors({
        errors,
      });
      return false;
    }

    const data = filter(formState);
    setSubmitted(true);
    setFormErrors(false);
    const submitTypeProp = isSecondary ? secondarySubmitType : submitType;
    submitTypeProp(data);
  };

  return (
    <Form
      validKey={validKey}
      showButton={showButton}
      className={className}
      preview={preview}
      submitRef={submitRef}
      disabled={disabled}
      buttonStyle={buttonStyle}
      buttonProps={buttonProps}
      submitText={submitText}
      secondaryButton={secondaryButton}
      secondaryButtonStyle={secondaryButtonStyle}
      secondaryText={secondaryText}
      error={error}
      submitClarification={submitClarification}
      loading={loading}
      layoutId={layoutId}
      animate={animate}
      submitted={submitted}
      formErrors={formErrors}
      checkValidKey={checkValidKey}
      submitForm={submitForm}
      formState={formState}
      handleInputChangeMethod={handleInputChangeMethod.current}
      rerenderKey={rerenderKey.current}
      formStructure={formStructure.current}
      wide={wide}
      submittedAt={submittedAt}
      title={title}
    />
  );
};

export default CreateForm;
