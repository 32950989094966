import __ from 'localisation';

export const editableKeys = [
  'startYear',
  'endYear',
  'amount',
  'description',
  'holding',
  'investment_amount',
  'profit_amount',
  'date',
  'from',
  'purpose',
  'percentage',
];

export const keyToIcon = {
  salary: 'check payment sign',
  dividend: 'cash payment coins',
  property: 'real estate insurance dollar hand',
  investment_options: 'Performance Money Increase',
  sale_company: 'real estate action building dollar',
  inheritance_gift: 'resize expand',
  direct_investments: 'Performance Money Increase',
  profits_investment: 'Performance Money Increase',
  company_profits: 'check payment sign',
  company_property: 'real estate insurance dollar hand',
  company_sale_company: 'real estate action building dollar',
  company_dividend: 'cash payment coins',
  company_other: 'Common File Text by Streamlinehq',
  issue_stock: 'cash payment bag',
  issue_bonds_loan: 'Cash Payment Bag 1',
  company_financing: 'Cash Payment Bills',
  company_profits_investment: 'cash payment coins',
  company_direct_investments: 'Performance Money Increase',
};

const deafaultHeadersWithApprovalStatus = [
  {
    Header: __('created_at'),
    accessor: 'created_at',
  },
  {
    Header: __('event'),
    accessor: 'type',
  },
  {
    Header: __('status'),
    accessor: 'status',
    Cell: ({ row }) => (row.original.approval_status || row.original.status)
  }
];

const deafaultHeaders = [
  {
    Header: __('created_at'),
    accessor: 'created_at',
  },
  {
    Header: __('event'),
    accessor: 'type',
  },
  {
    Header: __('status'),
    accessor: 'status',
  }
];
export const dynamicHeaders = (event, isApproval = false) => {
  if (!event) return deafaultHeaders;

  const foundEditableKeys = Object.keys(event).filter((key) => editableKeys.includes(key));

  return [
    ...isApproval ? deafaultHeadersWithApprovalStatus : deafaultHeaders,
    ...foundEditableKeys.map((key) => ({
      Header: __(key),
      accessor: key,
    }))
  ];

};
