import React, { useState, useEffect } from 'react';
import DocumentsList from './DocumentsList';
import middleman from 'helpers/middleman';

const Documents = (props) => {

  const [documents, setDocuments] = useState(false);

  const getDocumentsInfo = (response) => {
    let newDocuments = response.data[0].documents;
    if (props.tags) {
      const tagsArray = props.tags.split(',');
      newDocuments = newDocuments.filter(
        doc => tagsArray.every((tag) => doc.tags.includes(tag))
      );
    }
    if (props.excludeTag) {
      newDocuments = newDocuments.filter(
        doc => !doc.tagsAsSet.includes(props.excludeTag)
      );
    }
    setDocuments(newDocuments);
  };

  useEffect(() => {
    const authUrl = props.auth ? '' : 'unauth/';
    const marketLanguage = props.languageSupport ? `/SE/SE` : '';
    const uri = `/v1/fa/${authUrl}documents/${props.path}${marketLanguage}`;
    middleman.post(uri, {}, getDocumentsInfo);
  }, []);

  return (
    <DocumentsList
      documents={documents}
      auth={props.auth}
      placeholders={props.placeholders}
      table={props.table}
      title={props.title}
      compact={props.compact}
      className={props.className}
    >
      {props.children}
    </DocumentsList>
  );

};

Documents.defaultProps = {
  auth: true,
  className: '',
  tags: false,
};

export default Documents;
