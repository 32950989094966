import React, { useState, useEffect, useRef } from 'react';
import LoginModal from 'modules/BankIdModal/LoginModal';
import { __route } from 'localisation';
import { Redirect } from "react-router-dom";
import { swedishSsn } from "components/Validation";
import { sanitizeSsn } from "helpers";
import { bypass } from "states/utilities";

import middleman, { csrf } from 'helpers/middleman';

const Login = ({
  // Redux
  logoutUser,
  clearUser,
  // Component
  abortCallback,
  callback,
}) => {
  const [ssn, setSsn] = useState('');
  const [ssnError, setSsnError] = useState(false);
  const [signupRedirect, setSignupRedirect] = useState(false);
  const [reference, setReference] = useState(false);
  const [signStatus, setSignStatus] = useState('visible'); //animateState??
  const [pollFailed, setPollFailed] = useState(false);

  const abortController = useRef(new AbortController());

  useEffect(() => {
    clearUser();
  }, []);

  useEffect(() => {
    if (pollFailed) {
      if (signStatus !== 'visible') {
        setSignStatus('error');
      }
      setPollFailed(false);
    }
  }, [pollFailed, signStatus]);

  const poll = () => {
    middleman
      .promiseKeepAlivePost('/bauth/status', {
        authReference: reference,
        platform: "hubins"
      }, abortController.current.signal)
      .then(response => {
        if (response.status === 'pending') {
          setTimeout(poll, 2000);
          return;
        }
        if (response.httpStatus === 201) {
          if (!response.user) {
            setSignStatus('notFound');
            return;
          }
          setSignStatus('complete');
          if (callback) {
            callback(response);
          }
          return;
        }
        return;
      }).catch((e) => {
        // if (e.name !== 'AbortError') {
        //   setPollFailed(true);
        //   console.error('POLLERROR: ', e.message);
        // }
        setPollFailed(true);
        console.error('POLLERROR: ', e.message);
      });
  };

  const processCallback = (res) => {
    // If there is a reference, post poll for status
    if (res.authReference) {
      setReference(res.authReference);
    }
  };

  const fetchData = async () => {

    const initBody = {
      ssn,
      mobileBankId: false,
      isTest: bypass(),
      process: 'login',
    };
    await csrf();
    middleman
      .promiseKeepAlivePost('/bauth/init', initBody, abortController.current.signal)
      .then(res => processCallback(res))
      .catch(e => {
        setSignStatus('error');
        console.error('INITERROR: ', e.message);
      });
  };

  useEffect(() => {
    if (reference) {
      poll();
    }
  }, [reference]);

  const handleAbort = async () => {
    if (abortCallback) {
      abortCallback();
    }
    abortController.current.abort();
    abortController.current = new AbortController();
    middleman.promisePost('/bauth/abort', { authReference: reference });
    setReference(false);
    setSignStatus('visible');
  };

  const redirectToSignup = () => {
    logoutUser();
    setSignupRedirect(true);
  };

  const handleSubmit = e => {
    e.preventDefault();
    // Sanitize ssn
    let sanitizedSsn = sanitizeSsn(ssn);
    setSsn(sanitizeSsn);

    // Validate SSN
    const isSsnNotValid = swedishSsn(sanitizedSsn);
    if (isSsnNotValid) return;

    setSignStatus('waiting');
    fetchData();
  };

  const resetBankID = async () => {
    if (reference) {
      try {
        abortController.current.abort();
        abortController.current = new AbortController();
        await middleman.promisePost('/bauth/abort', { authReference: reference });
      } catch (e) {
        console.error(e.message);
      }
    }
    setReference(false);
    setSignStatus('visible');
  };

  if (signupRedirect) {
    return (
      <Redirect
        to={{
          pathname: __route('signup'),
          search: "?from=login",
          state: { ssn: ssn.value }
        }}
      />
    );
  }

  return (
    <LoginModal
      animateState={signStatus}
      handleSubmit={handleSubmit}
      ssn={ssn}
      ssnError={ssnError}
      setSsn={setSsn}
      setSsnError={setSsnError}
      handleAbort={handleAbort}
      redirectToSignup={redirectToSignup}
      reset={resetBankID}
    />
  );
};

export default Login;
