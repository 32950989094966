import React from "react";

import "./index.scss";
import Icon from "@hubins/components/IconNew";
import { Tab } from "react-tabs";

/**
 *
 * @param {number} status - The current selected tab
 * @param {number} index -  The single tabs own index value
 * @param {boolean} error - If true - show the edit icon TODO: Change or remove
 * @returns {string} - Returns the current className name
 */
const toStatus = (status, index, error) => {
  if (status === index) return "current";
  else if (!error) return "done";
  else return "comming";
};

export const ProgressTabGroup = ({ title, active, children, relativeWidth, className }) => {
  return (
    <li
      className={`progresstab ${(active ? " progresstab--active" : "")} ${className}`}
      style={relativeWidth && { flexGrow: 100 * relativeWidth }}
    >
      <div className="progresstab__title">{title}</div>
      <ul className="progresstab__group">{children}</ul>
    </li>
  );
};

const ProgressTab = (props) => {
  const {
    status,
    title,
    group,
    index,
    error,
    className,
    relativeWidth,
    // showIndex,
    disabled,
    icon,
    // groupCompleted,
    ...restProps
  } = props;
  const showTooltip = group ? { ["data-tooltip"]: title } : null;
  return (
    <Tab
      {...restProps}
      index={index}
      key={title + index}
      disabled={disabled}
      className={`progresstab--${toStatus(status, index, error)} ${
        group ? "progresstab--group" : ""
      } ${className}`}
      data-id={index}
      style={relativeWidth && { flexGrow: 100 * relativeWidth }}
    >
      {/* <div>
        {showIndex && <span className="progresstab__index">{index + 1}. </span>}
        {!hideTitle && (
          <span className={"progresstab__title"}>{group || title}</span>
        )}
      </div> */}
      <div
        className={"progresstab__bg" + (group ? " progresstab__bg--group" : "")}
        {...showTooltip}
        data-id={index}
      >
        {icon ? (
          <Icon className="progresstab__icon" icon={icon} size="16" />
        ) : (
          <>
            { index < status && (
              <Icon className="progresstab__icon" color="white" icon="check signle" size="16" />
            )}
            {index === status && (
              <Icon className="progresstab__icon" icon="View 1 by Streamlinehq" size="16" />
            )}
          </>
        )}

      </div>
    </Tab>
  );
};

ProgressTabGroup.defaultProps = {
  className: ''
};

ProgressTab.defaultProps = {
  showIndex: true,
  group: false,
  icon: false,
};

export default ProgressTab;
