import React from 'react';
import { motion } from 'framer-motion';
import './index.scss';

const ErrorMessage = ({ text, showError = false, className = "" }) => {

  return (
    <div className={`error_message_outer_wrapper ${className}`}>
      {showError &&
      <motion.div
        initial={{ height: 0, opacity: 0 }}
        animate={{ height: 'auto',  opacity: 1 }}
        exit={{ height: 0,  opacity: 0 }}
      >
        <div className="error_message__wrap error_message__wrap--visible" role="alert">
          <span className="error_message">{text}</span>
        </div>
      </motion.div>
      }
    </div>
  );
};

export default ErrorMessage;
