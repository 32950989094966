import React from 'react';
import SimpleTable from 'modules/Tables/SimpleTable';
import Heading from 'components/Heading';
import LoadingBar from 'components/LoadingIndicators';
import './index.scss';
import __ from 'localisation';
import { Columns, Column } from 'components/Columns';

const headers = [
  {
    Header: '',
    accessor: 'type',
  },
  {
    Header: '',
    accessor: 'value',
  },
];

const PolicyCard = ({
  portfolio,
}) => {

  const isLoading = !portfolio;

  const data = [
    {
      type: 'Försäkringsnummer',
      value: isLoading ? <LoadingBar /> : portfolio.short_name,
    },
    {
      type: 'Försäkringstagare',
      value: isLoading ? <LoadingBar /> : portfolio.policy_holder.map(user => user.name).join(', '),
    },
    {
      type: 'Datum för utfärdande',
      value: isLoading ? <LoadingBar /> : portfolio.start_date,
    },
  ];


  return (
    <div className="">
      <Columns>
        <Column s="12">
          <Heading size="4" className="policy-card__title">
            {__('policy_information')}
          </Heading>
        </Column>
        <Column s="12">
          <SimpleTable
            // className="policy-table"
            headers={headers}
            data={data}
          />
        </Column>
      </Columns>
      {/* <Button href={policy.url} download>Ladda ner PDF</Button> */}
    </div>
  );
};

PolicyCard.defaultProps = {
  currency: 'SEK'
};

export default PolicyCard;
