import React from 'react';
import Template from './compactTemplate';
import Init from './init';
import './compact.scss';

const Compact = (props) => (
  <div className="formselect-compact">
    <Init {...props} Template={Template} />
  </div>
);

export { Template };
export default Compact;
