import React, { useState } from 'react';
import __, { __route } from 'localisation/hubins';
import Wrapper from '@hubins/components/Wrapper';
import { Columns, Column } from 'components/Columns';
import Heading from 'components/Heading';
import { connect } from "react-redux";
import ListButton from '@hubins/components/ListButton';
import newInsuranceImgUrl from 'assets/illustrations/new_insurance.svg';
import CompanyRoaringModal from '@hubins/components/CompanyRoaringModal/CompanyRoaringModal';

const RegisterInsurance = ({ user }) => {

  const [comp, setCompany] = useState(false);

  const closeModal = () => {
    setCompany(false);
  };

  const privateProfile = user.private_persons && user.private_persons.length > 0 ? user.private_persons[0] : false;

  return (
    <Wrapper>
      <CompanyRoaringModal open={!!comp} closeModal={closeModal}/>
      <Columns>
        <Column m="6">
          <Heading size="2" className="s-bottom-m">
            {__('register_insurance_title_new')}
          </Heading>
          <Heading size="5" className="s-bottom-xl">{__('register_insurance_text')}</Heading>
          <Heading size="4" className="s-bottom-l">
            Ny registrering
          </Heading>
          <ListButton
            title="Kapitalförsäkring för bolag"
            text="Öppna kapitalförsäkring för att investera och flytta in onoterat innehav för ett eller flera bolags räkning."
            icon="Briefcase 2"
            onClick={() => setCompany(true)}
          />

          {privateProfile && (
            <ListButton
              key={privateProfile.id}
              title="Kapitalförsäkring för privatpersoner"
              text="Öppna en eller flera kapitalförsäkringar för att investera och flytta in onoterat innehav som privatperson. "
              icon="Single Neutral Actions Add by Streamlinehq"
              href={`${__route("p_presale")}/${privateProfile.uuid}`}
            />
          )}
          {user.companies && user.companies.length > 0 && (
            <>
              <Heading size="4" className="s-bottom-l s-top-xl">
                Lägg till ytterliggare kapitalförsäkring för befintligt bolag
              </Heading>
              {user.companies.map((company) => (
                <ListButton
                  key={company.id}
                  title={company.name}
                  icon="Briefcase 2"
                  href={`${__route("c_presale_existing")}/${company.uuid}`}
                />
              ))}
            </>
          )}
        </Column>
        <Column m="6">
          <img src={newInsuranceImgUrl} alt="New insurance" />
        </Column>
      </Columns>
    </Wrapper>
  );
};

function mapStateToProps(state) {
  return {
    user: state.auth.User,
  };
}

export default connect(
  mapStateToProps
)(RegisterInsurance);
