
/** Feature Flags
 *
 * Usage: Set a flag in localStorage, and refresh the page to try out the feature.
 * Ex: FF_loginUsingBankId: 'true' to try BankID instead of Signicat - set to false (or remove) if you wanna use Signicat
*/

//  import { getMarket } from 'states/utilities';
//  const market = getMarket();
const featureKeys = {
  'FF_loginUsingBankId': true,
  'FF_bypassBankId': true,
  'FF_fakeRoaring': true,
};

Object.keys(featureKeys).map((key) => {
  const value = featureKeys[key];
  if (window.localStorage.getItem(key) === null) { window.localStorage.setItem(key, value); }
});

// const isSet = (key) => window.localStorage.getItem(key) === 'true'; // the value in the key need to be set to the string true
const fromStorage = (key) => {
  let value = window.localStorage.getItem(key);
  if (value.toLowerCase() === 'true') return true;
  if (value.toLowerCase() === 'false') return false;
  return window.localStorage.getItem(key);
};

const FF = {
  /** FFloginUsingBankId
   * set false to use signicat as verification
   * currently bankid is bypassed outside production server,
   * and doesn't work at all on the production server
   */
  loginUsingBankId: fromStorage('FF_loginUsingBankId'),
  bypassBankId: fromStorage('FF_bypassBankId'),
  fakeRoaring: fromStorage('FF_fakeRoaring'),
};

export default FF;
