import React, { useEffect, useRef, useState } from 'react';
import { loadForm } from '../../helpers/form';
import { LoadingBox } from "components/LoadingIndicators";
import Heading from "components/Heading";
import Wrapper from "@hubins/components/Wrapper";
import Input from '@hubins/components/Input';
import Icon from '@hubins/components/IconNew';
import InquiryModal from './InquiryModal';
import CreateForm from "modules/CreateForm";
import { formDataToCollection } from '../../helpers/filters';
import __reg from 'localisation/hubins/registration';
import { validateNumber } from 'components/Validation';

import { useHistory } from 'react-router-dom';
import './index.scss';

import middleman from 'helpers/middleman';
/* Custom Hook to run only on updates except initial mount.
*/
const useUpdateEffect = (effect, dependencies = []) => {

  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      effect();
    }
  }, dependencies);
};

const TransferExtraInfo = ({ holdings }) => {


  let history = useHistory();

  const [form, setForm] = useState(false);
  const [renderForm, setRenderForm] = useState(true);
  const [selected, setSelected] = useState(0);
  const [holding, setHolding] = useState(null);
  const [isin, setIsin] = useState({
    value: true,
    error: false,
  });
  const [inquiryModalOpen, setInquiryModalOpen] = useState(false);

  /* Scrolls to the top of the page when form is rerendred.
  */
  useUpdateEffect(() => {
    window.scrollTo(0, 1000);
    setRenderForm(true);
  }, [selected]);


  const resetForm = () => {
    setForm(false);
    setRenderForm(false);
  };

  /* Handels next form by increase the selcted by 1
  * and if remove param is present it pushes navigation to "/transer".
  * Otherwise it opens the inquiryModal.
  */
  const handleNext = (remove) => {
    if (selected < (holdings.length - 1)) {
      return setSelected(selected + 1);
    }
    if(remove) {
      return history.push("/transfer");
    }
    return setInquiryModalOpen(!inquiryModalOpen);
  };

  /* Post API call to middleman where it updates the current holding.
  */
  const updateHolding = async () => {
    try {
      const response = await middleman.post(`/holdings/update/${holding.uuid}`, { isin: isin.value });
      if(response.status === 200) {
        handleNext();
      }
    } catch (error) {
      console.log(error);
    }
  };

  /* Post API call to middleman where it deletes the current holding.
  */
  const deleteHolding = async () => {
    try {
      const response = await middleman.promiseDelete(`/holdings/delete/${holding.uuid}`, {});
      if(response.status === 200) {
        handleNext(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  /* Submits the form and updates holding and goes to next form.
  * NOTES: Need to make a formRespones there aswell.
  */
  const submit = (values) => {
    // create a form response
    console.log(formDataToCollection(values));
    updateHolding();
    resetForm();
  };

  useEffect(() => {
    loadForm("info_toa", "register", setForm, () => {
    });
    setHolding(holdings[selected]);
    setIsin({
      ...isin,
      value: true,
      error: false,
    });
  }, [selected]);


  return (
    <>
      {(holding && renderForm) && <>
        <Wrapper narrow tight>
          <Heading size="3" className="c-primary">{holding.security.name}</Heading>
          <a className='clickable' onClick={deleteHolding}>Ta bort investering <Icon icon="Bin by Streamlinehq" size={16} /></a>
        </Wrapper>
        <Wrapper narrow tight>
          <Heading className="heading heading--4 s-bottom-sm">Ange ISIN eller Org.nummer</Heading>
          <Input
            required
            type="text"
            label="Maecenas sed diam eget risus varius blandit sit amet non magna. Cras mattis consectetur purus sit amet fermentum."
            error={isin.error}
            value={holding.security.isin}
            callback={(value) =>
              setIsin({
                ...isin,
                value: value,
                error: validateNumber(value, true),
              })
            }
          />

        </Wrapper>
      </>
      }
      {form === false ?
        <LoadingBox />
        :
        <>
          {renderForm && (
            <CreateForm
              className="wrapper form wide"
              formData={form.content}
              submitType={submit}
              submitText={__reg("save_information")}
              loading={false}
              disabled={false}
            />
          )}
        </>
      }
      <InquiryModal
        inquiryModalOpen = {inquiryModalOpen}
        setInquiryModalOpen = {setInquiryModalOpen}
      >
      </InquiryModal>
    </>
  );
};

export default TransferExtraInfo;
