import React from 'react';
import __ from 'localisation';
import Badge from '@hubins/components/Badge';
import { LoadingBar } from 'components/LoadingIndicators';
import { formatMoney } from 'helpers';
import Heading from 'components/Heading';

import EventCard from 'modules/EventCard';

const DepositCard = ({
  data = {},
  ...restProps
}) => {

  const type = data.type ? data.type.typeCode : '';

  return (
    <EventCard
      {...restProps}
      alt={__('type_' + type)}
      text={data.eventCard ? __(data.eventCard.text) : false}
      meta={
        type
          ? `${__('type_' + type)} - ${data.transactionDate}`
          : <LoadingBar medium />
      }
      status={<Badge color={data.eventCard.color}>{__(data.eventCard.title)}</Badge>}
      header={
        <>
          <Heading element="h3" size="4">{formatMoney(data.amount, data.currencyCode || 'SEK')}</Heading>
          <p className="clarification">{__(data.eventCard.text)}</p>
        </>
      }
    />
  );

};

export default DepositCard;
