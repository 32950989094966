import React, { useCallback, useEffect, useState } from 'react';
import SimpleTable from 'modules/Tables/SimpleTable';
import LoadingBar from 'components/LoadingIndicators';
import { getMoney } from 'helpers';
// import { getendowmentTransactionDefaultState } from 'states/reports/reducers';
import ReadMore from 'components/Accordeon/ReadMore';
import Heading from 'components/Heading';
import __ from 'localisation';
import { mergeEndowments } from 'helpers/endowment';

const getYields = (endowmentValues) => {
  const { marketValue: mv, marketValueStart: mvs, janToJun: jtj, julToDec: jtd, withdrawalAmount: w } = endowmentValues;
  const yearlyYield = (mv || 0) - (mvs || 0) - (jtj || 0) - (jtd || 0) + (w || 0);
  return yearlyYield;
};

const InsuranceFees = ({ endowment, transactionsEndowment, isLoading, currency, qlyr }) => {

  const data = isLoading ? [] : mergeEndowments(endowment, transactionsEndowment);

  const [generatedData, setGeneratedData] = useState({ tableData: [], headers: [], tableData2: [], headers2: [], repurchaseValue: <LoadingBar /> });

  const generateData = useCallback(() => {
    let headers = [
      {
        Header: '',
        accessor: 'type',
        Cell: ({ row }) => <div className={row.original.className}>{row.original.type}</div>
      }
    ];

    let headers2 = [
      {
        Header: '',
        accessor: 'type',
      }
    ];

    let totalInsuranceFees = { type: __('total_insurance_fees') };
    let totalInsuranceFeesQL = {
      type: __('total_insurance_fees_ql'),
      className: 'row-child',
    };
    let totalInsuranceFeesHubins = {
      type: __('total_insurance_fees_hubins'),
      className: 'row-child',
    };
    let totalInsuranceFeesHubins1 = {
      type: __('total_insurance_fees_hubins_1'),
      className: 'row-child',
    };
    let totalInsuranceFeesOther = {
      type: __('total_insurance_fees_other'),
      className: 'row-child',
    };
    let totalInsuranceToThirdParty = {
      type: __('total_insurance_from_third_party'),
      className: 'row-child',
    };
    let totalInsuranceFromThirdParty = {
      type: __('total_insurance_to_third_party'),
      className: 'row-child',
    };

    let oneTimeFeeTotal = { type: __('one_time_fee_total') };
    let oneTimeFeeQL = {
      type: __('one_time_fee_ql'),
      className: 'row-child',
    };
    let oneTimeFeeHubins = {
      type: __('one_time_fee_hubins'),
      className: 'row-child',
    };

    let runningFixedCostTotal = { type: __('running_fixed_cost_total') };
    let runningFixedCostQL = {
      type: __('running_fixed_cost_ql'),
      className: 'row-child',
    };
    let runningFixedCostHubins = {
      type: __('running_fixed_cost_hubins'),
      className: 'row-child',
    };

    let runningVariableCostTotal = { type: __('running_variable_cost_total') };
    let runningVariableCostQL = {
      type: __('running_variable_cost_ql'),
      className: 'row-child',
    };
    let runningVariableCostHubins = {
      type: __('running_variable_cost_hubins'),
      className: 'row-child',
    };
    let runningVariableCostHubins1 = {
      type: __('running_variable_cost_hubins1'),
      className: 'row-child',
    };

    let runningCostRiskpreTotal = { type: __('running_cost_riskpre_total') };
    let runningCostRiskpreQL = {
      type: __('running_cost_riskpre_ql'),
      className: 'row-child',
    };

    let totalYieldWithoutFees = { type: __('total_yield_without_fees') };
    let totalFeesAndCosts = { type: __('total_fees_and_costs') };
    let totalYieldAfterFeesAndCosts = { type: __('total_yield_after_fees_and_costs') };

    let repurchaseValue = <LoadingBar />;

    if (!isLoading) {

      const allYears = Object.keys(data);

      allYears.forEach((year, index) => {

        if (Number(year) >= 2020) {
          if (index === allYears.length - 1) {
            repurchaseValue = getMoney(data[year].marketValue, currency);
          }

          const yearData = data[year];

          const AMFOIFA = yearData.fees ? (yearData.fees.AMFOIFA || 0) : 0;
          const TCQL1 = yearData.fees ? (yearData.fees.TCQL1 || 0) : 0;
          const TCQL2 = yearData.fees ? (yearData.fees.TCQL2 || 0) : 0;
          const FPCQL = yearData.fees ? (yearData.fees.FPCQL || 0) : 0;
          const FPCQL2 = yearData.fees ? (yearData.fees.FPCQL2 || 0) : 0;
          const AMFQL = yearData.fees ? (yearData.fees.AMFQL || 0) : 0;
          const AMFQL2 = yearData.fees ? (yearData.fees.AMFQL2 || 0) : 0;
          const AMFIFA = yearData.fees ? (yearData.fees.AMFIFA || 0) : 0;
          const RISKPRE = yearData.fees ? (yearData.fees.RISKPRE || 0) : 0;

          headers.push({
            Header: year,
            accessor: year
          });

          headers2.push({
            Header: year,
            accessor: year
          });

          totalInsuranceFees[year] = getMoney(TCQL1 + FPCQL + AMFQL + RISKPRE + TCQL2 + FPCQL2 + AMFQL2 + AMFIFA + AMFOIFA, currency);
          totalInsuranceFeesQL[year] = getMoney(TCQL1 + FPCQL + AMFQL + RISKPRE, currency);
          totalInsuranceFeesHubins[year] = getMoney(TCQL2 + FPCQL2 + AMFQL2, currency);
          totalInsuranceFeesHubins1[year] = getMoney(AMFIFA, currency);
          totalInsuranceFeesOther[year] = getMoney(AMFOIFA, currency);
          totalInsuranceFromThirdParty[year] = getMoney(0, currency);
          totalInsuranceToThirdParty[year] = getMoney(0, currency);
          oneTimeFeeTotal[year] = getMoney(TCQL1 + TCQL2, currency);
          oneTimeFeeQL[year] = getMoney(TCQL1, currency);
          oneTimeFeeHubins[year] = getMoney(TCQL2, currency);
          runningFixedCostTotal[year] = getMoney(FPCQL + FPCQL2, currency);
          runningFixedCostQL[year] = getMoney(FPCQL, currency);
          runningFixedCostHubins[year] = getMoney(FPCQL2, currency);
          runningVariableCostTotal[year] = getMoney(AMFQL + AMFQL2 + AMFIFA, currency);
          runningVariableCostQL[year] = getMoney(AMFQL, currency);
          runningVariableCostHubins[year] = getMoney(AMFQL2, currency);
          runningVariableCostHubins1[year] = getMoney(AMFIFA, currency);
          runningCostRiskpreTotal[year] = getMoney(RISKPRE, currency);
          runningCostRiskpreQL[year] = getMoney(RISKPRE, currency);

          const totalFeesAndCostsValue = TCQL1 + FPCQL + AMFQL + RISKPRE + TCQL2 + FPCQL2 + AMFQL2 + AMFIFA + AMFOIFA;
          const totalYieldAfterFeesAndCostsValue = getYields(yearData);



          totalFeesAndCosts[year] = getMoney(totalFeesAndCostsValue, currency);
          totalYieldAfterFeesAndCosts[year] = getMoney(totalYieldAfterFeesAndCostsValue, currency);
          totalYieldWithoutFees[year] = getMoney(totalFeesAndCostsValue + totalYieldAfterFeesAndCostsValue, currency);

        }

      });

    }

    let tableData = [
      totalInsuranceFees,
      qlyr ? false : totalInsuranceFeesQL,
      qlyr ? false : totalInsuranceFeesHubins,
      qlyr ? false : totalInsuranceFeesHubins1,
      qlyr ? false : totalInsuranceFeesOther,
      qlyr ? false : totalInsuranceToThirdParty,
      qlyr ? false : totalInsuranceFromThirdParty,
      oneTimeFeeTotal,
      qlyr ? false : oneTimeFeeQL,
      qlyr ? false : oneTimeFeeHubins,
      runningFixedCostTotal,
      qlyr ? false : runningFixedCostQL,
      qlyr ? false : runningFixedCostHubins,
      runningVariableCostTotal,
      qlyr ? false : runningVariableCostQL,
      qlyr ? false : runningVariableCostHubins,
      qlyr ? false : runningVariableCostHubins1,
      runningCostRiskpreTotal,
      qlyr ? false : runningCostRiskpreQL,
    ].filter(item => item !== false);

    let tableData2 = [
      totalYieldWithoutFees,
      totalFeesAndCosts,
      totalYieldAfterFeesAndCosts
    ];

    if (isLoading) {

      headers.push({
        Header: '',
        accessor: 'loading'
      });
      headers2.push({
        Header: '',
        accessor: 'loading'
      });

      tableData = tableData.map(item => {
        item.loading = <LoadingBar />;
        return item;
      });
      tableData2 = tableData.map(item => {
        item.loading = <LoadingBar />;
        return item;
      });
    }

    setGeneratedData({
      tableData,
      headers,
      tableData2,
      headers2,
      repurchaseValue,
    });
  }, [endowment, transactionsEndowment, isLoading]);

  useEffect(() => {
    generateData();
  }, [endowment, transactionsEndowment, isLoading]);

  const { tableData, headers, tableData2, headers2, repurchaseValue } = generatedData;

  return (
    <>
      <Heading size="4" className="section-padding">
        {__('insurance_fees')}
      </Heading>
      {!qlyr && (
        <ReadMore
          className="maxwidth"
          visibleContent={__('insurance_fees_section_1')}
          hiddenContent={__('insurance_fees_section_2')}
        />
      )}
      <Heading size="5" className="s-top-xl">
        {__('repurchase_value')}: {repurchaseValue}
      </Heading>
      <SimpleTable
        headers={headers}
        data={tableData}
        className="s-bottom-m"
      />
      <Heading size="5" className="s-bottom-m s-top-xl">
        {__('insurance_fees_table_2_intro')}
      </Heading>
      <SimpleTable
        headers={headers2}
        data={tableData2}
      />
    </>
  );

};

export default InsuranceFees;
