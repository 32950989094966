import React, { Component } from 'react';

import './index.scss';

export const uiContext = React.createContext();

// /**
//  * A class to have a global UI state using React Contect API
//  * Is used in the Sidebar question component,
//  * but can be used in all UI related contexts.
//  *
//  * Add data-shiftonsidebaropen on all elements that you want
//  * to shift to the left to make room for the sidebar
//  *
//  * @class UiProvider
//  * @extends {Component}
//  */

class UiProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questionText: '',
      questionContent: '',
      questionOpen: false,
      currentQuestionId: 0,
      questionIcon: 'Question Circle by Streamlinehq',
      lastInput: null,
      overlayState: 'hidden', // hidden, login, sign, error or waiting (usually polling)

      // Cookie
      checked: true,
      acceptsCookies: false,
      consent: false,
    };
  }

  // set checked to true when user has clicked 'Accept' or 'Decline'
  // (true by default but is set to false if no 'consent' exist in localstorage,
  // this is to avoid CookieBanner flashing by before javascript detects localstorage. )
  setChecked = (checked) => {
    this.setState({ checked });
  }

  // set acceptsCookies to true when user has accepted cookies
  // ( when localstorage 'consent' === true )
  setAcceptsCookies = (acceptsCookies) => {
    this.setState({ acceptsCookies });
  }

  setConsent = (consent) => {
    this.setState({ consent });
  }

  animateElements = (open) => {
    const sliderElements = document.querySelectorAll('[data-shiftonsidebaropen]');

    if (open) {
      sliderElements.forEach(element => {
        element.classList.add('sidebar-shift');
      });
    } else {
      sliderElements.forEach(element => {
        element.classList.remove('sidebar-shift');
      });
    }
  }

  toggleQuestionOpen = (controlled) => {

    if (typeof controlled === "boolean") {
      this.setState({ questionOpen: controlled });
      this.animateElements(controlled);
      return;
    }

    if (!this.state.questionOpen) {
      this.setState({ questionOpen: true });
      this.animateElements(true);

    } else {
      this.setState({ questionOpen: false });
      this.animateElements(false);

    }
  }

  updateState = (newState, callback) => {
    this.setState(newState, callback);
  }

  setOverlayLogin = () => {
    this.setState({
      overlayType: 'login',
      overlayState: 'visible',
    });
  }

  setOverlaySign = (props) => {
    this.setState({
      signState: props,
      overlayType: 'sign',
      overlayState: 'visible',
      overlayClose: props.handleAbort,
      overlayPlatform: props.platform,
    });
  }

  setOverlayState = (str) => {
    this.setState({
      overlayState: str
    });
  }

  setOverlayClose = () => {
    this.setOverlayState('hidden');
  }

  // For custom content
  setOverlayContent = (content) => {
    this.setState({
      overlayState: 'login', // TODO: Change this state to visible instead of login everywhere
      overlayType: 'modal',
      overlayContent: content,
    });
  }

  render() {

    // const children = React.Children.map(this.props.children, child =>
    //   React.cloneElement(child, { id: 'sidebar_' + this.state.currentQuestionId })
    // );

    return (
      <uiContext.Provider value={{
        updateState: this.updateState,

        questionText: this.state.questionText,
        questionContent: this.state.questionContent,
        questionOpen: this.state.questionOpen,
        questionIcon: this.state.questionIcon,
        currentQuestionId: this.state.currentQuestionId,
        lastInput: this.state.lastInput,
        toggleQuestionOpen: this.toggleQuestionOpen,

        login: this.setOverlayLogin,
        sign: this.setOverlaySign,
        signState: this.state.signState,
        overlayType: this.state.overlayType, // ex login, sign
        overlayState: this.state.overlayState, // ex hidden, login, sign, error or waiting (usually polling)
        setOverlayState: this.setOverlayState,
        overlaySubmit: this.state.overlaySubmit,
        overlayClose: this.state.overlayClose,
        setOverlayClose: this.setOverlayClose,
        overlayPlatform: this.state.overlayPlatform,
        platform: 'Hubins', // ex Hubins
        overlayContent: this.state.overlayContent,
        setOverlayContent: this.setOverlayContent,
      }}>
        {/* {children} */}
        {this.props.children}
      </uiContext.Provider>
    );
  }
}

export default UiProvider;
