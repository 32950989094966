import React from 'react';
import { __route } from 'localisation';
import ListButton from '../ListButton';
import { Columns, Column } from 'components/Columns';
import Heading from 'components/Heading';
import IconNew from '../IconNew';
import "./DepositWithdrawal.scss";

const DepositWithdrawal = () => {

  return (
    <Columns className="neutral-2 basic-padding-large border-radius">
      <Column s="12">
        <Heading className={"c-primary"} size="4">Överför</Heading>
      </Column>
      <Column m="6" className="s-top-bottom-none">
        <ListButton
          href={__route('deposit')}
          title="Sätt in pengar"
          icon="Deposit"
          actionText={""}
          customIcon={<span className="DepositWithdrawal__icon"><IconNew icon="Deposit" size={32} /></span>}
          />
      </Column>
      <Column m="6" className="s-top-bottom-none">
        <ListButton
          href={__route('withdrawal')}
          title="Ta ut pengar"
          icon="Withdrawal"
          actionText={""}
          customIcon={<span className="DepositWithdrawal__icon"><IconNew icon="Withdrawal" size={32} /></span>}
        />
      </Column>
    </Columns>
  )

};

export default DepositWithdrawal;