import PropTypes from 'prop-types';

/*
Usage:

import PreCacheImg from 'components/PreCacheImg';
import image_onboaring from 'assets/illustrations/onboaring.svg';
import image_documents from 'assets/illustrations/documents.svg';

<PreCacheImg
  images={[image_onboaring, image_documents]}
/>

*/

const PreCacheImg = ({ images, callback }) => {
  const precache = () => {
    let image = null;
    for (let i = 0, len = images.length; i < len; i += 1) {
      image = new Image();
      image.src = images[i];
      if (callback) image.onload = () => {
        callback[i](true);
      };
    }
    return false;
  };

  return precache();
};

PreCacheImg.propTypes = {
  images: PropTypes.array.isRequired,
  callback: PropTypes.array,
};

export default PreCacheImg;
