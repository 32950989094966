import React, { useState } from 'react';
import InvestmentForm from "modules/InvestmentForm";
import Main from "components/Main";

const SecurityInformation = ({ selectedTab, setSelectedTab, selectedInvestments, setSelectedInvestments, juridical }) => {

  const [selected, setSelected] = useState(0);

  /* Checks if it's the end of selected investment array.
  * returns {boolean} true if it's the end of investments. false if it isn't
  */
  const endOfInvestments = () => {
    if (selected < selectedInvestments.length - 1) {
      return false;
    }
    return true;
  };

  /* Checks if there is no investments in array.
  * returns {boolean} true if it's the end of investments. false if it isn't
  */
  const nullInvestments = () => {
    if (selectedInvestments.length - 1 <= 0) {
      return true;
    }
    return false;
  };

  /* Parses form data composes it to a form_response.
  * params {values} values from the form.
  * params {key} null but needed.
  * params {type} null but needed.
  * retuns {object} a object with acquisition_value, current_value, expected_return_per_year and investment_horizon.
  */

  // eslint-disable-next-line no-unused-vars
  const parseFormData = (values, key, type) => {

    const form_response = {
      acquisition_value: values.acquisition_value,
      current_value: values.current_value,
      expected_return_per_year: values.expected_return_per_year,
      investment_horizon: values.investment_horizon
    };

    if (values.qualified_shares) {
      form_response.qualified_shares = values.qualified_shares[0];
    }

    if (values.intention_to_list) {
      form_response.intention_to_list = values.intention_to_list[0];
    }

    if (values.non_nutritional) {
      form_response.non_nutritional = values.non_nutritional[0];
    }
    return form_response;
  };

  /* Updates the security/investment with the new information added in the form.
  * Checks if asset_class is in the params and attaches it to the investment.
  * params {form_response} parsed data from the form.
  * params {form} key and type of form.
  * params {asset_class} asset_class from input in form.
  */

  const updateInvestment = (form_response, form, asset_class) => {
    let investments = [...selectedInvestments];
    let investment = { ...investments[selected] };
    investment.form_response = form_response;
    investment.form = form;
    if (asset_class) {
      investment.type = asset_class;
    }
    investments[selected] = investment;
    setSelectedInvestments(investments);
  };

  /* Determents the flow of next investment based on type of progression.
  * params {type} type of progression.
  * return {functions} differerent senarios of progression.
  */
  const proceed = (type) => {
    if (type === "update") {
      return endOfInvestments() ? setSelectedTab(selectedTab + 1) : setSelected(selected + 1);
    }
    if (type === "remove") {
      if (nullInvestments()) {
        return setSelectedTab(selectedTab - 1);
      }
      return endOfInvestments() && setSelectedTab(selectedTab + 1);
    }
  };

  /* handling update of an investment after investment submit and proceeds.
  * params {values} values from the form.
  * params {key} key of the form.
  * params {type} type of the form.
  */
  const handleUpdateInvestment = (values, key, type) => {
    const form_response = parseFormData(values);
    const form = {
      key: key,
      type: type
    };
    if (values.asset_class) {
      const asset_class = values.asset_class[0];
      updateInvestment(form_response, form, asset_class);
    }
    else {
      updateInvestment(form_response, form);
    }
    proceed("update");
  };

  /* handling of removed investment after investment submit fails and the user
  * determents to remove the security/investment.
  */
  const removeInvestment = () => {
    const investments = [...selectedInvestments];
    investments.splice(selected, 1);
    setSelectedInvestments(investments);
    proceed("remove");
  };

  return (
    <Main center={true}>
      <InvestmentForm
        selectedInvestment={selectedInvestments[selected]}
        handleUpdateInvestment={handleUpdateInvestment}
        removeInvestment={removeInvestment}
        juridical={juridical}
        endOfInvestments={endOfInvestments}
      ></InvestmentForm>
    </Main>
  );
};

export default SecurityInformation;
