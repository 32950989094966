/* eslint-disable react/forbid-elements */

import React, { Fragment } from 'react';

export const keys = (v = { test: true }) => ({

  // Customized strings for different markets - these will override the default STRINGS
  MARKET_STRINGS: {
    // Sweden
    SE: {
    },

    // Norway
    NO: {
    },

    // Finland
    FI: {
    }
  },

  // Default strings
  STRINGS: {

    /////// Work and Education related keys /////////

    /////////// Education
    'no_education': 'No Education',
    'college_degree': 'Collage Degree',
    'professional_degrees': 'Professional degree',
    'licentiate_degree': 'Licentiate degree',
    'doctor_degree': 'Doctor degree',
    'other_education': 'Other',
    ///////////////


    'daycare_children_education': 'Daycare and children education',
    'construction_design_interior': 'Construction, Design and interior',
    'electricity_and_energy': 'Electricity and energy',
    'aesthetics_art_crafts': 'Aesthetics, art and crafts',
    'vehicle_transport': 'Vehicle and transport',
    'humanism_staff_science': 'Humanism and human resources',
    'sports_health': 'Sports and health',
    'industry_plant': 'Industry and plant',
    'military_education': 'Military education',
    'environment_sustainability': 'Environment and sustainability',
    'nature_science': 'Nature and science',
    'resturant_food_production_hotel_turism': 'Restaurant, food production, hotel and tourism',
    'language_journalism_media': 'Languages, journalism and media',
    'education_pedagogy': 'Education and pedagogy',
    'plumbing_real_estate': 'Plumbing and real estate',


    //////// Employment-Type //////////
    'employed_company_less_then_10_employees': 'Employed by a company with fewer than 10 employees',
    'employed_company_between_10_and_100_employees': 'Employed by a company with 10 to 100 employees.',
    'employed_company_more_then_100_employees': 'Employed by a company with more than 10 employees',
    'self_employed': 'Self-employed (own company)',
    'self_employed_stock_trade_other_company': 'Self-employed Limited company, trading company, other ',
    'full_time_study': 'Full time study',
    'part_time_study': 'Part time study',
    'looking_for_work': 'Job-seeker',
    'financially_independent': 'Economically independent',
    'other_employment': 'Other',
    //new for KYCemployment-dropDown-function
    'studying': 'Studying',
    'employed': 'Employed',
    'own_business': 'Self-Employed',
    /////////////

    'construction': 'Construction',
    'financial_insurance_holding_company': 'Finance, insurance and holding company',
    'electricity_gas_heat_cold_supplier': 'Supplier of electricity, gas, heating and cooling',
    'household_services': '',
    'trade_repair_vehicle_services': 'Trade; repair of motor vehicles and motorcycles',
    'hotel_resturant_business': 'Hotel and restaurant business',
    'data_communication_business': 'Information and communication activities',
    'farming_forestry_fishing_business': 'Farming, forestry and fishing',
    'public_management_and_defending_mandatory_social': 'Public administration and defense; compulsory social insurance',
    'manufacturing': 'Manufacturing',
    'transport_warehousing_business': 'Transport and warehousing',
    'education': 'Education',
    'rental_real_estate_travel_agency_other_support_services': 'Rental, real estate services, travel services and other support services',
    'mining_business': 'Mining',
    'water_supplier_drain_cleaning_disposal_decontamination': 'Water supply; drain cleaning, disposal decontamination',
    'law_economic_science_engineering': 'Law, economic, science and engineering',
    'international_org_foreign_embassy': 'International organisations, foreign embassies, etc.',
    'healtcare_social_service': 'Health and social care; social services',
    'other_service_business': 'Other',


    'none_working': 'Does not work',
    'administration_economy_law': 'Administration, economy, law',
    'sale_purchase_marketing': 'Sales, purchases and marketing',
    'craft_trades': 'Craft professions',
    'installation_operation_property_maintenance': 'Installation, operation, property maintenance',
    'culture_media_design_pleasure_leisure': 'Culture, media, design, entertainment and leisure',
    'leading_position_public_sector': 'Leading position in the public sector',
    'leading_position_private_services': 'Leading position in the private sector',
    'advertising_pr_market_research': 'Media, advertising, pr & market research',
    'military': 'Military',
    'pedagogical_work': 'Educational work',
    'professional_athlete': 'Professional athletes',
    'social_worker': 'Social worker',
    'games_and_betting': 'Games and betting',
    'safety_emergency': 'Security work / rescue',
    'other_work': 'Other',

    /////////////////////////////

    '_example_usage_variables_': `To use variables in the keys, use back-ticket strings and ${v.variableName}`, // no need to translate

    'invest': 'Invest',
    'standard_user_error_message': `Sorry, something's gone wrong`,
    'standard_user_error_reason': 'It may be that your account is already registered, if so, click on the link below to log in',
    'standard_user_error_action': 'If the error persists, please contact customer service as below',
    'login': 'Login',
    'has_created_a_company_account': <>{v.name} has registered a corporate account with us.</>,
    'placeholder_org': 'xxxxxx-xxxx',

    'account_number': 'account number',
    'according_to_beneficiaries': 'According to the Designated Beneficiary',
    // 'acting_principal': 'Beneficial owner',
    'add_country': 'Additional tax domicile.',
    'address_error': 'Enter your address',
    'address_in_us': 'I have a postal address, ‘per’, ‘c/o’ or home address or a PO box in the United States',
    'address': 'Address',
    'advisor_name': 'You advisor’s name',
    'agriculture_forestry_hunting_fishing': 'Agriculture, Forestry, Hunting & Fishing',
    'bachelor': 'Bachelor’s degree',
    'bank_account_for_payment': 'Bank account for payment',
    'bank_bic': 'Enter the bank’s BIC/SWIFT code',
    'bank_name': 'Name of bank',
    'bank': 'Bank',
    'banking_finance_insurance': 'Bank, Finance & Insurance',
    'beneficiary_label': 'Beneficiaries',
    'beneficiary_regulates': 'A beneficiary clause regulates who receives the insurance amount if the life-insured person should die. A beneficiary clause is not affected by any last will and testament.',
    'born_in_us': 'I was born in the United States',
    'business_services': 'Corporate services',
    'cancel_registration': 'Complete registration',
    'change_answer_btn': 'Change my answer',
    'change_answer': 'Your answer must be changed in order to continue',
    'children_husband_wife_partner_heir': 'Children or, if there are none, spouse/co-habitant or, if there is none, heirs.',
    'choose_bank': 'Select bank',
    'choose_company_sector': 'Select industry',
    'choose_country': 'Select country',
    'choose_education_orientation': 'Select specialisation',
    'choose_education': 'Select education',
    'choose_employment': 'Select employment',
    'choose_occupation': 'Select job',
    'choose_one_or_more': 'Select one or more suitable alternatives',
    'citizenship_error': 'Select country',
    'citizenship': 'Citizenship',
    'city_error': 'Enter your current location',
    'city_of_birth_error': 'Enter place of birth',
    'city_of_birth': 'Place of birth',
    'city': 'City',
    'civics': 'District',
    'classifies_as_financial_institution': 'Is the company classified, as with insurance companies or investment firms, as a financial institution or a financial investment entity? ',
    'clearing_number_notice': 'N.B. Only enter the first 4 digits of your clearing number. E.g. 8440.',
    'clearing_number': 'clearing number',
    'construction_design_interior_design': 'Construction, Design & Interior Design Business',
    'consulting_info_clar': <Fragment>Based on your selections during registration, you have an advisor. If you wish to change advisor or <br /> continue using services without an advisor, please contact our client service at <br /> {v.email} or on 031-797 19 36</Fragment>,
    'consulting': 'Advice',
    'contact.registration.advisor_name': 'Your advisor’s name',
    'corporate_identity_number': 'Company registration number',
    'corporate_sales': 'Company sale',
    'country_of_birth_error': 'Select country',
    'country_of_birth': 'Country of birth',
    'country_outside_eu': 'Country outside the EU',
    'crew_employment_services': 'Staffing & Employment Agency',
    'culture_fun_leisure': 'Culture, Entertainment & Leisure',
    'data_it_telecommunications': 'Data, IT & Telecommunications',
    'diligence': 'Know Your Client (KYC)',
    'diplomat': 'Ambassador, head of mission, or high-ranking officer in the Armed Forces',
    'dividend_associated_comp': 'Share dividend from associate company',
    'dividend': 'Dividend payment',
    'education_completed': 'Highest completed course of education',
    'education_orientation_settings': 'Course’s main subject',
    'education_orientation': 'What was the main subject of your education?',
    'education_research_development': 'Education, Research & Development',
    'email_error': 'Enter your valid email address',
    'email': 'Email',
    'embassies_international_org': 'Embassies & International Orgs.',
    'employee': 'Employed',
    'employment_education': 'Employment & Education',
    'employment': "Employment",
    'family_member': 'Family member',
    'family_spouse_partner': 'Close relative refers to spouse, registered partner, co-habitant, parents, children and their spouses, registered partners or co-habitants. Known employees refers to a natural person who has or had close ties to a politically exposed person, e.g. by being the beneficial owner in a company together with such a person.',
    'fatca_classification_chooice': 'Financial institution within FATCA IGA Partner Jurisdiction',
    'fatca_classification': "FATCA classification",
    'fatca_person': 'FATCA – natural person',
    'financial_authorities': 'High-ranking officer at an auditing authority or member of the central bank’s governing body.',
    'first_name_error': 'Enter your first name',
    'first_name': 'First name',
    'food_production': 'Food production',
    'foreign_bank': 'Foreign bank',
    'foreign_insurance_co': 'Foreign insurance company',
    'general_conditions': 'General terms and conditions',
    'general_terms_check': 'I have read, understood and accept the general business terms and conditions and the general provisions for the holding account',
    'giveoverthekey_imagealt': 'A person who transfers a key to another person',
    'government_official': 'Head of state or government, minister or deputy/junior minister',
    'green_card': 'I have US citizenship or a Green Card',
    'hair_beauty': 'Hair & Beauty Care',
    'has_not_power_of_attorney': 'has not given power of attorney',
    'has_power_of_attorney': 'has given power of attorney',
    'health_medical': 'Health Care',
    'high_school_diploma': 'High-school diploma',
    'highest_education_completed': 'What is your highest completed level of education',
    'hotel_restaurant': 'Hotel & Restaurant',
    'husband_wife_partner_50_and_children_50_heir': 'Half (50%) to spouse/co-habitant and half (50%) to children. If there are none of these, heirs',
    'husband_wife_partner_children_heir': 'Spouse/co-habitant or, if there is none, children or, if there are none, heirs.',
    'income_source_settings': 'Source of capital',
    'individual_investments': 'Individual investments',
    'industry_employers_careers_organisation': 'Sector, Employer & Prof. org.',
    'inheritance_gift': 'Legacy/Gift',
    'insurance_company': 'Insurance company',
    'insurance_protection': 'Insurance Coverage',
    'insurance_number': 'Insurance number',
    'insurance_payout': 'Insurance payment',
    'international_organisation_leader': 'Member of senior management in an international organisation (e.g. the UN, UN-related organisations, the Council of Europe, NATO and the WTO)',
    'invest_rep_sign_open_new': <Fragment><p>Note that it is the investment representative who signs using his/her</p><p>personal BankID at this stage of the registration process.</p><p>Signing opens in a new window.</p></Fragment>,
    'invest_rep_sign': 'Note that it is the investment representative who signs using his/her personal BankID at this stage of the registration process.',
    'investment_representative': 'Investment representative',
    'judicial_officials': 'Judges of the Supreme Court, a constitutional court or of other high-level judicial body whose decisions are not subject to further appeal, except in exceptional circumstances',
    'known_associate': 'Known employee',
    'last_name_error': 'Enter you surname',
    'last_name': 'Surname',
    'law_economics_consulting': 'Legal, Financial & Consulting Services',
    'livelihood': 'Maintenance support',
    'loading': 'Loading',
    'logging_in': 'Logging in',
    'login_declined': 'Login denied',
    'long_stay': 'I have frequently, or for an extended period, stayed in the USA (>31 days over the past year or >183 days in total over the past 3 years)',
    'lottery_gaming': 'Lottery/Gambling',
    'manufacturing_industry': 'Manufacturing & Industry',
    'master': 'Two-year Master’s degree',
    'masters_degree': 'One-year Master’s (‘magister’) degree',
    'matching_alternativs': 'Which of the alternatives below regarding connection to the USA apply to you?',
    'me': 'Me',
    'media': 'Media',
    'money_origin_other': 'Other',
    'money_transferred_from_zone': 'Select country',
    'money_transferred_from': 'Where will the money be transferred from?',
    'motor_vehicle_trade': 'Motor Vehicle Trade',
    'my_details_company': 'Information for companies and investment representatives',
    'my_details': 'My details',
    'my_pages': 'My Pages',
    'no_beneficiary': 'No beneficiary: The money is paid according to the order of succession to the policy holder’s heirs.',
    'no': 'No',
    'none': 'None of the alternatives below apply to me',
    'nordics': 'Nordic region',
    'ongoing_investments': 'Ongoing investments',
    'other_bank': 'Other bank',
    'other_code': 'What country will your money be transferred from?',
    'other_consumer_services': 'Other Consumer Services',
    'other_eu_country': 'Other EU country',
    'other_income_source': 'Other source of income',
    'other_none_eu_country': 'Country outside the EU',
    'other': 'Other',
    'own_money': 'Do you own the money yourself?',
    'payments': 'I maintain a standing payment order for transfers or regular payments to a US account',
    'pensioner': 'Pensioner',
    'per_or_co_adress_in_usa': 'I have a ‘per’/ ‘c/o’ address in the United States',
    'phd': 'PhD',
    'phone_error': 'Enter your valid telephone number',
    'phone': 'Telephone number',
    'please_fill_in_tax_countries_co': 'Please enter all countries that the company is liable to pay tax in',
    'please_fill_in_tax_countries': 'Please enter all countries that you are liable to pay tax in',
    'please_observe_no_autogiro': 'Please note that this account will also be used for any direct debit payments',
    'political_risk_position': 'What position does the person in question hold?',
    'political_risk_settings': 'Politically exposed position',
    'political_risk_who_settings': 'Politically exposed person',
    'political_risk_why_settings': 'Political position',
    'political_risk': 'Are you or do you have a close relative or known employee who is in a politically exposed position, or has been in such a position in the past 18 months?',
    'politicians': 'Member of the executive body of a political party',
    'prepare_for_autogiro': 'Our recommended payment option when you want to invest is direct debit. Do you wish to use direct debit for payments?',
    'preparing_accounts_sections_texts': ["To ensure you have the best possible user experience, we will set up your account so that you can open a holding account. This is, of course, free of charge and is activated when you deposit money. "],
    'preparing_accounts_sections': 'Preparation for direct debit and holding',
    'preselected_bankaccount': 'Registered bank account',
    'previous_investments': 'Previous investments',
    'primary_degree': 'School leaving certificate',
    'primary_occupation_settings': 'main field of work',
    'primary_occupation': 'What is your main field of work?',
    'profits_daily_operations': 'Profit from daily operations',
    'property_sale': 'Sale of property',
    'proxy_test': 'Power of attorney',
    'proxy': 'I have a valid power of attorney or authority to sign on behalf of a person or a company with an address in the United States',
    'public_administration_society': 'Public Administration & Society',
    'real_estate': 'Property Activities',
    'rental_leasing': 'Renting & Leasing',
    'repair_installation': 'Repairs & Installation',
    'retail': 'Retail Sales',
    'salary_pension': 'Salary/Pension',
    'science': 'Nature',
    'self-employed': 'Self-employed',
    'share_dividend_from_own_company': 'Share dividend from own company',
    'sign_bankid': 'Sign using BankID',
    'signatory': 'Authorised signatory',
    'specify_advisor_error': 'Enter the name of your advisor',
    'specify_advisor': 'Please specify who your current advisor is',
    'specify_who_political_risk': 'Specify who is politically exposed',
    'ssn_error': 'Enter a valid personal identity number in the format yyyymmddxxxx',
    'ssn_placeholder': 'yyyymmddxxxx',
    'ssn': 'Personal identity number',
    'start_page': 'Home page',
    'start': 'Start',
    'state_owned_enterprise_executive': 'A person who is a member of state-owned, administrative, management or supervisory bodies',
    'student': 'Student',
    'domestic_bank': 'Swedish bank',
    'domestic_insurance_co': 'Swedish insurance company',
    'advisor_error': 'Please select Yes or No',
    'tax_residence_code_co': 'TIN/Company registration number',
    'tax_residence_code': 'TIN/Personal identity number',
    'tax_residence_domestic_only': 'Are you only tax-resident in Sweden?',
    'tax_resident_1': 'Tax domicile',
    'technical_consultancy': 'Technical Consulting Firm',
    'terms_and_conditions_pdf_error': 'Please read the Terms and Conditions in the PDF file above. Approval is required to continue.',
    'terms_and_conditions_pdf': 'Terms and Conditions',
    'terms_autogiro_regitration': 'Terms and conditions to register for direct debit',
    'the_company': 'The Company',
    'the_policyholder': 'The Policy Holder',
    'transfer_from': 'Transfer from',
    'transport_warehousing': 'Transport & Storage',
    'travel_agency_tourism': 'Travel Agency & Tourism',
    'unemployed': 'Unemployed',
    'us_citizenship_or_green_card': 'I have US citizenship or a Green Card',
    'us_phone': 'I have a US telephone number (landline or mobile phone)',
    'waiting_signing': 'Awaiting signing',
    'wastewater_waste_electricity_water': 'Wastewater, Waste, Electricity & Water',
    'what_is_your_employment': 'What is your employment?',
    'wholesale': 'Wholesale trade',
    'yes': 'Yes',
    'zip_error': 'Enter your valid postcode',
    'zip': 'Postcode',

    // Start signup
    'corporate_clients': 'Corporate client',
    'private_customer': 'Private client',
    'signup_description': 'Becoming a client with us gives you access to our platform and you can make investments. You will also receive a holding account, at no extra cost of course.',
    'signup_title': 'Becoming a client takes just a few minutes',

    // Signup completed
    'you_are_registered': <h5>You are registered!</h5>, //ändring start
    'hubins_registration_completed': 'You have now completed the company registration on Hubins.',
    'see_capital_insurance_application_below': 'Nedan har du Quantum Lebens ansökan för att öppna upp bolagets kapitalförsäkring. Vänligen fyll i och skicka in den till Kundservice@hubins.com ',

    'thanks': 'Thank you.',
    'signatory_thanks_message': 'We have registered your information. When all parties have completed the registration, your company will be able to make investments with us.',

    // Signatory view
    'your_company_account_has_been_registered_p1': <>{v.name} gave your name as the beneficial owner of {v.companyName} and you also therefore need to enter some information and sign a power of attorney entitling {v.name} to make investments on behalf of the company.</>,
    'your_company_account_has_been_registered_principal_p1': <>{v.name} gave your name as the beneficial owner of {v.companyName} and you also therefore need to enter some information.</>,
    'your_company_account_has_been_registered_p2': 'If you have any questions about why you need to do this, please contact us.',



    //countrys - remove
    'sweden': 'Sweden',
    'norway': 'Norway',

    'ownership_in_percentage': 'Ownership, as a %',
    no_signatories: 'No signatories',
    no_beneficials: 'No beneficial owners',
    'sign_with_other_signatory': 'Are you authorised to sign for the company together with other company signatories?',
    'type_of_acting_principal': 'Type of beneficial owner',
    'valid_org_number': 'Enter a valid company registration number using the format xxxxxx-xxxx',
    'more_benefitial_owners_then_above': 'Utöver de personer som visas ovan, finns det ytterligare bolagspersoner?', // Translate
    'when_to_add_bo_info': '',
    'add_bo_help_text': 'Please add more beneficials owners below',
    'add_bo_bankid_note': '',

    // Company registration - Signup 2
    'company_information': 'Company details',
    'senior_executive_position': 'Do you hold a senior management position in the company?',
    'specify_determing_influence_company': 'Please specify how you have a controlling influence over the company',
    'lei_title': 'LEI number',
    'lei_label': 'LEI number',
    'please_observe_company_payment': 'All payments will go to the account you specify.',
    'life_asured': 'Life Assured',

    'company_is_from_usa_or_under_laws_or_has_adress': 'Was the company founded or is the company domiciled in the United States, is the company subject to United States federal laws or any of the United States’ state laws, or does the company have an address in the United States?',

    'principal': 'Beneficial owner',

    // Signatorys fullmakt
    'principal_1': 'First beneficial owner',
    'principal_2': 'Second beneficial owner',
    'principal_3': 'Third beneficial owner',
    'principal_4': 'Fourth beneficial owner',
    'principal_5': 'Fifth beneficial owner',
    'principal_6': 'Sixth beneficial owner',
    'principal_7': 'Seventh beneficial owner',

    'signatory_1': 'First company signatory',
    'signatory_2': 'Second company signatory',
    'signatory_3': 'Third company signatory',
    'signatory_4': 'Fourth company signatory',
    'signatory_5': 'Fifth company signatory',
    'signatory_6': 'Sixth company signatory',
    'signatory_7': 'Seventh company signatory',

    //Banker - no translation needed
    'swedsess': 'Swedbank',
    'essesess': 'SEB',
    'handsess': 'Handelsbanken',
    'ndeasess': 'Nordea',

    ql_org_nr_label: 'Corporate identity number:',
    ql_org_nr: 'FL-0002 124 995-9',
    hubins_org_nr: '559184-0003',
    hubins_org_nr_label: 'Corporate identity number:',
    insurance_distributor: 'Insurance distributor',
    insurance_currency: 'Insurance currency',
    issuing_date: 'Issuing date',
    "warning": "Warning",
    "wish_to_change_form": "If you wish to go back and change previous answers, your current changes in this form will not be saved.",
    policyholder: 'Policyholder',
    a_policy_verdict: 'Due Diligence Checks',
    p_kt: 'Knowledge test',
    p_da: 'Krav- och Behovsprövning',
    p_terms: 'Villkor',
    c_kt: 'Kunskapstest',
    c_da: 'Krav- och Behovsprövning',
    c_terms: 'Villkor',
    a_policy: 'Due Diligence Checks',
    p_kt_done: 'Kunskapstest genomfört',
    approved: 'Godkänt',
    p_ins: 'Försäkringsansökan och Kundkännedom',
    c_ins: 'Försäkringsansökan och Kundkännedom',
    c_bi: 'Bolagsinformation',
    c_hm: 'Kundkännedom Bolagsrepresentant',
    p_roaring: 'Personal information',
    company_personnel: 'Signatories & Company Representatives',
    choose_person: 'Choose person',
  }
});

export default keys;
