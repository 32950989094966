import React from 'react';

import './index.scss';


const DualContent = ({ sections, sectionColor = '' }) => {

  return (
    <div
      className="DualContent"
    >
      {sections.map((section, index) => (
        <div key={index} className={`DualContent__section ${sectionColor
          ? sectionColor
          : index % 2 === 0
            ? 'secondary'
            : 'neutral-1'}`}>{section}</div>
      ))}
    </div>
  );
};

export default DualContent;
