import React from 'react';
import WithdrawalAndPremiumCard from '../WithdrawalAndPremiumCard/WithdrawalAndPremiumCard';
import { Columns } from 'components/Columns';
import { __route } from 'localisation';

const TransactionCards = ({transactions}) => {
  return (
    <Columns>
      {transactions.map((t, index) => (
        <WithdrawalAndPremiumCard
          key={index}
          href={`${__route("deposit")}/${t.portfolio.uuid}#sow`}
          {...t}
        />
      ))}
    </Columns>
  )
};

export default TransactionCards;