import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { __ } from "localisation";
import Heading from 'components/Heading';

import './index.scss';

class Countdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // time: new Date(props.givenDate.replace(/\s/, 'T')) / 1000,
      last: 0,
      // date: new Date(props.givenDate.replace(/\s/, 'T')),
      timeLeft: this.props.msDiff || false,
      open: false,
      full: props.full,
      notice: false
    };

    this.update = this.update.bind(this);

    this.lastTime = false;

    this.handleVisibilityChange = this.handleVisibilityChange.bind(this);
    this.initVisibilityChange = this.initVisibilityChange.bind(this);
  }

  update(now) {
    let timeLeft = this.state.timeLeft;
    if (!this.lastTime || now >= this.lastTime) {
      this.lastTime = now + 1000;
      timeLeft = timeLeft - 1000;
      let open = timeLeft <= 0;
      let notice = timeLeft <= 900000; //send notice when less then 15 min left
      if (this.unmounted) return false;

      if (!this.state.notice && notice) { //Only send the notice once
        this.setState({ notice });
        this.sendNotice();
      }

      this.setState({
        timeLeft,
        open,
      });



      if (open) {
        this.setOpen();
        cancelAnimationFrame(this.raf);
        return false;
      }
    }
    this.raf = requestAnimationFrame(this.update);
  }

  setOpen() {
    if (this.props.callback) this.props.callback(true);
  }

  sendNotice() {
    //Just added a second param to the callback to prevent breaking countdowns where this feature isnt needed.
    if (this.props.callback) this.props.callback(false, true);
  }

  componentDidMount() {
    if (!this.props.static) {

      // MsDiff in usually undefined in the mount
      this.props.msDiff && this.update();

      // Visability API browser tweaks
      this.hidden = undefined;
      this.visibilityChange = undefined;

      if (typeof document.hidden !== "undefined") { // Opera 12.10 and Firefox 18 and later support
        this.hidden = "hidden";
        this.visibilityChange = "visibilitychange";
      } else if (typeof document.msHidden !== "undefined") {
        this.hidden = "msHidden";
        this.visibilityChange = "msvisibilitychange";
      } else if (typeof document.webkitHidden !== "undefined") {
        this.hidden = "webkitHidden";
        this.visibilityChange = "webkitvisibilitychange";
      }

      this.initVisibilityChange();
    }
  }

  componentWillUnmount() {
    cancelAnimationFrame(this.raf);
    document.removeEventListener(this.visibilityChange, this.handleVisibilityChange, false);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.msDiff !== this.props.msDiff) {
      this.props.msDiff && this.update();

      this.setState({
        timeLeft: this.props.msDiff
      });
    }
  }

  // If the page is hidden, pause the video;
  // if the page is shown, play the video
  handleVisibilityChange() {
    if (document[this.hidden]) {
      this.lastActiveTime = Date.now();
      cancelAnimationFrame(this.raf);
    } else {
      const diff = Date.now() - this.lastActiveTime;

      this.setState({
        timeLeft: this.state.timeLeft - diff
      }), this.update();
    }
  }

  initVisibilityChange() {
    // Warn if the browser doesn't support addEventListener or the Page Visibility API
    if (typeof document.addEventListener === "undefined" || this.hidden === undefined) {
      console.error("This demo requires a browser, such as Google Chrome or Firefox, that supports the Page Visibility API.");
    } else {
      // Handle page visibility change
      document.addEventListener(this.visibilityChange, this.handleVisibilityChange, false);
    }
  }

  render() {
    let classModifier = this.props.className;
    this.props.colorTheme === 'dark' ? classModifier += ' countdown--dark' : '';
    this.props.static === true ? classModifier += ' countdown--static' : '';
    if (this.state.open) {
      return (
        <div className={`countdown ${classModifier}`}>
          <Heading element="h3" size="5" className="countdown__value countdown__value--open">
            {this.props.full ? __('sold_out') : __('Live')}
          </Heading>
        </div>
      );
    }

    let msDiff = this.state.timeLeft;

    let days = parseInt(msDiff / (24 * 3600 * 1000)).toString();
    let hours = parseInt(msDiff / (3600 * 1000) - (days * 24)).toString();
    let mins = parseInt(msDiff / (60 * 1000) - (days * 24 * 60) - (hours * 60)).toString();
    let secs = parseInt(msDiff / (1000) - (mins * 60) - (days * 24 * 60 * 60) - (hours * 60 * 60)).toString();

    const { small } = this.props;

    return (
      <div className={`countdown ${classModifier}`}>
        {!small && (
          <span className="countdown__heading">{__('time_left_to_open')}</span>
        )}
        <div className="countdown__content">
          <span className="countdown__part">
            <span className="countdown__value">
              {days.length < 2 ? "0" + days : days}
            </span>
            {!small && (
              <span className="countdown__label">{__('days')}</span>
            )}
          </span>

          <span className="countdown__seperator">:</span>

          <span className="countdown__part">
            <span className="countdown__value">
              {hours.length < 2 ? "0" + hours : hours}
            </span>
            {!small && (
              <span className="countdown__label">{__('hours')}</span>
            )}
          </span>

          <span className="countdown__seperator">:</span>

          <span className="countdown__part">
            <span className="countdown__value">
              {mins.length < 2 ? "0" + mins : mins}
            </span>
            {!small && (
              <span className="countdown__label">{__('minutes')}</span>
            )}
          </span>

          <span className="countdown__seperator">:</span>

          <span className="countdown__part">
            <span className="countdown__value">
              {secs.length < 2 ? "0" + secs : secs}
            </span>
            {!small && (
              <span className="countdown__label">{__('seconds')}</span>
            )}
          </span>
        </div>
      </div>
    );
  }
}

// Countdown.propTypes = {
//   timeLeft: PropTypes.number.isRequired, // October 13, 2019 00:00:00 GMT+0100
// };

Countdown.defaultProps = {
  msDiff: 3600000, // 1 day
  className: '',
  small: false,
};

Countdown.propTypes = {
  msDiff: PropTypes.number.isRequired, // time left in ms, gets from middleman api
  callback: PropTypes.func, // to update the parent component, always returns true (open)
  static: PropTypes.bool, // maybe not used?
  // open: PropTypes.bool, // used as an initial state
  full: PropTypes.bool, // If the security is "fulltecknad"
  small: PropTypes.bool, // Used for example placing the countdown in a PageFooter component
};

export default Countdown;
