import React, { useState } from 'react';
import './index.scss';
import { connect } from 'react-redux';

import __ from "localisation";
import { alertsOperations } from '../../state/ducks/alerts';
import ProgressTabs from "components/ProgressTabs";
import AddInvestments from "./AddInvestments";
import TofRegistrationIntro from './TofRegistrationIntro';
import SecurityInformation from './SecurityInformation';

const TransferOfFunds = ({ active_profile, skipRegistration, updateAlerts }) => {

  const contact_juridical = active_profile
    ? (active_profile.fa_connection
      ? active_profile.fa_connection.contact_juridical
      : false)
    : false;

  const [juridical, setJuridical] = useState(contact_juridical ? (contact_juridical === 'CO' ? 'c' : 'p') : null);
  const [selectedTab, setSelectedTab] = useState(0);
  const [investments, setInvestments] = useState([]);
  const [selectedInvestments, setSelectedInvestments] = useState([]); // array of objects: title, status, form input data?

  const [organisationNumber, setOrganisationNumber] = useState(""); // save data received from roaring

  return (
    <>
      <ProgressTabs
        selected={selectedTab}
        noButton
        noSpacing
        setSelected={setSelectedTab}
        preventGoingBack
        tabs={[
          {
            component: AddInvestments,
            forceRender: false,
            componentProps: {
              selectedTab,
              setSelectedTab,
              investments,
              setInvestments,
              selectedInvestments,
              setSelectedInvestments,
              juridical,
              setJuridical
            },
            group: `1. ${__("tof_progress_bar_investments")}`,
            time: 10,
            title: __("tof_progress_bar_investments"),
          },
          {
            component: SecurityInformation,
            forceRender: false,
            componentProps: {
              selectedTab,
              setSelectedTab,
              selectedInvestments,
              setSelectedInvestments,
              juridical
            },
            group: `4. ${__("tof_progress_bar_investments")}`,
            time: 10,
            title: "Investeringsinformation",
          },
          {
            component: (props) => (<TofRegistrationIntro updateAlerts={props.updateAlerts} />),
            forceRender: false,
            componentProps: {
              skipRegistration,
              selectedTab,
              setSelectedTab,
              organisationNumber,
              setOrganisationNumber,
              selectedInvestments,
              juridical
            },
            group: `2. ${__("tof_progress_bar_terms")}`,
            time: 10,
            title: __("tof_progress_bar_terms"),
          }
        ]}
      />
    </>
  );
};


function mapStateToProps(state) {
  return {
    updateAlerts: alertsOperations.updateAlerts,
    active_profile: state.auth.User.active_profile
  };
}

export default connect(mapStateToProps)(TransferOfFunds);
