import React from 'react';
import ReactDOMServer from 'react-dom/server';
import __ from 'localisation';
import parse from 'html-react-parser';
import { appendAllFieldValues } from 'helpers/formscms';
import { formDataToCollection } from 'helpers/filters';
import { mergeAllFiles } from 'helpers/createForm';
import middleman from 'helpers/middleman';


export const getLanguage = () => {
  const storedLanguage = window.localStorage.getItem('language');

  switch (storedLanguage){
    case 'FI':
      return 'fi';
    case 'NO':
      return 'no';
    case 'EN':
      return 'en';
    case 'SE':
    default:
      return 'sv';
  }
};

export const hasSelected = (answer, needle) => {
  if (Array.isArray(answer)) {
    return answer.includes(needle);
  }
  return false;
};

export const normaliseSliderForms = (content) => {
  if (Array.isArray(content[0])) {
    return content.reduce((normalisedForm, form) => {
      normalisedForm = [...normalisedForm, ...form];
      return normalisedForm;
    }, []);
  }
  return content;
};

const sanitizeObjFromJsx = (obj) => {
  for (let i in obj) {
    if (React.isValidElement(obj[i])) {
      obj[i] = ReactDOMServer.renderToStaticMarkup(obj[i]);
    }
  }
  return obj;
};

const formContentHandler = (obj) => {
  const contentKeys = ['title', 'label', 'summaryTitle', 'text', 'texts', 'heading', 'tooltip', 'description'];
  for (let i in obj) {
    if (contentKeys.includes(i) && typeof obj[i] === 'string' && obj[i]) {
      obj[i] = parse(obj[i]);
    }
  }
  return obj;
};

const loopForm = (form, callback) => {
  return form.map((section) => {
    section = callback(section);
    if (section.fields && Array.isArray(section.fields)) {
      section.fields = section.fields.map(field => {
        field = callback(field);
        if (field.options && Array.isArray(field.options)) {
          field.options = field.options.map((option) => {
            option = callback(option);
            return option;
          });
        }
        return field;
      });
    }
    return section;
  });
};

const loopSlides = (form, callback) => {
  return form.map((slide) => (
    loopForm(slide, callback)
  ));
};

export const formatContent = (form) => {
  const content = form.content;
  if (Array.isArray(content[0])) {
    return loopSlides(content, formContentHandler);
  }
  return loopForm(content, formContentHandler);
};

// Helper for converting form containing jsx to strings
export const sanitizeJsx = (form) => {
  if (Array.isArray(form[0])) {
    return loopSlides(form, sanitizeObjFromJsx);
  }
  return loopForm(form, sanitizeObjFromJsx);
};

// Helper for printing out forms to add to mongodb
export const printForm = (form) => {
  console.log(JSON.stringify(sanitizeJsx(form)));
};

export const getForm = async (key, type, profileUuid, portfolioUuid, params = false) => {
  const language = getLanguage();
  const form = await middleman.promisePost('/forms/form', { key, type, profileUuid, portfolioUuid, params, language });
  const formData = form.data;
  formData.content = formatContent(formData);
  if (formData.response) {
    formData.content = appendAllFieldValues(formData.content, formData.response);
  }
  return formData;
};

export const loadForm = async (key, type, profileUuid, portfolioUuid, callback, params = false) => {
  const form = await getForm(key, type, profileUuid, portfolioUuid, params);
  callback(form);
};


export const getSummary = async (key, profileUuid, portfolioUuid, setAnswer, userId, params = {}) => {
  const language = getLanguage();
  const response = await middleman.promisePost('/forms/summary', { key, profileUuid, portfolioUuid, userId, language, ...params })
    .then(res => {
      const formData = res.data;
      formData.content = formatContent(formData);
      return formData;
    })
    .catch(() => ({
      response: []
    }));

  setAnswer(response);
};

export const createAnswerPromise = (form) => {
  form['data'] = formDataToCollection(form['data']);
  return middleman.promisePost('/forms/create', form);
};

export const createAnswer = async (form, setError = false) => {

  try {
    const res = await createAnswerPromise(form);
    return res;
  } catch (e) {
    if (setError) {
      const standardErrorMessage = __('roaring_error_message');
      setError((e.response.data && e.response.data.message)
        ? e.response.data.message
        : standardErrorMessage
      );
    }
    throw new Error(e.message);
  }
};



export const generateFilesFormData = (form) => {
  const formData = new FormData();
  const files = mergeAllFiles(form);

  if (files.length > 0) {
    files.forEach((file) => {
      if (file.size) {
        formData.append('files[]', file, file.name);
      }
    });
  }
  return formData;
};

export const getHistory = async (key, profileUuid, portfolioUuid, setHistory, userId = undefined, type = undefined, portfolioOrProfile = undefined) => {
  const language = getLanguage();
  const response = await middleman.promisePost(`/ql/crm/forms/history`, { key, profileUuid, portfolioUuid, userId, language, type, portfolioOrProfile })
    .then(res => (res.data))
    .catch(() => ({
      form: [],
      history: [],
    }));
  setHistory(response);
};

export const crmGetApprovalHistory = async (key, caseId, setHistory) => {
  const response = await middleman.promisePost('/ql/crm/forms/approval/history', { key, caseId })
    .then(res => (res.data))
    .catch(() => ({
      form: [],
      history: [],
    }));
  setHistory(response);
};

export const getApprovalForm = async (key, setForm) => {
  const response = await getForm(key, 'approval');
  setForm(response);
};

export const getApprovalAnswer = async (key, caseId, setApproval) => {
  const response = await middleman.promisePost('/ql/crm/approvals/form', { key, caseId })
    .then(res => (res.data))
    .catch(() => {
      return false;
    });

  setApproval(response);
};

export const submitApproval = (caseId, status, formResponse = false, verdict = false) => {

  let body = {
    caseId,
    status,
    verdict
  };

  if (formResponse) {
    formResponse['data'] = formDataToCollection(formResponse['data']);
    if (typeof formResponse['data']['verdict'] !== 'undefined') {
      verdict = formResponse['data']['verdict'];
      delete formResponse['data']['verdict'];
    }

    body.verdict = verdict;
    body.formResponse = formResponse;
  }

  return middleman.promisePost('/ql/crm/approval/update', body);
};

export const createAnswerCatch = (error, setErrorMessage) => {
  if (error.response) {
    if (error.response.status === 422) {
      const responseData = error.response.data;
      if (responseData.message) {
        setErrorMessage(responseData.message);
        return;
      }
    }
  }
  setErrorMessage(__('common_error_validation'));
  return;
};

export const NEW_getAnswerPromise = async (key, type, profileUuid, portfolioUuid, options = {}) => {
  return await middleman.promisePost(`/forms/show`, { key, type, profileUuid, portfolioUuid, ...options });
};

export const NEW_getAnswer = async (key, type, profileUuid, portfolioUuid, setAnswer, options = {}) => {
  const response = await NEW_getAnswerPromise(key, type, profileUuid, portfolioUuid, options);
  setAnswer(response.data);
};

export const loadFormWithAnswer = async (key, type, profileUuid, portfolioUuid, setForm, params = false, options = {}) => {
  const formPromise = getForm(key, type, profileUuid, portfolioUuid, params);
  const answerPromise = NEW_getAnswerPromise(key, type, profileUuid, portfolioUuid, options).catch(() => false);
  const [form, answer] = await Promise.all([formPromise, answerPromise]);

  if (answer) {
    form.content = appendAllFieldValues(form.content, answer.data.response);
    form.submitted_at = answer.data.created_at;
    form.response = answer.data.response;
    if (answer?.data?.approval_section_uuid) {
      form.approval_section_uuid = answer.data.approval_section_uuid;
    }

  }

  setForm(form);
};

export const getFullDaResponse = async (key, profileUuid, portfolioUuid, options = {}) => {

  const promises = await Promise.all([
    NEW_getAnswerPromise(`${key}_purpose`, 'register', profileUuid, portfolioUuid, options),
    NEW_getAnswerPromise(`${key}_transactions`, 'register', profileUuid, portfolioUuid, options),
    NEW_getAnswerPromise(`${key}_products`, 'register', profileUuid, portfolioUuid, options),
    NEW_getAnswerPromise(`${key}_target_return`, 'register', profileUuid, portfolioUuid, options),
    NEW_getAnswerPromise(`${key}_tax`, 'register', profileUuid, portfolioUuid, options),
    NEW_getAnswerPromise(`${key}_beneficiaries`, 'register', profileUuid, portfolioUuid, options),
  ]);

  const mergedAnswers = promises.reduce((merged, item) => {
    return {
      ...merged,
      ...item?.data?.response || {},
    };
  }, {});

  return mergedAnswers;
};
