import React from 'react';
import { Link } from 'react-router-dom';
import { Logo } from 'settings';
import './Menu.scss';
import './MenuLogo.scss';

const Menu = ({ className = '', rootUrl = '/', logo: TheLogo = Logo, children }) => {
  return (
    // <nav className={`menu ${props.className} ${props.inverted ? 'menu--inverted' : ''}  ${props.split ? 'menu--split' : ''}`}>
    <nav className={`menu ${className}`}>
      <Link to={rootUrl} className="menu__logo">
        <TheLogo />
      </Link>
      {children}
    </nav>
  );
};

export default Menu;
