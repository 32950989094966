export const userStatuses = {
  init: 'UserStatus/INIT',
  loading: 'UserStatus/LOADING',
  success: 'UserStatus/SUCCESS',
  error: 'UserStatus/ERROR',
  polling: 'UserStatus/POLLING',
  logout: 'UserStatus/LOGOUT',
  login_error: 'UserStatus/LOGIN_ERROR',
};

export const formStatuses = {
  init: 'FormStatus/INIT',
  loading: 'FormStatus/LOADING',
  success: 'FormStatus/SUCCESS',
  error: 'FormStatus/ERROR',
};
