import React, { useEffect } from "react";

import Main from "components/Main";
import Modal from "components/Modal";
import HubinsLoadingAnimation from "components/Icon/HubinsLoadingAnimation";
import { LoadingDots } from 'components/LoadingIndicators';

import { useHistory } from "react-router-dom";
import { __route } from 'localisation';
import middleman from '../../../server'
const Assemble = ({ selectedInvestments }) => {


  let history = useHistory();

  /* redirect the user to the plattform with the path of "/transfer"
  */
  const redirect = () => {
    history.push({
      pathname: __route("transfer")
    });
  };

  /* Post API call to middleman where it does a batch creation of holdings,
  * based on portfolio and selectedInvestments. If it's successful it calls the
  * function redirect() otherwise it tries to createHolding again.
  */

  const createHoldings = async () => {
    try {
      const response = await middleman.post(`/holdings/batch`, selectedInvestments);
      if (response.status === 200) {
        redirect();
      }
    } catch (err) {
      setInterval( () => createHoldings, 1500);
    }
  };

  /* Post API call to middleman where it does a batch creation of new investments,
  * based on selectedInvestments with the status of "new". If it's successful it calls the
  * function createHoldings() otherwise it tries to storeNewInvestments again.
  */

  const storeNewInvestments = async () => {
    const securities = selectedInvestments.filter(investment => investment.status === 'new');
    try {
      const response = await middleman.post(`/securities/batch`, securities);
      if (response.status === 200) {
        createHoldings();
      }
    } catch (err) {
      setInterval( () => storeNewInvestments, 1500);
    }
  };

  /* Post API call to middleman where it creates portfolios. If it's successful it calls the
  * function storeNewInvestments() otherwise it tries to createPortfolio again.
  */

  const createPortfolio = async () => {
    try {
      const response = await middleman.post(`/v1/fa/auth/portfolio/create`, {});
      if (response.status === 200) {
        storeNewInvestments();
      }
    } catch (err) {
      setInterval( () => createPortfolio, 1500);
    }
  };

  useEffect(() => {
    createPortfolio();
  }, []);

  return (
    <Main
      className="neutral-2"
      center={true}>
      <Modal
        className="flex-center"
        closeButton={false}
        contentClass=""
        noWrapper={false}
        open={true}
      >
        <div className="story-box-grid__item">
          <HubinsLoadingAnimation active />
          <LoadingDots text="Vi bearbetar investeringarna" />
        </div>
      </Modal>
    </Main>
  );
};

export default Assemble;
