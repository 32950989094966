import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import useInterval from 'hooks/useInterval';
import { removeItem } from 'helpers';
import { REFRESH_BUFFER } from 'helpers/idleTimer';

const CHECK_INTERVAL = 4000; // in ms
const WARNING_TIME = 30000; // in ms (0.5min before logging out) a
const INACTIVE_BUFFER = 5000;
const STORE_KEY = 'lastAction';

const interactions = ['click', 'touchstart', 'touchend', 'mousemove', 'keydown', 'keyup', 'keypress'];

export const IdleTimer = (props) => {
  const getNow = () => { return Date.now(); };
  const tokenStates = { OK: 'ok', EXPIRED: 'expired', SOON: 'soon' };
  const [tokenState, setTokenState] = useState(tokenStates.OK);
  const [lastActionTime, setLastActionTime] = useState(getNow());

  const timeSinceLastAction = () => {
    return getNow() - lastActionTime;
  };

  const timeLeftOnToken = () => {
    return props.tokenExpires - getNow();
  };

  const checkIsIrrelevant = () => {
    if (!props.authToken) { return true; }
    if (tokenState.EXPIRED) { return true; }
    return false;
  };

  useEffect(() => {
    // Hängslen och livrem
    if (tokenState === tokenStates.OK) return;
    if (tokenState === tokenStates.SOON) return;
    if (timeLeftOnToken() > 0) return;
    // Else tokenstate is expired

    props.logoutUser();
  }, [tokenState]);

  // Run on mount
  const checkLogout = () => {
    if (checkIsIrrelevant()) return;
    if (timeLeftOnToken() < 0) {
      setTokenState(tokenStates.EXPIRED);
      return true;
    }
    return false;
  };

  const inactiveUser = () => {
    // If last action was within buffer, user is active
    if (timeSinceLastAction() < INACTIVE_BUFFER) { return false; }
    return true;
  };

  const activeUser = () => { return !inactiveUser(); };

  const check = () => {

    if (checkIsIrrelevant()) { return; }

    const _timeLeftOnToken = timeLeftOnToken();

    if (_timeLeftOnToken < 0) {
      setTokenState(tokenStates.EXPIRED);
      return;
    }

    if (_timeLeftOnToken < REFRESH_BUFFER && activeUser()) {
      props.refreshToken();
      return;
    }

    if (_timeLeftOnToken < WARNING_TIME && inactiveUser()) {
      setTokenState(tokenStates.SOON);
      return;
    }
  };

  const reset = () => {
    setLastActionTime(getNow());
    if (tokenState === tokenStates.SOON) {
      setTokenState(tokenStates.OK);
    }
  };

  const initListeners = () => {
    interactions.forEach(interaction => {
      window.addEventListener(interaction, reset);
    });
  };

  const removeListeners = () => {
    interactions.forEach(interaction => {
      window.removeEventListener(interaction, reset, false);
    });
  };


  useInterval(() => {
    check();
  }, CHECK_INTERVAL);

  useEffect(() => {
    initListeners();
    checkLogout();
    return () => {
      removeListeners();
      removeItem(STORE_KEY);
    };
  }, [props.tokenExpires]);


  return useMemo(() => {
    return (<></>);
  }, [tokenState]);
};

IdleTimer.propTypes = {
  refreshToken: PropTypes.func,
  logoutUser: PropTypes.func,
};


export default IdleTimer;
