import React, { useState, useEffect } from 'react';
import __, { __route } from 'localisation/hubins';
import Wrapper from '@hubins/components/Wrapper';
import { Columns, Column } from 'components/Columns';
import { loadForm } from 'helpers/form';
import { LoadingBox } from 'components/LoadingIndicators';
import CreateForm from "modules/CreateForm";
import { connect } from "react-redux";
import { authOperations } from "../../state/ducks/auth";
import middleman from 'helpers/middleman';
import { alertsOperations } from '../../state/ducks/alerts';
import { createAnswer } from 'helpers/form';
import { Redirect } from "react-router-dom";

const Presale = ({ match, updateAlerts, removeAlert, updateUser }) => {
  const { profileUuid, portfolioUuid } = match.params;
  const [form, setForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    loadForm(
      'p_presale', 'register', profileUuid, null, setForm
    );
  }, []);

  useEffect(() => {
    if (error) {
      setLoading(false);
      updateAlerts({
        id: 'signupFormsSummary',
        type: 'danger',
        closeButton: true,
        content: <>{__("standard_error_message", { email: __('platform_email'), tel: __('platform_tel') })}: {error}</>,
      });
    } else {
      removeAlert('signupFormsSummary');
    }
  }, [error]);

  const submit = async (values) => {
    try {

      let portfolioToSubmit = portfolioUuid;

      setLoading(true);

      if (!portfolioUuid) {
        const res = await middleman.promisePost(`/v1/fa/auth/portfolio/create`, { profileUuid });
        const createdPortfolio = res.data;
        if (!createdPortfolio || !createdPortfolio.uuid) {
          throw new Error('Something went wrong');
        }
        portfolioToSubmit = createdPortfolio.uuid;
      }

      const body = {
        ...form,
        data: values,
        profileUuid,
        portfolioUuid: portfolioToSubmit
      };

      await createAnswer(body);

      if (!portfolioUuid) {
        const updatedUser = await middleman.get('/user');
        updateUser(updatedUser.data);
      }

      setRedirect(`${__route('register_summary')}/${profileUuid}/${portfolioToSubmit}`);
    } catch (e) {
      setError(e.message);
    }
  };

  if (redirect) return <Redirect to={redirect} />;

  return (
    <Wrapper>
      <Columns>
        <Column m="6">
          {form ? (
            <CreateForm
              formData={form.content}
              submitType={submit}
              submitText={__('accept_and_proceed')}
              buttonProps={{ icon: 'arrow right 1', iconColor: 'white', size: 'large' }}
              loading={loading}
              disabled={loading}
            />
          ) : (
            <LoadingBox />
          )}
        </Column>
        <Column m="6">

        </Column>
      </Columns>
    </Wrapper>
  );
};

export default connect(null, {
  updateUser: authOperations.updateUser,
  updateAlerts: alertsOperations.updateAlerts,
  removeAlert: alertsOperations.removeAlert,
})(Presale);
