import React from 'react';
import SimpleTable from 'modules/Tables/SimpleTable';
import { __ } from "localisation";
import { getMoney } from 'helpers';
import LoadingBar from 'components/LoadingIndicators';
import { mergeEndowments } from 'helpers/endowment';

const loadingObj = { year: <LoadingBar />, marketValueStart: <LoadingBar />, janToJun: <LoadingBar />, julToDec: <LoadingBar />, withdrawals: <LoadingBar />, yields: <LoadingBar />, marketValue: <LoadingBar /> };
const loadingState = [
  loadingObj,
  loadingObj,
  loadingObj,
];

const getTotalValueOfKey = (key, data, currency) => {
  return getMoney(
    Object.keys(data).reduce((value, year) => {
      const valueToAdd = data[year][key];
      if (typeof valueToAdd === 'undefined') {
        return value;
      }
      value += data[year][key];
      return value;
    }, 0), currency);
};

const EndowmentTable = ({ endowment, transactionsEndowment, isLoading, currency }) => {

  const data = isLoading ? [] : mergeEndowments(endowment, transactionsEndowment);

  let allYears = Object.keys(data);

  const getYields = (endowmentValues) => {
    const { marketValue: mv, marketValueStart: mvs, janToJun: jtj, julToDec: jtd, withdrawalAmount: w } = endowmentValues;
    const yearlyYield = mv - mvs - (jtj || 0) - (jtd || 0) + (w || 0);
    return yearlyYield;
  };
  const recalculatedData = {};
  allYears.forEach((year) => {
    recalculatedData[year] = {
      ...data[year],
      yields: getYields(data[year])
    };
  });
  const checkIfDataIsUpdated = () => {


    // 51709281,92-14930229,84-1501062,10-34108766,66+6283444,44

    const hasYears = allYears.length > 0;
    const firstYear = hasYears ? Math.min.apply(Math, allYears) : false;
    const lastYear = hasYears ? Math.max.apply(Math, allYears) : false;

    const tableData = allYears.map((year) => {
      return {
        year,
        marketValueStart: getMoney(recalculatedData[year].marketValueStart, currency),
        janToJun: getMoney(recalculatedData[year].janToJun, currency),
        julToDec: getMoney(recalculatedData[year].julToDec, currency),
        withdrawals: getMoney(recalculatedData[year].withdrawalAmount, currency),
        yields: getMoney(recalculatedData[year].yields, currency),
        marketValue: getMoney(recalculatedData[year].marketValue, currency),
      };
    });

    let headers = [{
      Header: (<span>År</span>),
      accessor: 'year',
      maxWidth: 70,
      Footer: (<span>{__('whole_period')}</span>),
    },
    {
      Header: (<span>{__('market_value_start')}</span>),
      accessor: 'marketValueStart',
      Footer: getMoney(firstYear ? recalculatedData[firstYear].marketValueStart : 0, currency),
    },
    {
      Header: (<span>{__('janoojun')}</span>),
      accessor: 'janToJun',
      Footer: getTotalValueOfKey('janToJun', recalculatedData, currency),
    },
    {
      Header: (<span>{__('jultodec')}</span>),
      accessor: 'julToDec',
      Footer: getTotalValueOfKey('julToDec', recalculatedData, currency),
    },
    {
      Header: (<span>{__('type_withdrawal')}</span>),
      accessor: 'withdrawals',
      Footer: getTotalValueOfKey('withdrawalAmount', recalculatedData, currency),
    },
    {
      Header: (<span>{__('dev_during_period')}</span>),
      accessor: 'yields',
      Footer: getTotalValueOfKey('yields', recalculatedData, currency),
    },
    {
      Header: (<span>{__('final_balance')}</span>),
      accessor: 'marketValue',
      Footer: getMoney(lastYear ? recalculatedData[lastYear].marketValue : 0, currency),
    }
    ];

    return { headers, tableData };
  };

  const { headers, tableData } = checkIfDataIsUpdated();



  return (
    <SimpleTable
      className="ReactTable--tight"
      headers={headers}
      data={isLoading ? loadingState : tableData}
      footer
    />
  );
};

export default EndowmentTable;
