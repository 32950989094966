import React, { Fragment } from 'react';
import Loadable from 'jaybe-react-loadable';

import { formatPercent, flattenValues, mergeByKey, objectToArray } from 'helpers';
import __ from 'localisation';

import { HubinsLoadingAnimation as LoadingAnimation } from "components/Icon/HubinsLoadingAnimation";
import Placeholder from 'components/Placeholder';

import 'components/Charts/index.scss'; // TODO: this SASS should import from main
import './index.scss';
import placeHolderImageUrl from 'assets/illustrations/accounts_empty.svg';
import { Colors } from 'settings';
export const CustomTooltip = (props) => {

  if (!props.label || !props.payload[0]) return null;
  return (
    <div className="chart-line__tooltip">
      <span className="chart-line__tooltiplabel">{props.label}</span>
      {props.payload.map((tooltip, index) => (
        <span key={index} className="chart-line__tooltipvalue">{formatPercent(tooltip.value - 100)}{tooltip.payload.xUnit}</span>
      ))}
    </div>
  );
};

export const gradientDefinitions = () => {
  const colors = { 'gradient_secondary': Colors.secondary, 'gradient1': Colors.chartline1, 'gradient2': Colors.chartline2, 'gradient3': Colors.chartline3, 'gradient4': Colors.chartline4, };
  return (
    <defs>
      {Object.keys(colors).map((key) => {
        const color = colors[key];
        return (
          <linearGradient key={key + color} id={key} x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor={color} stopOpacity={0.8} />
            <stop offset="95%" stopColor={color} stopOpacity={0} />
          </linearGradient>
        );
      })}
      <linearGradient id="hover-gradient" x1="0" y1="0" x2="0" y2="1">
        <stop offset="0%" stopColor={Colors.secondary} stopOpacity={0} />
        <stop offset="99.5%" stopColor={Colors.secondary} stopOpacity={0} />
        <stop offset="99.51%" stopColor={Colors.secondary} stopOpacity={1} />
        <stop offset="100%" stopColor={Colors.secondary} stopOpacity={1} />
      </linearGradient>
    </defs>
  );
};

export const AccountArea = (gradientId, color, account, Area) => {
  return (<Area
    activeDot={{ stroke: color, color, fill: Colors.primary, strokeWidth: 2, r: 6 }}
    type='monotone'
    label={<span>Label</span>}
    dataKey={`y${account.id}`}
    stroke={color}
    strokeWidth={2}
    fillOpacity={1}
    fill={gradientId}
  />);
};

export const formatData = (initData) => {
  const allValues = flattenValues(initData);
  const valuesByDate = mergeByKey('x', allValues);
  const data = objectToArray(valuesByDate);
  return data;
};

const ChartLineLoaded = ({ Area, AreaChart, CartesianGrid, ResponsiveContainer, ReferenceDot, Tooltip, XAxis, YAxis, ...props }) => {

  const content = () => {

    if (props.loading || !props.data || props.data.placeholder) {
      return (
        <div className="numbers chart-line flex-center">
          <LoadingAnimation active />
        </div>
      );
    }
    if (
      props.data.accounts.length === 0
      || props.data.accounts[0].values.length === 0
    ) {
      return (
        <Placeholder image={placeHolderImageUrl}> <p>{__('no_data_yet')}</p> </Placeholder>
      );
    }
    if (!props.data.placeholder && props.data.accounts.length > 0) {
      return (
        <div className="numbers chart-line">
          {props.data.accounts[0].values.length === 0 && (
            <Placeholder className="chart-line__placeholder">
              {__('no_data_found_in_period')}
            </Placeholder>
          )}
          {/* <h3 className="chart-line__heading smallheading center">{props.legendX}</h3> */}
          <ResponsiveContainer>
            <AreaChart
              data={formatData(props.data.accounts)}
              margin={{ top: -1, right: -1, left: -1, bottom: -1 }}>

              {gradientDefinitions()}

              <CartesianGrid stroke={Colors.charlineTick} strokeDasharray="1 2" />
              <XAxis
                dataKey="x"
                minTickGap={15}
                axisLine={false}
                mirror={true}
                tick={{ dy: -10, dx: -10, color: Colors.charlineTick }}
                tickLine={false}
              >
              </XAxis>
              <YAxis
                // padding={{ top: 80 }}
                type="number"
                tickCount={10}
                domain={props.xDomain}
                allowDecimals={false}
                axisLine={false}
                mirror={true}
                tickFormatter={yValue => yValue - 100}
                unit="%"
                tick={{ dy: 5, color: Colors.charlineTick }}
                tickLine={false}
              />
              {/*<YAxis type="number" domain={['dataMin', 'dataMax']} />*/}
              <Tooltip
                offset={-40}
                content={<CustomTooltip />}
              />
              <ReferenceDot r={20} fill="red" stroke="none" />
              {/* TODO: Make this a loop, to support more than three accounts. */}
              {AccountArea("url(#gradient1)", Colors.chartline1, props.data.accounts[0], Area)}
              {props.data.accounts.length > 1 && AccountArea("url(#gradient2)", Colors.chartline2, props.data.accounts[1], Area)}
              {props.data.accounts.length > 2 && AccountArea("url(#gradient3)", Colors.chartline3, props.data.accounts[2], Area)}
            </AreaChart>
          </ResponsiveContainer>
        </div>
      );
    }
  };
  return (
    <div className="chart-line__container">
      {content()}
      {/* if the component has children or more than one graph, it gets a footer that displays optional buttons
      and/or a legend. If the component only has children and no legend, the children are centered. */}
      {props.data && !props.data.placeholder && props.data.accounts.length > 0 ? (props.children || props.data.accounts.length > 1 ? (
        <div className={`${props.data.accounts.length === 0 ? 'chart-line__area-below--center' : ''} chart-line__area-below`}>
          <div className="chart-line__button-area">{props.children}</div>
          <div className="chart-line__legend">
            {props.data.accounts.length > 1 && (
              <Fragment>
                <span><p>{props.data.accounts[0].name}</p>
                  <svg viewBox="0 0 100 100" height="12px" width="12px" xmlns="http://www.w3.org/2000/svg">
                    <circle fill={Colors.chartline1} cx="50" cy="50" r="50" />
                  </svg>
                </span>
                <span><p>{props.data.accounts[1].name}</p>
                  <svg viewBox="0 0 100 100" height="12px" width="12px" xmlns="http://www.w3.org/2000/svg">
                    <circle fill={Colors.chartline2} cx="50" cy="50" r="50" />
                  </svg>
                </span>
              </Fragment>
            )}
            {props.data.accounts.length > 2 && (
              <span><p>{props.data.accounts[2].name}</p>
                <svg viewBox="0 0 100 100" height="12px" width="12px" xmlns="http://www.w3.org/2000/svg">
                  <circle
                    stroke={Colors.chartline3stroke || 'none'}
                    strokeWidth={Colors.chartline3stroke ? 10 : 0}
                    fill={Colors.chartline3}
                    cx="50" cy="50"
                    r={Colors.chartline3stroke ? 40 : 50}
                  />
                </svg>
              </span>
            )}
          </div>
        </div>
      ) : null) : null}
    </div >
  );
};

const ChartLine = Loadable({
  loader: () => import('recharts/es6/' /* webpackChunkName: "recharts" */),
  loading: LoadingAnimation,
  timeout: 120000, // 2 min
  render(loaded, props) {

    return <ChartLineLoaded {...props}
      AreaChart={loaded.AreaChart}
      Area={loaded.Area}
      XAxis={loaded.XAxis}
      YAxis={loaded.YAxis}
      CartesianGrid={loaded.CartesianGrid}
      Tooltip={loaded.Tooltip}
      ReferenceDot={loaded.ReferenceDot}
      ResponsiveContainer={loaded.ResponsiveContainer}
    />;
  },
});

export default ChartLine;
