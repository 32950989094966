import React from 'react';
import './index.scss';
import Imgix from 'react-imgix';

const CaseImage = (props) => {

  return (
    <div className="case-image section-padding">
      <Imgix
        src={props.value}
        fit="clip"
        sizes="100vw"
        imgProps={{
          alt: props.text || 'image',
        }}
      />
    </div>
  );
};

CaseImage.defaultProps = {
  clarifications: []
};

export default CaseImage;
