import * as types from "./types";
import { setItem } from "helpers";
import { getMarketFromProcess } from "helpers/market";
import { convertRoute } from "localisation/localisationFunctions";
import { createReducer } from "../utilities/independent";
import { formDataToCollection } from "helpers/filters";
import middleman from "helpers/middleman";

// FOR DEBUGGING:
// To change market and language, set node env and hostname accordingly
// See localisation/localisationFunctions.test.js for descriptions of usage.
const market = getMarketFromProcess();

export const initialUser = {
  userRole: [],
  tags: { TERMSdone: false },
  market,
  language: market,
  ssn: false,
  signicatUrl: false,
  signicatProcessId: false,
};

// Add the reducer in the same file as the action

/*

#######
##    ##
##   ##
#######
##    ##

_______ R as in Reducers _______

*/

/* authReducer */

// [types.GET]
export const authReducer_GET = (state, action) => {
  if (!action.payload || !action.payload.data) {
    return state;
  }

  return {
    ...state,
    ...action.payload.data,
  };
};
// types.CREATE
export const authReducer_CREATE = (state, action) => {
  if (!action.payload || !action.payload.data) {
    return state;
  }

  const data = action.payload.data;
  return {
    ...state,
    ...data,
  };
};

// [types.REGISTER]
export const authReducer_REGISTER = (state, action) => {
  if (
    !action.payload.data ||
    !action.payload.data.ssn ||
    !action.payload.data.signicatUrl
  ) {
    return { ...state };
  }
  return {
    ...state,
    ssn: action.payload.data.ssn,
    signicatUrl: action.payload.data.signicatUrl,
  };
};

// [types.REGISTER_BANKID]
export const authReducer_REGISTER_BANKID = (state, action) => {
  // if (!action.payload.data.user && !action.payload.data.person ) {}
  let user = action.payload.user;

  if (action.payload.registeredUser) {
    user.registeredUser = true;
  }

  if (action.payload && action.payload.person) {
    return {
      ...state,
      roaringData: action.payload.person,
      ...user,
    };
  }
  return { ...state, authStatus: action.payload.status, ...user };

  // console.log(action.payload, 'from redux');
  // return { ...state, roaringData: false };
};

// [types.COMPANYREG]
export const authReducer_COMPANYREG = (state, action) => {
  if (
    !action.payload.data ||
    !action.payload.data.ssn ||
    !action.payload.data.signicatUrl
  ) {
    return { ...state };
  }
  const data = action.payload.data;
  return {
    ...state,
    ...data,
  };
};

// [types.LOGIN]
export const authReducer_LOGIN = (state, action) => {
  // const market = getMarket();
  // prepare for moving middleman from returning a string with a signicat url
  // to when it will return a object with both signicatUrl and processId
  const data = action.payload.data;
  const signicatUrl = typeof data === "string" ? data : data.signicatUrl;
  const signicatProcessId =
    market === "SE"
      ? data.authReference
      : typeof data === "string"
        ? state.signicatProcessId
        : data.signicatProcessId;

  return {
    ...state,
    signicatUrl,
    signicatProcessId,
    ssn: action.payload.ssn,
  };
};

// [types.LOGOUT]
export const authReducer_LOGOUT = () => {
  return initialUser;
};

// [types.UPDATE]
export const authReducer_UPDATE = (state, action) => {
  if (!action.payload) return { ...state, error: "Could not update user" };
  // when polling the payload is just the signicatProcessId, when passing the bankid sign, the payload is a user data object

  return { ...state, ...action.payload };
};

// [types.REFRESHTOKEN]
export const authReducer_REFRESHTOKEN = (state, action) => {
  const {
    payload: { data: { authToken, tokenExpires, tokenType } = {}} = {},
  } = action;

  if (tokenExpires) {
    return {
      ...state,
      authToken,
      tokenExpires,
      tokenType,
      tokenUpdated: Date.now(),
    };
  }
  return { ...state };
};

// [types.COLLECTDATA]
export const authReducer_COLLECTDATA = (state, action) => {
  if (!action.payload.data || action.error) {
    return { ...state, error: "Error" };
  }

  return { ...state, ...action.payload.data, error: false };
};

// [types.INFO]
export const authReducer_INFO = (state, action) => {
  return { ...state, company_info: action.payload.data.simpleAttributes };
};

// [types.LANGUAGE]
export const authReducer_LANGUAGE = (state, action) => {
  state.language = action.payload;

  const convertedRoute = convertRoute(action.payload);
  setItem("language", action.payload);

  history.replaceState({}, "JOOL", convertedRoute);
  location.reload();

  return { ...state };
};

/* formStatusReducer */
export const formStatusReducer = createReducer(false)({
  [types.COMPLETEFORM]: () => {
    return true;
  },
  [types.RESETFORM]: () => {
    return false;
  },
  [types.COLLECTDATA]: (state, action) => {
    const acceptedStatuses = [200, 201];
    const status = acceptedStatuses.includes(action.payload.status);

    return status;
  },
});

export const formSubmittedStatus_UPDATESTATUS = (state, action) => {
  return action.payload;
};

/*

    ##
   ####
  ##  ##
 ########
##      ##

_______ A as in Actions _______

*/

export const updateUser = (userData) => {
  return {
    type: types.UPDATE,
    payload: userData,
  };
};

export const registerRepresentative = () => {
  if (!middleman) {
    console.error("no middleman connector defined in registerRepresentative");
    return false;
  }
  return (id) => {
    return {
      type: types.GET,
      payload: middleman.promisePost(`/v2/fa/register/company/representative`, {
        id,
      }),
    };
  };
};

export const switchUser = (id) => {
  return {
    type: types.GET,
    payload: middleman.promisePost(`/v2/fa/user/switch`, { id }),
  };
};

// If you want to log a user out. Set the status to logout with the
// setStatus function above. The auth component will take it from there.
export const logoutUser = () => {
  return (dispatch) => {
    middleman
      .promisePost(`/v1/fa/logout`, null)
      .then((request) => {
        dispatch({
          type: types.LOGOUT,
          payload: initialUser,
        });
        return request;
      })
      .catch(() => {
        dispatch({
          type: types.LOGOUT,
          payload: initialUser,
        });
      });
  };
};

// Used to make sure there is not previous user data in the store
// But does not trigger a logut in middleman (as in logoutUser() )
// Ex in components LoginForm and Auth
export const clearUser = () => {
  return {
    type: types.LOGOUT,
    payload: initialUser,
  };
};

export const submitUser = (response) => {
  return {
    type: types.REGISTER_BANKID,
    payload: response,
  };
};

// Registration action for company users in JOOL
// Used in CompanyStep1
export const registerCompany = (formData) => {
  formData["contact.company.client_of_country"] = market;
  const request = middleman.promisePost(
    `/v1/fa/register/company/bankid`,
    formData
  );
  return {
    type: types.COMPANYREG,
    payload: request,
  };
};

export const createCompanyProfile = (formData) => {
  formData["client_of_country"] = market;
  const request = middleman.promisePost(
    `/v2/fa/register/quantum/company`,
    formData
  );

  return {
    type: types.CREATE,
    payload: request,
  };
};

export const createProfile = (formData) => {
  const request = middleman.promisePost(`/v2/fa/register/quantum/`, formData);

  return {
    type: types.CREATE,
    payload: request,
  };
};

// Only used in IdleTimer
export const refreshToken = () => {
  const request = middleman.promisePost(`/v1/fa/token/refresh`, null);
  console.log("AuthTimer refresh", request);
  return {
    type: types.REFRESHTOKEN,
    payload: request,
  };
};

export const completeForm = () => {
  return {
    type: types.COMPLETEFORM,
    payload: true,
  };
};

// Send registration data to FA
// Used ex in YearlyCheckup and Slides
export const collectData = (formData /* companyId = false */) => {
  const request = middleman.promisePost(`/v1/fa/auth/userinfo`, formData);

  return {
    type: types.COLLECTDATA,
    payload: request,
  };
};

export const collectPortfolioData = (formData) => {
  const request = middleman.promisePost(`/v1/fa/auth/portfolioinfo`, formData);
  return {
    type: types.COLLECTDATA,
    payload: request,
  };
};

// Does what is sounds like. Send in 'SE', or 'EN', I think.
export const switchLanguage = (newLanguage) => {
  // TODO: we dont use authStatus so need to check if user is logged in
  // if (store().getState().auth.AuthStatus === userStatuses.init) {
  //   let dataToSend = {
  //     "c.language": newLanguage,
  //     "c.contactId": store().getState().auth.User.contact_ssn,
  //   };
  //   middleman.promisePost(`/v1/fa/auth/userinfo`, dataToSend);
  // }
  return {
    payload: newLanguage,
    type: types.LANGUAGE,
  };
};

//
export const resetFormStatus = () => {
  return {
    type: types.RESETFORM,
    payload: false,
  };
};

//
export const lockTest = () => {
  return {
    type: types.UPDATE,
    payload: { RiskBlocked: true },
  };
};

export const updateFormStatus = (status) => {
  return {
    type: types.UPDATESTATUS,
    payload: status,
  };
};

export const sofCollectData = (formData) => {
  const request = middleman.promisePost(`/v1/fa/auth/portfolioinfo`, formData);

  return {
    type: types.COLLECTDATA,
    payload: request,
  };
};

export const createAnswer = (values, form, additionalData = {}) => {
  const body = {
    key: form.key,
    type: form.type,
    data: formDataToCollection(values),
    version: form.version,
    ...additionalData,
  };
  const request = middleman.promisePost("/forms/create", body);
  return {
    type: types.COLLECTDATA,
    payload: request,
  };
};
