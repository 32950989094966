import React, { Component } from 'react';
import { __ } from "localisation";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }
  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    // We also log the error to Bugsnag with console error
    console.error('ErrorBoundary: ', error, info);
  }
  render() {
    if (this.state.hasError) {
      const wrapStyle = {
        border: '1px dashed #e8e8f0',
        maxWidth: '100%',
        maxHeight: '100%',
        overflow: 'hidden',
        padding: '0.5rem',
        margin: '0.5rem auto',
      };
      const iconStyle = {
        fontStyle: 'normal',
        width: '2rem',
        height: '2rem',
        display: 'inline-block',
        fontSize: 'inherit',
        overflow: 'visible',
        fill: 'none',
        strokeWidth: '2px',
        color: 'inherit',
        stroke: 'currentColor',
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
        strokeMiterlimit: '10',
        margin: '0 0.5rem',
      };
      const contentStyle = {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
      };
      const h3Style = {
        textAlign: 'left',
        margin: '0 1rem 0 0.75rem',
        display: 'flex',
        alignItems: 'center',
        flex: '1',
        fontSize: '0.8rem'
      };
      return (
        <div style={wrapStyle}>
          <div style={contentStyle}>
            <div className="body-big clarification" style={h3Style}>
              <svg
                style={iconStyle}
                width="28px" height="28px" viewBox="0 0 28 28" version="1" xmlns="http://www.w3.org/2000/svg"><title>Icon warning</title><path vectorEffect="non-scaling-stroke" d="M14 17v-6"></path><circle vectorEffect="non-scaling-stroke" className="icon__fillstroke" cx="14" cy="21" r=".5"></circle><path vectorEffect="non-scaling-stroke" d="M9.4 10.7L2 23.5a1 1 0 0 0 .9 1.5h22.4a1 1 0 0 0 1-1.5L23.4 19l-4.2-7.2-4.4-7.4a1 1 0 0 0-1.8 0l-1.6 2.8"></path></svg>
              <div>{__("standard_error_message", { email: __('platform_email'), tel: __('platform_tel') })}</div>
            </div>
            <div className="button__wrap button__wrap--noTopSpacing">
              <button className="button cta small" onClick={() => location.reload()} type="button">{__('reload_page')}</button>
              <a className="button small" href={`mailto:${__('platform_email')}?subject=Error%20on ${escape(window.location.href)}&body=-%20PLEASE%20INCLUDE%20THIS%20INFO%20-Version:%20${import.meta.env.VITE_MODE === 'beta' ? 'BETA%20-%20' : ''}v.${VITE_VERSION}%20-%20Build:%20${VITE_TIMESTAMP}%0D%0ACurrent%20page:%20${escape(window.location.href)}%0D%0AUser%20agent:%20${escape(navigator.userAgent)}%0D%0ABrowser%20size:%20${window.innerWidth}%20x%20${window.innerHeight}%0D%0A-%0D%0A%0D%0A`} rel="noopener noreferrer" target="_blank">{__('report_error')}</a>
            </div>
          </div>
        </div>
      );
    }
    // if no error, return the content inside of the Error Boundary
    return this.props.children;
  }
}

export default ErrorBoundary;
