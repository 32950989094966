import React from 'react';
import './index.scss';

const CaseVideo = (props) => {
  let iframe = '';
  const vimeoReg = /(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^\/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:[a-zA-Z0-9_\-]+)?/i;
  const match = props.value.match(vimeoReg);
  if (match){
    let videoId = match[1];
    iframe = <iframe src={`https://player.vimeo.com/video/${videoId}`} frameBorder="0" allowFullScreen title={`Vimeo video id ${videoId}`}></iframe>;
  }

  return (
    <div className="caseVideo">
      <div className="iframeWrapper">
        {iframe}
      </div>
    </div>
  );
};

export default CaseVideo;
