import __ from 'localisation';
import __ui from 'localisation/hubins/registration';

export default (premium, amount, reason = '') => {

  const pdfData = {
    header: {
      title: premium ? __('new_deposit') : __('withdrawal')
    },
    data: [
      {
        title: '',
        fields: [
          {
            title: __("trade_amount") + ' SEK',
            type: 'input',
            value: amount
          },
          ...premium ? [
            {
              title: __('sof_checkbox'),
              type: 'input',
              value: __ui('approved')
            },
            {
              title: __('demand_assesment_checkbox'),
              type: 'input',
              value: __ui('approved')
            },
          ] : [
            {
              title: __('reason_for_withdrawal'),
              type: 'input',
              value: reason
            },
            {
              title: __('demand_assesment_checkbox'),
              type: 'input',
              value: __ui('approved')
            },
          ],
        ]
      }
    ]
  };
  return pdfData;
}
;
