import React from 'react';
import __ from 'localisation';
import Heading from 'components/Heading';
import ErrorMessage from 'components/ErrorMessage';

const Template = (props) => (
  <>
    {props.title && (<Heading element="h3" size={4} className="s-bottom-sm">{props.title}</Heading>)}
    {props.description && <div className="clarification s-bottom-md p fc">{props.description}</div>}
    <label className={`s-bottom-m  ${props.className}`}>
      <div className="label-wrapper">
        {props.label && (<div className="label">{props.label}:</div>)}
      </div>
      <div className={`input__wrap ${props.throwError ? 'input__wrap--error' : ''} ${props.inverted ? 'input__wrap--inverted' : ''}`}>
        <textarea
          disabled={props.disabled}
          id={props.id}
          className={`${props.throwError ? 'input error' : 'input'} ${props.isValidated ? 'input--validated' : ''}`}
          placeholder={__(props.placeholder)}
          onBlur={() => { props.handleFocus ? props.handleFocus(true) : () => {}; }}
          onFocus={() => { props.handleFocus ? props.handleFocus(false) : () => {}; }}
          onChange={props.handleChange}
          value={props.value}
          aria-invalid={props.optionsState.error ? true : false}
        />
      </div>
      <ErrorMessage text={props.errorMessage} showError={props.throwError} />
    </label>
  </>
);

Template.defaultProps = {
  placeholder: '',
  optionsState: {}
};

export default Template;
