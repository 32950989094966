import React, { useState } from 'react';
import Heading from 'components/Heading';
import ErrorMessage from 'components/ErrorMessage';
import SidebarTrigger from 'components/Sidebar/SidebarTrigger';
import './index.scss';

type Props = {
  options: { id: string, title: string }[];
  value: string;
  id?: string;
  error?: string;
  title?: string;
  callback?: (value: string) => void;
  label?: string;
  disabled?: boolean;
  required?: boolean;
  placeholder?: string;
  tooltip?: string;
  className?: string;
}

const Dropdown = ({
  options,
  value: propsValue,
  id,
  error,
  title,
  callback,
  label,
  disabled,
  required,
  placeholder,
  tooltip,
  className,
}: Props) => {

  const [value, setValue] = useState(propsValue || "");

  const handleChange = (event) => {
    setValue(event.target.value);
    if (callback) callback(event.target.value);
  }

  return (
    <>
    {title && <Heading size="4" className="form-group__title">{title}</Heading> }
    <label id={id} className={`${className} dropdown-label`}>
        {label && (
          <div className="label">{label}:</div>
        )}
      <select
        value={value}
        onChange={handleChange}
        disabled={disabled}
        required={required}
        >
        {placeholder && <option value="" hidden>{placeholder}</option>}
        {options.map((item, index) => (
          <option key={`${item.id}-${index}`} value={item.id}>{item.title}</option>
        ))}
      </select>
        {tooltip && (
          <SidebarTrigger
            id={id}
            icon="question"
            text={tooltip}
          />
        )}
      <ErrorMessage text={error} showError={!!error} />
    </label>
    </>
  )
};

export default Dropdown;