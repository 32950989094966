import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useCollapse } from 'react-collapsed';
import __ from 'localisation';
import Button from '@hubins/components/Button';
import Icon from '@hubins/components/IconNew';
import DownloadLink from 'components/DownloadLink';

const ExpandableTable = (props) => {

  const [ isExpanded, setExpanded ] = useState(false);

  const mapProps = (rows, hideRowIfEmpty) => {
    if (hideRowIfEmpty) {
      rows = rows.filter( row => row.value);
    }

    if (rows.length === 0) {
      rows = [
        {
          label: props.emptyRowLabel,
          value: '',
          status: 'inactive',
        }
      ];
    }

    return rows.map((obj, index) => {
      let className = 'status' in obj ? `table__row table__row--${obj.status}` : 'table__row';

      const icon = 'icon' in obj && <Icon icon={obj.icon} size={8} />;

      return (
        <div className={className} key={index}>
          {
            obj.download &&
            <DownloadLink
              auth={true}
              secured={true}
              key={index}
              name={obj.label}
              path={obj.href}
              icon="pdf"
              small
            />
          }
          {obj.href && !obj.download &&
            <a href={obj.href} >{icon} {obj.label}</a>
          }
          {!obj.href && !obj.download &&
            <span className="table__label">{icon} {obj.label}</span>
          }
          <span className="table__value">{obj.value}</span>
        </div>
      );
    });
  };

  const [stateRows, setRows] = useState(mapProps(props.rows, props.hideRowIfEmpty));

  useEffect(() => {
    setRows(mapProps(props.rows, props.hideRowIfEmpty));
  }, [props.rows]);

  const handleClick = () => setExpanded(!isExpanded);

  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });

  return (
    <div className={`table ${props.className} table--expandable`}>
      <div className="table__header">
        {props.title}
      </div>
      {stateRows.slice(0, 4)}
      {stateRows.length > 4 && (
        <div {...getCollapseProps()} className="accordeon">
          {stateRows.slice(4, stateRows.length)}
        </div>
      )
      }
      <div className="table__footer button__wrap">
        {stateRows.length > 4 && (
          <Button
            {...getToggleProps({ onClick: handleClick })}
            className="spacing small"
          >
            {isExpanded ? __('close') : __('show_more')}
          </Button>
        )}
        {props.children}
      </div>
    </div>
  );
};

ExpandableTable.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  rows: PropTypes.array,
  hideRowIfEmpty: PropTypes.bool,
  emptyRows: PropTypes.array, // should be an array containt objects with rows
};

ExpandableTable.defaultProps = {
  className: '',
  title: '',
  hideRowIfEmpty: false,
  rows: {}, // ex { date: '1988-06-01', value: '', status: 'active' }
  emptyRowLabel: __('empty_rows')
};

export default ExpandableTable;
