import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'components/Box';
import './index.scss';

/**
 * generateColumnClasses - Usage: className={`${generateColumnClasses(props.media)}`}
 * @param {object} media - Takes an object like { s: 12, m: 6 }
 * @return {string} - With all classNames
 */
export const generateColumnClasses = (media) => {
  const { xs, s, m, l, xl, offsetXs, offsetS, offsetM, offsetL, offsetXl, align, box, border, orderTopOnMobile } = media;

  const c_xs = xs ? ' column--xs' + xs : '';
  const c_s = s ? ' column--s' + s : '';
  const c_m = m ? ' column--m' + m : '';
  const c_l = l ? ' column--l' + l : '';
  const c_xl = xl ? ' column--xl' + xl : '';

  const c_offsetXS = offsetXs ? ' column--offsetXs' + offsetXs : '';
  const c_offsetS = offsetS ? ' column--offsetS' + offsetS : '';
  const c_offsetM = offsetM ? ' column--offsetM' + offsetM : '';
  const c_offsetL = offsetL ? ' column--offsetL' + offsetL : '';
  const c_offsetXl = offsetXl ? ' column--offsetXl' + offsetXl : '';

  const c_box = box ? ' box' + (typeof box === 'string' ? ' box--' + box : ' ') : '';
  const c_border = border ? ' column--border' : '';
  const c_align = align ? ' column--' + align : '';
  const c_orderTopOnMobile = orderTopOnMobile ? ' column--ordertoponmobile' : '';

  return `column${c_xs}${c_s}${c_m}${c_l}${c_xl}${c_offsetXS}${c_offsetS}${c_offsetM}${c_offsetL}${c_offsetXl}${c_align}${c_box}${c_border}${c_orderTopOnMobile}`;
};

class Column extends Component {
  render() {
    return (
      <div
        className={
          `${generateColumnClasses(this.props)} ${this.props.bg} ${this.props.className}`
        }
        id={this.props.id}
        style={this.props.style}
      >
        {this.props.children}
      </div>
    );
  }
}

Column.propTypes = {
  xs: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  s: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  m: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  l: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  xl: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  offsetXs: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  offsetS: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  offsetM: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  offsetL: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  offsetXl: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  align: PropTypes.oneOf(['start', 'end', 'center', 'grow', '']),
  bg: PropTypes.string,
  box: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  border: PropTypes.bool,
  className: PropTypes.string,
  orderTopOnMobile: PropTypes.bool,
  id: PropTypes.string,
};

Column.defaultProps = {
  xs: '12',
  s: '',
  m: '',
  l: '',
  xl: '',
  offsetXs: '',
  offsetS: '',
  offsetM: '',
  offsetL: '',
  offsetXl: '',
  align: '',
  bg: '',
  box: '',
  border: false,
  className: '',
  orderTopOnMobile: false,
};

const Columns = (props) => {
  const { children, className = '', ...mods } = props;
  let modifiers = Object.keys(mods).map(prop => ' columns--' + prop).join('');

  return (
    <div className={`columns__container ${className}`}>
      <div className={`columns${modifiers}`}>
        {children}
      </div>
    </div>
  );
};

Columns.propTypes = {
  className: PropTypes.string,
  tight: PropTypes.bool,
  reverseM: PropTypes.bool,
};

export { Columns, Column };
