import React, { Component } from 'react';
import DatePicker from '@hubins/components/DatePicker';
import Button, { ButtonWrap } from '@hubins/components/Button';
import PropTypes from 'prop-types';
import { Columns, Column } from 'components/Columns';

import './index.scss';
import { __ } from 'localisation';

class RangeDatePicker extends Component {

  constructor(props) {
    super(props);
    this.state = {
      from: new Date(this.props.from),
      to: new Date(this.props.to)
    };
    this.handleToChange = this.handleToChange.bind(this);
    this.handleFromChange = this.handleFromChange.bind(this);
  }

  handleToChange(to) {
    this.setState({ to });
  }

  handleFromChange(from) {
    if (from > this.state.to) {
      this.setState({ from, to: from });
    } else {
      this.setState({ from });
    }
  }

  render() {
    const { from, to } = this.state;
    const modifiers = { start: from, end: to };
    const fromProps = { selectedDays: [from, { from, to }], disabledDays: { after: new Date() }, modifiers };
    const toProps = { selectedDays: [from, { from, to }], month: to, disabledDays: { before: from, after: new Date() }, modifiers };
    return (
      <form
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
          this.props.onClick(from, to);
        }}
      >
        <Columns tight>
          <Column  s="6">
            <div className="range-date-picker__from s-bottom-m">
              <DatePicker
                dayPickerProps={fromProps}
                disabled={this.props.staticFrom}
                callback={this.handleFromChange}
                date={this.state.from}
                label={__('from')}
              />
            </div>
          </Column>
          <Column s="6">
            <div className="range-date-picker__to s-bottom-m">
              <DatePicker
                dayPickerProps={toProps}
                callback={this.handleToChange}
                date={this.state.to} min={this.props.from}
                label={__('to')}
              />
            </div>
          </Column>
          <Column s="12">

            <Button
              type="submit"
              iconColor="primary"
              loading={this.props.loading}
            >
          Uppdatera
            </Button>

          </Column>
        </Columns>
      </form>
    );
  }

}

RangeDatePicker.propTypes = {
  loading: PropTypes.bool,
  staticFrom: PropTypes.bool,
  from: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]).isRequired,
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]).isRequired,
  onClick: PropTypes.func.isRequired
};

RangeDatePicker.defaultProps = {
  from: '2017-06-08',
  to: '2017-06-10',
  loading: false,
};

export default RangeDatePicker;
