import React, { useState } from 'react';
import { __ } from 'localisation';
import SidebarTrigger from 'components/Sidebar/SidebarTrigger';
import ErrorMessage from 'components/ErrorMessage';
import Heading from 'components/Heading';

const checkIfPreSelectedExists = (options) => {
  const filteredOptions = options.filter((option) => option.value);
  if (filteredOptions[0]) return filteredOptions[0].id;
  return 'placeholder';
};

const FormDropdown = ({
  title = '',
  label = '',
  placeholder = ' ',
  error_message = __('dropdown_error'),
  optionsState = { error: false },
  name = undefined,
  autocomplete = undefined,
  disabled = false,
  locked = false,
  lockedText = false,
  ...props
}) => {

  const [selected, setSelected] = useState(checkIfPreSelectedExists(props.options));

  const renderOptions = () => {
    const options = props.options.map((item, index) => {
      return (<option key={index} value={item.id}>{item.title}</option>);
    });
    return options;
  };

  const handleChange = (partId, event) => {

    const prevState = selected === 'placeholder' ? false : selected;
    setSelected(event.target.value);

    if (props.onChange) {
      props.onChange(event);
    }

    if (props.callback) {
      props.callback(partId, event.target.value, prevState);
    }

  };

  const throwError = optionsState.error;

  return (
    <>
      {title && <Heading size="4" className="form-group__title">{title}</Heading>}
      <label id={props.id}>

        {label && (

          <div className="label">{label}:</div>

        )}

        <select
          className={`${optionsState.error ? 'select error' : 'select'}`}
          disabled={disabled || locked}
          value={selected}
          required={props.required}
          onChange={(event) => handleChange(props.partId, event)}
          name={name}
          autoComplete={autocomplete || name}
        >
          {placeholder && <option value="" hidden>{placeholder}</option>}
          {renderOptions()}
        </select>
        {props.tooltip && (
          <SidebarTrigger
            id={props.id}
            icon="question"
            text={props.tooltip}
          />
        )}
        {lockedText && (
          <p className="clarification center section-padding-small">{lockedText}</p>
        )}
        <ErrorMessage text={error_message} showError={throwError} />
      </label>
    </>
  );
};

export default FormDropdown;
