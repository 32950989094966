export const stateName = 'state_v2';

function isSupported(getStorage) {
  try {
    const key = "__supports_localstorage__";
    getStorage().setItem(key, key);
    getStorage().removeItem(key);
    return true;
  } catch (e) {
    console.error('LocalStorage is not supported, fallback to inBrowserMemory');
    return false;
  }
}

const isLsSupported = isSupported(() => localStorage); // => true | false
let inBrowserMemory = {
  [stateName]: {}
};

export const getItem = (key) => {
  return isLsSupported ? localStorage.getItem(key) : inBrowserMemory[key];
};

export const setItem = (key, value) => {
  return isLsSupported
    ? localStorage.setItem(key, value)
    : inBrowserMemory[key] = value;
};

export const removeItem = (key) => {
  if (isLsSupported) {
    localStorage.removeItem(key);
  } else {
    delete inBrowserMemory[key];
  }
};

export const loadState = () => {

  try {
    let serializedState = false;
    if (isLsSupported) {
      serializedState = localStorage.getItem(stateName);
    } else {
      serializedState = inBrowserMemory[stateName];
    }
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    console.error('Failed loading state from localeStorage');
    return undefined;
  }
};

export const saveState = (state, { authToken, tokenExpires }) => {
  // delete state.auth.User.signicatWindow.window;
  // For FF the state object cannot contain a reference to the window object
  try {
    const serializedState = JSON.stringify(state);
    if (isLsSupported) {
      localStorage.setItem(stateName, serializedState);
      localStorage.setItem('token', authToken);
      localStorage.setItem('tokenExpires', tokenExpires);
    } else {
      inBrowserMemory[stateName] = serializedState;
      inBrowserMemory.token = authToken;
      inBrowserMemory.tokenExpires = tokenExpires;
    }
  } catch (err) {
    console.error('Failed saving state to localeStorage');
  }
};


export const clearState = () => {
  try {
    if (isLsSupported) {
      localStorage.removeItem('state');
      localStorage.removeItem('state_v2');
      localStorage.removeItem('token');
      localStorage.removeItem('tokenExpires');
    } else {
      inBrowserMemory = {
        [stateName]: {}
      };
    }
    return undefined;
  } catch (err) {
    console.error('Failed clearing state in localeStorage');
    return undefined;
  }
};

// Example of initial v1 state ('state'):
// {"auth":{"User":{"userRole":[],"market":"SE","language":"SE"},"Status":"UserStatus/INIT"}}

// Eample of initial v2 state ('state_v2'):
// {"auth":{"User":{"userRole":[],"market":"SE","language":"SE","ssn":false,"signicatUrl":false,"signicatProcessId":false}}}
