import React, { Component, Fragment } from 'react';
import { Column } from 'components/Columns';

class CaseStatsTable extends Component {

  renderTable(rows) {
    if (!rows) return false;

    return rows.map((row, index) => (
      <div key={index} className="table__row">
        {row.label}
        <span className="table__value">{row.value}</span>
      </div>
    ));
  }

  render() {
    return (
      <Fragment>
        <Column s="12" m="6">
          <div className="table section-padding">
            <div className="table__header">
              {this.props.leftHeader}
            </div>
            {this.renderTable(this.props.left)}
          </div>
        </Column>
        <Column s="12" m="6">
          <div className="table section-padding">
            <div className="table__header">
              {this.props.rightHeader}
            </div>
            {this.renderTable(this.props.right)}
          </div>
        </Column>
      </Fragment>

    );
  }

}

export default CaseStatsTable;
