import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import Menu from 'modules/Menu/Menu';
import MenuTitle from 'modules/Menu/MenuTitle';
import { __, __route } from 'localisation';
import Button from '@hubins/components/Button';
import { uiContext } from 'components/UiContext';

import './MenuHeading.scss';
import './MenuCancel.scss';
import './MenuSignup.scss';

const MenuHeading = ({
  title = false,
  logo,
  btnType,
  backBtn,
  hideSignup,
  clearUser,
  ...restProps
}) => {
  const { login } = useContext(uiContext);

  return (
    <Menu className="menu--heading" homeLink={true} logo={logo} {...restProps}>
      {title && <MenuTitle
        className={`
        ${btnType === 'signup' ? 'with_signup_btn' : ''}
      `}
        title={title}
      />}
      {btnType === 'cancel' && (
        <div className="menu__cancel">
          {/* Should be a <Link>, but is some bugg with the slider form
        that it is keeping its state, so needs a hard refresh */}
          <Link to={backBtn || '/'} className="link">{__('cancel')}</Link>
        </div>
      )}
      {btnType === 'signup' && (
        <div className="menu__signup">
          <Button className="small link" onClick={() => {
            clearUser && clearUser();
            login();
          }}>
            {__('login')}
          </Button>
          {/* temp hidden since jool cant accept new customers */}
          {!hideSignup &&
            <Button className="small cta" id="signup-3" href={__route('signup')}>
              {__('create_account')}
            </Button>
          }

        </div>
      )}
      {btnType === 'logout' && (
        <div className="menu__cancel">
          <Link to={__route('logout')} className="link">{__('logout')}</Link>
        </div>
      )}
    </Menu>
  );
};

export default MenuHeading;
