import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';


const FixedFooter = (props) => {

  return (
    <>
      <div className="flex-pusher" />
      <div className={`fixed-footer ${props.expanded ? 'fixed-footer--expanded' : ''} ${props.className} ${props.above ? 'fixed-footer--above' : ''} ${props.fill ? ' fixed-footer--fill' : ''}`}>
        <div className="fixed-footer__col1">
          {props.col1 || props.children}
        </div>
        {props.col2 && (
          <div className="fixed-footer__col2">
            {props.col2}
          </div>
        )}
      </div>
    </>
  );
};

FixedFooter.propTypes = {
  className: PropTypes.string,
  expanded: PropTypes.bool,
  above: PropTypes.bool,
  col1: PropTypes.oneOfType([PropTypes.bool, PropTypes.object, PropTypes.string]),
  col2: PropTypes.oneOfType([PropTypes.bool, PropTypes.object, PropTypes.string]),
  fill: PropTypes.bool,
};

FixedFooter.defaultProps = {
  className: '',
  expanded: true,
  col1: undefined,
  col2: undefined,
  above: false,
  fill: false,
};

export default FixedFooter;
