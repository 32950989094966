import React from 'react';
import Heading from "components/Heading";
import Wrapper from "@hubins/components/Wrapper";
import { Columns, Column } from "components/Columns";
import { getMoney } from 'helpers';

const ResultBox = ({ realized }) => {

  const taxConsequence = () => {
    if(realized < 0) {
      return realized * 0.3 * (5 / 6) * 0.7;
    }
    if(realized === 0) {
      return realized;
    }
    if(realized > 0) {
      return realized * 0, 3;
    }
  };

  const hint = () => {
    if(realized < 0) {
      return "Du har hittils gjort en förlust på din investering vilket innebär att du har möjlighet att göra en förlustavdrag enligt ovan och sedan flytta din investering till en kapitalförsäkring för att få möjligheten att istället betala en låg årlig schablonskatt på den eventuella värdeökning som du förväntar dig under investeringsperioden.";
    }
    if(realized === 0) {
      return "Du har ingen uppbyggd vinstskatt att betala. ";
    }
    if(realized > 0) {
      return "Du har hittils gjort vinst på din investering vilket innebär att du kommer att behöva betala vinskatt enligt ovan belopp vid en försäljning. Du tror även att investeringen kommer att forsätta öka i värde, vilket helt enkelt innebär ännu högre vinstskatt i framtiden vid försäljning. Enligt vår beräkning kommer din uppbyggda skatt motsvara 1 240 116 kr under investeringsperioden. Genom att flytta din investering till en kapitalförsäkring har du då möjlighet att istället betala en låg årlig schablonskatt på den eventuella värdeökningen som du förväntar dig.";
    }
  };

  return (
    <Wrapper tight className="neutral-1">
      <section className="section section--wide">
        <Heading size="4" className="smallheading">Hur har det gått hittils?</Heading>
        <Columns className="s-md no-padding-sm">
          <Column m="6">
            <strong>Realiserad vinst/förlust vid inflytt</strong>
          </Column>
          <Column m="6" className="align-right">
            <strong>{getMoney(realized, 'kr', 0)}</strong>
          </Column>
          <Column m="6">
            <strong>Skattekonsekvens vid flytt</strong>
          </Column>
          <Column m="6" className="align-right">
            <strong>{getMoney(taxConsequence(), 'kr', 0)}</strong>
          </Column>
        </Columns>
        <p>{hint()}</p>
      </section>
    </Wrapper>

  );
};

export default ResultBox;
