import Logo from "assets/logo";
export { default as Logo } from "assets/logo";
export { default as QLLogo } from "assets/logo_ql";

export const Colors = {
  primary: "rgba(2, 6, 76, 1)",
  primaryTransparent: "rgba(2, 6, 76, 0)",
  secondary: "rgb(255, 203, 148)",
  secondaryTransparent: "rgba(255, 203, 148, 0)",
  accent: "#B07FB3",

  chartline1: "#B07FB3",
  chartline2: "#FFCB94",
  chartline3: "#ffffff",
  chartline4: "#B3B4C9", // usually directinvestment
  chartline3stroke: "#02064C",
  chartlineCombined: "#766490",
  charlineTick: "#E6E6ED",
  chartLineBg: "#fff",

  danger: "#FF7154",
  warning: "#FFD044",
  info: "#33A9D4",
  success: "#15BB89",

  neutral1: "#F6F7FB", // fd $c-lighter & $c-bg
  neutral2: "#F6F7FB", // fd $c-border
  neutral3: "#E6E6ED", // Input line - fd #ddd - works great as a line on $c-neutral-1/ $c-bg
  neutral4: "#B3B4C9", // should be: #B3B4C9, fd $c-grey. .clarification
  neutral5: "#8082A5", // ny // TODO: remove - replace with $c-neutral-4?
  neutral6: "#4E5182", // fd $c-dark // TODO: remove - replace with black
  neutral7: "#1B1F5E", // Just for hubins - not sure where to be used
};

// Easing for Motion
export const Easing = {
  inOut: {
    ease: [0.31, 0.01, 0, 0.98], // easing from variables.scss easeinout
  },
  out: {
    ease: [0.11, 0.29, 0.18, 0.98],
  },
  in: {
    ease: [0.65, 0.01, 1, 1],
  },
};

// Features:
export const LANG_SUPPORT = true; // Set to true to enable language switches. If enabled, standard langugage is 'SE'
export const LANG_DEFAULT = "SE";

// Most used settings in one object
export const settings = {
  Platform: "hubins",
  Colors: Colors,
  Logo: Logo,
  LogoWidth: 12, // in rem. height is always 2rem
};

export default settings;
