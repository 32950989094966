import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Tabs, TabList, TabPanel } from "react-tabs";
import FixedFooter from "components/FixedFooter";
import ProgressTab, { ProgressTabGroup } from "components/ProgressTab";
import Button from "@hubins/components/Button";
import Heading from "components/Heading";
import Wrapper from "@hubins/components/Wrapper";
import ErrorBoundary from "components/ErrorBoundary";
import ScrollToEndTrigger from 'components/ScrollToEndTrigger';
import Tooltip from "components/Tooltip";
import "components/Tabs/index.scss";

const ProgressTabs = ({
  tabs,
  buttonProps,
  selected,
  setSelected,
  preventGoingBack,
  showIndex,
  hasScrolledDown,
  tooltip,
  noButton,
  noSpacing,
}) => {

  const [hasReachPos, setHasReachPos] = useState(false);

  useEffect(() => {
    hasScrolledDown && hasScrolledDown(false);
  }, [selected]);

  useEffect(() => {
    hasScrolledDown && hasScrolledDown(true);
  }, [hasReachPos]);


  /*
  Group tabs together
  Example structure:

  let groups = [
    {
    id: "de_1",
     title: "Tab group 1",
     time: 20,
     tabs: [ tab1, tab2,]
    },
    {
      id: "a_be",
      title: "Tab group 2",
      time: 12,
      tabs: [tab3],
    }
  ];
  */
  let groups = [];
  let totalTime = 0;
  tabs.forEach((tab, index) => {
    tab.id = index;
    if (tab.time) totalTime += tab.time;


    // check if last item in group has same group id as this
    if (index !== 0 && (tab.group && tab.group === tabs[index - 1].group)) {
      const currentGroup = groups[groups.length - 1];
      currentGroup.items.push(tab);
      currentGroup.isGroup = true;
      currentGroup.title = tab.group;
      if(tab.time) currentGroup.time += tab.time;
    } else {
      groups.push({
        id: tab.group || "g_" + index,
        title: tab.title,
        items: [tab],
        time: tab.time || 1,
      });
      // // Check if past groups items is all completed
      // if (selected > index) {
      //   previousGroup.groupCompleted = true;
      // }
    }
  });


  return (
    <Tabs
      className="react-tabs flex flex-pusher"
      selectedTabClassName="tab--selected"
      // only trigger a click, if it's allowed (backwards)
      onSelect={(groupIndex, _, event) => {
        if (preventGoingBack) return;
        // When using groupd, the index is broken,
        // so has added a data-id instead on the DOM element.
        // Please dont't shoot me.
        const id =
          (event.target.dataset && event.target.dataset.id) || selected - 1;
        id < selected && setSelected(Number(id));

        setHasReachPos(false);
      }}
      selectedIndex={selected}
    >
      {tabs.map((Item, index) => (
        <TabPanel
          key={`panel-${Item.title}`}
          forceRender={
            typeof Item.forceRender !== "undefined" ? Item.forceRender : true
          }
          className={selected === index ? "" : "hidden"}
        >

          <Wrapper stretch={noSpacing} noSpacing={noSpacing}>
            {Item.mainTitle && (
              <Heading size="2" className="s-bottom-xl basic-padding-small">
                {Item.mainTitle}
              </Heading>
            )}
            <ErrorBoundary>
              {Item.component ?
                <ScrollToEndTrigger callback={() => setHasReachPos(Item.id)} offset={-100}>
                  <Item.component {...Item.componentProps || {}} />
                </ScrollToEndTrigger>
                : false}
            </ErrorBoundary>
          </Wrapper>
        </TabPanel>
      ))}
      <FixedFooter fill>
        <TabList className="progresstabs">
          {groups.map((group, index) => (
            <ProgressTabGroup
              key={group.id}
              title={(showIndex ? index + 1 + ". " : "") + group.title}
              active={
                group.isGroup &&
                selected >= group.items[0].id &&
                selected <= group.items[group.items.length - 1].id
              }
              relativeWidth={group.time / totalTime}
            >
              {group.items.map((item) =>
                ProgressTab({
                  index: item.id,
                  title: item.title,
                  group: item.group,
                  status: selected,
                  error: selected <= item.id,
                  disabled: selected < item.id,
                  relativeWidth: item.time / group.time,
                  // groupCompleted: item.groupCompleted,
                })
              )}
            </ProgressTabGroup>
          ))}
        </TabList>
        {!noButton && (
          <>
            {tooltip ? (
              <Tooltip
                data-tooltip={tooltip}
                element={function noRefCheck() { }}
              >
                <Button {...buttonProps} />

              </Tooltip>
            ) : (
              <Button {...buttonProps} />
            )}

          </>
        )}
      </FixedFooter>
    </Tabs>
  );
};

ProgressTabs.propTypes = {
  selected: PropTypes.number,
  setSelected: PropTypes.func,
  preventGoingBack: PropTypes.bool,
  noButton: PropTypes.bool,
  // title: PropTypes.string,
  // number: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
};

ProgressTabs.defaultProps = {
  selected: 0,
  preventGoingBack: false,
  noButton: false,
  buttonProps: {
    className: "cta responsive",
    children: "Nästa",
  },
};

export default ProgressTabs;
