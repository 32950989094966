import React, { useEffect, useRef, useCallback } from 'react';
import __ from 'localisation';
import Icon from '@hubins/components/IconNew';
import HubinsAnim from './HubinsAnim';
import { illustrationVariants, contentVariants, inputVariants, submitVariants } from './animationData.js';
import { motion } from 'framer-motion';
import Heading from 'components/Heading';
import Input from '@hubins/components/Input';
import { swedishSsn } from "components/Validation";
import { sanitizeSsn } from "helpers";
import Button from '@hubins/components/Button';
import './index.scss';

const LoginModal = ({
  animateState,
  handleSubmit,
  ssn,
  ssnError,
  setSsn,
  setSsnError,
  handleAbort,
  redirectToSignup,
  reset
}) => {

  const inputField = useRef(null);
  useEffect(() => {
    if (inputField.current) inputField.current.focus();
  }, [inputField]);

  const sanitizeOnBlur = useCallback((e) => {
    const newValue = sanitizeSsn(e.target.value);
    setSsn(newValue);
    setSsnError(swedishSsn(newValue));
  }, [setSsn, setSsnError]);

  return (
    <div className="loginform" key="login">
      <motion.div
        animate={animateState}
        // initial={{ opacity: 0 }}
        className="loginform__content">
        <HubinsAnim
          animateState={animateState} //waiting success visible?
          variants={illustrationVariants}
          platform="Hubins"
        />
        <motion.div
          key="logincontent"
          className="loginform__content"
        >
          <Heading element={motion.h2} size="3" animate className="s-bottom-s center">
            {__('here_login')}
          </Heading>
          <motion.form
            onSubmit={handleSubmit}
            className="loginform__form"
          >
            <motion.div className="loginform__form__input" variants={inputVariants}>
              <Input
                id="ssn"
                inputRef={inputField}
                placeholder={__('ssn_placeholder')}
                required
                type="tel"
                label={__('enter_your_ssn')}
                controlled
                onBlur={sanitizeOnBlur}
                value={ssn}
                error={ssnError}
                className="s-bottom-s"
                callback={(value) => {
                  setSsn(value);
                  setSsnError(swedishSsn(value));
                }}
              />
            </motion.div>
            <motion.div className="center section-padding" variants={submitVariants}>
              <Button
                id="login-button"
                size="large"
                type="submit"
                className="cta spacing inverted"
                loading={animateState === 'waiting'}
                icon="bankid"
                iconSize={16}
                label="BankID"
              >
                {__('login_button')}
              </Button>
            </motion.div>
          </motion.form>
          {/* When the user started the login process */}
          <motion.div
            className={`loginform__content loginform__content--over ${animateState === 'waiting' ? '' : 'noclicks'}`}
            animate={animateState === 'waiting' ? 'visible' : 'hidden'}
            variants={contentVariants}
            onSubmit={handleSubmit}
            initial={{ opacity: 0 }}
            exit="exit"
          >
            <motion.div>
              <Heading element="h1" size="3" className="center c-white">{__('bankid_title_new')}</Heading>
              <motion.div className="s-top-md center">
                <Button
                  className="button cta spacing inverted withicon withicon--medium large"
                  href={`bankid:///`}
                  target="_blank"
                  rel="noopener noreferrer"
                  icon="bankid"
                  external
                >
                  {__('bankid_open')}
                </Button>

                <motion.div className="center" >
                  <Button className="inverted link spacing" onClick={handleAbort}>
                    {__('bankid_abort')}
                  </Button>
                </motion.div>
              </motion.div>
            </motion.div>
          </motion.div>
        </motion.div>
        {/* if error */}
        <motion.div
          className={`loginform__content loginform__content--over ${(animateState === 'error' || animateState === 'notFound') ? '' : 'noclicks'}`}
          animate={(animateState === 'error' || animateState === 'notFound') ? 'visible' : 'hidden'}
          variants={contentVariants}
          onSubmit={handleSubmit}
          initial={{ opacity: 0 }}
          exit="exit"
        >
          <Icon
            // color="c-warning"
            icon="Sign Warning"
            size={24}
            className="s-bottom-l "
          />
          <Heading element="h2" size={3}>
            {animateState === 'error' ? "Inloggning misslyckades" : "Hittade ingen användare"}
          </Heading>
          <p>
            {animateState === 'error'
              ? (
                <>Något gick fel under inloggningen. Vänligen försök igen. Om problemet kvarstår, vänligen kontakta oss via <a href={`mailto:${__('platform_email')}`}>{__('platform_email')}</a> eller på telefonnummer <a href="tel:0317971936">031-797 19 36</a>.</>)
              : "Hittade ingen användare. Vänligen kontrollera att du har skrivit in rätt personnummer och försök igen. Om du inte har ett konto kan du skapa ett här nedan."
            }
          </p>
          {animateState === 'notFound' && (
            <Button
              onClick={() => redirectToSignup()}
            >
              {__('create_account')}
            </Button>
          )}
          <Button
            className="cta spacing inverted"
            onClick={reset}
          >
            Försök igen
          </Button>
        </motion.div>
      </motion.div>
    </div>
  );

};

export default LoginModal;
