import { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';

export const addSpacesTovalue = (rawValue) => {
    let v = '';

    if (typeof rawValue === 'number') {
      rawValue = rawValue.toString();
    }
    // Check if string is 4, add space after every 3rd character from the end
    for (let i = rawValue.length - 1; i > -1; i--) {
      // 1: if the length of the string - the current character is a third - add a space
      // 2: If it's the last character, don't add a space
      if (
        (rawValue.length - i) % 3 === 0 // 1
        && i !== 0 // 2
      ) {
        v = ' ' + rawValue[i] + v;
      } else {
        v = rawValue[i] + v;
      }
    }
    return v;
  };

function useCurrency(initialValue) {
  const [formatedValue, setFormatedValue] = useState(initialValue);

  let timeout = false;

  // Fix carrot position
  // const resetCaret = (input, rawValue, inputValue, v) => {
  //   if (input) {
  //     const numberOfSpaceInserted = v.length - rawValue.length;
  //     const caretPos = input.selectionStart;
  //     const lengthNormalized = rawValue.length - inputValue.length; //value is the value get back from the input field before adding any space. N is the unformated value (without spaces)
  //     const normalizedCaretPos = caretPos + numberOfSpaceInserted + lengthNormalized;
  //     timeout = setTimeout(() => {
  //       input.setSelectionRange(normalizedCaretPos, normalizedCaretPos);
  //     }, 1);
  //   }
  //   // Didn't find any good sollution for this setTimeout.
  //   // Need to be run after the formatedValue in the Input component has been updated.
  //   // Tried using a useEffect here but didn't work.
  //   // Maybe works if this function is set in the actual input component instead
  // };

  // To fix the "cant remove space bug" :
  // check if last value without spaces is the same as new value without spaces,
  // just set this value, and then format again onBlur.
  function formatValue(inputValue, byPass = false) {
    if (inputValue === formatedValue && !byPass) return formatedValue;
    // Check for nulls and one letter
    if (!inputValue || inputValue.toString().length <= 1) {
      setFormatedValue(inputValue);
      return inputValue;
    }

    // Force number values to string
    inputValue = inputValue.toString();

    //Replace all spaces in the string
    const rawValue = inputValue.replace(/ /g, '');

    // Check if it's not a number
    if (isNaN(rawValue) && rawValue !== '-') {
      setFormatedValue(formatedValue);
      return formatedValue;
    }
    if (rawValue === '-') {

      setFormatedValue('-');
      return formatedValue;
    }


    let v = addSpacesTovalue(rawValue);

    // resetCaret(input, rawValue, inputValue, v);

    setFormatedValue(v);

    return v;
  }

  useEffect(() => {
    formatValue(initialValue, true);
    return () => {
      timeout ? clearTimeout(timeout) : undefined;
    };
  }, []);

  return [formatedValue, formatValue];
}

export default useCurrency;


/*
1 SEK
10 SEK
100 SEK
1 000 SEK
10 000 SEK
100 000 SEK
1 000 000 SEK


*/
